/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKLanguage from '../../../model/awsdk_language';
import AWSDKResponse from './awsdk_response';
import GenericParser from '../../parser/generic_parser';

/**
 * Represents a response class for an array of {@link model.AWSDKLanguage|AWSDKLanguage} supported by the sdk.
 * @property {model.AWSDKLanguage[]} languages an array of languages supported by the sdk.
 * @extends model.AWSDKResponse
 */
class AWSDKLanguagesResponse extends AWSDKResponse {
  get languages() {
    return this.__data.languages.map(item => GenericParser.parseObject(item, AWSDKLanguage));
  }
}

export default AWSDKLanguagesResponse;
