/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2019 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import TMCMini from 'awcoresdk/lib/test_my_computer/mini';
import AWCoreSDKTestMyComputerWizard from 'awcoresdk/lib/test_my_computer/Wizard';
import Service from './service';
import Util from '../internal/util/util';
import AWSDKError from '../error/awsdk_error';
import CustomProtocolHandler from '../internal/util/custom_protocol_handler';
import Validator from '../internal/validator/validator';
import AWSDKAppointmentReadinessRequest from '../model/appointment/awsdk_appointment_readiness_request';
import AWSDKConsumer from '../model/consumer/awsdk_consumer';
import AWSDKAppointment from '../model/appointment/awsdk_appointment';
import AWSDKTechCheckURLResponse from '../internal/model/response/awsdk_tech_check_url_response';

/**
 * This service handles everything related to running the test my computer flow
 *
 * @since 2.0.0
 * @hideconstructor
 */
class TestMyComputerService extends Service {
  constructor(props) {
    super(props);
    this.__systemConfiguration = props.systemConfiguration;
    this.__appointmentService = props.appointmentService;
  }

  /**
   * This method will launch the TelehealthVideo Client in 'Test My Computer' mode.<br>
   *
   * <p>
   * NOTE: <em><b>The telehealth client is only supported on Windows.</b></em>
   * </p>
   * Note: In order to use WebRTC, the appropriate server configurations need to be made on the AmWell platform to enable WebRTC and to use the latest supported video platform.
   * @param {model.AWSDKConsumer} [consumer] if provided will run the Test My Computer workflow and store the result for the authenticated consumer
   * @returns {Promise<boolean|error.AWSDKError>} Returns a promise that will be resolved to a {@link boolean} indicating if the
   * telehealth video has launched successfully or will be rejected with an {@link error.AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If authentication is null or not a valid instance of AWSDKAuthentication.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.AWSDKInitializationError|AWSDKErrorCode.AWSDKInitializationError}</td><td>The AWSDK was not initialized correctly.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 2.0.0
   */
  launchTestMyComputerTelehealthVideoClient(consumer) {
    const currentFunction = 'testMyComputerService.launchTestMyComputerTelehealthVideoClient';
    this.__logger.debug(`${currentFunction} Started`);

    const propertyName = this.__getTelehealthClientPropertyName(consumer);

    let launchUri;
    if (consumer) {
      if (!(consumer instanceof AWSDKConsumer)) {
        const error = AWSDKError.AWSDKIllegalArgument('consumer must be of type AWSDKConsumer');
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }

      launchUri = Util.findNamedLinkUrl(consumer.links, propertyName);
      if (!Validator.isValidString(launchUri)) {
        const error = AWSDKError.AWSDKInternalError(`consumer does not have a valid "${propertyName}" link entry`);
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }
    } else {
      launchUri = this.__systemConfiguration[propertyName];

      if (!Validator.isValidString(launchUri)) {
        const error = AWSDKError.AWSDKInitializationError(`${propertyName} missing from systemConfiguration`);
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }
    }

    return (() => {
      if (this.__systemConfiguration.webRTCEnabled && consumer) {
        return this.__getAuthenticatedElectronURL(consumer);
      }
      return Promise.resolve(launchUri);
    })()
      .then(uri => (
        new Promise((resolve, reject) => {
          const handler = new CustomProtocolHandler(this.__config.launchTelehealthVideoClientTimeout);
          handler.launchUri(uri, reject, resolve);
        })
      ))
      .then(() => {
        this.__logger.debug(currentFunction, 'Completed - launched ok returned true');
        return true;
      })
      .catch(() => {
        this.__logger.debug(currentFunction, 'Completed - not launched returned false');
        return false;
      });
  }

  /**
   * Gets a new instance of the Test My Computer wizard.<br>
   *
   * Note: In order to use WebRTC, the appropriate server configurations need to be made on the AmWell platform to enable WebRTC and to use the latest supported video platform.
   * @param {Object} config The configuration used to create the wizard.
   * @param {HTMLElement} config.container The DOM node that the wizard will attach itself to
   * @param {Function} config.doneCallback The callback to be invoked with the test results when the user has completed the wizard
   * @param {model.AWSDKConsumer=} config.consumer The consumer to run the wizard for, upon completion their appointment readiness will be updated if SystemConfiguration.appointmentReadinessEnabled is TRUE
   * @param {model.AWSDKAppointment=} config.appointment The appointment to run the wizard for, upon completion the readiness for the appointment will be updated
   * @returns {Object} returns an instance of the wizard which can be started with wizard.start() and stopped with wizard.stop()
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>The provided config or has a null container element.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.AWSDKInitializationError|AWSDKErrorCode.AWSDKInitializationError}</td><td>The AWSDK was not initialized correctly.</td></tr>
   * </tbody>
   * </table>
   */
  getTestMyComputerWizard(config) {
    const currentFunction = 'testMyComputerService.getTestMyComputerWizard';
    if (!this.__systemConfiguration) {
      const error = AWSDKError.AWSDKInitializationError();
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    }
    if (!config) {
      const error = AWSDKError.AWSDKIllegalArgument('config must not be null or undefined');
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    }
    if (!(config.container instanceof Element)) {
      const error = AWSDKError.AWSDKIllegalArgument('config.container must be a valid html Node');
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    }
    if (config.consumer && !(config.consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('config.consumer must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    }
    if (config.appointment && !(config.appointment instanceof AWSDKAppointment)) {
      const error = AWSDKError.AWSDKIllegalArgument('config.appointment must be an instance of AWSDKAppointment');
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    }

    // Wrap the user's callback with our own that maps the result to the cwsdk's AppointmentReadinessRequest obj
    const userProvidedCallback = config.doneCallback || (result => this.__logger.debug('No TMC callback provided: ', result));
    config.doneCallback = (result) => {
      const appointmentReadinessRequest = new AWSDKAppointmentReadinessRequest();
      Object.assign(appointmentReadinessRequest, result);

      if (config.appointment) {
        appointmentReadinessRequest.appointment = config.appointment;
      }

      if (config.consumer && this.__systemConfiguration.appointmentReadinessEnabled) {
        this.__appointmentService.updateReadiness(config.consumer, appointmentReadinessRequest)
          .then((readiness) => {
            userProvidedCallback(readiness);
          })
          .catch((error) => {
            userProvidedCallback(error);
          });
      } else {
        userProvidedCallback(appointmentReadinessRequest);
      }
    };

    config.speedTestConfig = this.__systemConfiguration.speedTestConfiguration;
    config.systemConfiguration = this.__systemConfiguration;
    return new AWCoreSDKTestMyComputerWizard(config);
  }

  /**
   * Get an instance of a TestMyComputerMini component. Register an `onStatus` callback, and
   * call `start` when ready. Call `stop` when tearing down.
   *
   * @private
   * @param {object} props A configuration object to define callbacks
   * @param {function=} props.onStatus fired for all device permission states. Called with an object containing `mic` and `camera` members.  The absence of ` mic.error` or `camera.error` in the state indicates success.
   * @param {HTMLElement} props.container The HTMLElement that will host the TestMyComputerMini DOM
   * @param {string} locale A locale to use for messages, defaults to built-in `en-US` strings
   * @param {object} messages Individual message overrides, to align with i18n keys.  Trumps `locale` messages detected.
   * @returns {TMCMini} the MiniWizard component to start/stop
   * @since 2.6.0
   */
  getTestMyComputerMiniWizard(props) {
    const wizard = new TMCMini(props);
    return wizard;
  }

  /**
   * Returns the relevant WINDOWS Telehealth Video installation url
   * @since 2.0.0
   * @type {string}
   */
  get testMyComputerTelehealthVideoClientInstallUrl() {
    return this.__systemConfiguration.electronDownloadUrl;
  }

  /**
   * Utility function to figure out the telehealth client test URL link name
   * @private
   */
  __getTelehealthClientPropertyName(consumer) {
    if (consumer) {
      return 'techCheck';
    }
    return 'electronVideoTestUri';
  }

  /**
   * Utility function to figure out the telehealth client test URL for an authenticated consumer
   * @private
   */
  __getAuthenticatedElectronURL(consumer) {
    const currentFunction = 'TestMyComputerService.__getAuthenticatedElectronURL';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'techCheck');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a "techCheck" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKTechCheckURLResponse)
      .then(techCheckUrlResponse => techCheckUrlResponse.techCheckURL.URL)
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }
}

export default TestMyComputerService;
