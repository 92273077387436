/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2019 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKConnectionType.
 * @readonly
 * @enum {string}
 * @since 2.0.0
 */
const AWSDKConnectionType = {
  /**
   * None (Default).
   */
  NONE: 'NONE',

  /**
   * Mobile device connection.
   */
  MOBILE: 'MOBILE',

  /**
   * Multiple sessions were detected.
   */
  MULTIPLE: 'MULTIPLE',

  /**
   * Only Phone.
   */
  PHONE: 'PHONE',

  /**
   * Only Web. A visit may be set to WEB_AND_PHONE once a consumer and provider enter a visit
   * and add phone to the conversation
   */
  WEB: 'WEB',

  /**
   * Web video primary with Phone secondary. (Web-Add-Phone)
   */
  WEB_AND_PHONE: 'WEB_AND_PHONE',

  /**
   * web SDK connection.
   */
  WEB_SDK: 'WEB_SDK',

  /**
   * web SDK primary connection with Phone secondary. (Web-Add-Phone)
   */
  WEB_SDK_AND_PHONE: 'WEB_SDK_AND_PHONE',

  /**
   * Only Kiosk.
   */
  KIOSK: 'KIOSK',

  /**
   * Only Telemed Tablet.
   * */
  TELEMED: 'TELEMED',
};
Object.freeze(AWSDKConnectionType);
export default AWSDKConnectionType;

