/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * The base class for all data objects in AWSDK
 */
class AWSDKDataObject {
  constructor(data = {}) {
    this.__data = data;
  }

  toString() {
    const dataString = JSON.stringify(this.__data);
    return dataString;
  }
}

export default AWSDKDataObject;
