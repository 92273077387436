/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKAuthentication from './../model/authentication/awsdk_authentication';
import AWSDKConsumer from '../model/consumer/awsdk_consumer';
import AWSDKError from './../error/awsdk_error';
import AWSDKRecoverEmailResponse from './../internal/model/response/awsdk_recover_email_response';
import AWSDKResponse from './../internal/model/response/awsdk_response';
import Service from './service';
import Validator from '../internal/validator/validator';
import Utils from '../internal/util/util';
import GenericParser from '../internal/parser/generic_parser';
import AWSDKTwoFactorRequiredAction from '../model/authentication/awsdk_two_factor_required_action';
import AWSDKDisclaimerResponse from '../internal/model/response/awsdk_disclaimer_response';

/**
 * This service handles everything related to authentication of a {@link model.AWSDKConsumer|AWSDKConsumer}.
 * <br>Should be obtained from {@link awsdk.AWSDK#authenticationService|AWSDK.authenticationService}
 * @since 1.0.0
 * @hideconstructor
 * @extends service.Service
 */
class AuthenticationService extends Service {
  /**
   * Authenticates a consumer with the Amwell Home Platform using their credentials.<br>
   * Receiving a successful response does not guarantee a fully authenticated consumer. The following fields must be checked:<br>
   * <ul>
   *   <li>{@link model.AWSDKAuthentication#fullyAuthenticated will be false if the consumer is not fully authenticated and subsequent steps need to be taken.
   *   <li>{@link model.AWSDKAuthentication#twoFactorInfo|AWSDKAuthentication.twoFactorInfo} provides information about two-factor authentication.
   *     <ul>
   *       <li>If {@link model.AWSDKTwoFactorInfo#setupNeeded|AWSDKTwoFactorInfo.setupNeeded} is true,
   *       calls to {@link AuthenticationService#setupTwoFactorAuthentication|AuthenticationService.setupTwoFactorAuthentication},
   *       then {@link AuthenticationService#validateTwoFactorAuthenticationCode|AuthenticationService.validateTwoFactorAuthenticationCode} are required.
   *       <li>If {@link model.AWSDKTwoFactorInfo#loginNeeded|AWSDKTwoFactorInfo.loginNeeded} is true,
   *       calls to {@link AuthenticationService#sendTwoFactorAuthenticationCode|AuthenticationService.sendTwoFactorAuthenticationCode},
   *       then {@link AuthenticationService#validateTwoFactorAuthenticationCode|AuthenticationService.validateTwoFactorAuthenticationCode} are required
   *     </ul>
   *   <li>If {@link model.AWSDKAuthentication#needsToCompleteRegistration|AWSDKAuthentication.needsToCompleteRegistration} is true,
   *   a call to {@link ConsumerService#completeRegistration|ConsumerService.completeRegistration} is required.
   * </ul>
   *
   * @param {String} username - the username
   * @param {String} password - the password
   * @param {String} [options.consumerAuthKey] - the optional consumerAuthKey
   * @param {String} [options.twoFactorTrustedDeviceToken] - the optional token from trusted device to bypass two-factor authentication
   * @returns {Promise<model.AWSDKAuthentication|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKAuthentication|AWSDKAuthentication} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationRequiredParameterMissing|AWSDKErrorCode.validationRequiredParameterMissing}</td><td>Missing parameter.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.authenticationAccessDenied|AWSDKErrorCode.authenticationAccessDenied}</td><td>The credentials didn't match a user on the telehealth platform.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.updateConsumerAuthKeyFailed|AWSDKErrorCode.updateConsumerAuthKeyFailed}</td><td>Updating the consumer auth key failed.</td></tr>
   * </table>
   * @since 3.3.0
   */
  authenticate(username, password, options) {
    const currentFunction = 'authenticationService.authenticate';
    this.__logger.debug(currentFunction, 'Started', username, password, options);
    const link = this.findNamedLink(this.__links, 'userAuthWithCredentials');
    let sdkUserAuthKey;
    let twoFactorTrustDeviceToken;
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "userAuthWithCredentials" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(username)) {
      const error = AWSDKError.AWSDKValidationRequiredParameterMissing('username');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(password)) {
      const error = AWSDKError.AWSDKValidationRequiredParameterMissing('password');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (options) {
      if (!(options instanceof Object)) {
        const error = AWSDKError.AWSDKIllegalArgument('options must be an object', 'options');
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }
      if (options.consumerAuthKey) {
        if (Validator.isValidString(options.consumerAuthKey)) {
          sdkUserAuthKey = options.consumerAuthKey;
        } else {
          const error = AWSDKError.AWSDKIllegalArgument('options.consumerAuthKey must be a valid string', 'options.consumerAuthKey');
          this.__logger.error(currentFunction, 'error', error);
          return Promise.reject(error);
        }
      }
      if (options.twoFactorTrustedDeviceToken) {
        if (Validator.isValidString(options.twoFactorTrustedDeviceToken)) {
          twoFactorTrustDeviceToken = options.twoFactorTrustedDeviceToken;
        } else {
          const error = AWSDKError.AWSDKIllegalArgument('options.twoFactorTrustedDeviceToken must be a valid string\', \'options.twoFactorTrustedDeviceToken\'');
          this.__logger.error(currentFunction, 'error', error);
          return Promise.reject(error);
        }
      }
    }
    const requestOptions = this.generateOptions('POST', link.url, false);
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.auth = this.getSdkAuth();
    requestOptions.body = JSON.stringify({
      username,
      password,
      sdkUserAuthKey,
      sdkApiKey: this.__config.sdkApiKey,
      twoFactorTrustDeviceToken,
    });

    return this.executeRequest(requestOptions, AWSDKAuthentication)
      .then((authentication) => {
        this.__logger.debug(currentFunction, 'Got response', authentication);
        if (authentication) {
          this.__serverLogger.logToServer('info',
            'Consumer {0} has successfully authenticated using Consumer Web SDK',
            [
              authentication.getServerLogParam(),
            ]);
        }
        this.addUserAuthEntry(authentication);
        this.__logger.info(currentFunction, 'Complete');
        return authentication;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Initiate two-factor authentication setup if required for this login.
   * A verification code will be sent to the provided phone number.
   * To validate the verification code, use {@link AuthenticationService#validateTwoFactorAuthenticationCode}.<br>
   *
   * @param {model.AWSDKAuthentication} authentication - the partially authenticated {@link model.AWSDKAuthentication|AWSDKAuthentication}
   * @param {object} options - the options for two-factor authentication setup
   * @param {number=} options.phoneNumber - the phone number. The value of options.phoneNumber is not needed and will be ignored if the user is opting out
   * @returns {Promise<model.AWSDKAuthentication|error.AWSDKError>} Returns a promise that will be resolved to a fully
   * or partially authenticated {@Link model.model.AWSDKAuthentication|AWSDKAuthentication}
   * or will be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing argument or argument is not the correct type.</td>
   * <tr><td>{@link error.AWSDKErrorCode.validationRequiredParameterMissing|AWSDKErrorCode.validationRequiredParameterMissing}</td><td>Missing parameter.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 3.3.0
   */
  setupTwoFactorAuthentication(authentication, options) {
    const currentFunction = 'authenticationService.setupTwoFactorAuthentication';
    this.__logger.debug(currentFunction, 'Started', options);

    if (!(authentication instanceof AWSDKAuthentication)) {
      const error = AWSDKError.AWSDKIllegalArgument('authentication is null or not an instance of AWSDKAuthentication');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    if (authentication.fullyAuthenticated) {
      const error = AWSDKError.AWSDKValidationError('User is already fully authenticated');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    if (authentication.twoFactorInfo.requiredAction !== AWSDKTwoFactorRequiredAction.SETUP) {
      const error = AWSDKError.AWSDKValidationError('User does not require two-factor authentication setup');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    const link = this.findNamedLink(authentication.twoFactorInfo.links, 'twoFactorAuthSetup');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('twoFactorInfo does not have a valid "twoFactorAuthSetup" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    if (!(options instanceof Object)) {
      const error = AWSDKError.AWSDKIllegalArgument('options must be an object', 'options');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    if (!Validator.isPhoneNumberValid(options.phoneNumber)) {
      const error = AWSDKError.AWSDKValidationRequiredParameterMissing('phoneNumber');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    const requestOptions = this.generateOptions('POST', link.url, false);
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.auth = this.getUserAuthFrom(authentication);
    requestOptions.body = JSON.stringify({
      phoneNumber: options.phoneNumber,
      sdkApiKey: this.__config.sdkApiKey,
    });

    return this.executeRequest(requestOptions, AWSDKAuthentication)
        .then((updatedAuthentication) => {
          this.__logger.debug(currentFunction, 'Got response', updatedAuthentication);
          this.addUserAuthEntry(updatedAuthentication);
          return updatedAuthentication;
        })
        .catch((error) => {
          this.__logger.error(currentFunction, 'Error', error);
          throw error;
        });
  }

  /**
   * Send two-factor authentication code to the phone number previously registered with two-factor authentication.<br>
   * Invoking this method again will result in a new code being sent, which will invalidate any previously sent codes.<br>
   * To validate the verification code, use {@link AuthenticationService#validateTwoFactorAuthenticationCode}.
   *
   * @param {model.AWSDKAuthentication} authentication - the partially authenticated {@link model.AWSDKAuthentication|AWSDKAuthentication}
   * @returns {Promise<model.AWSDKAuthentication|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKAuthentication|AWSDKAuthentication} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing argument or argument is not the correct type.</td>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 3.3.0
   */
  sendTwoFactorAuthenticationCode(authentication) {
    const currentFunction = 'authenticationService.sendTwoFactorAuthenticationCode';
    this.__logger.debug(currentFunction, 'Started');

    if (!(authentication instanceof AWSDKAuthentication)) {
      const error = AWSDKError.AWSDKIllegalArgument('authentication is null or not an instance of AWSDKAuthentication');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    if (authentication.fullyAuthenticated) {
      const error = AWSDKError.AWSDKValidationError('User is already fully authenticated');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    if (authentication.twoFactorInfo.requiredAction === AWSDKTwoFactorRequiredAction.NONE) {
      const error = AWSDKError.AWSDKValidationError('User does not require two-factor authentication');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    // this can be used to resend a code in either the setup or login flows
    const linkName = (authentication.twoFactorInfo.requiredAction === AWSDKTwoFactorRequiredAction.SETUP) ? 'twoFactorAuthSetup' : 'twoFactorAuthLogin';
    const link = this.findNamedLink(authentication.twoFactorInfo.links, linkName);
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError(`authentication.twoFactorInfo does not have a valid ${linkName} link entry`);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    const requestOptions = this.generateOptions('POST', link.url, false);
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.auth = this.getUserAuthFrom(authentication);
    requestOptions.body = JSON.stringify({
      sdkApiKey: this.__config.sdkApiKey,
    });

    return this.executeRequest(requestOptions, AWSDKAuthentication)
      .then((updatedAuthentication) => {
        this.__logger.debug(currentFunction, 'Got response', updatedAuthentication);
        return updatedAuthentication;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Validate the verification code sent by {@link AuthenticationService#sendTwoFactorAuthenticationCode} or {@link AuthenticationService#setupTwoFactorAuthentication}.
   *
   * @param {model.AWSDKAuthentication} authentication - the partially authenticated {@link model.AWSDKAuthentication|AWSDKAuthentication}
   * @param {object} options parameter options
   * @param {number} options.verificationCode six digit verification code
   * @param {boolean=} options.rememberThisDevice true to remember this device as trusted. The twoFactorTrustedDeviceToken will be sent back in authentication response in case of success.
   * The token needs to be stored on the device securely and provided along with login credentials in order to bypass two-factor authentication next time
   * @returns {Promise<model.AWSDKAuthentication|error.AWSDKError>} Returns a promise that will be resolved to a fully authenticated {@link model.AWSDKAuthentication|AWSDKAuthentication} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing argument or argument is not the correct type.</td>
   * <tr><td>{@link error.AWSDKErrorCode.validationRequiredParameterMissing|AWSDKErrorCode.validationRequiredParameterMissing}</td><td>Missing parameter.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.twoFactorAuthenticationInvalidCode|AWSDKErrorCode.twoFactorAuthenticationInvalidCode}</td><td>The two-factor code is invalid.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.twoFactorAuthenticationExpiredCode|AWSDKErrorCode.twoFactorAuthenticationExpiredCode}</td><td>The two-factor code has expired.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.twoFactorAuthenticationMaxRetryReached|AWSDKErrorCode.twoFactorAuthenticationMaxRetryReached}</td><td>The consumer has reached the limit for incorrect two-factor code validation attempts.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 3.3.0
   */
  validateTwoFactorAuthenticationCode(authentication, options) {
    const currentFunction = 'authenticationService.validateTwoFactorAuthenticationCode';
    this.__logger.debug(currentFunction, 'Started', options);

    if (!(authentication instanceof AWSDKAuthentication)) {
      const error = AWSDKError.AWSDKIllegalArgument('authentication is null or not an instance of AWSDKAuthentication');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    if (authentication.fullyAuthenticated) {
      const error = AWSDKError.AWSDKValidationError('User is already fully authenticated');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    if (authentication.twoFactorInfo.requiredAction === AWSDKTwoFactorRequiredAction.NONE) {
      const error = AWSDKError.AWSDKValidationError('User does not require two-factor authentication');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    const link = this.findNamedLink(authentication.twoFactorInfo.links, 'validateVerificationCode');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('authentication.twoFactorInfo does not have a valid "validateVerificationCode" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    if (!(options instanceof Object)) {
      const error = AWSDKError.AWSDKValidationRequiredParameterMissing('options');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    if (!Validator.isCVV(options.verificationCode, 6)) {
      const error = AWSDKError.AWSDKValidationError('verificationCode', 'param "verificationCode" must be a 6 digit number');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    const requestOptions = this.generateOptions('PUT', link.url, false);
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.auth = this.getUserAuthFrom(authentication);
    requestOptions.body = JSON.stringify({
      verificationCode: options.verificationCode,
      rememberThisDevice: GenericParser.parseBoolean(options.rememberThisDevice),
      sdkApiKey: this.__config.sdkApiKey,
    });

    return this.executeRequest(requestOptions, AWSDKAuthentication)
      .then((updatedAuthentication) => {
        this.__logger.debug(currentFunction, 'Got response', updatedAuthentication);
        if (updatedAuthentication) {
          this.__serverLogger.logToServer('info',
            'Consumer {0} has successfully two factor authenticated using Consumer Web SDK',
            [
              updatedAuthentication.getServerLogParam(),
            ]);
        }
        this.addUserAuthEntry(updatedAuthentication);
        this.__logger.info(currentFunction, 'Complete');
        return updatedAuthentication;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Opts out a consumer from enrolling into two-factor authentication.<br>
   * In order for the method to succeed, the two-factor authentication has to be configured as optional, <br>
   * and the user should not be already set up with two-factor authentication. <br>
   * As the result the two-factor authentication will be switched off for this user.
   *
   * @param {model.AWSDKAuthentication} authentication - the partially authenticated {@link model.AWSDKAuthentication|AWSDKAuthentication}
   * @returns {Promise<model.AWSDKAuthentication|error.AWSDKError>} Returns a promise that will be resolved to a fully
   * authenticated {@Link model.model.AWSDKAuthentication|AWSDKAuthentication}
   * or will be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing argument or argument is not the correct type.</td>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 3.3.0
   */
  optOutTwoFactorAuthentication(authentication) {
    const currentFunction = 'authenticationService.optOutTwoFactorAuthentication';
    this.__logger.debug(currentFunction, 'Started');

    if (!(authentication instanceof AWSDKAuthentication)) {
      const error = AWSDKError.AWSDKIllegalArgument('authentication is null or not an instance of AWSDKAuthentication');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    if (authentication.fullyAuthenticated || authentication.twoFactorInfo.requiredAction !== AWSDKTwoFactorRequiredAction.SETUP) {
      const error = AWSDKError.AWSDKValidationError('the user has been already set with two-factor authentication up and can\'t be opted out');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    const link = this.findNamedLink(authentication.twoFactorInfo.links, 'twoFactorAuthSetup');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('twoFactorInfo does not have a valid "twoFactorAuthSetup" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    const requestOptions = this.generateOptions('POST', link.url, false);
    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.auth = this.getUserAuthFrom(authentication);
    requestOptions.body = JSON.stringify({
      optOut: true,
      sdkApiKey: this.__config.sdkApiKey,
    });

    return this.executeRequest(requestOptions, AWSDKAuthentication)
        .then((updatedAuthentication) => {
          this.__logger.debug(currentFunction, 'Got response', updatedAuthentication);
          this.addUserAuthEntry(updatedAuthentication);
          return updatedAuthentication;
        })
        .catch((error) => {
          this.__logger.error(currentFunction, 'Error', error);
          throw error;
        });
  }

  /**
   * Authenticates a consumer with American Well's Telehealth Platform using their consumerAuthKey.
   *
   * @param {String} token - the security token used for authenticating
   * @returns {Promise<model.AWSDKAuthentication|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKAuthentication|AWSDKAuthentication} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationRequiredParameterMissing|AWSDKErrorCode.validationRequiredParameterMissing}</td><td>Missing parameter.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.authenticationAccessDenied|AWSDKErrorCode.authenticationAccessDenied}</td><td>The credentials didn't match a user on the telehealth platform.</td></tr>
   * </table>
   * @since 1.0.0
   */
  authenticateMutualAuthWithToken(token) {
    const currentFunction = 'authenticationService.authenticateMutualAuthWithToken';
    this.__logger.debug(currentFunction, 'Started', token);
    const link = this.findNamedLink(this.__links, 'mutualUserAuthWithKey');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "mutualUserAuthWithKey" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(token)) {
      const error = AWSDKError.AWSDKValidationRequiredParameterMissing('token');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url);
    options.auth = this.getSdkAuth();
    options.form.set('externalAuthToken', token);
    options.form.set('sdkApiKey', this.__config.sdkApiKey);
    return this.executeRequest(options, AWSDKAuthentication)
      .then((authentication) => {
        this.__logger.debug(currentFunction, 'Got response', authentication);
        if (authentication) {
          this.__serverLogger.logToServer('info',
            'Consumer {0} has successfully authenticated using Consumer Web SDK',
            [
              authentication.getServerLogParam(),
            ]);
        }
        this.addUserAuthEntry(authentication);
        this.__logger.info(currentFunction, 'Complete');
        return authentication;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Clear the stored authentication information for last authenticated user. <br>
   * this essentially "logs out" the user<br>
   * this is a client-side method, there is no server communication.<br>
   *
   * @param {model.AWSDKConsumer} consumer the consumer to log out
   * @throws {error.AWSDKError} if consumer is null or not an instance of {@link model.AWSDKConsumer|AWSDKConsumer} errorCode will be {@link error.AWSDKErrorCode.IllegalArgument|AWSDKErrorCode.IllegalArgument}
   * @since 1.0.0
   */
  clearAuthentication(consumer) {
    const currentFunction = 'authenticationService.clearAuthentication';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('Param "consumer" must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    this.removeUserAuthEntry(consumer);
    this.__logger.info(currentFunction, 'Complete');
  }
  /**
   * Recover the consumer's user name.<br>
   * if the username does not match the consumer's email address, an email with the username will be sent and a status of USERNAME_EMAILED will be returned.<br>
   * if the username matches the consumer's email address, a status of EMAIL_RECOVERED with a redacted email address will be returned. <br>
   * if the given lastName and dob do NOT correspond to an existing consumer's account, a status of USERNAME_EMAILED will still be returned, even though<br>
   * no email is actually sent - no indication is given as to whether a given consumer account exists.<br>
   * @param {String} lastName the consumer's last name
   * @param {Date} dob the consumer's date of birth
   * @returns {Promise<model.AWSDKRecoverEmail|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKRecoverEmail} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If lastName or dob are undefined or dob not an instance of Date</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If lastName or dob are invalid.</td></tr>
   * </table>
   * @since 1.1.0
   */
  recoverEmail(lastName, dob) {
    const currentFunction = 'authenticationService.recoverEmail';
    this.__logger.debug(currentFunction, 'Started', lastName, dob);
    const errors = [];
    if (lastName === undefined) {
      const error = AWSDKError.AWSDKIllegalArgument('lastName is undefined');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (dob === undefined) {
      const error = AWSDKError.AWSDKIllegalArgument('dob is undefined');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (dob != null && !(dob instanceof Date)) {
      const error = AWSDKError.AWSDKIllegalArgument('dob is not an instance of Date');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(lastName)) {
      errors.push(AWSDKError.AWSDKFieldValidationError(null, 'lastName', 'field required', 'set to non-empty value'));
    }
    if (dob == null) {
      errors.push(AWSDKError.AWSDKFieldValidationError(null, 'dob', 'field required', 'set to non-empty value'));
    }
    if (errors.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errors);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'recoverEmail');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "recoverEmail" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    options.form.set('lastName', lastName);
    options.form.set('dob', Utils.formatISODate(dob));
    return this.executeRequest(options, AWSDKRecoverEmailResponse)
      .then((recoverEmailResponse) => {
        this.__logger.debug(currentFunction, 'Got response', recoverEmailResponse);
        this.__logger.info(currentFunction, 'Complete');
        return recoverEmailResponse.recoverEmail;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * Send an email with instructions to reset the consumer's password.<br>
   * Returns true even if these consumer details did not actually match an existing consumer account.<br>
   * No indication is given as to whether a given consumer account exists.<br>
   * @param {String} email the consumer's email
   * @param {String} lastName the consumer's last name
   * @param {Date} dob the consumer's date of birth
   * @returns {Promise<boolean|error.AWSDKError>} Returns a promise that will be resolved to a boolean  or will be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If email, lastName or dob are undefined or dob not an instance of Date</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If email, lastName or dob are invalid.</td></tr>
   * </table>
   * @since 1.1.0
   */
  requestPasswordReset(email, lastName, dob) {
    const currentFunction = 'authenticationService.requestPasswordReset';
    this.__logger.debug(currentFunction, 'Started', email, lastName, dob);
    const errors = [];
    if (email === undefined) {
      const error = AWSDKError.AWSDKIllegalArgument('email is undefined');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (lastName === undefined) {
      const error = AWSDKError.AWSDKIllegalArgument('lastName is undefined');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (dob === undefined) {
      const error = AWSDKError.AWSDKIllegalArgument('dob is undefined');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (dob != null && !(dob instanceof Date)) {
      const error = AWSDKError.AWSDKIllegalArgument('dob is not an instance of Date');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isEmailValid(email)) {
      errors.push(AWSDKError.AWSDKFieldValidationError(null, 'email', 'invalid format', 'See valid format in docs'));
    }
    if (!Validator.isValidString(lastName)) {
      errors.push(AWSDKError.AWSDKFieldValidationError(null, 'lastName', 'field required', 'set to non-empty value'));
    }
    if (dob == null) {
      errors.push(AWSDKError.AWSDKFieldValidationError(null, 'dob', 'field required', 'set to non-empty value'));
    }
    if (errors.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errors);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'resetPassword');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "resetPassword" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getSdkAuth();
    options.form.set('email', email);
    options.form.set('lastName', lastName);
    options.form.set('dob', Utils.formatISODate(dob));
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.__logger.info(currentFunction, 'Complete');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * Update the consumer's password.<br>
   * @param {String} accountId the consumer's account id
   * @param {String} token the password reset token
   * @param {String} password the new password
   * @returns {Promise<boolean|error.AWSDKError>} Returns a promise that will be resolved to a boolean  or will be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotFound|AWSDKErrorCode.consumerNotFound}</td><td>Consumer not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If accountId, token or password are undefined or null</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.invalidPassword|AWSDKErrorCode.invalidPassword}</td><td>The password is invalid</td</tr>
   * </table>
   * @since 1.1.0
   */
  updatePassword(accountId, token, password) {
    const currentFunction = 'authenticationService.updatePassword';
    this.__logger.debug(currentFunction, 'Started', accountId, token, password);
    if (accountId == null) {
      const error = AWSDKError.AWSDKIllegalArgument('accountId is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (token == null) {
      const error = AWSDKError.AWSDKIllegalArgument('token is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (password == null) {
      const error = AWSDKError.AWSDKIllegalArgument('password is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(password)) {
      const error = AWSDKError.AWSDKInvalidPassword();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'updatePassword');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "updatePassword" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getSdkAuth();
    options.form.set('accountId', accountId);
    options.form.set('token', token);
    options.form.set('password', password);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.__logger.info(currentFunction, 'Complete');
        return true;
      })
      .catch((error) => {
        throw error;
      });
  }

  /**
   * Retrieves the default disclaimer, which includes the Terms of Use and Notice of Privacy Policies, formatted as an HTML snippet.<br>
   * @param {model.AWSDKAuthentication} authentication The authentication
   * @returns {Promise<model.AWSDKDisclaimer|error.AWSDKError>} Returns a promise that will be resolved to a {@link model.AWSDKDisclaimer|AWSDKDisclaimer} or will be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If authentication is undefined or null</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>When the consumer is not authenticated.</td></tr>
   * </table>
   * @since 3.3.0
   */
  getDisclaimer(authentication) {
    const currentFunction = 'AuthenticationService.getDisclaimer';
    this.__logger.debug(currentFunction, 'Started', authentication);
    if (!(authentication instanceof AWSDKAuthentication)) {
      const error = AWSDKError.AWSDKIllegalArgument('authentication is not an instance of AWSDKAuthentication');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const link = this.findNamedLink(authentication.authenticationLinks, 'anonymousLegalNotice');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('authentication does not have a valid "anonymousLegalNotice" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const request = this.generateOptions('GET', link.url, false);
    request.auth = this.getUserAuthFrom(authentication);
    if (!request.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }

    return this.executeRequest(request, AWSDKDisclaimerResponse)
      .then((response) => {
        this.__logger.trace(currentFunction, 'Got response', response);
        this.__logger.debug(currentFunction, 'Finished');
        return response.legalNotice;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
}

export default AuthenticationService;
