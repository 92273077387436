/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import URLSearchParams from 'url-search-params';

/**
 * This encapsulates URLSearchParams and overrides append and set
 * to ignore values that are undefined or null.
 * @since 1.0.0
 * @hideconstructor
 */
class Form {
  constructor(...args) {
    this.__params = new URLSearchParams(...args);
  }
  /**
   * Override to not call append when value is undefined or null
   */
  append(name, value) {
    if (value != null) {
      this.__params.append(name, value);
    }
  }
  delete(name) { this.__params.delete(name); }
  entries(pointer) { return this.__params.entries(pointer); }
  get(name) { return this.__params.get(name); }
  getAll(name) { return this.__params.getAll(name); }
  has(name) { return this.__params.has(name); }
  keys() { return this.__params.keys(); }
  /**
   * Override to not call set when value is undefined or null
   */
  set(name, value) {
    if (value != null) {
      this.__params.set(name, value);
    }
  }
  values(pointer) { return this.__params.values(pointer); }
  toString() { return this.__params.toString(); }
  /**
   * return the URLSearchParams
   */
  get params() {
    return this.__params;
  }
}

export default Form;
