/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKConferenceStatus.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKConferenceStatus = {
  /**
   * Waiting.
   */
  Waiting: 'Waiting',
  /**
   * Started.
   */
  Started: 'Started',
  /**
   * Ended.
   */
  Ended: 'Ended',
  /**
   * Cancelled.
   */
  Cancelled: 'Cancelled',
  /**
   * Disabled.
   */
  Disabled: 'Disabled',
};
Object.freeze(AWSDKConferenceStatus);
export default AWSDKConferenceStatus;
