/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from './awsdk_dataobject';
import AWSDKState from './awsdk_state';
import AWSDKError from '../error/awsdk_error';
import GenericParser from '../internal/parser/generic_parser';


/**
 * Contains requisite information for updating an {@link model.AWSDKAddress}
 * @property {String} address1 the first line of the address
 * @property {String} address2 the second line of the address
 * @property {String} city  the city name
 * @property {model.AWSDKState} geographicalState the actual underlying {@link model.AWSDKState} of the address
 * @property {String} zipCode  the zip (postal) code for this physical location
 * @extends model.AWSDKDataObject
 */
class AWSDKAddressUpdate extends AWSDKDataObject {
  constructor(address1, address2, city, geographicalState, zipCode) {
    super();
    this.__data.address1 = address1;
    this.__data.address2 = address2;
    this.__data.city = city;
    if (geographicalState && !(geographicalState instanceof AWSDKState)) {
      throw AWSDKError.AWSDKIllegalArgument('geographicalState is not instance of AWSDKState');
    }
    this.__data.state = geographicalState;
    this.__data.zipCode = zipCode;
  }

  get stateCode() {
    return this.__data.state ? this.__data.state.code : null;
  }

  get geographicalState() {
    return this.__data.state ? GenericParser.parseObject(this.__data.state, AWSDKState) : null;
  }

  get countryCode() {
    return this.__data.state ? this.__data.state.countryCode : null;
  }

  get zipCode() {
    return this.__data.zipCode;
  }

  get address1() {
    return this.__data.address1;
  }

  get address2() {
    return this.__data.address2;
  }

  get city() {
    return this.__data.city;
  }

  set geographicalState(geographicalState) {
    if (!(geographicalState instanceof AWSDKState)) {
      throw AWSDKError.AWSDKIllegalArgument('geographicalState is not instance of AWSDKState');
    }
    this.__data.state = geographicalState;
  }

  set address1(address1) {
    this.__data.address1 = address1;
  }

  set address2(address2) {
    this.__data.address2 = address2;
  }

  set city(city) {
    this.__data.city = city;
  }

  set zipCode(zipCode) {
    this.__data.zipCode = zipCode;
  }
}

export default AWSDKAddressUpdate;
