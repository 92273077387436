/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import deprecated from 'awcoresdk/lib/util/deprecated';
import defaultMessages from 'awcoresdk/lib/i18n';
import AWCoreSDKVideoConsole from 'awcoresdk/lib/external_video_console';
import AWCoreSDKVideoMetrics from 'awcoresdk/lib/video_metrics';
import AWCoreSDKVideoParticipant from 'awcoresdk/lib/participant';
import AWCoreSDKVideoParticipantType from 'awcoresdk/lib/awcoresdk_video_participant_type';
import AWCoreSDKVisitConfiguration from 'awcoresdk/lib/visit_configuration';
import AWCoreSDKVideoConfiguration from 'awcoresdk/lib/video_configuration';
import AWCoreSDKVendor from 'awcoresdk/lib/awcoresdk_vendor';
import AWCoreSDKBaseEventHandler from 'awcoresdk/lib/awcoresdk_base_event_handler';
import version from '../version';
import Service from './service';
import AWSDKError from './../error/awsdk_error';
import AWSDKErrorCode from './../error/awsdk_error_code';
import AWSDKResponse from '../internal/model/response/awsdk_response';
import AWSDKConsumer from './../model/consumer/awsdk_consumer';
import AWSDKProvider from './../model/provider/awsdk_provider';
import AWSDKAppointment from './../model/appointment/awsdk_appointment';
import Validator from '../internal/validator/validator';
import AWSDKTransfer from '../model/visit/awsdk_transfer';
import AWSDKVisit from '../model/visit/awsdk_visit';
import AWSDKVisitContext from '../model/visit/awsdk_visit_context';
import AWSDKVisitSummary from '../model/visit/awsdk_visit_summary';
import AWSDKVisitCostResponse from '../internal/model/response/awsdk_visit_cost_response';
import AWSDKVisitRequestBody from '../internal/model/request/awsdk_visit_request_body';
import AWSDKVisitResponse from '../internal/model/response/awsdk_visit_response';
import AWSDKVisitContextResponse from '../internal/model/response/awsdk_visit_context_response';
import AWSDKVisitSummaryResponse from '../internal/model/response/awsdk_visit_summary_response';
import CustomProtocolHandler from '../internal/util/custom_protocol_handler';
import AWSDKChatReportResponse from '../internal/model/response/awsdk_chat_report_response';
import AWSDKFirstAvailableConfiguration from '../model/practice/awsdk_first_available_configuration';
import AWSDKFirstAvailableRequest from '../internal/model/request/awsdk_first_available_request';
import AWSDKFirstAvailableResponse from '../internal/model/response/awsdk_first_available_response';
import AWSDKLanguage from '../model/awsdk_language';
import AWSDKVideoInvitationResponse from '../internal/model/response/awsdk_video_invitation_response';
import AWSDKVideoParticipant from '../model/visit/awsdk_video_participant';
import AWSDKVideoParticipantResponse from '../internal/model/response/awsdk_video_participant_response';
import AWSDKFirstAvailableStatus from '../model/visit/awsdk_first_available_status';
import AWSDKDisposition from '../model/visit/awsdk_disposition';
import AWSDKEndReason from '../model/visit/awsdk_end_reason';
import AWSDKConferenceStatus from '../model/visit/awsdk_conference_status';
import AWSDKSpeedPassResponse from '../internal/model/response/awsdk_speed_pass_response';
import AWSDKSpeedPass from '../model/visit/awsdk_speed_pass';
import AWSDKVideoContext from '../model/visit/awsdk_video_context';
import AWSDKProviderNotesResponse from '../internal/model/response/awsdk_notes_response';
import ServerLogger from '../internal/logger/server_logger';
import CombinedLogger from '../internal/logger/combined_logger';
import AWSDKUserType from '../awsdk_user_type';
import AWSDKVisitLoggingDataObject from '../internal/model/awsdk_visit_logging_data_object';
import AWSDKPractice from '../model/practice/awsdk_practice';
import AWSDKVisitSearchCriteria from '../model/visit/awsdk_visit_search_criteria';
import AWSDKVisitsResponse from '../internal/model/response/awsdk_visits_response';
import Util from '../internal/util/util';
import GenericParser from '../internal/parser/generic_parser';
import AWSDKProviderType from '../model/provider/awsdk_provider_type';
import AWSDKVisitModalityType from '../model/visit/awsdk_visit_modality_type';
import AWSDKConsumerOverrideDetails from '../model/consumer/awsdk_consumer_override_details';
import AWSDKConsumerMiddleNameHandling from '../model/consumer/awsdk_consumer_middle_name_handling';
import AWSDKGuestLoggingDataObject from '../internal/model/awsdk_guest_logging_data_object';
import AWSDKVideoInvitationListResponse from '../internal/model/response/awsdk_video_invitation_list_response';

/**
 * The VisitService class handles all the aspects of a visit.
 * <br/><br/>
 * The basic sequence for a consumer visit should be
 * <ul>
 * <li>Start by calling {@link service.VisitService#getVisitContext|getVisitContext}</li>
 * <li>Update the visit context with data provided by the consumer</li>
 * <li>Call {@link service.VisitService#createVisit|createVisit} passing the visitContext</li>
 * <p>Once the visit is created, the process of calculating the cost of the visit will begin. The cost calculation can take some time to complete.</p>
 * <li>Call {@link service.VisitService#waitForVisitCostCalculationToFinish|waitForVisitCostCalculationToFinish}</li>
 * <li>At this point, if the visit is not free, the payment method can be checked by calling {@link service.ConsumerService#getPaymentMethod|getPaymentMethod}</li>
 * <li>If needed, the consumer credit card can be updated by calling {@link service.ConsumerService#updatePaymentMethod|updatePaymentMethod}</li>
 * <li>A coupon can be applied by calling {@link service.VisitService#applyCouponCode|applyCouponCode}</li>
 * <li>Call {@link service.VisitService#startVisit|startVisit}</li>
 * <p>The consumer is now in the provider's waiting room and the provider has been notified</p>
 * </ul>
 * <br/>
 * <br/>
 * For visits using <b>The Telehealth Video Client</b>:
 * <br/>
 * <br/>
 * <li>Call {@link service.VisitService#launchTelehealthVideo|launchTelehealthVideo}</li>
 * <p>This will indicate if the consumer has successfully launched the telehealth video.
 * If telehealth video failed to launch the consumer does not have the telehealth video installed.
 * The consumer will need to download and install the Telehealth Video from the {@link service.VisitService#telehealthVideoInstallUrl|telehealthVideoInstallUrl}</li>
 * Once the Telehealth Video is installed it can be re-launched by calling {@link service.VisitService#launchTelehealthVideo|launchTelehealthVideo}</p>
 * <li>Call {@link service.VisitService#waitForTelehealthVideoToStart|waitForTelehealthVideoToStart}</li>
 * <p>The Teleahealth video will now have successfully started</p>
 * <li>Call {@link service.VisitService#waitForProviderToJoinVisit|waitForProviderToJoinVisit}</li>
 * <p>The provider has now joined and the visit can proceed</p>
 * <li>Call {@link service.VisitService#createVideoContext|createVideoContext} passing the configuration object</li>
 * <li>Call {@link service.VisitService#startWebRTCVisit|startWebRTCVisit} passing the videoConfiguration created in the step above</li>
 * <li>Call {@link service.VisitService#getVisitSummary|getVisitSummary}</li>
 * <br/>
 * <br/>
 * For visits using <b>The WebRTC Video Experience</b>:.
 * <br/>
 * <br/>
 * The WebRTC video console handles updating the connection status and waiting for the visit to finish so the workflow
 * after calling {@link service.VisitService#startVisit|startVisit} would be:
 * <br/>
 * <br/>
 * <li>Call {@link service.VisitService#waitForProviderToJoinVisit|waitForProviderToJoinVisit}</li>
 * <li>Call {@link service.VisitService#createVideoContext|createVideoContext}</li>
 * <p>Among other things, this function expects callback handlers to be specified for when the visit is updated, finished, or encounters an error.</p>
 * <li>Call {@link service.VisitService#startWebRTCVisit|startWebRTCVisit}</li>
 * <li>Call {@link service.VisitService#getVisitSummary|getVisitSummary}</li>
 * <p>Once the visit is finished (the provided successCallback on {@link model.AWSDKVideoContext} is invoked), getVisitSummary can be called.</p>
 * </ul>
 * @property {String} telehealthVideoInstallUrl the TelehealthVideo install url
 * @extends service.Service
 */
class VisitService extends Service {
  constructor(props) {
    super(props);
    // eslint-disable-nextline no-unused-vars
    if (this.__config.visitPollingInterval == null) {
      this.__config.visitPollingInterval = 3000;
    }
    if (this.__config.visitCostPollingInterval == null) {
      this.__config.visitCostPollingInterval = 3000;
    }
    if (this.__config.visitCostPollingTimeout == null) {
      this.__config.visitCostPollingTimeout = 60000;
    }
    if (this.__config.firstAvailablePollingInterval == null) {
      this.__config.firstAvailablePollingInterval = 3000;
    }
    if (this.__config.firstAvailablePollingTimeout == null) {
      this.__config.firstAvailablePollingTimeout = 60000;
    }
    this.__systemConfiguration = props.systemConfiguration;
    this.__customProtocolHandler = new CustomProtocolHandler(this.__config.launchTelehealthVideoClientTimeout);
  }

  /** --------------------- Delegate Methods ------------------*/

  /**
   * @private
   */
  __pollForVisit(videoContext, visit) {
    const currentFunction = 'VisitService.__pollForVisit';
    this.__logger.info(currentFunction, 'Started', visit);
    return this.getVisit(visit, true).then((result) => {
      this.__visit = result;
      this.__logger.debug(currentFunction, 'Returning with result', result);
      if (!this.__visit.finished && videoContext.consoleWrapperAttachPoints) {
        this.__updateConsoleWrapper(videoContext.consoleWrapperAttachPoints, this.__visit);
      }
      return result;
    }).catch((error) => {
      this.__logger.error(currentFunction, 'Encountered an error when polling for visit', error);
      return Promise.reject(error);
    });
  }

  /**
   * @private
   */
  __pollForVisitWithTimeout(videoContext, visit, timeout) {
    const currentFunction = 'VisitService.__pollForVisitWithTimeout';
    this.__logger.info(currentFunction, 'Started', visit);

    // race between a timeout and the getVisit
    return Promise.race([this.__pollForVisit(videoContext, visit),
      new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), timeout))]);
  }

  /**
   * @private
   */
  __pollForGuestParticipant(participant) {
    const currentFunction = 'VisitService.__pollForGuestParticipant';
    this.__logger.info(currentFunction, 'Started', participant);
    return this.getVideoParticipant(participant);
  }

  /**
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.InProgress|InProgress}</li>
   * <li>{@link model.AWSDKDisposition.PreVisitScreening|PreVisitScreening}</li>
   * </ul>
   * </p>
   * @private
   */
  __updateMetrics(visit, metrics) {
    const currentFunction = 'VisitService.__updateMetrics';
    this.__logger.debug(currentFunction, 'Started', visit, metrics);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('Visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    if (!(metrics instanceof AWCoreSDKVideoMetrics)) {
      const error = AWSDKError.AWSDKIllegalArgument('Metrics argument is not of type AWCoreSDKVideoMetrics');
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'videoMetricsMember');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit does not have a "videoMetricsMember" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    options.form.set('isJoined', metrics.isJoined);
    options.form.set('isSignedIn', metrics.isSignedIn);
    options.form.set('isEndRequested', metrics.isEndRequested);
    options.form.set('videoFailureReason', metrics.videoFailureReason);
    options.form.set('manualRetryCount', metrics.manualRetryCount);
    options.form.set('autoRetryCount', metrics.autoRetryCount);
    options.form.set('videoPlatform', 'WEBRTC');
    options.form.set('clientVersion', version.sdkVersion);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.updateUserAuthEntry(visit.consumer, response.authToken);
        this.__logger.debug(currentFunction, 'Got response', response);
        return response;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * @private
   */
  __onVisitEnd(successCallback, consoleWrapperAttachPoints, visit, visitEndDetails) {
    const currentFunction = 'VisitService.__onVisitEnd';
    this.__logger.info(currentFunction, 'Started', successCallback, visit, visitEndDetails);

    if (consoleWrapperAttachPoints) {
      this.__cleanUpAppendedContent(consoleWrapperAttachPoints);
    }
    if (successCallback) {
      successCallback(visit, visitEndDetails);
    }
  }

  /**
   * @private
   */
  __onVisitUpdated(updatedCallback, visit) {
    const currentFunction = 'VisitService.__onVisitUpdated';
    this.__logger.info(currentFunction, 'Started', updatedCallback, visit);
    if (updatedCallback) {
      updatedCallback(visit);
    }
  }
  /**
   * @private
   */
  __onVisitError(errorCallback, visit, endReason, error) {
    const currentFunction = 'VisitService.__onVisitError';
    this.__logger.error(currentFunction, 'Started', errorCallback, visit, endReason, error);
    if (errorCallback) {
      errorCallback(visit, endReason, error);
    }
  }

  /**
   * @private
   */
  __onExitVisitRequested(visit) {
    const currentFunction = 'VisitService.__onExitVisitRequested';
    this.__logger.debug(currentFunction, 'Started');
    return (visit.disposition === AWSDKDisposition.InProgress) ? this.endVisit(visit) : this.cancelVisit(visit);
  }

  /**
   * @private
   */
  __onGuestExitVisitRequested(visit) {
    const currentFunction = 'VisitService.__onGuestExitVisitRequested';
    this.__logger.debug(currentFunction, 'Started');
    return Promise.resolve(visit);
  }

  /**
   * @private
   */
  __onCallMeSelected(visit) {
    const currentFunction = 'VisitService.__onCallMeSelected';
    this.__logger.debug(currentFunction, 'Started');
    return this.addPhoneToVisit(visit);
  }

  /**
   * @private
   */
  __setClickableEvents(consoleWrapperAttachPoints, messages) {
    if (consoleWrapperAttachPoints) {
      const currentFunction = 'VisitService.__setClickableEvents';
      this.__logger.info(currentFunction, 'started', consoleWrapperAttachPoints);
      const providerNotesDOMNode = consoleWrapperAttachPoints.providerNotesDOMNode;
      const chatDOMNode = consoleWrapperAttachPoints.chatDOMNode;
      const inviteGuestDOMNode = consoleWrapperAttachPoints.inviteGuestDOMNode;
      const menuDetailsWrapperDOMNode = consoleWrapperAttachPoints.menuDetailsWrapperDOMNode;
      const notesDetailsDOMNode = consoleWrapperAttachPoints.noteDetailsDOMNode;
      const chatDetailsDOMNode = consoleWrapperAttachPoints.chatDetailsDOMNode;
      const chatMsgAreaDOMNode = consoleWrapperAttachPoints.chatDetailsMessageAreaDOMNode;
      const inviteGuestDetailsDOMNode = consoleWrapperAttachPoints.inviteGuestDetailsDOMNode;
      const chatDetailsInputButtonDOMNode = consoleWrapperAttachPoints.chatDetailsInputButtonDOMNode;
      const chatDetailsInputDOMNode = consoleWrapperAttachPoints.chatDetailsInputDOMNode;
      const inviteGuestButtonDOMNode = consoleWrapperAttachPoints.inviteGuestButtonDOMNode;
      const inviteGuestInputDOMNode = consoleWrapperAttachPoints.inviteGuestInputDOMNode;
      const closeMenuDOMNode = consoleWrapperAttachPoints.closeMenuDOMNode;
      this.__cleanUpAppendedContent(consoleWrapperAttachPoints);
      providerNotesDOMNode.onclick = (e) => {
        e.preventDefault();
        this.__removeClass(menuDetailsWrapperDOMNode, 'awsdk-hidden');
        this.__removeClass(notesDetailsDOMNode, 'awsdk-hidden');
        this.__addClass(providerNotesDOMNode, 'awsdk-expanded');
        this.__removeClass(providerNotesDOMNode, 'awsdk-newContent');
        // prevents multiple tabs open at same time
        this.__addClass(chatDetailsDOMNode, 'awsdk-hidden');
        this.__addClass(inviteGuestDetailsDOMNode, 'awsdk-hidden');
        // resets their background
        this.__removeClass(chatDOMNode, 'awsdk-expanded');
        this.__removeClass(inviteGuestDOMNode, 'awsdk-expanded');
      };
      chatDOMNode.onclick = (e) => {
        e.preventDefault();
        this.__removeClass(menuDetailsWrapperDOMNode, 'awsdk-hidden');
        this.__removeClass(chatDetailsDOMNode, 'awsdk-hidden');
        this.__removeClass(chatDOMNode, 'awsdk-newContent');
        this.__addClass(chatDOMNode, 'awsdk-expanded');
        // prevents multiple tabs open at same time
        this.__addClass(notesDetailsDOMNode, 'awsdk-hidden');
        this.__addClass(inviteGuestDetailsDOMNode, 'awsdk-hidden');
        // resets their background
        this.__removeClass(providerNotesDOMNode, 'awsdk-expanded');
        this.__removeClass(inviteGuestDOMNode, 'awsdk-expanded');
        chatMsgAreaDOMNode.scrollTop = chatMsgAreaDOMNode.scrollHeight;
      };
      inviteGuestDOMNode.onclick = (e) => {
        e.preventDefault();
        this.__removeClass(menuDetailsWrapperDOMNode, 'awsdk-hidden');
        this.__removeClass(inviteGuestDetailsDOMNode, 'awsdk-hidden');
        this.__removeClass(inviteGuestDOMNode, 'awsdk-newContent');
        this.__addClass(inviteGuestDOMNode, 'awsdk-expanded');
        // prevents multiple tabs open at same time
        this.__addClass(chatDetailsDOMNode, 'awsdk-hidden');
        this.__addClass(notesDetailsDOMNode, 'awsdk-hidden');
        // resets their background
        this.__removeClass(chatDOMNode, 'awsdk-expanded');
        this.__removeClass(providerNotesDOMNode, 'awsdk-expanded');
      };
      chatDetailsInputButtonDOMNode.onclick = (e) => {
        e.preventDefault();
        const message = chatDetailsInputDOMNode.value;
        chatDetailsInputDOMNode.value = '';
        this.addChatMessage(this.__visit, message, this.__lastOrdinal).then((chatReport) => {
          this.__processChatMessages(consoleWrapperAttachPoints, chatReport);
        });
      };
      chatDetailsInputDOMNode.onkeydown = (e) => {
        if (e.which === 13 || e.keyCode === 13) { // allow enter.
          e.preventDefault();
          chatDetailsInputButtonDOMNode.click();
        }
      };
      inviteGuestButtonDOMNode.onclick = (e) => {
        e.preventDefault();
        const email = inviteGuestInputDOMNode.value;
        const validationResult = this.__validateGuestInvites(email, messages);
        if (!validationResult.errorMessage) {
          this.inviteGuestsToVisit(this.__visit, [email]).then(() => {
            this.__processSendGuestInvite(consoleWrapperAttachPoints, this.__visit);
            inviteGuestInputDOMNode.value = ''; // clear the value
          }).catch((error) => {
            if (error.errorCode === AWSDKErrorCode.guestEmailAlreadyInvited) {
              consoleWrapperAttachPoints.inviteGuestErrorTextDOMNode.innerHTML = messages.invite_guests_already_invited;
              consoleWrapperAttachPoints.inviteGuestErrorTextDOMNode.classList.remove('awsdk-hidden');
            }
          });
        } else {
          consoleWrapperAttachPoints.inviteGuestErrorTextDOMNode.innerHTML = validationResult.errorMessage;
          consoleWrapperAttachPoints.inviteGuestErrorTextDOMNode.classList.remove('awsdk-hidden');
        }
      };
      closeMenuDOMNode.onclick = (e) => {
        e.preventDefault();
        menuDetailsWrapperDOMNode.classList.add('awsdk-hidden');
        providerNotesDOMNode.classList.remove('awsdk-expanded');
        chatDOMNode.classList.remove('awsdk-expanded');
        inviteGuestDOMNode.classList.remove('awsdk-expanded');
      };
    }
  }

  /**
   * @private
   */
  __cleanUpAppendedContent(consoleWrapperAttachPoints) {
    if (consoleWrapperAttachPoints) {
      this.__cleanUpChildren(consoleWrapperAttachPoints.chatDetailsMessageAreaDOMNode);
      this.__cleanUpChildren(consoleWrapperAttachPoints.providerNotesContentDOMNode);
      this.__cleanUpChildren(consoleWrapperAttachPoints.inviteGuestDetailsInvitedDOMNode);
      this.__lastNoteMessage = null;
    }
  }

  /**
   * @private
   */
  __cleanUpChildren(DOMNode) {
    while (DOMNode.firstChild) {
      DOMNode.removeChild(DOMNode.firstChild);
    }
  }

  /**
   * @private
   */
  __updateConsoleWrapper(attachPoints, visit) {
    if (attachPoints) {
      this.__updateRemainingTime(attachPoints, visit);
      this.__processProviderNotes(attachPoints, visit);
      if (this.__visit.allowsChatWithProvider) {
        this.__processChatMessages(attachPoints, visit.chatReport);
      }
      this.__refreshInvitedGuestsEmailList(attachPoints, visit);
    }
  }

  /**
   * @private
   */
  __updateRemainingTime(attachPoints, visit) {
    if (attachPoints) {
      const timeRemainingNode = attachPoints.timerDOMNode;
      const existingMessages = timeRemainingNode.innerHTML.split(' ');
      let remainingMessage;
      const timeRemainingFromServerInMillis = visit.timeRemainingMilliseconds;
      const timeLeft = new Date(timeRemainingFromServerInMillis);
      const formattedTime = `${timeLeft.getMinutes()}:${timeLeft.getSeconds().toString().padStart(2, '0')}`;
      if (existingMessages.length === 2) {
        remainingMessage = `${formattedTime} ${existingMessages[1]}`;
      } else { // for initial message with no time
        remainingMessage = `${formattedTime} ${existingMessages[0]}`;
      }
      timeRemainingNode.innerHTML = remainingMessage;
      this.__lastPolledTime = this.__currentPolledTime;
      timeRemainingNode.classList.remove('awsdk-hidden');
    }
  }

  /**
   * @private
   */
  __processChatMessages(attachPoints, chatReport) {
    if (attachPoints && chatReport && chatReport.chatItems) {
      const chatIcon = attachPoints.chatDOMNode;
      const chatMessageArea = attachPoints.chatDetailsMessageAreaDOMNode;
      chatReport.chatItems.filter(item => item.ordinal > this.__lastOrdinal).forEach((item) => {
        const chatDiv = document.createElement('div');
        // container
        chatDiv.setAttribute('id', `awsdk-chat${item.ordinal}`);
        chatDiv.setAttribute('class', 'awsdk-chatMessage');

        // content wrapper
        const chatContentDiv = document.createElement('div');
        chatContentDiv.setAttribute('class', 'awsdk-chatMessageContent');
        chatDiv.appendChild(chatContentDiv);

        // grab message
        const msgSpan = document.createElement('span');
        const msgText = document.createTextNode(item.message);

        // if it's a consumer or provider message then put it in a bubble and show name, otherwise it's a system message
        if (item.userType !== AWSDKUserType.ADMIN && item.userType !== AWSDKUserType.SDK) {
          // name of sender
          const name = document.createElement('span');
          name.setAttribute('class', 'awsdk-chatItemTitle');
          name.innerHTML = item.fullName;
          chatContentDiv.appendChild(name);

          // message text
          msgSpan.setAttribute('class', 'awsdk-bubble');
          if (item.isSelf) {
            chatDiv.classList.add('awsdk-mine');
          }
        } else {
          chatDiv.classList.add('awsdk-systemMessage');
        }

        msgSpan.appendChild(msgText);
        chatContentDiv.appendChild(msgSpan);
        chatMessageArea.appendChild(chatDiv);

        // scroll to bottom if we added a new message
        chatMessageArea.scrollTop = chatMessageArea.scrollHeight;
      });

      if (this.__lastOrdinal < chatReport.lastOrdinal && !chatIcon.classList.contains('awsdk-expanded')) {
        chatIcon.classList.add('awsdk-newContent');
      }

      // the last ordinal from a chat report coming from polling can be behind that of one that came as a response from
      // adding a chat message so to avoid double messages we keep track of whichever is highest
      this.__lastOrdinal = Math.max(chatReport.lastOrdinal, this.__lastOrdinal);
    }
  }

  /**
   * @private
   */
  __processSendGuestInvite(attachPoints, visit) {
    const currentFunction = 'VisitService.__processSendGuestInvite';
    this.__logger.debug(currentFunction, 'Started', visit);
    this.getVisit(visit, true).then((result) => {
      this.__visit = result;
      this.__logger.trace(currentFunction, 'Returning with result', result);
      this.__refreshInvitedGuestsEmailList(attachPoints, this.__visit);
    }).catch((error) => {
      this.__logger.error(currentFunction, 'Encountered an error when getting a visit', error);
    });
    this.__logger.debug(currentFunction, 'Finished');
  }

  /**
   * @private
   */
  __processProviderNotes(attachPoints, visit) {
    if (attachPoints) {
      const providerNotesIcon = attachPoints.providerNotesDOMNode;
      const providerNotesContent = attachPoints.providerNotesContentDOMNode;
      // get the iframe element
      let notesBodyElement = providerNotesContent.firstChild;
      let newContent = false;
      const providerEntries = visit.providerEntries;
      const notes = providerEntries && providerEntries.notes;
      this.__logger.debug('Found provider notes: ', notes);
      if (notes) {
        newContent = notes !== this.__lastNoteMessage;
        if (!notesBodyElement) {
          const notesDocumentFragment = document.createDocumentFragment();
          // WS-2227: creating an iframe element here since the provider notes
          // can be a full HTML page with it's own inline styling
          // and can, thus, potentially overflow a div or any other container element
          notesBodyElement = document.createElement('iframe');
          notesBodyElement.setAttribute('id', 'awsdk-notesBody');
          notesBodyElement.setAttribute('src', 'about:blank');
          notesBodyElement.classList.add('awsdk-notesBody');
          // for FF
          notesBodyElement.addEventListener('load', () => {
            notesBodyElement.contentWindow.document.body.innerHTML = notes;
          }, false);
          notesDocumentFragment.appendChild(notesBodyElement);
          providerNotesContent.appendChild(notesDocumentFragment);
        }
        if (newContent) {
          this.__lastNoteMessage = notes;
          notesBodyElement.contentWindow.document.body.innerHTML = notes;
        }
      }

      // if we have new content and we're not already looking at the notes section add the newContent class
      if (newContent && !providerNotesIcon.classList.contains('awsdk-expanded')) {
        providerNotesIcon.classList.add('awsdk-newContent');
      }
    }
  }

  /**
   * @private
   */
  __refreshInvitedGuestsEmailList(attachPoints, visit) {
    const currentFunction = 'VisitService.__refreshInvitedGuestsEmailList';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (attachPoints && visit) {
      const inviteGuestDetailsInvitedDOMNode = attachPoints.inviteGuestDetailsInvitedDOMNode;
      const maxInviteeDOMNode = attachPoints.maxInviteeDOMNode;
      const inviteGuestInputWrapperDOMNode = attachPoints.inviteGuestInputWrapperDOMNode;
      const hasInvitedGuestNodes = (inviteGuestDetailsInvitedDOMNode && maxInviteeDOMNode && inviteGuestInputWrapperDOMNode);
      if (hasInvitedGuestNodes) {
        while (inviteGuestDetailsInvitedDOMNode.firstChild) {
          inviteGuestDetailsInvitedDOMNode.removeChild(inviteGuestDetailsInvitedDOMNode.firstChild);
        }
        const invitedGuestsEmailCount = visit.invitedGuestEmails.length;
        if (invitedGuestsEmailCount > 0) {
          inviteGuestDetailsInvitedDOMNode.classList.remove('awsdk-hidden');
          const guestEmailsDocumentFragment = document.createDocumentFragment();
          visit.invitedGuestEmails.forEach((guestEmailAddress) => {
            const guestEmailAddressNode = document.createElement('span');
            guestEmailAddressNode.setAttribute('class', 'awsdk-invitees');
            guestEmailAddressNode.innerHTML = guestEmailAddress;
            guestEmailsDocumentFragment.appendChild(guestEmailAddressNode);
          });
          inviteGuestDetailsInvitedDOMNode.appendChild(guestEmailsDocumentFragment);
          if (invitedGuestsEmailCount >= this.__systemConfiguration.maxVideoInvites) {
            inviteGuestInputWrapperDOMNode.classList.add('awsdk-hidden');
            maxInviteeDOMNode.classList.remove('awsdk-hidden');
          }
        }
        this.__logger.info('Successfully refreshed invited guests email list');
      }
    }
    this.__logger.debug(currentFunction, 'Finished');
  }

  /**
   * @private
   */
  __validateGuestInvites(emailAddress, messages) {
    const currentFunction = 'VisitService.__validateGuestInvites';
    this.__logger.debug(currentFunction, 'Validating', emailAddress);
    const validationResult = { errorMessage: null };
    if (!Validator.isEmailValid(emailAddress)) {
      validationResult.errorMessage = messages.validation_email_invalid;
    }
    return validationResult;
  }

  /**
   * @private
   */
  __initializeDisplayContainers(attachPoints, messages) {
    const currentFunction = 'VisitService#__initializeDisplayContainers';
    if (attachPoints == null || (typeof attachPoints) !== 'object') {
      const error = AWSDKError.AWSDKIllegalArgument('videoContext.consoleWrapperAttachPoints must be an object');
      this.__logger.error(currentFunction, error);
      throw error;
    }
    // check if we should show chat
    if (!this.__systemConfiguration.alwaysHideChatTabEnabled && this.__visit.allowsChatWithProvider) {
      attachPoints.chatDetailsTitleDOMNode.innerHTML = messages.chat_window_title;
      attachPoints.chatDetailsIntroTextDOMNode.innerHTML = messages.chat_visit_begun;
      attachPoints.chatDetailsInputDOMNode.setAttribute('placeholder', messages.chat_input_placeholder_text);
      attachPoints.chatTabTitleDOMNode.innerHTML = messages.tab_chat;
    } else {
      attachPoints.chatDOMNode.classList.add('awsdk-hidden');
    }
    // check if we should show provider notes
    if (!this.__systemConfiguration.alwaysHideNotesTabEnabled) {
      attachPoints.providerNotesDetailTitleDOMNode.innerHTML = messages.visit_notes_title;
      attachPoints.notesTabTitleDOMNode.innerHTML = messages.tab_notes;
    } else {
      attachPoints.providerNotesDOMNode.classList.add('awsdk-hidden');
    }
    attachPoints.timerDOMNode.innerHTML = messages.time_remaining;

    // hide guest tab if disabled, otherwise initialize dom elements related to inviting guest
    if (!this.__systemConfiguration.multipleVideoParticipantsEnabled) {
      attachPoints.inviteGuestDOMNode.classList.add('awsdk-hidden');
    } else {
      attachPoints.inviteGuestDetailsTitleDOMNode.innerHTML = messages.invite_guests_title;
      attachPoints.inviteGuestLabelDOMNode.innerHTML = messages.invite_guests_small_title;
      attachPoints.inviteGuestErrorTextDOMNode.classList.add('awsdk-hidden');
      attachPoints.inviteGuestInputWrapperDOMNode.classList.remove('awsdk-hidden');
      attachPoints.inviteGuestInputDOMNode.setAttribute('placeholder', messages.invite_guests_email_placeholder);
      attachPoints.inviteGuestInputDOMNode.oninput = () => {
        if (!attachPoints.inviteGuestErrorTextDOMNode.classList.contains('awsdk-hidden')) {
          attachPoints.inviteGuestErrorTextDOMNode.innerHTML = '';
          attachPoints.inviteGuestErrorTextDOMNode.classList.add('awsdk-hidden');
        }
      };
      attachPoints.inviteGuestButtonDOMNode.innerHTML = messages.invite_guests_send_invite;
      attachPoints.inviteGuestIntroTextDOMNode.innerHTML = messages.invite_guests_prompt;
      attachPoints.maxInviteeDOMNode.classList.add('awsdk-hidden');
      attachPoints.maxInviteeDOMNode.innerHTML = messages.invite_guests_max_guests_prompt;
      attachPoints.inviteTabTitleDOMNode.innerHTML = messages.tab_invite;
    }
  }

  /**
   * @private
   */
  __removeClass(node, klass) {
    node.classList.remove(klass);
  }

  /**
   * @private
   */
  __addClass(node, klass) {
    node.classList.add(klass);
  }

  /** --------------------- CoreSDK Constructor Wrappers ------------------*/

  /**
   * Creates a video participant of the given type.
   *
   * @param {String} name the name of the participant as it should appear in the video console
   * @param {Object} videoDOMNode a reference to the <video> DOM node that will render the participant's self-view
   * @param {Object} nameDOMNode a reference to the DOM node that will render the participant's name
   * @param {AWCoreSDKVideoParticipantType} participantType the type of the participant: 'Consumer', 'Provider' or 'Guest'
   * @returns {AWCoreSDKVideoParticipant} a fully constructed instance of AWCoreSDKVideoParticipant
   * @since 2.0.0
   */
  createWebRTCVideoParticipant(name, videoDOMNode, nameDOMNode, participantType) {
    const currentFunction = 'VisitService#createWebRTCVideoParticipant';
    this.__logger.debug(currentFunction, 'Started');
    if (!Validator.isValidString(name)) {
      const error = AWSDKError.AWSDKIllegalArgument('name argument is not of type String');
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    }
    if (!videoDOMNode) {
      const error = AWSDKError.AWSDKIllegalArgument('videoDOMNode argument is required');
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    }
    if (!nameDOMNode) {
      const error = AWSDKError.AWSDKIllegalArgument('nameDOMNode argument is required');
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    }
    const validParticipantTypes = Object.keys(AWCoreSDKVideoParticipantType).map(k => AWCoreSDKVideoParticipantType[k]);
    if (!participantType || !validParticipantTypes.includes(participantType)) {
      const error = AWSDKError.AWSDKIllegalArgument('participantType argument is not one of Consumer, Provider or Guest');
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    }
    return new AWCoreSDKVideoParticipant({
      name,
      participantType,
      videoDOMNode,
      nameDOMNode,
    });
  }

  /**
   * The enumeration for the valid types of video participants.
   *
   * @type {AWCoreSDKVideoParticipantType}
   * @since 2.0.0
   */
  get videoParticipantType() {
    return AWCoreSDKVideoParticipantType;
  }

  /**
   * A nested object whose keys represent all the language/locale supported in the video console.
   * The value of each key is an object that represents all the possible key/value message resource for that language/locale.
   * Clients of the sdk are free to override the value of each key with translations of their own.
   *
   * @type {Object}
   * @since 2.0.0
   */
  getDefaultMessages() {
    return defaultMessages;
  }

  /**
   * Creates an {@link model.AWSDKVideoContext} from the given argument.<br>
   *
   * Note: In order to use WebRTC, the appropriate server configurations need to be made on the AmWell platform to enable WebRTC and to use the latest supported video platform.
   *
   * @param {Object} config an object that aggregates the following context properties
   * @param {function} config.successCallback Function to execute when the video visit has ended successfully
   * @param {function} config.errorCallback Function to execute when the video visit has encountered an unrecoverable error
   * @param {function} config.updatedCallback Function to execute when the video visit has been updated
   * @param {HTMLElement} config.videoConsoleContainer The DOM node where the video visit console should be hooked to
   * @param {Object} [context.messages] A flattened key/value bundle of localized strings to use in the video console. Takes precedence over `context.locale`. To get all the possible overridable messages use the {@link VisitService#getDefaultMessages|VisitService.getDefaultMessages()} function.
   * @param {string} [context.locale] A locale identifier used to lookup built-in localized strings. Defaults to `en-US`. `context.messages` overrides any defaults read by passing a `locale`.
   * @param {AWCoreSDKVideoAttachPoints} [config.attachPoints] the various nodes in the DOM where the video will be rendered. <em>This parameter is used by the Consumer Web SDK sample app to support a cohesive user experience </em>
   * @returns {model.AWSDKVideoContext} a fully-composed instance of {@link model.AWSDKVideoContext}
   * @since 2.0.0
   */
  createVideoContext(config) {
    return new AWSDKVideoContext(config);
  }

  /**
   * Connects the consumer of the given {@link model.AWSDKVisit|AWSDKVisit} to a video call where their visit with the
   * assigned {@link model.AWSDKProvider|AWSDKProvider} will occur. Requires that {@link VisitService#startVisit} has already been called successfully.
   * Once started, the video call will progress through its phases of waiting for the provider to join and then having the actual visit,
   * fully rendered in the various DOM attach points specified as arguments.
   * Should an unrecoverable error occur at any point after the call has been initiated, the errorCallback will be called.
   * Otherwise, should the visit complete successfully, the successCallback will be called.
   *
   * For a description of how to customize the video console see the [How To Override The Video Console UI Elements]{@tutorial HOWTO}<br>
   *
   * Note: In order to use WebRTC, the appropriate server configurations need to be made on the AmWell platform to enable WebRTC and to use the latest supported video platform.
   *
   * The screen sharing function requires WebRTC to be enabled, as well as screen sharing in the platform configuration. Both participants must have a supported browser and
   * version on web or mobile, which are:
   *  o Google Chrome v79 or higher
   *  o Mozilla Firefox v70 or higher
   *
   * @param {model.AWSDKVisit} visit The visit for which to establish a video call
   * @param {model.AWSDKVideoContext} videoContext the context in which this visit will appear
   * @returns {Promise<AWCoreSDKVideoConsole|error.AWSDKError>} a promise that will resolve to a {@link AWCoreSDKVideoConsole|AWCoreSDKVideoConsole} or rejected with an {@link error.AWSDKError|AWSDKError}
   * if visit is not a valid instance of {@link model.AWSDKVisit|AWSDKVisit} or if the
   * videoContext is not a valid instance of {@link model.AWSDKVideoContext|AWSDKVideoContext}.
   * @since 2.0.0
   */
  startWebRTCVisit(visit, videoContext) {
    const currentFunction = 'VisitService#startWebRTCVisit';
    this.__logger.info(currentFunction, 'Starting webrtc visit for ', visit, videoContext);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit must be of type AWSDKVisit');
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    if (!(visit.conferenceDetails)) {
      const error = AWSDKError.AWSDKUnsupportedVideoPlatform();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    if (!(videoContext instanceof AWSDKVideoContext)) {
      const error = AWSDKError.AWSDKIllegalArgument('videoContext must be of type AWSDKVideoContext');
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    const consoleWrapperAttachPoints = (videoContext.consoleWrapperAttachPoints &&
      videoContext.consoleWrapperAttachPoints !== {} &&
        videoContext.consoleWrapperAttachPoints) || null;
    const videoConsoleContainer = videoContext.videoConsoleContainer;
    if (videoConsoleContainer == null || !(videoConsoleContainer instanceof HTMLElement)) {
      const error = AWSDKError.AWSDKIllegalArgument('videoContext.videoConsoleContainer must be an HTMLElement');
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    this.__visit = visit;
    this.__lastOrdinal = 0;
    const messages = videoContext.messages;
    if (consoleWrapperAttachPoints) {
      this.__initializeDisplayContainers(consoleWrapperAttachPoints, messages);
      this.__setClickableEvents(consoleWrapperAttachPoints, messages);
    }
    const delegate = {
      pollForVisitLowTimeout: this.__pollForVisitWithTimeout.bind(this, videoContext),
      pollForVisit: this.__pollForVisit.bind(this, videoContext),
      updateMetrics: this.__updateMetrics.bind(this),
      onVisitEnd: this.__onVisitEnd.bind(this, videoContext.successCallback, consoleWrapperAttachPoints),
      onVisitError: this.__onVisitError.bind(this, videoContext.errorCallback),
      onCallMeSelected: this.__onCallMeSelected.bind(this),
      updateConnectionStatus: this.__updateConnectionStatus.bind(this),
      onExitVisitRequested: this.__onExitVisitRequested.bind(this),
      onVisitUpdated: this.__onVisitUpdated.bind(this, videoContext.updatedCallback),
      onIVRCallback: this.__initiateIVRCallback.bind(this),
    };
    const requiredHandlers = [
      new AWCoreSDKBaseEventHandler({ type: 'localParticipantJoinedEvent', handler: e => this.__logger.info('ConsumerWebSDK is handling event', e) }),
      new AWCoreSDKBaseEventHandler({ type: 'localParticipantLeftEvent', handler: e => this.__logger.info('ConsumerWebSDK is handling event', e) }),
      new AWCoreSDKBaseEventHandler({ type: 'remoteParticipantLeftEvent', handler: e => this.__logger.info('ConsumerWebSDK is handling event', e) }),
      new AWCoreSDKBaseEventHandler({ type: 'sdkDefaultVideoEvent', handler: e => this.__logger.info('ConsumerWebSDK is handling event', e) }),
    ];
    const visitConfig = new AWCoreSDKVisitConfiguration({ visit, visitPollingDelegate: delegate });
    const remoteLogger = new ServerLogger({ config: this.__config, authKeys: this.__authKeys, links: this.__links, logger: this.__logger });
    const combinedLogger = new CombinedLogger(this.__logger, remoteLogger);
    const videoConfig = new AWCoreSDKVideoConfiguration({
      requiredHandlers,
      vendor: AWCoreSDKVendor.PEXIP,
      visitConfig,
      messages,
      logger: combinedLogger,
      encoded: 0,
      roomPin: '',
      visitLoggingData: new AWSDKVisitLoggingDataObject(version.sdkVersion, this.__localeToAcceptLanguage(this.__config.locale), visit),
      participantType: this.videoParticipantType.CONSUMER,
      videoConsoleContainer: videoContext.videoConsoleContainer,
      systemConfiguration: this.__systemConfiguration,
      turnServerConfiguration: this.__systemConfiguration.turnServerConfiguration,
      isIVRCallbackEnabled: this.__config.isIVRCallbackEnabled,
      locale: this.__config.locale,
    });
    return this.__startWebRTCConsole(videoConfig);
  }

  /**
   * Connects the given {@link model.AWSDKVideoParticipant} guest to an ongoing webRTC video visit.
   * Should an unrecoverable error occur at any point after the call has been initiated, the errorCallback will be called.
   * Otherwise, should the visit complete successfully, the successCallback will be called.<br>
   *
   * Note: In order to use WebRTC, the appropriate server configurations need to be made on the AmWell platform to enable WebRTC and to use the latest supported video platform.
   *
   * @param {model.AWSDKVideoParticipant} videoParticipant The guest participant joining the visit
   * @param {model.AWSDKVideoContext} videoContext the context in which this visit will appear
   * @returns {Promise<AWCoreSDKVideoConsole|error.AWSDKError>} a promise that will resolve to a {@link AWCoreSDKVideoConsole|AWCoreSDKVideoConsole} or rejected with an {@link error.AWSDKError|AWSDKError}
   * @throws {error.AWSDKError} with an {@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}
   * if videoParticipant is not a valid instance of {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} or if the
   * videoContext is not a valid instance of {@link model.AWSDKVideoContext|AWSDKVideoContext}.
   * @since 2.0.0
   */
  startWebRTCVisitForGuest(videoParticipant, videoContext) {
    const currentFunction = 'VisitService#startWebRTCVisitForGuest';
    this.__logger.info(currentFunction, 'Starting guest WebRTC visit for ', videoParticipant, videoContext);
    if (!(videoParticipant instanceof AWSDKVideoParticipant)) {
      const error = AWSDKError.AWSDKIllegalArgument('videoParticipant must be of type AWSDKVideoParticipant');
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    if (!(videoParticipant.conferenceDetails)) {
      const error = AWSDKError.AWSDKUnsupportedVideoPlatform();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    if (!(videoContext instanceof AWSDKVideoContext)) {
      const error = AWSDKError.AWSDKIllegalArgument('videoContext must be of type AWSDKVideoContext');
      this.__logger.error(currentFunction, error);
      Promise.reject(error);
    }
    const videoConsoleContainer = videoContext.videoConsoleContainer;
    if (videoConsoleContainer == null || !(videoConsoleContainer instanceof HTMLElement)) {
      const error = AWSDKError.AWSDKIllegalArgument('videoContext.videoConsoleContainer must be an HTMLElement');
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }
    this.__videoParticipant = videoParticipant;
    const messages = videoContext.messages;
    // TODO make verbiage more general or come up with separate flow for clarity
    // this code overloads all the visit specific handlers with 'guest' logic
    // what this essentially means is from this point forward (down into the core sdk itself)
    // any place the 'visit' exists is actually a participant obj.
    const delegate = {
      pollForVisitLowTimeout: this.__pollForGuestParticipant.bind(this),
      pollForVisit: this.__pollForGuestParticipant.bind(this),
      updateMetrics: () => {
        this.__logger.debug('Ignoring call to update video metrics for guest');
        return Promise.resolve();
      },
      onVisitEnd: this.__onVisitEnd.bind(this, videoContext.successCallback, videoContext.consoleWrapperAttachPoints),
      onVisitError: this.__onVisitError.bind(this, videoContext.errorCallback),
      onCallMeSelected: this.__onCallMeSelected.bind(this),
      updateConnectionStatus: this.updateVideoParticipantConnectionStatus.bind(this, this.__videoParticipant),
      onExitVisitRequested: this.__onGuestExitVisitRequested.bind(this),
      onVisitUpdated: this.__onVisitUpdated.bind(this, videoContext.updatedCallback),
    };
    const requiredHandlers = [
      new AWCoreSDKBaseEventHandler({ type: 'localParticipantJoinedEvent', handler: e => this.__logger.info('ConsumerWebSDK is handling event', e) }),
      new AWCoreSDKBaseEventHandler({ type: 'localParticipantLeftEvent', handler: e => this.__logger.info('ConsumerWebSDK is handling event', e) }),
      new AWCoreSDKBaseEventHandler({ type: 'remoteParticipantLeftEvent', handler: e => this.__logger.info('ConsumerWebSDK is handling event', e) }),
      new AWCoreSDKBaseEventHandler({ type: 'sdkDefaultVideoEvent', handler: e => this.__logger.info('ConsumerWebSDK is handling event', e) }),
    ];
    const visitConfig = new AWCoreSDKVisitConfiguration({ visit: this.__videoParticipant, visitPollingDelegate: delegate });
    const remoteLogger = new ServerLogger({ config: this.__config, authKeys: this.__authKeys, links: this.__links, logger: this.__logger });
    const combinedLogger = new CombinedLogger(this.__logger, remoteLogger);
    const videoConfig = new AWCoreSDKVideoConfiguration({
      requiredHandlers,
      vendor: AWCoreSDKVendor.PEXIP,
      visitConfig,
      displayNameOverride: this.__videoParticipant.displayName,
      messages,
      logger: combinedLogger,
      encoded: 0,
      visitLoggingData: new AWSDKGuestLoggingDataObject(version.sdkVersion, this.__localeToAcceptLanguage(this.__config.locale), this.__videoParticipant),
      participantType: this.videoParticipantType.GUEST,
      videoConsoleContainer: videoContext.videoConsoleContainer,
      systemConfiguration: this.__systemConfiguration,
      turnServerConfiguration: this.__systemConfiguration.turnServerConfiguration,
      locale: this.__config.locale,
    });
    return this.__startWebRTCConsole(videoConfig);
  }

  /**
   * @private
   */
  __startWebRTCConsole(videoConfig) {
    const currentFunction = 'VisitService.__startWebRTCConsole';
    this.__logger.info(currentFunction, 'Instantiating video console with video config: ', videoConfig);
    this.console = new AWCoreSDKVideoConsole(videoConfig);
    return this.console.start();
  }

  /**
   * Get an instance of {@link model.AWSDKConsumerOverrideDetails|AWSDKConsumerOverrideDetails} to use when providing consumer data during cart-based visits. <br>
   * The {@link model.AWSDKConsumerOverrideDetails|AWSDKConsumerOverrideDetails} object obtained here can be validated by an (optional) call to the {@link service.VisitService#validateConsumerOverrideDetails(model.AWSDKConsumerOverrideDetails)|validateConsumerOverrideDetails(AWSDKConsumerOverrideDetails)} <br>
   * @returns {model.AWSDKConsumerOverrideDetails} returns an instance of a {@link model.AWSDKConsumerOverrideDetails|AWSDKConsumerOverrideDetails} object
   * @since 1.4.0
   */
  newConsumerOverrideDetails() {
    return new AWSDKConsumerOverrideDetails();
  }

  /**
   * Get an instance of {@link model.AWSDKVisitSearchCriteria|AWSDKVisitSearchCriteria} to use for finding a list of visits
   * @returns {model.AWSDKVisitSearchCriteria} returns an instance of a {@link model.AWSDKVisitSearchCriteria|AWSDKVisitSearchCriteria} object
   * @since 1.4.0
   */
  getNewVisitSearchCriteria(criteria) {
    return new AWSDKVisitSearchCriteria(criteria);
  }

  /**
   * Retrieve a {@link model.AWSDKVisitContext|AWSDKVisitContext} object for the given {@link model.AWSDKConsumer|AWSDKConsumer}
   * for a visit with the specified {@link model.AWSDKProvider|AWSDKProvider} or within the specified {@link model.AWSDKFirstAvailableConfiguration}<br>
   * This is the first (required) step when initiating a visit <br>
   * @param {model.AWSDKConsumer} consumer REQUIRED the consumer for whom the visit is being scheduled
   * @param {model.AWSDKProvider|model.AWSDKFirstAvailableConfiguration} providerOrFirstAvailableConfiguration REQUIRED either the {@link model.AWSDKProvider} or {@link model.AWSDKFirstAvailableConfiguration} for which to retrieve the context
   * @returns {Promise<model.AWSDKVisitContext|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisitContext|AWSDKVisitContext} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotFound|AWSDKErrorCode.consumerNotFound}</td><td>Consumer not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotFound|AWSDKErrorCode.providerNotFound}</td><td>Provider not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerVideoNotEnabled|AWSDKErrorCode.providerVideoNotEnabled}</td><td>Provider does not have enhanced video enabled</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.privacyPolicyDisclaimerMissing|AWSDKErrorCode.privacyPolicyDisclaimerMissing}</td><td>Server is missing a consumer privacy policy disclaimer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotAvailable|AWSDKErrorCode.providerNotAvailable}</td><td>This can occur for a variety of reasons, including when the provider is offline for an on-demand visit or the provider's waiting room is closed to incoming consumers.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.waitingRoomAccessDenied|AWSDKErrorCode.waitingRoomAccessDenied}</td><td>Provider does not accept waiting room requests from consumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotLicensedForConsumerLocation|AWSDKErrorCode.providerNotLicensedForConsumerLocation}</td><td>Provider is not licensed for consumer's location</td></tr>
   * </table>
   * @since 1.0.0
   */
  getVisitContext(consumer, providerOrFirstAvailableConfiguration) {
    const currentFunction = 'VisitService.getVisitContext';
    this.__logger.debug(currentFunction, 'Started', consumer, providerOrFirstAvailableConfiguration);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (providerOrFirstAvailableConfiguration == null) {
      const error = AWSDKError.AWSDKIllegalArgument('providerOrFirstAvailableConfiguration argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumerEncryptedId = consumer.id.encryptedId;
    let providerEncryptedId = null;
    let firstAvailableConfigurationEncryptedId = null;
    if (providerOrFirstAvailableConfiguration instanceof AWSDKProvider) {
      providerEncryptedId = providerOrFirstAvailableConfiguration.id.encryptedId;
    } else if (providerOrFirstAvailableConfiguration instanceof AWSDKFirstAvailableConfiguration) {
      firstAvailableConfigurationEncryptedId = providerOrFirstAvailableConfiguration.id.encryptedId;
    } else {
      const error = AWSDKError.AWSDKIllegalArgument('providerOrFirstAvailableConfiguration argument is neither an AWSDKProvider nor an AWSDKFirstAvailableConfiguration');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'visitConfiguration');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "visitConfiguration" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('memberId', consumerEncryptedId);
    options.form.set('providerId', providerEncryptedId);
    options.form.set('firstAvailableConfigurationId', firstAvailableConfigurationEncryptedId);
    options.form.set('ignorePropagation', !!this.__config.ignorePropagation);
    options.form.set('onDemandSpecialtyId', options.form.get('firstAvailableConfigurationId'));
    return this.executeRequest(options, AWSDKVisitContextResponse)
      .then((visitContextResponse) => {
        this.updateUserAuthEntry(consumer, visitContextResponse.authToken);
        this.__logger.debug(currentFunction, 'Got response', visitContextResponse);
        const visitContext = visitContextResponse.visitContext;
        visitContext.proxies = consumer.proxies;
        return visitContext;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Retrieve a {@link model.AWSDKSpeedPass|AWSDKSpeedPass} object for the specified {@link model.AWSDKConsumer|AWSDKConsumer} and {@link model.AWSDKFirstAvailableConfiguration|AWSDKFirstAvailableConfiguration} or {@link. model.AWSDKProvider|AWSDKProvider}<br>
   * The system needs to be configured with {@link model.AWSDKSystemConfiguration#speedPassActive|AWSDKSystemConfiguration.speedPassActive} set to TRUE
   * @param {model.AWSDKConsumer} consumer the consumer to get the speed pass for
   * @param {model.AWSDKProvider|model.AWSDKFirstAvailableConfiguration} providerOrFirstAvailableConfiguration REQUIRED either the {@link model.AWSDKProvider} or {@link model.AWSDKFirstAvailableConfiguration} for which to retrieve the speedPass
   * @returns {Promise<model.AWSDKSpeedPass|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKSpeedPass|AWSDKSpeedPass} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.speedPassDisabled|AWSDKErrorCode.speedPassDisabled}</td><td>SpeedPass is not enabled on this system</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotEligibleForSpeedPass|AWSDKErrorCode.consumerNotEligibleForSpeedPass}</td><td>SpeedPass is currently not available for this consumer.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * </table>
   * @since 1.2.0
   */
  getSpeedPass(consumer, providerOrFirstAvailableConfiguration) {
    const currentFunction = 'VisitService.getSpeedPass';
    this.__logger.debug(currentFunction, 'Started', consumer, providerOrFirstAvailableConfiguration);
    if (!this.__systemConfiguration.speedPassActive) {
      const error = AWSDKError.AWSDKSpeedPassDisabled();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (providerOrFirstAvailableConfiguration == null) {
      const error = AWSDKError.AWSDKIllegalArgument('providerOrFirstAvailableConfiguration argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    let providerEncryptedId;
    let firstAvailableConfigurationEncryptedId;
    if (providerOrFirstAvailableConfiguration instanceof AWSDKProvider) {
      providerEncryptedId = providerOrFirstAvailableConfiguration.id.encryptedId;
    } else if (providerOrFirstAvailableConfiguration instanceof AWSDKFirstAvailableConfiguration) {
      firstAvailableConfigurationEncryptedId = providerOrFirstAvailableConfiguration.id.encryptedId;
    } else {
      const error = AWSDKError.AWSDKIllegalArgument('providerOrFirstAvailableConfiguration argument is neither an AWSDKProvider nor an AWSDKFirstAvailableConfiguration');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'speedPassEligibility');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a "speedPassEligibility" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('providerId', providerEncryptedId);
    /**
     * To fetch from caretalks API onDemandSpecialtyId is a required parameter
     * After renaming on demand specialty to first available configuration REQ - WS-4263
     * Here before executing request add onDemandSpecialtyId to options.form / options.body  
    */
    options.form.set('onDemandSpecialtyId', firstAvailableConfigurationEncryptedId);
    return this.executeRequest(options, AWSDKSpeedPassResponse)
      .then((speedPassResponse) => {
        this.updateUserAuthEntry(consumer, speedPassResponse.authToken);
        this.__logger.debug(currentFunction, 'Got response', speedPassResponse);
        const speedPass = speedPassResponse.speedPass;
        if (firstAvailableConfigurationEncryptedId) {
          speedPass.firstAvailableConfigurationId = firstAvailableConfigurationEncryptedId;
        }
        if (providerEncryptedId) {
          speedPass.assignedProvider = providerOrFirstAvailableConfiguration;
          this.__logger.debug(currentFunction, 'Specific provider requested, overwriting assigned provider with', providerOrFirstAvailableConfiguration, speedPassResponse.speedPass);
        }
        return speedPass;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Retrieve a {@link model.AWSDKVisitContext|AWSDKVisitContext} object for the specified {@link model.AWSDKAppointment|AWSDKAppointment} <br>
   * This is the first (required) step when initiating a visit <br>
   * @param {model.AWSDKAppointment} appointment REQUIRED the appointment the visit is being scheduled
   * @returns {Promise<model.AWSDKVisitContext|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisitContext|AWSDKVisitContext} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotFound|AWSDKErrorCode.consumerNotFound}</td><td>Consumer not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotFound|AWSDKErrorCode.providerNotFound}</td><td>Provider not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.appointmentNotFound|AWSDKErrorCode.appointmentNotFound}</td><td>Appointment not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerVideoNotEnabled|AWSDKErrorCode.providerVideoNotEnabled}</td><td>Provider does not have enhanced video enabled</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.privacyPolicyDisclaimerMissing|AWSDKErrorCode.privacyPolicyDisclaimerMissing}</td><td>Server is missing a consumer privacy policy disclaimer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotAvailable|AWSDKErrorCode.providerNotAvailable}</td><td>This can occur for a variety of reasons, including when the provider is offline for an on-demand visit or the provider's waiting room is closed to incoming consumers.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.waitingRoomAccessDenied|AWSDKErrorCode.waitingRoomAccessDenied}</td><td>Provider does not accept waiting room requests from consumer</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotLicensedForConsumerLocation|AWSDKErrorCode.providerNotLicensedForConsumerLocation}</td><td>Provider is not licensed for consumer's location</td></tr>
   * </table>
   * @since 1.1.0
   */
  getVisitContextForAppointment(appointment) {
    const currentFunction = 'VisitService.getVisitContextForAppointment';
    this.__logger.debug(currentFunction, 'Started', appointment);
    if (appointment == null) {
      const error = AWSDKError.AWSDKIllegalArgument('appointment argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(appointment instanceof AWSDKAppointment)) {
      const error = AWSDKError.AWSDKIllegalArgument('appointment argument is not of type AWSDKAppointment');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = appointment.consumer;
    const engagementEncryptedId = appointment.id.encryptedId;
    const link = this.findNamedLink(this.__links, 'visitConfiguration');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "visitConfiguration" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('engagementId', engagementEncryptedId);
    return this.executeRequest(options, AWSDKVisitContextResponse)
      .then((visitContextResponse) => {
        this.updateUserAuthEntry(consumer, visitContextResponse.authToken);
        this.__logger.debug(currentFunction, 'Got response', visitContextResponse);
        const visitContext = visitContextResponse.visitContext;
        visitContext.proxies = consumer.proxies;
        return visitContext;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Create or update a visit given a {@link model.AWSDKVisitContext|AWSDKVisitContext}<br>
   * The visitContext should be obtained by a call to {@link service.VisitService#getVisitContext|getVisitContext}
   * This method will create or update a *NEW* visit and start to calculate the visit cost.  Update will be called for
   * visits with appointments.
   * @param {model.AWSDKVisitContext} visitContext is the context of the visit that was created by a call to getVisitContext.
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.uninitialized}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotFound|AWSDKErrorCode.consumerNotFound}</td><td>Consumer not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotFound|AWSDKErrorCode.providerNotFound}</td><td>Provider not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.appointmentNotFound|AWSDKErrorCode.appointmentNotFound}</td><td>Appointment not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerVideoNotEnabled|AWSDKErrorCode.providerVideoNotEnabled}</td><td>Provider does not have enhanced video enabled</td></tr>
   * </table>
   * @since 1.1.0
   */
  createOrUpdateVisit(visitContext) {
    const currentFunction = 'VisitService.createVisit';
    this.__logger.debug(currentFunction, 'Started', visitContext);
    if (visitContext == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visitContext argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visitContext instanceof AWSDKVisitContext)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitContext argument is not of type AWSDKVisitContext');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    if (visitContext.hasAppointment) {
      return this.updateVisit(visitContext);
    }

    return this.createVisit(visitContext);
  }

  /**
   * Create an On Demand visit given a {@link model.AWSDKVisitContext|AWSDKVisitContext}<br>
   * The visitContext should be obtained by a call to {@link service.VisitService#getVisitContext|getVisitContext}
   * This method will create a *NEW* On Demand visit and start to calculate the visit cost.
   * @param {model.AWSDKVisitContext} visitContext is the context of the visit that was created by a call to getVisitContext.
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.uninitialized}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If the consumerOverrideDetails are provided and contains validation errors.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotFound|AWSDKErrorCode.consumerNotFound}</td><td>Consumer not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotFound|AWSDKErrorCode.providerNotFound}</td><td>Provider not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerVideoNotEnabled|AWSDKErrorCode.providerVideoNotEnabled}</td><td>Provider does not have enhanced video enabled</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerAlreadyInVisit|AWSDKErrorCode.consumerAlreadyInVisit}</td><td>The consumer is already in the visit</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationIncompleteAddress|AWSDKErrorCode.validationIncompleteAddress}</td><td>The address provided on the consumerOverrideDetails is incomplete</td></tr>
   * </table>
   * @since 1.0.0
   */
  createVisit(visitContext) {
    const currentFunction = 'VisitService.createVisit';
    this.__logger.debug(currentFunction, 'Started', visitContext);
    if (visitContext == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visitContext argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visitContext instanceof AWSDKVisitContext)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitContext argument is not of type AWSDKVisitContext');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visitContext.consumer;
    if (consumer == null) {
      const error = AWSDKError.AWSDKInternalError('visitContext.consumer is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(visitContext.providerId) && !visitContext.hasFirstAvailableConfiguration) {
      const error = AWSDKError.AWSDKIllegalArgument('visitContext must contain either a provider or a first available configuration');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidEnumValue(visitContext.modalityType, AWSDKVisitModalityType)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitContext.modalityType must be a valid AWSDKVisitModalityType value', 'modalityType');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visitContext.consumerOverrideDetails) {
      if (!(visitContext.consumerOverrideDetails instanceof AWSDKConsumerOverrideDetails)) {
        const error = AWSDKError.AWSDKIllegalArgument('visitContext.consumerOverrideDetails must be a valid AWSDKConsumerOverrideDetails value', 'consumerOverrideDetails');
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }
      const errors = this.validateConsumerOverrideDetails(visitContext.consumerOverrideDetails);
      if (errors.length > 0) {
        const error = AWSDKError.AWSDKValidationErrors(errors);
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      }
      // we need all these fields to either be supplied together or not at all - we can't do partial addresses
      const requiredAddressFields = [
        visitContext.consumerOverrideDetails.address1,
        visitContext.consumerOverrideDetails.city,
        visitContext.consumerOverrideDetails.zipCode,
        visitContext.consumerOverrideDetails.state,
      ];

      // checks if every field is present or not by comparing the boolean coerced value to the first value in the array
      // true if all present or all not present
      if (!requiredAddressFields.every((f, i, arr) => !!f === !!arr[0])) {
        const error = AWSDKError.AWSDKValidationIncompleteAddress('AWSDKConsumerOverrideDetails', visitContext.consumerOverrideDetails);
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }
    }
    const link = this.findNamedLink(this.__links, 'buildVisit');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "buildVisit" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.headers['Content-Type'] = 'application/json';
    const visitRequestBody = new AWSDKVisitRequestBody(visitContext, !!this.__config.ignorePropagation);
    options.body = visitRequestBody.getAsJSONString(this.__systemConfiguration);
    return this.executeRequest(options, AWSDKVisitResponse)
      .then((visitResponse) => {
        this.__logger.debug(currentFunction, 'Got response', visitResponse);
        this.updateUserAuthEntry(consumer, visitResponse.authToken);
        const visit = visitResponse.visit;
        if (visitContext.firstAvailableData == null) {
          visit.firstAvailableConfigurationId = visitContext.firstAvailableConfigurationId;
        } else {
          visit.isMatchmaking = true; // first-available search has already been performed; allow the visit to acquire a WR ticket
        }
        this.__logger.info(currentFunction, 'Completed');
        return visit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Search for visits for a given consumer. The {@link model.AWSDKVisitSearchCriteria|AWSDKVisitSearchCriteria} can be obtained from {@link service.VisitService#getNewVisitSearchCriteria|getNewVisitSearchCriteria}.
   * @param {model.AWSDKConsumer} consumer the consumer to get the visits for.
   * @param {model.AWSDKVisitSearchCriteria} visitSearchCriteria the criteria to filter the result by.
   * @returns {Promise<model.AWSDKVisit[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.uninitialized}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * </table>
   * @since 1.4.0
   */
  searchVisits(consumer, visitSearchCriteria) {
    const currentFunction = 'VisitService.searchVisits';
    this.__logger.debug(currentFunction, 'Started', consumer, visitSearchCriteria);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    const link = this.findNamedLink(consumer.links, 'visitSearch');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "visitSearch" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, true);

    if (visitSearchCriteria) {
      if (!(visitSearchCriteria instanceof AWSDKVisitSearchCriteria)) {
        const error = AWSDKError.AWSDKIllegalArgument('visitSearchCriteria argument is not of type AWSDKVisitSearchCriteria');
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }

      if (visitSearchCriteria.startDate && !(visitSearchCriteria.startDate instanceof Date)) {
        const error = AWSDKError.AWSDKValidationError('startDate');
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }

      if (visitSearchCriteria.endDate && !(visitSearchCriteria.endDate instanceof Date)) {
        const error = AWSDKError.AWSDKValidationError('endDate');
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }

      if (visitSearchCriteria.maxResults && visitSearchCriteria.maxResults < 0) {
        const error = AWSDKError.AWSDKValidationError('maxResults');
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }

      if (visitSearchCriteria.sourceId && !Validator.isStringType(visitSearchCriteria.sourceId)) {
        const error = AWSDKError.AWSDKValidationError('sourceId');
        this.__logger.error(currentFunction, 'error', error);
        return Promise.reject(error);
      }

      let stringDispositions = null;
      if (visitSearchCriteria.dispositions) {
        try {
          stringDispositions = visitSearchCriteria.dispositions.map(s => GenericParser.parseAndConvertEnumValue(s));
        } catch (ignored) {
          const error = AWSDKError.AWSDKValidationError('dispositions');
          this.__logger.error(currentFunction, 'error', error);
          return Promise.reject(error);
        }
      }

      if (visitSearchCriteria.maxResults) options.form.set('pageSize', visitSearchCriteria.maxResults);
      if (visitSearchCriteria.startDate) options.form.set('startDate', Util.formatISODateTime(visitSearchCriteria.startDate));
      if (visitSearchCriteria.endDate) options.form.set('endDate', Util.formatISODateTime(visitSearchCriteria.endDate));
      if (visitSearchCriteria.sourceId) options.form.set('sourceId', visitSearchCriteria.sourceId);
      if (visitSearchCriteria.removeReconnectedVisits) options.form.set('removeReconnectedVisits', visitSearchCriteria.removeReconnectedVisits);
      if (stringDispositions) {
        stringDispositions.forEach((s) => {
          options.form.append('disposition', s);
        });
      }
    }

    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKVisitsResponse)
      .then((visitsResponse) => {
        this.__logger.debug(currentFunction, 'Got response', visitsResponse);
        this.updateUserAuthEntry(consumer, visitsResponse.authToken);
        return visitsResponse.visits;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Create a visit given a {@link model.AWSDKSpeedPass|AWSDKSpeedPass}<br>
   * This method will create a *NEW* visit from a {@link model.AWSDKSpeedPass|AWSDKSpeedPass}.
   * @param {model.AWSDKSpeedPass} AWSDKSpeedPass is the {@link model.AWSDKSpeedPass|AWSDKSpeedPass} acquired from {@link service.VisitService#getSpeedPass|getSpeedPass}.
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * </table>
   * @since 1.2.0
   */
  createVisitFromSpeedPass(speedPass) {
    const currentFunction = 'VisitService.createVisitFromSpeedPass';
    this.__logger.debug(currentFunction, 'Started', speedPass);
    if (!(speedPass instanceof AWSDKSpeedPass)) {
      const error = AWSDKError.AWSDKIllegalArgument('speedPass argument is not of type AWSDKSpeedPass');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'buildVisit');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "buildVisit" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = speedPass.consumer;
    const options = this.generateOptions('POST', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.headers['Content-Type'] = 'application/json';
    options.body = speedPass.buildVisitRequestJson();
    return this.executeRequest(options, AWSDKVisitResponse)
      .then((visitResponse) => {
        this.__logger.debug(currentFunction, 'Got response', visitResponse);
        this.updateUserAuthEntry(consumer, visitResponse.authToken);
        this.__logger.info(currentFunction, 'Completed');
        const visit = visitResponse.visit;
        if (speedPass.firstAvailableConfigurationId) {
          visit.firstAvailableConfigurationId = speedPass.firstAvailableConfigurationId;
        }
        return visitResponse.visit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Update a visit given a {@link model.AWSDKVisitContext|AWSDKVisitContext}<br>
   * The visitContext should be obtained by a call to {@link service.VisitService#getVisitContext|getVisitContext}
   * This method will update a visit and calculate the visit cost.
   * @param {model.AWSDKVisitContext} visitContext is the context of the visit that was created by a call to getVisitContext.
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.uninitialized}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotFound|AWSDKErrorCode.consumerNotFound}</td><td>Consumer not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotFound|AWSDKErrorCode.providerNotFound}</td><td>Provider not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.appointmentNotFound|AWSDKErrorCode.appointmentNotFound}</td><td>Appointment not found</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerVideoNotEnabled|AWSDKErrorCode.providerVideoNotEnabled}</td><td>Provider does not have enhanced video enabled</td></tr>
   * </table>
   * @since 1.1.0
   */
  updateVisit(visitContext) {
    const currentFunction = 'VisitService.updateVisit';
    this.__logger.debug(currentFunction, 'Started', visitContext);
    if (visitContext == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visitContext argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visitContext instanceof AWSDKVisitContext)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitContext argument is not of type AWSDKVisitContext');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visitContext.consumer;
    const engagementEncryptedId = visitContext.engagementId;
    if (consumer == null) {
      const error = AWSDKError.AWSDKInternalError('visitContext.consumer is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(engagementEncryptedId)) {
      const error = AWSDKError.AWSDKInternalError('visitContext.engagementId is not valid');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidEnumValue(visitContext.modalityType, AWSDKVisitModalityType)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitContext.modalityType must be a valid AWSDKVisitModalityType value');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'buildVisit');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "buildVisit" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.headers['Content-Type'] = 'application/json';
    const visitRequestBody = new AWSDKVisitRequestBody(visitContext);
    options.body = visitRequestBody.getAsJSONString(this.__systemConfiguration);
    return this.executeRequest(options, AWSDKVisitResponse)
      .then((visitResponse) => {
        this.updateUserAuthEntry(consumer, visitResponse.authToken);
        this.__logger.debug(currentFunction, 'Got response', visitResponse);
        this.__logger.info(currentFunction, 'Completed');
        return visitResponse.visit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.Unpublished|Unpublished}</li>
   * <li>{@link model.AWSDKDisposition.Unscheduled|Unscheduled}</li>
   * <li>{@link model.AWSDKDisposition.Scheduled|Scheduled}</li>
   * <li>{@link model.AWSDKDisposition.Parked|Parked}</li>
   * <li>{@link model.AWSDKDisposition.PreVisitScreening|PreVisitScreening}</li>
   * </ul>
   * </p>
   * @private
   */
  getVisitCost(visit) {
    const currentFunction = 'VisitService.getVisitCost';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (visit == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit is not instance of AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visit.consumer;
    const costLink = this.findNamedLink(visit.links, 'cost');
    if (!Validator.isValidLink(costLink)) {
      const error = AWSDKError.AWSDKInternalError('visit does not have "cost" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', costLink.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKVisitCostResponse)
      .then((visitCostResponse) => {
        this.__logger.debug(currentFunction, 'Got response', visitCostResponse);
        this.updateUserAuthEntry(consumer, visitCostResponse.authToken);
        return visitCostResponse.cost;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        this.__serverLogger.logToServer('error',
          'Consumer {0} has failed to get a visit cost using Consumer Web SDK',
          [
            visit.getServerLogParam(),
          ]);
        throw error;
      });
  }

  /**
   * This method will wait for the cost calculation to finish processing.<br>
   * @param {model.AWSDKVisit} visit is the object that was created by a call to createVisit.
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.pollingTimeout|AWSDKErrorCode.pollingTimeout}</td><td>The cost calculation exceeded its allotted polling time</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.Unpublished|Unpublished}</li>
   * <li>{@link model.AWSDKDisposition.Unscheduled|Unscheduled}</li>
   * <li>{@link model.AWSDKDisposition.Scheduled|Scheduled}</li>
   * <li>{@link model.AWSDKDisposition.Parked|Parked}</li>
   * <li>{@link model.AWSDKDisposition.PreVisitScreening|PreVisitScreening}</li>
   * </ul>
   * </p>
   * @since 1.0.0
   */
  waitForVisitCostCalculationToFinish(visit) {
    const currentFunction = 'VisitService.waitForVisitCostCalculationToFinish';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (visit == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit is not instance of AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const interval = this.__config.visitCostPollingInterval;
    const endTime = Number(new Date()) + (this.__config.visitCostPollingTimeout);
    let errorCount = 0;
    const errorLimit = 3;
    const checkCondition = (resolve, reject) => {
      this.getVisitCost(visit)
        .then((visitCost) => {
          this.__logger.debug(currentFunction, 'Got response', visitCost);
          if (visitCost && visitCost.costCalculationStatus !== 'PROCESSING') {
            const updatedVisit = visit;
            updatedVisit.cost = visitCost;
            this.__logger.info(currentFunction, 'Completed');
            resolve(updatedVisit);
          } else if (Number(new Date()) < endTime) {
            setTimeout(checkCondition, interval, resolve, reject);
          } else {
            errorCount += 3;
            const error = AWSDKError.AWSDKPollingTimeout();
            this.__logger.error(currentFunction, 'Polling request timed out', error);
            reject(error);
          }
        })
        .catch((error) => {
          errorCount += 1;
          this.__logger.warn(currentFunction, 'error during polling', errorCount, error);
          if (errorCount >= errorLimit) {
            this.__logger.error(currentFunction, 'error', error);
            reject(error);
          } else {
            setTimeout(checkCondition, interval, resolve, reject);
          }
        });
    };
    return new Promise(checkCondition);
  }

  /**
   * This method will put the consumer into the provider's waiting room.<br>
   * @param {model.AWSDKVisit} visit is the object that was created by a call to createVisit
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.consumerAlreadyInVisit}</td><td>The consumer is already in a visit</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerBusy}</td><td>The consumer is busy</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.creditCardDeclinedError}</td><td>Credit card used as payment method has been declined</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.creditCardInvalidCVV}</td><td>Invalid CVV code for credit card used as payment method</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.creditCardInvalidZipCode}</td><td>Invalid zip code for credit card used as payment method</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.creditCardValidationError}</td><td>Credit card used as payment method validation has failed</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerBusy}</td><td>The provider is busy and no longer available</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotAvailable|AWSDKErrorCode.providerNotAvailable}</td><td>This can occur for a variety of reasons, including when the provider is offline for an on-demand visit or the provider's waiting room is closed to incoming consumers.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotLicensedForConsumerLocation}</td><td>The provider is not licensed for the consumer's legal residence</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.startVisitFailed}</td><td>Unable to start the visit.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitConsumerCallbackInvalid}</td><td>The visit has an invalid consumer callback number.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitExpired}</td><td>The visit has already ended.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid}</td><td>The visit is no longer valid.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.waitingRoomAccessDenied}</td><td>The consumer was denied access to the provider's waiting room, possibly due to provider's configuration.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalUsage}</td><td>The visit specified a first available configuration but did not contain the required first-available data</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalUsage}</td><td>The visit specified a null cost or the cost object did not have a status value of finished</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalUsage}</td><td>The visit specified guest invitation emails but the system is not configured for multiple video guests</td></tr>
   * </table>
   * @since 1.0.0
   */
  startVisit(visit) {
    const currentFunction = 'VisitService.startVisit';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (visit == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.firstAvailableConfigurationId != null && visit.firstAvailableData == null) {
      const desc = 'An AWSDKFirstAvailableConfiguration was specified without requisite first-available data';
      const corrected = 'Obtain first-available data for the AWSDKFirstAvailableConfiguration prior to starting the visit';
      const error = AWSDKError.AWSDKIllegalUsage(desc, corrected);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.firstAvailableConfigurationId == null && visit.firstAvailableData != null) {
      const desc = 'First-available data was specified without a requisite AWSDKFirstAvailableConfiguration';
      const corrected = 'Use an AWSDKFirstAvailableConfiguration to get first-available data for a visit';
      const error = AWSDKError.AWSDKIllegalUsage(desc, corrected);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.cost == null) {
      const desc = 'The visit cost is null';
      const corrected = 'Ensure that you call the VisitService#waitForVisitCostCalculationToFinish() before calling this method';
      const error = AWSDKError.AWSDKIllegalUsage(desc, corrected);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.cost.costCalculationStatus !== 'FINISHED') {
      const desc = 'The visit cost calculation has not finished';
      const corrected = 'Ensure that you call the VisitService#waitForVisitCostCalculationToFinish() before calling this method';
      const error = AWSDKError.AWSDKIllegalUsage(desc, corrected);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.guestInvitationEmails != null && visit.guestInvitationEmails.length !== 0 && !this.__systemConfiguration.multipleVideoParticipantsEnabled) {
      const desc = 'The system is not configured to allow multiple guests to join a video visit';
      const corrected = 'Configure the system to allow multiple guests to join a video visit';
      const error = AWSDKError.AWSDKIllegalUsage(desc, corrected);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'startVisit');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "startVisit" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('engagementId', visit.id.encryptedId);
    options.form.set('connectionType', 'Web');
    options.form.set('isMatchmakingVisit', visit.isMatchmaking);
    options.form.set('preferredVendor', AWCoreSDKVendor.PEXIP);
    const firstAvailableData = visit.firstAvailableData;
    if (visit.firstAvailableConfigurationId != null && firstAvailableData != null) {
      const prov = firstAvailableData.provider;
      options.form.set('providerId', prov.id.encryptedId);
      options.form.set('firstAvailableSearchStartTime', firstAvailableData.getFormattedStartTime());
      options.form.set('firstAvailableSearchEndTime', firstAvailableData.getFormattedEndTime());
      options.form.set('firstAvailableProviderAskCount', firstAvailableData.firstAvailableProviderAskCount);
    }
    if (visit.guestInvitationEmails != null) {
      visit.guestInvitationEmails.forEach((email) => {
        options.form.append('inviteEmails', email);
      });
    }
    return this.executeRequest(options)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.__logger.info(currentFunction, 'Completed');
        const updatedVisit = this.getVisit(visit);
        this.__serverLogger.logToServer('info',
          'Consumer {0} has successfully started a visit using Consumer Web SDK',
          [
            visit.getServerLogParam(),
          ]);
        return updatedVisit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        this.__serverLogger.logToServer('info',
          'Consumer {0} has failed to start a visit using Consumer Web SDK',
          [
            visit.getServerLogParam(),
          ]);
        throw error;
      });
  }

  /**
   * This method will wait for the telehealth video to start by getting an updated {@link model.AWSDKVisit|AWSDKVisit} and checking the telehealthVideoStarted property.
   * The call will also return if the visit has finished.<br>
   * @param {model.AWSDKVisit} visit is the object that was created by a call to createVisit.
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.videoConnectionFailure|AWSDKErrorCode.videoConnectionFailure}</td><td>Connection to the Video server failed.</td></tr>
   * </table>
   * @since 1.0.0
   */
  waitForTelehealthVideoToStart(visit) {
    const currentFunction = 'VisitService.waitForTelehealthVideoToStart';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (visit == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.telehealthVideoStarted) {
      this.__logger.warn(currentFunction, 'Completed - telehealthVideo already started');
      return Promise.resolve(visit);
    }
    if (visit.finished) {
      this.__logger.warn(currentFunction, 'Completed - visit already finished');
      return Promise.resolve(visit);
    }
    const interval = this.__config.visitPollingInterval;
    let errorCount = 0;
    const errorLimit = 3;
    const checkCondition = (resolve, reject) => {
      this.getVisit(visit)
        .then((updatedVisit) => {
          if (updatedVisit.telehealthVideoStarted) {
            this.__logger.info(currentFunction, 'Completed - telehealthVideo started');
            resolve(updatedVisit);
          } else if (updatedVisit.finished) {
            this.__logger.info(currentFunction, 'Completed - visit finished');
            resolve(updatedVisit);
          } else {
            setTimeout(checkCondition, interval, resolve, reject);
          }
        })
        .catch((error) => {
          errorCount += 1;
          this.__logger.warn(currentFunction, 'error during polling', errorCount, error);
          if (errorCount >= errorLimit) {
            this.__logger.error(currentFunction, 'error', error);
            reject(error);
          } else {
            setTimeout(checkCondition, interval, resolve, reject);
          }
        });
    };
    return new Promise(checkCondition);
  }

  /**
   * This method will wait for the provider to join the visit.<br>
   * @param {model.AWSDKVisit} visit is the object that was created by a call to {@link service.VisitService#createVisit|createVisit}.
   * @param {function} [callback] the callback to be called on before visit starts
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * </table>
   * @since 1.0.0
   */
  waitForProviderToJoinVisit(visit, callback) {
    const currentFunction = 'VisitService.waitForProviderToJoinVisit';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (visit == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (callback != null && !(callback instanceof Function)) {
      const error = AWSDKError.AWSDKIllegalArgument('callback argument is not a function');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.providerConnected) {
      this.__logger.warn(currentFunction, 'Completed - provider already connected');
      return Promise.resolve(visit);
    }
    if (visit.finished) {
      this.__logger.warn(currentFunction, 'Completed - visit already finished');
      return Promise.resolve(visit);
    }
    const interval = this.__config.visitPollingInterval;
    let errorCount = 0;
    const errorLimit = 3;
    const checkCondition = (resolve, reject) => {
      this.getVisit(visit)
        .then((updatedVisit) => {
          if (updatedVisit.providerConnected) {
            this.__logger.info(currentFunction, 'Completed - provider connected');
            resolve(updatedVisit);
          } else if (updatedVisit.finished) {
            this.__logger.info(currentFunction, 'Completed - visit finished');
            resolve(updatedVisit);
          } else {
            if (callback != null) {
              try {
                callback(updatedVisit);
              } catch (error) {
                this.__logger.error(currentFunction, 'error', error);
              }
            }
            setTimeout(checkCondition, interval, resolve, reject);
          }
        })
        .catch((error) => {
          errorCount += 1;
          this.__logger.warn(currentFunction, 'error during polling', errorCount, error);
          if (errorCount >= errorLimit) {
            this.__logger.error(currentFunction, 'error', error);
            reject(error);
          } else {
            setTimeout(checkCondition, interval, resolve, reject);
          }
        });
    };
    return new Promise(checkCondition);
  }

  /**
   * This method will wait for the visit to finish.
   * @param {model.AWSDKVisit} visit is the object that was created by a call to {@link service.VisitService#createVisit|createVisit}.
   * @param {function} [callback] the callback to be called on while the visit is in progress
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * </table>
   * @since 1.0.0
   */
  waitForVisitToFinish(visit, callback) {
    const currentFunction = 'VisitService.waitForVisitToFinish';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (callback != null && !(callback instanceof Function)) {
      const error = AWSDKError.AWSDKIllegalArgument('callback argument is not a function');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.finished) {
      this.__logger.warn(currentFunction, 'Completed - visit already finished');
      return Promise.resolve(visit);
    }
    const interval = this.__config.visitPollingInterval;
    let errorCount = 0;
    const errorLimit = 3;
    const checkCondition = (resolve, reject) => {
      this.getVisit(visit)
        .then((updatedVisit) => {
          if (updatedVisit.finished) {
            this.__logger.info(currentFunction, 'Completed');
            resolve(updatedVisit);
          } else {
            if (callback != null) {
              try {
                callback(updatedVisit);
              } catch (error) {
                this.__logger.error(currentFunction, 'error', error);
              }
            }
            setTimeout(checkCondition, interval, resolve, reject);
          }
        })
        .catch((error) => {
          errorCount += 1;
          this.__logger.warn(currentFunction, 'error during polling', errorCount, error);
          if (errorCount >= errorLimit) {
            this.__logger.error(currentFunction, 'error', error);
            reject(error);
          } else {
            setTimeout(checkCondition, interval, resolve, reject);
          }
        });
    };
    return new Promise(checkCondition);
  }

  /**
   * This method will get an updated Visit
   * @param {model.AWSDKVisit} visit is the object that was created by a call to {@link service.VisitService#createVisit|createVisit}.
   * @param {boolean} [alwaysRetrieveChatMessages] TRUE if the visit response should contain the full chat report, including any messages added by the provider, even if an assistant has not initiated the chat.
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * </table>
   * @since 1.0.0
   */
  getVisit(visit, alwaysRetrieveChatMessages = false) {
    const currentFunction = 'VisitService.getVisit';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (visit == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (typeof (alwaysRetrieveChatMessages) !== 'boolean') {
      const error = AWSDKError.AWSDKIllegalArgument('alwaysRetrieveChatMessages argument is not of type boolean');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', visit.href, true);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('alwaysRetrieveChatMessages', alwaysRetrieveChatMessages);
    const visitCost = visit.cost;
    return this.executeRequest(options, AWSDKVisitResponse)
      .then((visitResponse) => {
        this.updateUserAuthEntry(visit.consumer, visitResponse.authToken);
        this.__logger.debug(currentFunction, 'Got response', visitResponse);
        const updatedVisit = visitResponse.visit;
        updatedVisit.cost = visitCost;
        return updatedVisit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        this.__serverLogger.logToServer('error',
          'Consumer {0} has failed to get a visit using Consumer Web SDK',
          [
            visit.getServerLogParam(),
          ]);
        throw error;
      });
  }

  /**
   * @private
   */
  __updateConnectionStatus(visit, vendorId) {
    const currentFunction = 'VisitService.__updateConnectionStatus';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (visit == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', visit.href, true);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('accountId', visit.consumer.id.encryptedId);
    options.form.set('connected', 'true');
    options.form.set('vendorId', vendorId);
    const visitCost = visit.cost;
    return this.executeRequest(options, AWSDKVisitResponse)
      .then((visitResponse) => {
        this.__logger.debug(currentFunction, 'Got response', visitResponse);
        this.updateUserAuthEntry(visit.consumer, visitResponse.authToken);
        const updatedVisit = visitResponse.visit;
        updatedVisit.cost = visitCost;
        this.__logger.info(currentFunction, 'Completed');
        return updatedVisit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        this.__serverLogger.logToServer('error',
          'Consumer {0} has failed to update his connection status for the visit using Consumer Web SDK',
          [
            visit.getServerLogParam(),
          ]);
        throw error;
      });
  }

  /**
   * This method will cancel the Visit that has not started.<br>
   * @param {model.AWSDKVisit} visit is the object that was created by a call to {@link service.VisitService#createVisit|createVisit}.
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.Unpublished|Unpublished}</li>
   * <li>{@link model.AWSDKDisposition.Unscheduled|Unscheduled}</li>
   * <li>{@link model.AWSDKDisposition.Scheduled|Scheduled}</li>
   * <li>{@link model.AWSDKDisposition.Parked|Parked}</li>
   * <li>{@link model.AWSDKDisposition.PreVisitScreening|PreVisitScreening}</li>
   * </ul>
   * </p>
   * @since 1.0.0
   */
  cancelVisit(visit) {
    const currentFunction = 'VisitService.cancelVisit';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (visit == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'cancelVisit');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "cancelVisit" link');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url, false);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        const updatedVisit = this.getVisit(visit);
        if (updatedVisit) {
          this.__serverLogger.logToServer('info',
            'Consumer {0} has successfully canceled a visit using Consumer Web SDK',
            [
              visit.getServerLogParam(),
            ]);
        }
        this.__logger.info(currentFunction, 'Completed');
        return updatedVisit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        this.__serverLogger.logToServer('error',
          'Consumer {0} has failed to cancel the visit using Consumer Web SDK',
          [
            visit.getServerLogParam(),
          ]);
        throw error;
      });
  }

  /**
   * Ends an in progress visit.
   * @param {model.AWSDKVisit} visit The visit that is in progress
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an updated {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.InProgress|InProgress}</li>
   * </ul>
   * </p>
   * @since 1.2.0
   */
  endVisit(visit) {
    const currentFunction = 'VisitService.endVisit';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'endVisit');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "endVisit" link');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url, false);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        const updatedVisit = this.getVisit(visit);
        if (updatedVisit) {
          this.__serverLogger.logToServer('info',
            'Consumer {0} has successfully ended a visit using Consumer Web SDK',
            [
              visit.getServerLogParam(),
            ]);
        }
        this.__logger.info(currentFunction, 'Completed');
        if (this.console) {
          this.console.leaveVisit(true);
        }
        return updatedVisit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        this.__serverLogger.logToServer('error',
          'Consumer {0} has failed to end a visit using Consumer Web SDK',
          [
            visit.getServerLogParam(),
          ]);
        throw error;
      });
  }

  /**
   * launchTelehealthVideo<br>
   * This method will launch the telehealth video.
   * <p>
   * NOTE: <em><b>The telehealth client is only supported on Windows.</b></em>
   * To successfully initiate a visit, please switch to a WebRTC implementation as described in {@link service.VisitService#startWebRTCVisit()|VisitService.startWebRTCVisit()}.
   * </p>
   * @param {model.AWSDKVisit} visit is the object returned by a call to {@link service.VisitService#startVisit|startVisit}.
   * @returns {Promise<boolean|error.AWSDKError>} Returns a promise that will be resolved to an {@link boolean} indicating if the
   * telehealth video has launched successfully or will be rejected with an {@link error.AWSDKError|AWSDKError} if the input is incorrect.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.unsupportedVideoPlatform|AWSDKErrorCode.unsupportedVideoPlatform}</td><td>The system is configured with an unsupported video platform.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.0.0
   */
  launchTelehealthVideo(visit) {
    const currentFunction = 'VisitService.launchTelehealthVideo';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!this.__systemConfiguration.webRTCEnabled) {
      const error = AWSDKError.AWSDKUnsupportedVideoPlatform();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'startElectronVisitURL');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError("visit argument does not have a valid 'startElectronVisitURL' link entry");
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    return new Promise((resolve, reject) => {
      this.__customProtocolHandler.launchUri(link.url, reject, resolve);
    })
      .then(() => {
        this.__logger.info(currentFunction, 'Completed - launched ok returned true');
        return true;
      })
      .catch(() => {
        this.__logger.info(currentFunction, 'Completed - not launched returned false');
        return false;
      });
  }

  /**
   * After completing a visit via the Video Visit console activity, this method will return the
   * {@link model.AWSDKVisitSummary|AWSDKVisitSummary} with the final details from the provider
   * @param {model.AWSDKVisit} visit is the object returned by a call to startVisit.
   * @returns {Promise<model.AWSDKVisitSummary|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisitSummary|AWSDKVisitSummary}
   * or will be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.ProviderWrapup|ProviderWrapup}</li>
   * <li>{@link model.AWSDKDisposition.Completed|Completed}</li>
   * <li>{@link model.AWSDKDisposition.Expired|Expired}</li>
   * </ul>
   * </p>
   * @since 1.0.0
   */
  getVisitSummary(visit) {
    const currentFunction = 'VisitService.getVisitSummary';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (visit == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visit.consumer;
    const link = this.findNamedLink(visit.links, 'wrapUp');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "wrapUp" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKVisitSummaryResponse)
      .then((visitSummaryResponse) => {
        this.__logger.debug(currentFunction, 'Got response', visitSummaryResponse);
        this.updateUserAuthEntry(consumer, visitSummaryResponse.authToken);
        return visitSummaryResponse.visitSummary;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  get telehealthVideoInstallUrl() {
    if (this.__systemConfiguration.webRTCEnabled) {
      return this.__systemConfiguration.electronDownloadUrl;
    }

    return (navigator.platform.toUpperCase().indexOf('MAC') >= 0) ?
      this.__systemConfiguration.telehealthVideoInstallUrlForMac :
      this.__systemConfiguration.telehealthVideoInstallUrlForWindows;
  }

  /**
   * This method will apply a coupon code to the visit
   * @param {model.AWSDKVisit} visit is the object returned by a call to {@link service.VisitService#startVisit|startVisit}.
   * @param {String} couponCode is the string of the coupon code
   * @returns {Promise<model.AWSDKVisitCost|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisitCost|AWSDKVisitCost} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.costCalculationNotFinishedError|AWSDKErrorCode.costCalculationNotFinishedError}</td><td>Cost calculation has not finished.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.invalidCouponError|AWSDKErrorCode.invalidCouponError}</td><td>Coupon code is invalid.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.Unpublished|Unpublished}</li>
   * <li>{@link model.AWSDKDisposition.Unscheduled|Unscheduled}</li>
   * <li>{@link model.AWSDKDisposition.Scheduled|Scheduled}</li>
   * <li>{@link model.AWSDKDisposition.Parked|Parked}</li>
   * <li>{@link model.AWSDKDisposition.PreVisitScreening|PreVisitScreening}</li>
   * </ul>
   * </p>
   * @since 1.0.0
   */
  applyCouponCode(visit, couponCode) {
    const currentFunction = 'VisitService.applyCouponCode';
    this.__logger.debug(currentFunction, 'Started', visit, couponCode);
    if (visit == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (couponCode == null) {
      const error = AWSDKError.AWSDKIllegalArgument('couponCode argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(couponCode)) {
      const error = AWSDKError.AWSDKValidationError('couponCode');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'cost');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "cost" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.cost == null || visit.cost.costCalculationStatus !== 'FINISHED') {
      const error = AWSDKError.AWSDKCostCalculationNotFinishedError();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visit.consumer;
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('couponCode', couponCode);
    return this.executeRequest(options, AWSDKVisitCostResponse)
      .then((visitCostResponse) => {
        this.updateUserAuthEntry(consumer, visitCostResponse.authToken);
        this.__logger.debug(currentFunction, 'Got response', visitCostResponse);
        this.__logger.info(currentFunction, 'Completed');
        return visitCostResponse.cost;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        if (error.httpResponseCode === 400) {
          return Promise.reject(AWSDKError.AWSDKInvalidCouponError('invalid coupon'));
        }
        return Promise.reject(error);
      });
  }

  /**
   * This method will send the visit summary to an array of email addresses, fax numbers, or both. Either must be provided.
   * @param {model.AWSDKVisitSummary} visitSummary is the object returned by a call to method {@link service.VisitService#getVisitSummary|getVisitSummary}.
   * @param {boolean} hipaaNoticeAccepted is whether or not the HIPAA notice was accepted.
   * @param {String[]} [emailAddresses] the email addresses to share the visit summary with
   * @param {String[]} [faxNumbers] the fax numbers to share the visit summary with
   * @returns {Promise<model.AWSDKVisitSummary|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisitSumary|AWSDKVisitSummary} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>Parameters emailAddresses or faxNumbers contain invalid values.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitSummaryNotSentError|AWSDKErrorCode.visitSummaryNotSentError}</td><td>Visit Summary couldn't be sent.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.ProviderWrapup|ProviderWrapup}</li>
   * <li>{@link model.AWSDKDisposition.Completed|Completed}</li>
   * <li>{@link model.AWSDKDisposition.Expired|Expired}</li>
   * </ul>
   * </p>
   * @since 1.1.0
   */
  sendVisitSummary(visitSummary, hipaaNoticeAccepted, emailAddresses, faxNumbers) {
    const currentFunction = 'VisitSummary.sendVisitSummary';
    this.__logger.debug(currentFunction, 'Started', visitSummary, hipaaNoticeAccepted, emailAddresses, faxNumbers);
    if (!(visitSummary instanceof AWSDKVisitSummary)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitSummary is not of type AWSDKVisitSummary');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (typeof (hipaaNoticeAccepted) !== 'boolean') {
      const error = AWSDKError.AWSDKIllegalArgument('hipaaNoticeAccepted argument is not of type boolean');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (emailAddresses && !Array.isArray(emailAddresses)) {
      const error = AWSDKError.AWSDKIllegalArgument('emailAddresses must be of type Array');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (faxNumbers && !Array.isArray(faxNumbers)) {
      const error = AWSDKError.AWSDKIllegalArgument('faxNumbers must be of type Array');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if ((!emailAddresses || emailAddresses.length === 0) && (!faxNumbers || faxNumbers.length === 0)) {
      const error = AWSDKError.AWSDKIllegalArgument('emailAddresses or faxNumbers must be provided');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const validationErrors = [];
    if (emailAddresses) {
      emailAddresses.forEach((emailAddress) => {
        if (!Validator.isEmailValid(emailAddress)) {
          validationErrors.push(AWSDKError.AWSDKValidationError('emailAddresses', emailAddress));
        }
      });
    }
    if (faxNumbers) {
      faxNumbers.forEach((faxNumber) => {
        if (!Validator.isPhoneNumberValid(faxNumber)) {
          validationErrors.push(AWSDKError.AWSDKValidationError('faxNumbers', faxNumber));
        }
      });
    }
    if (validationErrors.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(validationErrors);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visitSummary.links, 'sendReport');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visitSummary argument does not have a valid "sendReport" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visitSummary.consumer;
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    options.form.set('hipaaNoticeAccepted', hipaaNoticeAccepted);

    if (emailAddresses) emailAddresses.forEach(item => options.form.append('emails', item));
    if (faxNumbers) faxNumbers.forEach(item => options.form.append('faxNumbers', item));

    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return visitSummary;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        if (error.httpResponseCode === 400) {
          return Promise.reject(AWSDKError.AWSDKVisitSummaryNotSentError());
        }
        return Promise.reject(error);
      });
  }

  /**
   * This method will submit the consumer's response to a visit feedback question
   * @param {(model.AWSDKVisitSummary|model.AWSDKVisit)} visitOrVisitSummary either the visit or visit summary to add feedback for.
   * @param {String} question is the string representing the question that the consumer was asked.
   * @param {String} answer is the string representing the answer that the consumer provided for the question asked.
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.feedbackNotSubmittedError|AWSDKErrorCode.feedbackNotSubmittedError}</td><td>Feedback could not be added.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>All dispositions supported</li>
   * </ul>
   * </p>
   * @since 1.0.0
   */
  addFeedback(visitOrVisitSummary, question, answer) {
    const currentFunction = 'VisitService.addFeedback';
    this.__logger.debug(currentFunction, 'Started', visitOrVisitSummary, question, answer);
    if (!(visitOrVisitSummary instanceof AWSDKVisitSummary) && !(visitOrVisitSummary instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitSummary argument is not of type AWSDKVisitSummary or AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (question == null) {
      const error = AWSDKError.AWSDKIllegalArgument('question argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(question)) {
      const error = AWSDKError.AWSDKValidationError('question');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (answer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('answer argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(answer)) {
      const error = AWSDKError.AWSDKValidationError('answer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visitOrVisitSummary.links, 'feedback');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visitSummary argument does not have a valid "feedback" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visitOrVisitSummary.consumer;
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('feedbackQuestion', question);
    options.form.set('feedbackAnswer', answer);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        if (error.httpResponseCode === 400) {
          return Promise.reject(AWSDKError.AWSDKFeedbackNotSubmittedError());
        }
        return Promise.reject(error);
      });
  }

  /**
   * This method allows the consumer to submit an NPS rating for a visit.
   * This rating is based on a one to nine rating system, where a one represents the lowest/worst experience, and a nine the highest/best experience.
   * @param {model.AWSDKVisit} visit is the visit to submit the NPS rating for.
   * @param {Integer} netProviderScore is the given rating
   * @param {String} feedback is an optional text comment to be stored with the rating
   *
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.npsRatingNotSubmittedError|AWSDKErrorCode.npsRatingNotSubmittedError}</td><td>NPS couldn't be added.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>All dispositions supported</li>
   * </ul>
   * </p>
   * @since 4.2.0
   */
  addNpsRating(visit, netPromoterScore, feedback) {
    const currentFunction = 'VisitService.addNpsRating';
    this.__logger.debug(currentFunction, 'Started', visit, netPromoterScore, feedback);
    if (visit == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (netPromoterScore == null) {
      const error = AWSDKError.AWSDKIllegalArgument('netPromoterScore argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (netPromoterScore !== parseInt(netPromoterScore, 10)) {
      const error = AWSDKError.AWSDKValidationError('netPromoterScore');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (feedback != null && !Validator.isStringType(feedback)) {
      const error = AWSDKError.AWSDKIllegalArgument('feedback argument is not of type string');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    const link = this.findNamedLink(visit.links, 'npsRatings');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "npsRatings" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visit.consumer;
    const options = this.generateOptions('POST', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('netPromoterScore', netPromoterScore);
    options.form.set('feedback', feedback);
    return this.executeRequest(options)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.__logger.info(currentFunction, 'Completed');
        return visit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        if (error.httpResponseCode === 400) {
          return Promise.reject(AWSDKError.AWSDKNpsRatingNotSubmittedError());
        }
        return Promise.reject(error);
      });
  }

  /**
   * This method allows the consumer to submit a star rating for the visit just concluded and/or the provider to whom he/she just had a visit with. <br>
   * The rating is based on a zero to five star rating system, where a zero represents the lowest/worst experience and a five the highest/best experience.
   * @param {model.AWSDKVisitSummary} visitSummary is the object that was created by a call to {@link service.VisitService#getVisitSummary|getVisitSummary}.
   * @param {Integer} providerRating is the number of stars attributed to the provider as rating
   * @param {Integer} visitRating is the number of stars attributed to the visit as rating
   * @returns {Promise<model.AWSDKVisitSummary|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisitSummary|AWSDKVisitSummary} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.ratingNotSubmittedError|AWSDKErrorCode.ratingNotSubmittedError}</td><td>Rating couldn't be added.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>All dispositions supported</li>
   * </ul>
   * </p>
   * @since 1.0.0
   */
  addRating(visitSummary, providerRating, visitRating) {
    const currentFunction = 'VisitService.addRating';
    this.__logger.debug(currentFunction, 'Started', visitSummary, providerRating, visitRating);
    if (visitSummary == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visitSummary argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visitSummary instanceof AWSDKVisitSummary)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitSummary argument is not of type AWSDKVisitSummary');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (providerRating == null) {
      const error = AWSDKError.AWSDKIllegalArgument('providerRating argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (providerRating !== parseInt(providerRating, 10)) {
      const error = AWSDKError.AWSDKValidationError('providerRating');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visitRating == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visitRating argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visitRating !== parseInt(visitRating, 10)) {
      const error = AWSDKError.AWSDKValidationError('visitRating');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visitSummary.links, 'ratings');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visitSummary argument does not have a valid "ratings" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visitSummary.consumer;
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('providerRating', providerRating);
    options.form.set('engagementRating', visitRating);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return visitSummary;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        if (error.httpResponseCode === 400) {
          return Promise.reject(AWSDKError.AWSDKRatingNotSubmittedError());
        }
        return Promise.reject(error);
      });
  }

  /**
   * This method adds a chat message to an existing chat session. This happens when a staff communicates with a consumer during the waiting room while the provider hasn't joined.
   * @param {model.AWSDKVisit} visit the visit associated with this chat message
   * @param {String} message the new chat message content
   * @param {Number} lastPolledChatOrdinal the ordinal for the last reported chat message
   * @returns {Promise<model.AWSDKChatReport|error.AWSDKError>} a promise that will resolve to a {@link model.AWSDKChatReport|AWSDKChatReport} or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.InProgress|InProgress}</li>
   * <li>{@link model.AWSDKDisposition.PostVisitConversation|PostVisitConversation}</li>
   * <li>{@link model.AWSDKDisposition.PreVisitScreening|PreVisitScreening}</li>
   * </ul>
   * </p>
   * @since 1.1.0
   */
  addChatMessage(visit, message, lastPolledChatOrdinal) {
    const currentFunction = 'VisitService.addChatMessage';
    this.__logger.debug(currentFunction, 'Started', visit, message, lastPolledChatOrdinal);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isStringType(message)) {
      const error = AWSDKError.AWSDKIllegalArgument('message argument is not of type string');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(message)) {
      const error = AWSDKError.AWSDKValidationError('message');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if ((typeof lastPolledChatOrdinal !== 'number' || lastPolledChatOrdinal !== parseInt(lastPolledChatOrdinal, 10))) {
      const error = AWSDKError.AWSDKIllegalArgument('lastPolledChatOrdinal argument is not a number');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'addChatMessage');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "addChatMessage" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('message', message);
    options.form.set('ordinal', lastPolledChatOrdinal);
    return this.executeRequest(options, AWSDKChatReportResponse)
      .then((chatReportResponse) => {
        this.__logger.debug(currentFunction, 'Got response', chatReportResponse);
        this.__logger.info(currentFunction, 'Complete');
        this.updateUserAuthEntry(visit.consumer, chatReportResponse.authToken);
        return chatReportResponse.chatReport;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Setup alerts via Text messages when the provider is ready or this {@link model.AWSDKVisit|AWSDKVisit} goes up in the waiting list
   * @param {model.AWSDKVisit} visit the visit to set up alerts for
   * @param {String} phoneNumber the phoneNumber to text
   * @param {Boolean} [providerReadyAlert] whether or not to be alerted when provider is ready
   * @param {Boolean} [movedUpInQueueAlert] whether or not to be alerted when moved up in the queue
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>The provided phone number is not the correct format.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationRequiredParameterMissing|AWSDKErrorCode.validationRequiredParameterMissing}</td><td>The provided phone number is missing.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitNotFound|AWSDKErrorCode.visitNotFound}</td><td>The provided visit cannot be found.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.PreVisitScreening|PreVisitScreening}</li>
   * </ul>
   * </p>
   * @since 1.1.0
   */
  setupWaitingRoomAlerts(visit, phoneNumber, providerReadyAlert = false, movedUpInQueueAlert = false) {
    const currentFunction = 'VisitService.setupWaitingRoomAlerts';
    this.__logger.debug(currentFunction, 'Started', visit, phoneNumber, providerReadyAlert, movedUpInQueueAlert);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is null or not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (phoneNumber == null) {
      const error = AWSDKError.AWSDKIllegalArgument('phoneNumber argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isPhoneNumberValid(phoneNumber)) {
      const error = AWSDKError.AWSDKValidationError('phoneNumber');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'alerts');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "alerts" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('phoneNumber', phoneNumber);
    options.form.set('providerReadyAlert', providerReadyAlert);
    options.form.set('movedUpInQueueAlert', movedUpInQueueAlert);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(visit.consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * This method accepts a suggested transfer. If the suggested provider is still available the visit will be cancelled.
   * Once the visit has cancelled call handleTransfer to create a new visit that will copy details from this visit.
   * If the suggested provider is no longer available an error will be returned. If a new suggested provider is available it will be set on the visit.
   * @param {model.AWSDKVisit} visit the visit with a suggested transfer
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to boolean indicating if the transfer was accepted successfully or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotAvailable|AWSDKErrorCode.providerNotAvailable}</td><td>This can occur for a variety of reasons, including when the provider is offline for an on-demand visit or the provider's waiting room is closed to incoming consumers.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.PreVisitScreening|PreVisitScreening}</li>
   * </ul>
   * </p>
   * @since 1.1.0
   */
  acceptSuggestedTransfer(visit) {
    const currentFunction = 'VisitService.acceptSuggestedTransfer';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.disposition !== AWSDKDisposition.PreVisitScreening) {
      const error = AWSDKError.AWSDKInternalError(`visit is not set up for suggested transfer, visit is ${String(visit.disposition)} suggestedProvider=${visit.suggestedProviderForTransfer}`);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.suggestedProviderForTransfer == null) {
      const error = AWSDKError.AWSDKInternalError('visit is not set up for suggested transfer, visit suggestedProviderForTransfer is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'acceptTransferVisitSuggestion');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "acceptTransferVisitSuggestion" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(visit.consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        if (error.errorCode === AWSDKErrorCode.responseError && error.httpResponseCode === 409) {
          throw AWSDKError.AWSDKProviderNotAvailable();
        }
        throw error;
      });
  }

  /**
   * This method accepts the option of transferring to a Find First Available provider and begins the process of finding a provider to transfer to.
   * @param {model.AWSDKVisit} visit the visit that is currently being waited on
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} a promise that will resolve to an {@link model.AWSDKVisit|AWSDKVisit} or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.PreVisitScreening|PreVisitScreening}</li>
   * </ul>
   * </p>
   * @since 1.1.0
   */
  acceptFindFirstAvailableTransferVisitSuggestion(visit) {
    const currentFunction = 'VisitService.acceptAskMeTransferVisitSuggestion';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.disposition !== AWSDKDisposition.PreVisitScreening) {
      const error = AWSDKError.AWSDKInternalError(`visit is not set up for suggested transfer, visit is ${String(visit.disposition)} suggestedProvider=${visit.suggestedProviderForTransfer}`);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!visit.optionForFindFirstAvailableTransferAvailable) {
      const error = AWSDKError.AWSDKInternalError('suggestion to search ask me network for provider has not been made');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'acceptAskMeTransferVisitSuggestion');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "acceptAskMeTransferVisitSuggestion" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(visit.consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return visit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * This method declines a suggested transfer. If the dontSuggestTransferAgain is set to true no other transfers will be suggested for this visit.
   * @param {model.AWSDKVisit} visit the visit with a suggested transfer
   * @param {Boolean} dontSuggestTransferAgain set to true to stop other suggested transfers for this visit.
   * @returns {Promise<model.AWSDKVisit|error.AWSDKError>} a promise that will resolve to an {@link model.AWSDKVisit|AWSDKVisit} or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.PreVisitScreening|PreVisitScreening}</li>
   * </ul>
   * </p>
   * @since 1.1.0
   */
  declineSuggestedTransfer(visit, dontSuggestTransferAgain) {
    const currentFunction = 'VisitService.declineSuggestedTransfer';
    this.__logger.debug(currentFunction, 'Started', visit, dontSuggestTransferAgain);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (dontSuggestTransferAgain == null) {
      const error = AWSDKError.AWSDKIllegalArgument('dontSuggestTransferAgain argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (typeof (dontSuggestTransferAgain) !== 'boolean') {
      const error = AWSDKError.AWSDKIllegalArgument('dontSuggestTransferAgain argument is not of type boolean');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.disposition !== AWSDKDisposition.PreVisitScreening) {
      const error = AWSDKError.AWSDKIllegalArgument(`visit is not set up for suggested transfer, visit is ${String(visit.disposition)} suggestedProvider=${visit.suggestedProviderForTransfer}`);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!visit.optionForFindFirstAvailableTransferAvailable && visit.suggestedProviderForTransfer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visit is not set up for suggested transfer, visit suggestedProviderForTransfer is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'declineTransferVisitSuggestion');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "declineTransferVisitSuggestion" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('dontSuggestTransferAgain', dontSuggestTransferAgain);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(visit.consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return visit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        if (error.errorCode === AWSDKErrorCode.responseError && error.httpResponseCode === 409) {
          throw AWSDKError.AWSDKProviderNotAvailable();
        }
        throw error;
      });
  }

  /**
   * This method handles either returning a visitContext or a visit if the transfer is eligible for quick transfer.
   * @param {model.AWSDKVisit} visit the visit with a suggested transfer
   * @returns {Promise<model.AWSDKTransfer|error.AWSDKError>} a promise that will resolve to an {@link model.AWSDKTransfer|AWSDKTransfer} or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.1.0
   */
  handleTransfer(visit) {
    const currentFunction = 'VisitService.handleTransfer';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (![AWSDKDisposition.Declined, AWSDKDisposition.ConsumerCanceled, AWSDKDisposition.Completed, AWSDKDisposition.ProviderWrapup].includes(visit.disposition)) {
      const error = AWSDKError.AWSDKInternalError(`visit is not set up for transfer, visit is ${String(visit.disposition)}`);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.disposition === AWSDKDisposition.Declined && visit.endReason !== AWSDKEndReason.PROVIDER_DECLINE_AND_TRANSFER && visit.endReason !== AWSDKEndReason.ASSISTANT_DECLINE_AND_TRANSFER) {
      const error = AWSDKError.AWSDKInternalError(`visit is not set up for transfer, visit is declined with endReason=${String(visit.endReason)}`);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.disposition === AWSDKDisposition.ConsumerCanceled && visit.endReason !== AWSDKEndReason.CONSUMER_TRANSFER) {
      const error = AWSDKError.AWSDKInternalError(`visit is not set up for transfer, visit is ConsumerCanceled with endReason=${String(visit.endReason)}`);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visit.providerForTransfer == null) {
      const error = AWSDKError.AWSDKInternalError('visit is not set up for transfer, visit providerForTransfer is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.getVisitContext(visit.consumer, visit.providerForTransfer)
      .then((visitContext) => {
        visitContext.setupTransfer(visit);
        if (visit.isProviderForTransferEligibleForQuickTransfer) {
          this.__logger.info(currentFunction, 'Eligible for Quick Transfer checking cost');
          let createdVisit = null;
          return this.createVisit(visitContext)
            .then((newVisit) => {
              createdVisit = newVisit;
              return this.waitForVisitCostCalculationToFinish(newVisit);
            })
            .then((newVisitWithCost) => {
              if (visitContext.proposedCouponCode != null && newVisitWithCost.cost.canApplyCouponCode) {
                this.__logger.info(currentFunction, 'Eligible for Quick Transfer checking cost. Applying coupon');
                return this.applyCouponCode(newVisitWithCost, visitContext.proposedCouponCode)
                  .then((visitCost) => {
                    newVisitWithCost.cost = visitCost;
                    return newVisitWithCost;
                  })
                  // if cannot apply coupon ignore error and continue
                  .catch(() => newVisitWithCost);
              }
              return newVisitWithCost;
            })
            .then((updatedVisit) => {
              if (updatedVisit.cost.hasCostChangedWithVisitTransfer) {
                this.__logger.info(currentFunction, 'Eligible for Quick Transfer checking cost. Applying coupon');
                createdVisit = null;
                return this.cancelVisit(updatedVisit)
                  .then(() => new AWSDKTransfer(visitContext))
                  // return with visitContext even if cancel fails
                  .catch(() => new AWSDKTransfer(visitContext));
              }
              updatedVisit.isMatchmaking = visit.optionForFindFirstAvailableTransferAvailable;
              return new AWSDKTransfer(visitContext, updatedVisit);
            })
            // if cannot create visit or calculate cost ignore error and just return the visitContext
            .catch(() => {
              this.__logger.info(currentFunction, 'Error during Quick Transfer logic returning normal transfer');
              if (createdVisit != null) {
                this.__logger.info(currentFunction, 'Error during Quick Transfer logic cleaning up visit');
                return this.cancelVisit(createdVisit)
                  .then(() => new AWSDKTransfer(visitContext))
                  // return with visitContext even if cancel fails
                  .catch(() => new AWSDKTransfer(visitContext));
              }
              return new AWSDKTransfer(visitContext);
            });
        }
        return new AWSDKTransfer(visitContext);
      });
  }

  /**
   * This internal api is used to allow the consumer to initiate an IVR callback in the case of something like network connectivity issues.
   *
   * @param {model.AWSDKVisit} visit the visit to do initiate an ivr callback for
   * @param {boolean} isRetry TRUE if this call is not the first IVR callback attempt
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean value of true or rejected with an instance of {@link error.AWSDKError|AWSDKError}
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.InProgress|InProgress}</li>
   * <li>{@link model.AWSDKDisposition.PostVisitConversation|PostVisitConversation}</li>
   * <li>{@link model.AWSDKDisposition.ProviderWrapup|ProviderWrapup}</li>
   * <li>{@link model.AWSDKDisposition.Completed|Completed}</li>
   * <li>{@link model.AWSDKDisposition.Expired|Expired}</li>
   * </ul>
   * </p>
   * @since 2.0.0
   * @internal
   */
  __initiateIVRCallback(visit, isRetry) {
    const currentFunction = 'VisitService.__initiateIVRCallback';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (typeof (isRetry) !== 'boolean') {
      const error = AWSDKError.AWSDKIllegalArgument('isRetry argument is not of type boolean');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'callback');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "callback" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('isProvider', false);
    options.form.set('isRetry', isRetry);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(visit.consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * This method allows the addition of a phone conversation while the visit is already in progress. This allows providers and consumers to stay in a conversation
   * when there are network issues that are impacting video visits. The phone number used for the visit is the one the consumer provided during intake or the one
   * on file for this particular consumer.
   * @param {model.AWSDKVisit} visit the visit that we want to add a phone conversation to
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean value of true or rejected with an instance of {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.AWSDKIllegalUsage|AWSDKErrorCode.AWSDKIllegalUsage}</td><td>The allowsVisitAddPhone field is not true.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.InProgress|InProgress}</li>
   * </ul>
   * </p>
   * @since 2.0.0
   */
  addPhoneToVisit(visit) {
    const currentFunction = 'VisitService.addPhoneToVisit';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visit.consumer;
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKInternalError('visit.consumer must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!visit.allowsVisitAddPhone) {
      const error = AWSDKError.AWSDKIllegalUsage('visit.allowsVisitAddPhone must be true');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'addPhoneToExistingVisit');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "addPhoneToExistingVisit" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(visit.consumer, response.authToken);
        this.__logger.info(currentFunction, 'Complete');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Internal use only
   * @private
   */
  __checkFirstAvailableSearchStatus(visitInfo, languageSpoken) {
    const currentFunction = 'VisitService.__checkFirstAvailableSearchStatus';
    this.__logger.debug(currentFunction, 'Started', visitInfo, languageSpoken);
    if (!(visitInfo instanceof AWSDKVisitContext) && !(visitInfo instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitInfo argument is not of type AWSDKVisitContext or AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visitInfo.consumer;
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitContext.consumer is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (languageSpoken != null && !(languageSpoken instanceof AWSDKLanguage)) {
      const error = AWSDKError.AWSDKIllegalArgument('languageSpoken optional argument is not of type AWSDKLanguage');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'matchmaker');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer argument does not have a valid "matchmaker" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const auth = this.getUserAuth(visitInfo.consumer);
    if (!auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, true);
    options.auth = auth;
    options.headers.Accept = 'application/vnd.amwell-v3+json, application/json';
    // matchmaker caretalks endpoint is still looking for ondemandspecialtyid parameter
    options.form.set('onDemandSpecialtyId', visitInfo.firstAvailableConfigurationId);
    if (languageSpoken) {
      options.form.set('languageSpoken', languageSpoken.value);
    }
    return this.executeRequest(options, AWSDKFirstAvailableResponse)
      .then((firstAvailableSearchStatusResponse) => {
        this.__logger.debug(currentFunction, 'Got response', firstAvailableSearchStatusResponse);
        this.updateUserAuthEntry(visitInfo.consumer, firstAvailableSearchStatusResponse.authToken);
        return firstAvailableSearchStatusResponse;
      }).catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Internal use only
   * @private
   */
  __pollForFirstAvailableSearchStatus(visitInfo, languageSpoken) {
    const currentFunction = 'VisitService.__pollForFirstAvailableSearchStatus';
    this.__logger.debug(currentFunction, 'Started', visitInfo, languageSpoken);
    const interval = this.__config.firstAvailablePollingInterval;
    const endTime = Number(new Date()) + (this.__config.firstAvailablePollingTimeout);
    let errorCount = 0;
    const errorLimit = 3;
    const checkCondition = (resolve, reject) => {
      this.__checkFirstAvailableSearchStatus(visitInfo, languageSpoken)
        .then((firstAvailableSearchStatusResponse) => {
          const firstAvailableData = firstAvailableSearchStatusResponse.firstAvailableData;
          const firstAvailableProviderFound = firstAvailableData && firstAvailableData.provider !== null && (!firstAvailableData.status || AWSDKFirstAvailableStatus.PROVIDER_ACCEPTED === firstAvailableData.status);

          if (firstAvailableProviderFound) {
            this.__logger.info(currentFunction, 'Completed');
            // positive case, resolve
            resolve(firstAvailableData);
          } else if (firstAvailableData.status === AWSDKFirstAvailableStatus.PROVIDER_LIST_EXHAUSTED) {
            // negative case, exhausted
            const error = AWSDKError.AWSDKNoProvidersAvailable();
            errorCount += 3;
            this.__logger.error(currentFunction, 'Error', error);
            reject(error);
          } else if (Number(new Date()) < endTime) {
            // ambiguous case, poll again
            setTimeout(checkCondition, interval, resolve, reject);
          } else {
            // negative case, timeout
            const error = AWSDKError.AWSDKPollingTimeout();
            errorCount += 3;
            this.__logger.error(currentFunction, 'Polling request timed out', error);
            reject(error);
          }
        })
        .catch((error) => {
          errorCount += 1;
          this.__logger.warn(currentFunction, 'error during polling', errorCount, error);
          if (errorCount >= errorLimit) {
            this.__logger.error(currentFunction, 'Error', error);
            reject(error);
          } else {
            setTimeout(checkCondition, interval, resolve, reject);
          }
        });
    };
    return new Promise(checkCondition);
  }

  /**
   * Finds the First Available {@link model.AWSDKProvider|AWSDKProvider} to accept the given visit, either an {@link model.AWSDKVisit|AWSDKVisit} or an {@link model.AWSDKVisitContext|AWSDKVisitContext}.
   * @param {model.AWSDKVisit|model.AWSDKVisitContext} visitInfo a {@link model.AWSDKVisit|AWSDKVisit} or {@link model.AWSDKVisitContext|AWSDKVisitContext} for which to perform the search
   * @param {model.AWSDKLanguage} [languageSpoken] the language by which to filter search results
   * @param {String} [practice] the {@link model.AWSDKPractice} in which to search. When practice is provided, the practice associated to the id will be used instead of the practice associated to the specialty
   * @param {model.AWSDKProviderType[]} [providerTypes] the array of provider types to include in the search. When the list of provider types is provided, the list of provider types will be combined with the list of provider types associated to the specialty
   * @returns {Promise<model.AWSDKVisit|model.AWSDKVisitContext|error.AWSDKError>} Returns a promise that will be resolved to a visit of the type passed, either {@link model.AWSDKVisit|AWSDKVisit}
   * or {@link model.AWSDKVisitContext|AWSDKVisitContext}, or will be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.noProvidersAvailable|AWSDKErrorCode.noProvidersAvailable}</td><td>The search concluded and no providers were available</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.pollingTimeout|AWSDKErrorCode.pollingTimeout}</td><td>The search exceeded its allotted polling time</td></tr>
   * </table>
   * @since 1.1.0
   */
  findFirstAvailable(visitInfo, languageSpoken, practice, providerTypes) {
    const currentFunction = 'VisitService.findFirstAvailable';
    this.__logger.debug(currentFunction, 'Started', visitInfo, languageSpoken, practice, providerTypes);
    if (!(visitInfo instanceof AWSDKVisitContext) && !(visitInfo instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitInfo argument is not of type AWSDKVisitContext or AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (visitInfo.firstAvailableConfigurationId == null) {
      const error = AWSDKError.AWSDKIllegalArgument('visitInfo must have a specified AWSDKFirstAvailableConfiguration id');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visitInfo.consumer;
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitInfo.consumer is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (languageSpoken && !(languageSpoken instanceof AWSDKLanguage)) {
      const error = AWSDKError.AWSDKIllegalArgument('languageSpoken optional argument is not of type AWSDKLanguage');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (practice && !(practice instanceof AWSDKPractice)) {
      const error = AWSDKError.AWSDKIllegalArgument('practice optional argument is not of type AWSDKPractice');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (providerTypes && (!Array.isArray(providerTypes) || providerTypes.find(pt => !(pt instanceof AWSDKProviderType)))) {
      const error = AWSDKError.AWSDKIllegalArgument('providerTypes optional argument is not an array AWSDKProviderType');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'matchmaker');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer argument does not have a valid "matchmaker" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.headers['Content-Type'] = 'application/json';
    options.headers.Accept = 'application/vnd.amwell-v3+json, application/json';
    const practiceId = (practice && practice.id.encryptedId) || null;
    const request = new AWSDKFirstAvailableRequest(visitInfo.firstAvailableConfigurationId, languageSpoken, practiceId, providerTypes);
    options.body = request.getAsJSONString();
    options.body = options.body.replace('firstAvailableConfigurationId', 'specialtyId');
    const firstAvailableSearchStartTime = new Date();
    return this.executeRequest(options, AWSDKFirstAvailableResponse)
      .then((firstAvailableSearchResponse) => {
        this.__logger.debug(currentFunction, 'Got response', firstAvailableSearchResponse);
        this.updateUserAuthEntry(consumer, firstAvailableSearchResponse.authToken);
        const firstAvailableData = firstAvailableSearchResponse.firstAvailableData;
        if (firstAvailableData && firstAvailableData.provider != null) {
          // we've immediately been matched with a provider
          this.__logger.info(currentFunction, 'Complete, provider found');
          return firstAvailableSearchResponse.firstAvailableData;
        }
        this.__logger.info(currentFunction, 'Complete, starting to poll');
        // we've started matchmaking and should start polling
        return this.__pollForFirstAvailableSearchStatus(visitInfo, languageSpoken);
      }).then((firstAvailableData) => {
        const data = firstAvailableData;
        data.firstAvailableSearchStartTime = firstAvailableSearchStartTime;
        data.firstAvailableSearchEndTime = new Date();
        visitInfo.firstAvailableData = data;
        return visitInfo;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Invites a list of guests via email to participate in the existing visit using multi-way video.
   * Multi-way video must be enabled on the system.
   * @param {model.AWSDKVisit} visit the visit to invite the guest to
   * @param {String[]} guestEmails the email addresses where the invitation will be sent
   * @returns {Promise<model.AWSDKVideoInvitation[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKVideoInvitation|AWSDKVideoInvitation}s or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>The supplied email addresses were invalid.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalUsage}</td><td>The system is not configured for multiple video guests</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.guestEmailAlreadyInvited|AWSDKErrorCode.guestEmailAlreadyInvited}</td><td>The guest has already been invited</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>All dispositions supported</li>
   * </ul>
   * </p>
   * @since 2.0.0
   */
  inviteGuestsToVisit(visit, guestEmails) {
    const currentFunction = 'VisitService.inviteGuestsToVisit';
    this.__logger.debug(currentFunction, 'Started', visit, guestEmails);
    if (!this.__systemConfiguration.multipleVideoParticipantsEnabled) {
      const desc = 'The system is not configured to allow multiple guests to join a video visit';
      const corrected = 'Configure the system to allow multiple guests to join a video visit';
      const error = AWSDKError.AWSDKIllegalUsage(desc, corrected);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!guestEmails || !Array.isArray(guestEmails)) {
      const error = AWSDKError.AWSDKIllegalArgument('guestEmails must be an array of valid string email addresses');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (guestEmails.length === 0) {
      const error = AWSDKError.AWSDKValidationError('guestEmails', guestEmails);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const invalidEmail = guestEmails.find(guestEmail => !Validator.isEmailValid(guestEmail));
    if (invalidEmail) {
      const error = AWSDKError.AWSDKValidationError('guestEmails', invalidEmail);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'inviteGuests');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "inviteGuests" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    guestEmails.forEach(guestEmail => options.form.append('emails', guestEmail));

    return this.executeRequest(options, AWSDKVideoInvitationListResponse).then((response) => {
      this.__logger.info(currentFunction, 'Got response', response);
      this.updateUserAuthEntry(visit.consumer, response.authToken);
      return response.videoInvitations;
    }).catch((error) => {
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    });
  }

  /**
   * This method cancels any ongoing first-available search being performed on behalf of the {@link model.AWSDKConsumer|AWSDKConsumer}.
   * @param {model.AWSDKConsumer} consumer the consumer for whom to cancel the search
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.1.0
   */
  cancelFirstAvailableSearch(consumer) {
    const currentFunction = 'VisitService.cancelFirstAvailableSearch';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'matchmaker');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer argument does not have a valid "matchmaker" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('DELETE', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKResponse)
      .then(() => {
        this.__logger.info(currentFunction, 'Completed.');
        return true;
      }).catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Validates the given {@link model.AWSDKVisitInvitation} by id. Invitations must be validated before the guest can
   * enter a video visit.
   * Multi-way video must be enabled on the system.
   * @param {String} visitInviteId the id of the {@link model.AWSDKVisitInvitation} to validated
   * @param {String} email the email address associated with the invitation
   * @param {String} name the name of the guest joining the visit
   * @returns {Promise<model.AWSDKVideoParticipant|error.AWSDKError>} a promise that will resolve to an {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.emailAddressNotFound|AWSDKErrorCode.emailAddressNotFound}</td><td>The given email is not associated with an existing visit invitation</td></tr>
   * </table>
   * @since 1.1.0
   */
  validateGuestInvitation(videoInviteId, email, name) {
    const currentFunction = 'VisitService.validateGuestInvitation';
    this.__logger.debug(currentFunction, 'Started', videoInviteId, email, name);
    if (!Validator.isValidString(videoInviteId)) {
      const error = AWSDKError.AWSDKIllegalArgument('videoInviteId argument must be a valid string');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isEmailValid(email)) {
      const error = AWSDKError.AWSDKIllegalArgument('email argument must be a valid email address');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidString(name)) {
      const error = AWSDKError.AWSDKIllegalArgument('name argument must be a valid string');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'videoParticipant');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit argument does not have a valid "videoParticipant" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url, true);
    options.auth = this.getSdkAuth();

    options.form.set('id', videoInviteId);
    options.form.set('email', email);
    options.form.set('name', name);
    return this.executeRequest(options, AWSDKVideoParticipantResponse).then((response) => {
      this.__logger.info(currentFunction, 'Got response', response);
      return response.videoParticipant;
    }).catch((error) => {
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    });
  }

  /**
   * Gets an up-to-date instance of the given {@link model.AWSDKVideoParticipant}
   * @param {model.AWSDKVideoParticipant} participant the {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} to get updates for
   * @returns {Promise<model.AWSDKVideoParticipant|error.AWSDKError>} a promise that will resolve to an {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>The argument is not of type AWSDKVideoParticipant.</td></tr>
   * </table>
   * @since 1.1.0
   */
  getVideoParticipant(participant) {
    const currentFunction = 'VisitService.getVideoParticipant';
    this.__logger.debug(currentFunction, 'Started', participant);
    if (!(participant instanceof AWSDKVideoParticipant)) {
      const error = AWSDKError.AWSDKIllegalArgument('participant argument is not of type AWSDKVideoParticipant');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', participant.href);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKVideoParticipantResponse).then((response) => {
      this.__logger.info(currentFunction, 'Got response', response);
      return response.videoParticipant;
    }).catch((error) => {
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    });
  }

  /**
   * Indicate that the given {@link model.AWSDKVideoParticipant} intends to connect and is ready to launch the telehealth video.<br>
   * @param {model.AWSDKVideoParticipant} participant the {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} to connect
   * @returns {Promise<model.AWSDKVideoParticipant|error.AWSDKError>} a promise that will resolve to an {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>The argument is not of type AWSDKVideoParticipant.</td></tr>
   * </table>
   * @since 1.1.0
   */
  updateVideoParticipantConnectionStatus(participant) {
    const currentFunction = 'VisitService.updateVideoParticipantConnectionStatus';
    this.__logger.debug(currentFunction, 'Started', participant);
    if (!(participant instanceof AWSDKVideoParticipant)) {
      const error = AWSDKError.AWSDKIllegalArgument('participant argument is not of type AWSDKVideoParticipant');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', participant.href, true);
    options.auth = this.getSdkAuth();
    options.form.set('connected', true);
    return this.executeRequest(options, AWSDKVideoParticipantResponse).then((response) => {
      this.__logger.info(currentFunction, 'Got response', response);
      return response.videoParticipant;
    }).catch((error) => {
      this.__logger.error(currentFunction, 'error', error);
      throw error;
    });
  }

  /**
   * Waits for the visit associated with this {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} to start.
   * Must be preceded by {@link service.VisitService#updateVideoParticipantConnectionStatus|updateVideoParticipantConnectionStatus}.<br>
   * @param {model.AWSDKVideoParticipant} participant the {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} that is waiting
   * @returns {Promise<model.AWSDKVideoParticipant|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * </table>
   * @since 1.1.0
   */
  waitForVideoParticipantVisitToStart(participant) {
    const currentFunction = 'VisitService.waitForVideoParticipantVisitToStart';
    this.__logger.debug(currentFunction, 'Started', participant);
    if (!(participant instanceof AWSDKVideoParticipant)) {
      const error = AWSDKError.AWSDKIllegalArgument('participant argument is not of type AWSDKVideoParticipant');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }

    if (participant.conferenceStatus !== AWSDKConferenceStatus.Waiting) {
      this.__logger.info(currentFunction, `Visit has already resolved with status: ${String(participant.conferenceStatus)}`);
      return Promise.resolve(participant);
    }
    const interval = this.__config.visitPollingInterval;
    let errorCount = 0;
    const errorLimit = 3;
    const waitCondition = (resolve, reject) => {
      this.getVideoParticipant(participant)
        .then((result) => {
          if (result.conferenceStatus !== AWSDKConferenceStatus.Waiting && result.conferenceStatus !== AWSDKConferenceStatus.Disabled) {
            this.__logger.info(currentFunction, `Visit has resolved with status: ${String(result.conferenceStatus)}`);
            resolve(result);
          } else {
            setTimeout(waitCondition, interval, resolve, reject);
          }
        })
        .catch((error) => {
          errorCount += 1;
          this.__logger.warn(currentFunction, 'error during polling', errorCount, error);
          if (errorCount >= errorLimit) {
            this.__logger.error(currentFunction, 'error', error);
            reject(error);
          } else {
            setTimeout(waitCondition, interval, resolve, reject);
          }
        });
    };
    return new Promise(waitCondition);
  }

  /**
   * Waits for the visit associated with this {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} to end.
   * @param {model.AWSDKVideoParticipant} participant the {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} in the visit
   * @returns {Promise<model.AWSDKVideoParticipant|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVideoParticipant|AWSDKVideoParticipant} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * </table>
   * @since 1.1.0
   */
  waitForVideoParticipantVisitToEnd(participant) {
    const currentFunction = 'VisitService.waitForVideoParticipantVisitToEnd';
    this.__logger.debug(currentFunction, 'Started', participant);
    if (!(participant instanceof AWSDKVideoParticipant)) {
      const error = AWSDKError.AWSDKIllegalArgument('participant argument is not of type AWSDKVideoParticipant');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const conferenceHasEnded = result => result.conferenceStatus === AWSDKConferenceStatus.Ended || result.conferenceStatus === AWSDKConferenceStatus.Cancelled || result.conferenceStatus === AWSDKConferenceStatus.Disabled;
    if (conferenceHasEnded(participant)) {
      this.__logger.info(currentFunction, 'Visit has already ended');
      return Promise.resolve(participant);
    }
    const interval = this.__config.visitPollingInterval;
    let errorCount = 0;
    const errorLimit = 3;
    const waitCondition = (resolve, reject) => {
      this.getVideoParticipant(participant)
        .then((result) => {
          if (conferenceHasEnded(result)) {
            this.__logger.info(currentFunction, 'Visit has ended');
            resolve(result);
          } else {
            setTimeout(waitCondition, interval, resolve, reject);
          }
        })
        .catch((error) => {
          errorCount += 1;
          this.__logger.warn(currentFunction, 'error during polling', errorCount, error);
          if (errorCount >= errorLimit) {
            this.__logger.error(currentFunction, 'error', error);
            reject(error);
          } else {
            setTimeout(waitCondition, interval, resolve, reject);
          }
        });
    };
    return new Promise(waitCondition);
  }

  /**
   * Launches the telehealth video for this {@link model.AWSDKVideoParticipant}.<br />
   * NOTE: <em><b>The telehealth client is only supported on Windows.</b></em>
   * Must be preceded by {@link service.VisitService#waitForVideoParticipantVisitToStart|waitForVideoParticipantVisitToStart}.<br>
   * @param {model.AWSDKVideoParticipant} participant the participant to launch the video for
   * @returns {Promise<boolean|error.AWSDKError>} Returns a promise that will be resolved to a {@link boolean} indicating if the
   * telehealth video has launched successfully or will be rejected with an {@link error.AWSDKError|AWSDKError} if the input is incorrect.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.unsupportedVideoPlatform|AWSDKErrorCode.unsupportedVideoPlatform}</td><td>The system is configured with an unsupported video platform.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.1.0
   */
  launchVideoParticipantTelehealthVideo(participant) {
    const currentFunction = 'VisitService.launchVideoParticipantTelehealthVideo';
    this.__logger.debug(currentFunction, 'Started', participant);
    if (!(participant instanceof AWSDKVideoParticipant)) {
      const error = AWSDKError.AWSDKIllegalArgument('participant argument is not of type AWSDKVideoParticipant');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!this.__systemConfiguration.webRTCEnabled) {
      const error = AWSDKError.AWSDKUnsupportedVideoPlatform();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(participant.links, 'startElectronVisitURL');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError("participant argument does not have a valid 'startElectronVisitURL' link entry");
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return new Promise((resolve, reject) => {
      this.__customProtocolHandler.launchUri(link.url, reject, resolve);
    })
      .then(() => {
        this.__logger.info(currentFunction, 'Completed - launched ok returned true');
        return true;
      })
      .catch(() => {
        this.__logger.info(currentFunction, 'Completed - not launched returned false');
        return false;
      });
  }

  /**
   * Returns any notes made by the {@link model.AWSDKProvider|AWSDKProvider} during the ongoing {@link model.AWSDKVisit|AWSDKVisit}.
   * @param {model.AWSDKVisit} visit the visit to get notes for
   * @returns {Promise<model.AWSDKProviderNote[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKProviderNote|AWSDKProviderNote}s or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing argument or argument is not the correct type.</td>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.2.1
   */
  getProviderNotes(visit) {
    const currentFunction = 'VisitService.getProviderNotes';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit is not an instance of AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'notes');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('A "notes" link entry was not found');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKProviderNotesResponse).then((response) => {
      this.__logger.debug(currentFunction, 'Got response', response);
      this.updateUserAuthEntry(visit.consumer, response.authToken);
      return response.notes;
    }).catch((error) => {
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    });
  }

  /**
   * Accepts the offered paid extension for a visit.
   * @param {model.AWSDKVisit} visit the visit to extend
   * @param {Boolean} acceptExtension TRUE if the visit should be extended
   * @returns {Promise<boolean|error.AWSDKError>} Returns a promise that will be resolved to an {@link boolean} indicating if the visit extension was successfully accepted otherwise will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>The visit.paidExtensionOffered field must be true.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>Missing argument or argument is not the correct type.</td>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit has an invalid disposition</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.InProgress|InProgress}</li>
   * </ul>
   * </p>
   * @since 1.4.0
   */
  acceptPaidVisitExtension(visit, acceptExtension) {
    const currentFunction = 'VisitService.acceptPaidVisitExtension';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit is not an instance of AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (typeof (acceptExtension) !== 'boolean') {
      const error = AWSDKError.AWSDKIllegalArgument('acceptExtension argument is not of type boolean');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(visit.paidExtensionOffered)) {
      const error = AWSDKError.AWSDKValidationError('visit.paidExtensionOffered');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'extendVisit');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('A "extendVisit" link entry was not found');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(visit.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.form.set('accepted', acceptExtension);
    return this.executeRequest(options, AWSDKResponse).then((response) => {
      this.__logger.debug(currentFunction, 'Got response', response);
      this.updateUserAuthEntry(visit.consumer, response.authToken);
      return true;
    }).catch((error) => {
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    });
  }

  /**
   * Validate the {@link model.AWSDKConsumerOverrideDetails|AWSDKConsumerOverrideDetails} object<br>
   * <br>Potential validation errors:<br>
   * <table summary="validation" border="1">
   * <tr>
   * <th>Field</th>
   * <th>Validation Reason</th>
   * <th>Notes</th>
   * </tr>
   * <tr>
   * <td>firstName</td>
   * <td>field required</td>
   * <td>set to non-empty field</td>
   * </tr>
   * <tr>
   * <td>lastName</td>
   * <td>field required</td>
   * <td>set to non-empty field</td>
   * </tr>
   * <tr>
   * <td>firstName</td>
   * <td>invalid field format</td>
   * <td>At least 1 char long</td>
   * </tr>
   * <tr>
   * <td>middleNameOrInitial</td>
   * <td>invalid field format</td>
   * <td>If {@link model.AWSDKSystemConfiguration#consumerMiddleNameHandling|AWSDKSystemConfiguration.consumerMiddleNameHandling} is set to 'INITIAL'. Can be blank or at most 1 character long. </td>
   * </tr>
   * <tr>
   * <td>middleNameOrInitial</td>
   * <td>invalid field format</td>
   * <td>If {@link model.AWSDKSystemConfiguration#consumerMiddleNameHandling|AWSDKSystemConfiguration.consumerMiddleNameHandling} is set to 'FULLNAME'. Can be blank or at most 100 characters long. </td>
   * </tr>
   * <tr>
   * <td>lastName</td>
   * <td>invalid field format</td>
   * <td>At least 2 chars long</td>
   * </tr>
   * <tr>
   * <td>dob</td>
   * <td>invalid field format</td>
   * <td>Must be valid date</td>
   * </tr>
   * <td>email</td>
   * <td>invalid field format</td>
   * <td>At least 5 chars long, and valid email pattern</td>
   * </tr>
   * <tr>
   * <td>phone</td>
   * <td>invalid field format</td>
   * <td>Must have format '[\\+]?[0-9.-]+' </td>
   * </tr>
   * <tr>
   * <td>address1</td>
   * <td>invalid field format</td>
   * <td>Must be at least 1 char long</td>
   * </tr>
   * <tr>
   * <td>address2</td>
   * <td>invalid field format</td>
   * <td>Can be null, but if not, must be at least 1 char long</td>
   * </tr>
   * <tr>
   * <td>city</td>
   * <td>invalid field format</td>
   * <td>At least 2 char long</td>
   * </tr>
   * <tr>
   * <td>zipCode</td>
   * <td>invalid field format</td>
   * <td>Format must be: nnnnn or nnnnn-nnnn</td>
   * </tr>
   * <tr>
   * <td>brandings</td>
   * <td>invalid field format</td>
   * <td>Must be an array of string</td>
   * </tr>
   * <tr>
   * <td>genderIdentityKey</td>
   * <td>invalid field format</td>
   * <td>Must be null, empty or non-whitespace-only string</td>
   * </tr>
   * </table>
   *
   * @param {model.AWSDKConsumerOverrideDetails} AWSDKConsumerOverrideDetails object to validate
   * @returns {error.AWSDKError[]} an array of {@link error.AWSDKError|AWSDKError} that detail any validation errors.
   * @since 1.4.0
   */
  validateConsumerOverrideDetails(consumerOverrideDetails) {
    const currentFunction = 'ConsumerService.validateConsumerOverrideDetails';
    this.__logger.debug(currentFunction, 'Started', consumerOverrideDetails);
    const errArrayResult = [];
    this.__validateConsumerOverrideDetails(consumerOverrideDetails, errArrayResult);
    this.__logger.trace(currentFunction, 'Finished', errArrayResult);
    return errArrayResult;
  }

  /**
   * Internal Use only!!
   * @private
   */
  __validateConsumerOverrideDetails(consumerOverrideDetails, errors) {
    const currentFunction = 'VisitService.__validateConsumerOverrideDetails';
    this.__logger.trace(currentFunction, 'Started', consumerOverrideDetails, errors);
    let fieldName;
    let reason;
    let recoverySuggestion;
    if (Validator.isValidString(consumerOverrideDetails.firstName, false) && !Validator.isValidString(consumerOverrideDetails.lastName, false)) {
      fieldName = 'lastName';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(consumerOverrideDetails.lastName, false) && !Validator.isValidString(consumerOverrideDetails.firstName, false)) {
      fieldName = 'firstName';
      reason = 'field required';
      recoverySuggestion = 'Set to non-empty value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(consumerOverrideDetails.firstName, false) && !Validator.isFirstNameValid(consumerOverrideDetails.firstName)) {
      fieldName = 'firstName';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    // both middle initial and middle name can be empty even if the system is configured to accept them
    if (this.__systemConfiguration.consumerMiddleNameHandling === AWSDKConsumerMiddleNameHandling.INITIAL && consumerOverrideDetails.middleNameOrInitial != null && !Validator.isMiddleInitialValid(consumerOverrideDetails.middleNameOrInitial)) {
      fieldName = 'middleNameOrInitial';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (this.__systemConfiguration.consumerMiddleNameHandling === AWSDKConsumerMiddleNameHandling.FULLNAME && consumerOverrideDetails.middleNameOrInitial != null && !Validator.isMiddleNameValid(consumerOverrideDetails.middleNameOrInitial)) {
      fieldName = 'middleNameOrInitial';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(consumerOverrideDetails.lastName, false) && !Validator.isLastNameValid(consumerOverrideDetails.lastName)) {
      fieldName = 'lastName';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (consumerOverrideDetails.dob != null && (consumerOverrideDetails.dob instanceof Date) && consumerOverrideDetails.dob.getTime() > Date.now()) {
      fieldName = 'dob';
      reason = 'invalid value';
      recoverySuggestion = 'Set to valid value';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(consumerOverrideDetails.email) && !Validator.isEmailValid(consumerOverrideDetails.email)) {
      fieldName = 'email';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(consumerOverrideDetails.phone) && !Validator.isPhoneNumberValid(consumerOverrideDetails.phone)) {
      fieldName = 'phone';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(consumerOverrideDetails.address1) && !Validator.isAddressValid(consumerOverrideDetails.address1, true)) {
      fieldName = 'address1';
      reason = 'invalid format';
      recoverySuggestion = 'see valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(consumerOverrideDetails.address2) && !Validator.isAddressValid(consumerOverrideDetails.address2, false)) {
      fieldName = 'address2';
      reason = 'invalid format';
      recoverySuggestion = 'see valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(consumerOverrideDetails.city) && consumerOverrideDetails.city.length <= 1) {
      fieldName = 'city';
      reason = 'invalid format';
      recoverySuggestion = 'see valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    if (Validator.isValidString(consumerOverrideDetails.zipCode) && !Validator.isZipCodeValid(consumerOverrideDetails.zipCode)) {
      fieldName = 'zipCode';
      reason = 'invalid format';
      recoverySuggestion = 'see valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    const brandings = consumerOverrideDetails.brandings;
    if (Array.isArray(brandings) && brandings.size > 0 && brandings.some(item => typeof item !== 'string')) {
      fieldName = 'brandings';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    const { genderIdentityKey } = consumerOverrideDetails;
    // allowing gender identity key to be null/unset or empty string
    if (this.__systemConfiguration.genderSupportEnabled && genderIdentityKey != null && genderIdentityKey !== '' && !Validator.isValidString(genderIdentityKey, false)) {
      fieldName = 'genderIdentityKey';
      reason = 'invalid format';
      recoverySuggestion = 'See valid format in docs';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKConsumerOverrideDetails, fieldName, reason, recoverySuggestion));
    }
    this.__logger.trace(currentFunction, 'Finished', errors);
  }

  /**
   * If one exists, retrieves a {@link model.AWSDKVisit|AWSDKVisit} whose disposition is either InProgress or PreScreening for the given authenticated {@link model.AWSDKConsumer|AWSDKConsumer}. If there
   * is no active visit for the consumer, null is returned.
   * @param {model.AWSDKConsumer|AWSDKConsumer} consumer REQUIRED the consumer for which to get an active visit
   * @return {Promise<model.AWSDKVisit|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKVisit|AWSDKVisit} with disposition of either InProgress or PreVisitScreening, if one exists,
   * or will be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitNotFound|AWSDKErrorCode.visitNotFound}</td><td>Consumer has no active visits</td></tr>
   * </table>
   * @since 2.3.0
   */
  findActiveVisit(consumer) {
    const currentFunction = 'VisitService.findActiveVisit';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'activeVisit');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a "activeVisit" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKVisitResponse)
      .then((response) => {
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.debug(currentFunction, 'Got response', response);
        return response.visit;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Initiates the Video Callback feature, which allows the Consumer to stay in the waiting room of a provider without having
   * to stay authenticated with the SDK. The Consumer will be able to join the video visit at a later time using a URL link
   * within in a SMS message sent by the Amwell Home platform to the provided phone number.
   * @param {model.AWSDKVisit} visit is the visit that is requesting a video callback.
   * @param {String} [mobilePhoneNumber] is the phone number that will receive SMS text messages related to the video callback visit.
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationRequiredParameterMissing|AWSDKErrorCode.validationRequiredParameterMissing}</td><td>One or more required parameters were missing or invalid in the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitNotFound|AWSDKErrorCode.visitNotFound}</td><td>Visit not found.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.authenticationInfoMissing|AWSDKErrorCode.authenticationInfoMissing}</td><td>Missing authentication information</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.authorizationForbidden|AWSDKErrorCode.authorizationForbidden}</td><td>Authorization denied</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.waitingRoomPastVideoCallbackThreshold|AWSDKErrorCode.waitingRoomPastVideoCallbackThreshold}</td><td>Waiting room count is less than the required threshold to use the Video Callback feature</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.videoCallbackIVRException|AWSDKErrorCode.videoCallbackIVRException}</td><td>The Amwell Home platform wasn't able to use the provided phone number to initiate the video callback workflow</td></tr>
   * </table>
   * @since 3.5.0
   */
  requestVideoCallback(visit, mobilePhoneNumber) {
    const currentFunction = 'VisitService.requestVideoCallback';
    this.__logger.debug(currentFunction, 'Started', visit);
    if (!(visit instanceof AWSDKVisit)) {
      const error = AWSDKError.AWSDKIllegalArgument('visit argument is not of type AWSDKVisit');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumer = visit.consumer;
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKInternalError('visit.consumer is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    let phoneNumber;
    if (Validator.isPhoneNumberValid(mobilePhoneNumber)) {
      phoneNumber = mobilePhoneNumber;
    } else if (Validator.isPhoneNumberValid(consumer.phoneNumber)) {
      phoneNumber = consumer.phoneNumber;
    } else {
      const error = AWSDKError.AWSDKValidationError(phoneNumber);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(visit.links, 'videoCallback');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('visit does not have a valid "videoCallback" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.headers['Content-Type'] = 'application/json';
    const content = {};
    content.mobilePhoneNumber = phoneNumber;
    options.body = JSON.stringify(content);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }
}

export default VisitService;
