/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKEligibilityRequestStatus.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKEligibilityRequestStatus = {
  /**
   * Successful invocation of remote service with a response that contains coverage and benefits data.
   * The consumer has active coverage at the time of inquiry.
   */
  COVERED: 'COVERED',
  /**
   * Successful invocation of remote service with a response that contains coverage and benefits data.
   * The consumer does not have active coverage.
   * Consumer is not covered or not on file at the time of inquiry.
   */
  NOT_COVERED: 'NOT_COVERED',
  /**
   * Successful invocation of remote service with a response that request validation information.
   * Specifically, the 271 response contains AAA segment that contain
   * request validation error instead of eligibility and benefits info.
   */
  REQUEST_VALIDATION_RESPONSE: 'REQUEST_VALIDATION_RESPONSE',
  /**
   * Invocation of remote service failed..
   */
  FAILED: 'FAILED',
  /**
   * Invocation of remote service was cancelled in-flight.
   */
  CANCELLED: 'CANCELLED',
};
Object.freeze(AWSDKEligibilityRequestStatus);
export default AWSDKEligibilityRequestStatus;
