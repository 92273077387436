/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKDeviceIntegrationMode to distinguish between different device integration modes.
 * @readonly
 * @enum {string}
 * @since 2.9.0
 */
const AWSDKDeviceIntegrationMode = {
  /**
   * Tyto Device Live Stream mode.
   */
  TYTO_LIVESTREAM: 'TYTO_LIVESTREAM',
  /**
   * Tyto Device store and forward mode.
   */
  TYTO_STOREANDFORWARD: 'TYTO_STOREANDFORWARD',
  /**
   * Device is disabled.
   */
  DISABLED: 'DISABLED',
};
Object.freeze(AWSDKDeviceIntegrationMode);
export default AWSDKDeviceIntegrationMode;
