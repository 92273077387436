/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKDataObject from './awsdk_dataobject';
import GenericParser from '../internal/parser/generic_parser';
import AWSDKVisitModalityType from './visit/awsdk_visit_modality_type';
/**
 * AWSDKModality class represents a modality object with all necessary information to describe a modality.
 * @property {String} name The name of the modality
 * @property {String} localizedDisplayName The localized display name of the modality
 * @property {model.AWSDKVisitModalityType} modalityType The type of this modality
 * @since 1.4.0
 * @extends model.AWSDKDataObject
 */
class AWSDKModality extends AWSDKDataObject {
  get name() {
    return this.__data.name;
  }

  get localizedDisplayName() {
    return this.__data.localizedDisplayName;
  }

  get modalityType() {
    return GenericParser.parseEnum(this.__data.name, AWSDKVisitModalityType);
  }
}

export default AWSDKModality;

