/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKDevicePairingStatusEnum.
 * @readonly
 * @enum {string}
 * @since 2.6.0
 */
const AWSDKDevicePairingStatusEnum = {
  /**
     * Indicating that the device is paired
     */
  PAIRED: 'PAIRED',
  /**
     * Indicating that the device is not paired
     */
  NOT_PAIRED: 'NOT_PAIRED',
  /**
     * Indicating that the device is already paired
     */
  PREVIOUSLY_PAIRED: 'PREVIOUSLY_PAIRED',
};
Object.freeze(AWSDKDevicePairingStatusEnum);
export default AWSDKDevicePairingStatusEnum;
