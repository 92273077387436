/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKEntity from './awsdk_entity';
import GenericParser from '../internal/parser/generic_parser';
/**
 * AWSDKstate class contains all the necessary information to correctly describe a given state and other state relevant information
 * @property {String} code the state code
 * @property {String} name the state name
 * @property {String} countryCode the country code
 * @property {String} countryName the country name
 * @property {String} rxServiceState the rx service options
 * @property {Boolean} legalResidence if the state can be used to set set legal residence
 * @property {Boolean} legalAddress if the state can be used on an address
 * @extends model.AWSDKEntity
 */
class AWSDKState extends AWSDKEntity {
  get code() {
    return this.__data.code;
  }

  get name() {
    return this.__data.name;
  }

  get countryCode() {
    return this.__data.country ? this.__data.country.code : null;
  }

  get countryName() {
    return this.__data.country ? this.__data.country.name : null;
  }

  get rxServiceStatus() {
    return this.__data.rxServiceStatus;
  }

  get legalResidence() {
    return GenericParser.parseBoolean(this.__data.legalResidence);
  }

  get legalAddress() {
    return GenericParser.parseBoolean(this.__data.legalAddress);
  }
}

export default AWSDKState;
