/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKResponse from './awsdk_response';
import GenericParser from '../../parser/generic_parser';
import AWSDKProviderNote from '../../../model/visit/awsdk_provider_note';


/**
 * Simple wrapper for a collection of {@link model.AWSDKProviderNote|AWSDKProviderNote}
 * @extends model.AWSDKResponse
 */
class AWSDKProviderNotesResponse extends AWSDKResponse {
  get notes() {
    return this.__data.notes.map(item => GenericParser.parseObject(item, AWSDKProviderNote));
  }
}

export default AWSDKProviderNotesResponse;
