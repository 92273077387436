/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKResource from '../awsdk_resource';

/**
 * AWSDKProviderType class which represents a type of Provider
 * See {@link model.AWSDKResource|AWSDKResource} for details.
 * @since 1.3.1
 * @extends model.AWSDKResource
 */

class AWSDKProviderType extends AWSDKResource {}

export default AWSDKProviderType;
