/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKMessageActionType.
 * @readonly
 * @enum {string}
 * @since 1.3.0
 */
const AWSDKMessageActionType = {
  /**
   * The Forward message action
   */
  New: 'New',
  /**
   * The Forward message action
   */
  Forward: 'Forward',
  /**
   * The Forward message action
   */
  Reply: 'Reply',
};
Object.freeze(AWSDKMessageActionType);
export default AWSDKMessageActionType;
