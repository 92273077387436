/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKProvider from './awsdk_provider';
import GenericParser from '../../internal/parser/generic_parser';
import AWSDKDisclaimer from '../awsdk_disclaimer';
import AWSDKResource from '../awsdk_resource';
/**
 * AWSDKProviderDetails class represents extra information relative to a {@link model.AWSDKProvider|AWSDKProvider}
 * @property {String} gender the provider's gender.
 * @property {String} localizedGender the provider's localized gender
 * @property {Number} appointmentDuration the provider's appointment duration in minutes
 * @property {String} deferredBillingText the text to be displayed if deferred billing is enabled
 * @property {Date} graduatingYear the year of this provider's graduation
 * @property {Boolean} showTriageQuestions boolean true if show triage questions, false otherwise
 * @property {Boolean} missedVisitEmail boolean true if this provider sends a email on missed visits
 * @property {Number} totalRatings the number of ratings
 * @property {String[]} statesLicensedIn the states that this provider is licensed in
 * @property {String} residency the location of this provider's residency
 * @property {String} textGreeting the provider's greeting
 * @property {Number} yearsExperience the years of experience that this provider has
 * @property {Boolean} deferredBillingEnabled boolean true if this provider has deferred billing enabled
 * @property {Boolean} isPCP boolean true if this provider is a Primary Care Physician (PCP)
 * @property {String} schoolName the name of the school this provider attended
 * @property {model.AWSDKResource[]} spokenLanguages the list of spoken languages
 * @property {String[]} boardCertificates the list of board certifications
 * @property {String} internship the internship this Provider has done
 * @property {model.AWSDKDisclaimer} legalText the legal disclaimers associated with the Provider
 * @property {String[]} fellowships the institutions this Provider is a fellow of
 * @extends AWSDKProvider
 */

class AWSDKProviderDetails extends AWSDKProvider {
  get fellowships() {
    return this.__data.fellowships;
  }

  get disclaimer() {
    const legalText = this.__data.legalText;
    if (legalText) {
      return GenericParser.parseObject(legalText, AWSDKDisclaimer);
    }
    return null;
  }

  get internship() {
    return this.__data.internship;
  }

  get gender() {
    return this.__data.gender;
  }

  get localizedGender() {
    return this.__data.localizedGenderDisplayName;
  }

  get appointmentDuration() {
    return this.__data.appointmentDuration;
  }

  get deferredBillingText() {
    return this.__data.deferredBillingText;
  }

  get graduatingYear() {
    return GenericParser.parseDate(this.__data.graduatingYear).getFullYear();
  }

  get showTriageQuestions() {
    return GenericParser.parseBoolean(this.__data.showTriageQuestions);
  }

  get missedVisitEmail() {
    return GenericParser.parseBoolean(this.__data.missedVisitEmail);
  }

  get totalRatings() {
    return this.__data.totalRatings;
  }

  get statesLicensedIn() {
    return this.__data.statesLicensedIn;
  }

  get residency() {
    return this.__data.residency;
  }

  get textGreeting() {
    return this.__data.textGreeting;
  }

  get yearsExperience() {
    return this.__data.yearsExperience;
  }

  get deferredBillingEnabled() {
    return GenericParser.parseBoolean(this.__data.deferredBillingEnabled);
  }

  get isPCP() {
    return GenericParser.parseBoolean(this.__data.isPCP);
  }

  get schoolName() {
    return this.__data.schoolName;
  }

  get spokenLanguages() {
    if (!Array.isArray(this.__data.spokenLanguages) || this.__data.spokenLanguages.length === 0) {
      return [];
    }
    return this.__data.spokenLanguages.map(item => GenericParser.parseObject(item, AWSDKResource));
  }

  get boardCertificates() {
    return this.__data.boardCertificates;
  }
}

export default AWSDKProviderDetails;
