/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import deprecated from 'awcoresdk/lib/util/deprecated';
import AWSDKDataObject from './awsdk_dataobject';
import GenericParser from '../internal/parser/generic_parser';
import AWSDKState from './awsdk_state';


/**
 * AWSDKAddress class contains all the information necessary to correctly represent a physical residential address.
 * @property {String} address1 the first line of the address
 * @property {String} address2 the second line of the address
 * @property {String} city  the city name
 * @property {model.AWSDKState} geographicalState the actual underlying {@link model.AWSDKState} of the address
 * @property {String} stateCode  the two letter code for the state. example: MA
 * @property {String} stateName  the name of the state
 * @property {String} countryCode  the standardized country code for a particular country
 * @property {String} countryName  the name of the country
 * @property {String} zipCode  the zip (postal) code for this physical location
 * @property {String} formattedAddress the formatted address for display purposes
 * @extends model.AWSDKDataObject
 */
class AWSDKAddress extends AWSDKDataObject {
  get address1() {
    return this.__data.address1;
  }

  get address2() {
    return this.__data.address2;
  }

  get city() {
    return this.__data.city;
  }

  get stateCode() {
    return this.__data.state ? this.__data.state.code : null;
  }

  get stateName() {
    return this.__data.state ? this.__data.state.name : null;
  }

  get geographicalState() {
    return this.__data.state ? GenericParser.parseObject(this.__data.state, AWSDKState) : null;
  }

  get countryCode() {
    return this.__data.state && this.__data.state.country ? this.__data.state.country.code : null;
  }

  get countryName() {
    return this.__data.state && this.__data.state.country ? this.__data.state.country.name : null;
  }

  /**
   * @since 1.4.0
   */
  get formattedAddress() {
    return this.__data.formattedDisplayValue;
  }
  get zipCode() {
    return this.__data.zipCode;
  }
}

export default AWSDKAddress;
