/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKConsumerMiddleNameHandling.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKConsumerMiddleNameHandling = {
  /**
   * The consumer's full middle name is supported
   */
  FULLNAME: 'FULLNAME',
  /**
   * The consumer's middle initial is supported
   */
  INITIAL: 'INITIAL',
  /**
   * Neither full middle name or middle initial is supported
   */
  NONE: 'NONE',
};
Object.freeze(AWSDKConsumerMiddleNameHandling);
export default AWSDKConsumerMiddleNameHandling;
