/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

export default {
  sdkVersion: 'W4.3.0',
  supportedOnlineCareVersion: 'O6.5.0-',
};
