import AWCoreSDKVisitLoggingDataObject from 'awcoresdk/lib/visit_logging_data_object';


/**
 * Data object used to pass visit information into the common sdk for logging purposes.
 *
 * @private
 */
class AWSDKGuestLoggingDataObject extends AWCoreSDKVisitLoggingDataObject {
  /**
   * Constructor for AWSDKGuestLoggingDataObject
   *
   * @param {string} sdkVersion Current version of the provider or consumer SDK.
   * @param {string} language The accept-language used in the header..
   * @param {model.AWSDKVideoParticipant} participant The guest participant.
   * @private
   */
  constructor(sdkVersion, language, participant) {
    super();
    this._language = language;
    this._sdkVersion = sdkVersion;
    this._participant = participant;
  }

  get language() {
    return this._language;
  }

  get disposition() {
    return undefined;
  }

  get consumerId() {
    return this._participant.memberEntityId;
  }

  get providerId() {
    return this._participant.providerEntityId;
  }

  get visitId() {
    return undefined;
  }

  get sdkVersion() {
    return this._sdkVersion;
  }
}

export default AWSDKGuestLoggingDataObject;
