/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKCountry from '../../../model/awsdk_country';
import AWSDKHealthPlan from '../../../model/insurance/awsdk_health_plan';
import AWSDKLanguage from '../../../model/awsdk_language';
import AWSDKRelationshipToSubscriberCode from '../../../model/insurance/awsdk_relationship_to_subscriber_code';
import AWSDKResponse from './awsdk_response';
import AWSDKSystemConfiguration from '../../../model/awsdk_system_configuration';
import GenericParser from '../../parser/generic_parser';
import AWSDKCreditCardType from '../../../model/billing/awsdk_credit_card_type';
import AWSDKProviderType from '../../../model/provider/awsdk_provider_type';
import AWSDKModality from '../../../model/awsdk_modality';
import AWSDKModalityVendor from '../../../model/awsdk_modality_vendor';
import AWSDKPracticeSearchType from '../../../model/practice/awsdk_practice_search_type';

class AWSDKInitializationResponse extends AWSDKResponse {
  get systemConfiguration() {
    return this.__data.initialization ? GenericParser.parseObject(this.__data.initialization.systemConfiguration, AWSDKSystemConfiguration) : null;
  }

  get healthPlans() {
    return this.__data.initialization ? this.__data.initialization.healthPlans.map(item => GenericParser.parseObject(item, AWSDKHealthPlan)) : null;
  }

  get initializationLinks() {
    return this.__data.initialization ? this.__data.initialization.links : null;
  }

  get languages() {
    return this.__data.initialization ? this.__data.initialization.languages.map(item => GenericParser.parseObject(item, AWSDKLanguage)) : null;
  }

  get relationshipsToSubsriber() {
    return this.__data.initialization ? this.__data.initialization.relationshipsToSubscriber.map(item => GenericParser.parseObject(item, AWSDKRelationshipToSubscriberCode)) : null;
  }

  get countries() {
    return this.__data.initialization ? this.__data.initialization.countries.map(item => GenericParser.parseObject(item, AWSDKCountry)) : null;
  }

  get creditCardTypes() {
    return this.__data.initialization ? this.__data.initialization.creditCards.map(item => GenericParser.parseObject(item, AWSDKCreditCardType)) : null;
  }

  get providerTypes() {
    return this.__data.initialization ? this.__data.initialization.providerTypes.map(item => GenericParser.parseObject(item, AWSDKProviderType)) : null;
  }

  get practiceSearchTypes() {
    return this.__data.initialization ? this.__data.initialization.practiceSearchTypes.map(item => GenericParser.parseObject(item, AWSDKPracticeSearchType)) : null;
  }

  /**
   * @since 1.4.0
   */
  get availableModalities() {
    return (this.__data.initialization && Array.isArray(this.__data.initialization.availableModalities)) ? this.__data.initialization.availableModalities.map(item => GenericParser.parseObject(item, AWSDKModality)) : [];
  }

  /**
   * The modality vendors for the platform.
   * @type {model.AWSDKModalityVendor[]}
   * @since 2.0.0
   * @private
   */
  get modalityVendors() {
    return (this.__data.initialization && Array.isArray(this.__data.initialization.modalityVendors)) ? this.__data.initialization.modalityVendors.map(item => GenericParser.parseObject(item, AWSDKModalityVendor)) : [];
  }
}

export default AWSDKInitializationResponse;
