/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKEntity from './awsdk_entity';


/**
 * Base class to represent different types of users.
 *
 * @property {String} firstName the firstname on the account
 * @property {String} middleInitial the middle initial on the account
 * @property {String} lastName the lastname on the account
 * @property {String} fullName the full name on the account
 *
 * @since 1.1.0
 * @extends model.AWSDKEntity
 */
class AWSDKAccount extends AWSDKEntity {
  get firstName() {
    return this.__data.firstName;
  }
  get middleInitial() {
    return this.__data.middleInitial;
  }
  get lastName() {
    return this.__data.lastName;
  }
  get fullName() {
    return this.__data.fullName;
  }
}

export default AWSDKAccount;
