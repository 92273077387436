/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKModality from '../../../model/awsdk_modality';
import AWSDKResponse from './awsdk_response';
import GenericParser from '../../parser/generic_parser';

/**
 * Represents a response class for an array of {@link model.AWSDKModality|AWSDKModality} supported by the sdk.
 * @property {model.AWSDKModality[]} availableModalities an array of modalities supported by the sdk.
 * @since 1.4.0
 * @extends model.AWSDKResponse
 */
class AWSDKModalitiesResponse extends AWSDKResponse {
  get availableModalities() {
    return this.__data.availableModalities.map(item => GenericParser.parseObject(item, AWSDKModality));
  }
}
export default AWSDKModalitiesResponse;
