/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import Service from './service';
import Validator from '../internal/validator/validator';
import AWSDKConsumer from '../model/consumer/awsdk_consumer';
import AWSDKPharmacy from '../model/pharmacy/awsdk_pharmacy';
import AWSDKPharmaciesResponse from '../internal/model/response/awsdk_pharmacies_response';
import AWSDKPharmacyResponse from '../internal/model/response/awsdk_pharmacy_response';
import AWSDKResponse from '../internal/model/response/awsdk_response';
import AWSDKState from '../model/awsdk_state';
import AWSDKError from '../error/awsdk_error';

/**
 * This service handles everything related to an {@link model.AWSDKPharmacy|AWSDKPharmacy} and supporting infrastructure
 *
 * @since 1.0.0
 * @hideconstructor
 * @extends service.Service
 */
class PharmacyService extends Service {
  constructor(props) {
    super(props);
    this.__systemConfiguration = props.systemConfiguration;
  }

  /**
   * Retrieves a list of {@link model.AWSDKPharmacy|AWSDKPharmacy} objects pertaining to a {@link model.AWSDKConsumer|AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose pharmacies to retrieve
   * @returns {Promise<model.AWSDKPharmacy[]|error.AWSDKError>} a Promise that resolves to a list of {@link model.AWSDKPharmacy|AWSDKPharmacy} or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  getPharmaciesForConsumer(consumer) {
    const currentFunction = 'PharmacyService.getPharmaciesForConsumer';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'pharmacies');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "pharmacies" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKPharmaciesResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.pharmacies;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * Retrieves the preferred {@link model.AWSDKPharmacy|AWSDKPharmacy} for the given {@link model.AWSDKConsumer|AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose preferred pharmacy to retrieve
   * @returns {Promise<model.AWSDKPharmacy|error.AWSDKError>} a Promise that resolves to an {@link model.AWSDKPharmacy|AWSDKPharmacy} or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.noPreferredPharmacyFound|AWSDKErrorCode.noPreferredPharmacyFound}</td><td>No preferred pharmacy exists for the given consumer.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  getPreferredPharmacy(consumer) {
    const currentFunction = 'PharmacyService.getPreferredPharmacy';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'pharmacy');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "pharmacy" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKPharmacyResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.pharmacy;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Updates the preferred {@link model.AWSDKPharmacy|AWSDKPharmacy} for the given {@link model.AWSDKConsumer|AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose preferred pharmacy to update
   * @param {model.AWSDKPharmacy} pharmacy the preferred {@link model.AWSDKPharmacy|AWSDKPharmacy}
   * @returns {Promise<model.AWSDKConsumer|error.AWSDKError>} a Promise that resolves to the {@link model.AWSDKConsumer|AWSDKConsumer} that was passed, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  updatePreferredPharmacy(consumer, pharmacy) {
    const currentFunction = 'PharmacyService.updatePreferredPharmacy';
    this.__logger.debug(currentFunction, 'Started', consumer, pharmacy);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (pharmacy == null) {
      const error = AWSDKError.AWSDKIllegalArgument('pharmacy argument is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(pharmacy instanceof AWSDKPharmacy)) {
      const error = AWSDKError.AWSDKIllegalArgument('pharmacy argument is not of type AWSDKPharmacy');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'pharmacy');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "pharmacy" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.form.set('pharmacyId', pharmacy.id.encryptedId);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return consumer;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Search for {@link model.AWSDKPharmacy}s that meet the provided search criteria. At least one search criteria argument must be supplied.
   * @param {String} [city] the city in which to search
   * @param {model.AWSDKState} [state] the state in which to search
   * @param {String} [zipCode] the zipCode in which to search
   * @param {String} [pharmacyType] the type of pharmacy to search for
   * @returns {Promise<model.AWSDKPharmacy[]|error.AWSDKError>} a Promise that resolves a collection of {@link model.AWSDKPharmacy|AWSDKPharmacy} that match the search criteria, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  getPharmacies(city, state, zipCode, pharmacyType) {
    const currentFunction = 'PharmacyService.getPharmacies';
    this.__logger.debug(currentFunction, 'Started', city, state, zipCode, pharmacyType);
    if (!city && !state && !zipCode && !pharmacyType) {
      const error = AWSDKError.AWSDKIllegalArgument('minimum of 1 search criteria is required');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (Validator.isValidString(zipCode) && !Validator.isZipCodeValid(zipCode)) {
      const error = AWSDKError.AWSDKIllegalArgument('zip code is not valid', 'zipCode');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (state && !(state instanceof AWSDKState)) {
      const error = AWSDKError.AWSDKIllegalArgument('state argument is incorrect type');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'pharmacySearch');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "pharmacySearch" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    options.form.set('city', city);
    options.form.set('state', state ? state.code : null);
    options.form.set('zip', zipCode);
    options.form.set('type', pharmacyType);
    return this.executeRequest(options, AWSDKPharmaciesResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        return response.pharmacies;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Search for {@link model.AWSDKPharmacy}s that are within the specified radius of the given longitude and latitude.
   * @param {Number} longitude the longitude component of the coordinates to search for
   * @param {Number} latitude the latitude component of the coordinates to search for
   * @param {Number} [radius] the max radius to consider (in miles)
   * @param {Boolean} [excludeMailOrderPharmacies] exclude mail order pharmacies from the search result
   * @returns {Promise<model.AWSDKPharmacy[]|error.AWSDKError>} a Promise that resolves a collection of {@link model.AWSDKPharmacy|AWSDKPharmacy} that match the search criteria, or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  getPharmaciesByLongitudeAndLatitude(longitude, latitude, radius, excludeMailOrderPharmacies) {
    const currentFunction = 'PharmacyService.getPharmaciesByLongitudeAndLatitude';
    this.__logger.debug(currentFunction, 'Started', longitude, latitude, radius, excludeMailOrderPharmacies);
    if (!longitude || isNaN(parseFloat(longitude))) {
      const error = AWSDKError.AWSDKIllegalArgument('valid value for "longitude" not provided');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!latitude || isNaN(parseFloat(latitude))) {
      const error = AWSDKError.AWSDKIllegalArgument('valid value for "latitude" not provided');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (radius && isNaN(parseInt(radius, 10))) {
      const error = AWSDKError.AWSDKIllegalArgument('"radius" must be an integer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'pharmaciesNearMe');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a valid "pharmaciesNearMe" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    options.form.set('longitude', longitude);
    options.form.set('latitude', latitude);
    options.form.set('radius', radius);
    options.form.set('excludeMailOrderParam', excludeMailOrderPharmacies);
    return this.executeRequest(options, AWSDKPharmaciesResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        return response.pharmacies;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Delete the {@link model.AWSDKPharmacy}s from the given {@link model.AWSDKConsumer}'s account.
   * @param {model.AWSDKConsumer} consumer the consumer whose pharmacy to delete
   * @param {model.AWSDKPharmacy} pharmacy the pharmacy to delete
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.2.0
   */
  deletePharmacy(consumer, pharmacy) {
    const currentFunction = 'PharmacyService.deletePharmacy';
    this.__logger.debug(currentFunction, 'Started', consumer, pharmacy);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(pharmacy instanceof AWSDKPharmacy)) {
      const error = AWSDKError.AWSDKIllegalArgument('pharmacy argument is not of type AWSDKPharmacy');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'pharmacy');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "pharmacy" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('DELETE', link.url);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.form.set('pharmacyId', pharmacy.id.encryptedId);
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
}

export default PharmacyService;
