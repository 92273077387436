/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKAppointment from '../../../model/appointment/awsdk_appointment';
import AWSDKResponse from './awsdk_response';
import GenericParser from '../../parser/generic_parser';

/**
 * Simple wrapper around a collection of {@link model.AWSDKAppointment}s
 * @property {model.AWSDKAppointment[]} appointments the {@link model.AWSDKAppointment}s
 * @extends model.AWSDKResponse
 */
class AWSDKAppointmentsResponse extends AWSDKResponse {
  get appointments() {
    return (Array.isArray(this.__data.appointments) && this.__data.appointments.map(item => GenericParser.parseObject(item, AWSDKAppointment))) || [];
  }
}

export default AWSDKAppointmentsResponse;
