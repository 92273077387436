/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKResponse from './awsdk_response';
import AWSDKConsumer from '../../../model/consumer/awsdk_consumer';
import GenericParser from '../../parser/generic_parser';

/**
 * AWSDKConsumerResponse class represents the wrapper object that contains the result of a search for a consumer details information <br>
 * and contains a single instance of a {@link model.AWSDKConsumer|AWSDKConsumer} object
 * @property {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} object
 * @extends model.AWSDKResponse
 */
class AWSDKConsumerResponse extends AWSDKResponse {
  get consumer() {
    return GenericParser.parseObject(this.__data.member, AWSDKConsumer);
  }
}

export default AWSDKConsumerResponse;
