/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKFirstAvailableConfiguration from '../../../model/practice/awsdk_first_available_configuration';
import AWSDKResponse from './awsdk_response';
import GenericParser from '../../parser/generic_parser';

/**
 * Wrapper around a {@link model.AWSDKFirstAvailableConfiguration}
 * @property {model.AWSDKFirstAvailableConfiguration} first available configuration the {@link model.AWSDKFirstAvailableConfiguration}
 * @extends model.AWSDKResponse
 */
class AWSDKFirstAvailableConfigurationResponse extends AWSDKResponse {
  get firstAvailableConfiguration() {
    return this.__data.specialties.map(item => GenericParser.parseObject(item, AWSDKFirstAvailableConfiguration));
  }
}

export default AWSDKFirstAvailableConfigurationResponse;
