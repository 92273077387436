/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKUserType.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKUserType = {
  /**
   * Consumer type.
   */
  CONSUMER: 'CONSUMER',
  /**
   * Provider type.
   */
  PROVIDER: 'PROVIDER',
  /**
   * Admin type.
   */
  ADMIN: 'ADMIN',
  /**
   * Provider assistant type.
   */
  ASSISTANT: 'ASSISTANT',
  /**
   * Anonymous SDK user type
   */
  SDK: 'SDK',
};
Object.freeze(AWSDKUserType);
export default AWSDKUserType;
