/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2019 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKDataObject from './awsdk_dataobject';

/**
 * Representation of supported vendors for different visit types.
 * @property {string} name The name of the vendor.
 * @property {string} clientApiUrl The url used to access vendor api code
 * @since 2.0.0
 * @extends model.AWSDKDataObject
 */
class AWSDKModalityVendor extends AWSDKDataObject {
  get name() {
    return this.__data.name;
  }

  get clientApiUrl() {
    return this.__data.clientApiUrl;
  }
}

export default AWSDKModalityVendor;
