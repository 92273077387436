/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
/**
 * AWSDKEntityId class
 * @property {String} encryptedId the encrypted id
 * @property {String} persistentId the persistent id
 */
class AWSDKEntityId {
  constructor(data) {
    this.__data = data;
  }

  get encryptedId() {
    return this.__data.encryptedId;
  }

  get persistentId() {
    return this.__data.persistentId;
  }

  toString() {
    const dataString = JSON.stringify(this.__data);
    return dataString;
  }
}

export default AWSDKEntityId;
