/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKRecoverEmail from '../../../model/authentication/awsdk_recover_email';
import AWSDKResponse from './awsdk_response';
import GenericParser from '../../parser/generic_parser';

/**
 * Represents a response object wrapper to a single {@link model.AWSDKRecoverEmail} object type
 * @property {model.AWSDKRecoverEmail} recoverEmail The {@link model.AWSDKRecoverEmail}.
 * @extends model.AWSDKResponse
 */
class AWSDKRecoverEmailResponse extends AWSDKResponse {
  get recoverEmail() {
    return GenericParser.parseObject(this.__data.recoverEmail, AWSDKRecoverEmail);
  }
}

export default AWSDKRecoverEmailResponse;
