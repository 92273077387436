/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKCheckInStatus.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKCheckInStatus = {
  /**
   * On time for the appointment, within the window
   */
  ON_TIME: 'ON_TIME',
  /**
   * Early for the appointment, before the window
   */
  EARLY: 'EARLY',
  /**
   * Late for the appointment, after the window
   */
  LATE: 'LATE',
  /**
   * Provider not available for the appointment
   */
  NO_PROVIDER: 'NO_PROVIDER',
};
Object.freeze(AWSDKCheckInStatus);
export default AWSDKCheckInStatus;
