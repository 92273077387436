/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import GenericParser from '../parser/generic_parser';

const _anyOneCharNonLineTerminator = /./;
const _anySingleChar = /\S/;
const _phoneRegex = /^\+?[0-9.-]+$/;
const _emailRegex = /^[a-zA-Z0-9!#$%&amp;'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&amp;'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
const _zipRegex = /^[0-9]{5}(-[0-9]{4})?$/;
const _threeDigitRegex = /^\d{3}$/;
const _fourDigitRegex = /^\d{4}$/;
const _sixDigitRegex = /^\d{6}$/;

class Validator {
  static isInt(x) {
    const y = parseInt(x, 10);
    // eslint-disable-next-line eqeqeq
    return !isNaN(y) && x == y && x.toString() === y.toString();
  }
  static isValidString(element, isWhitespaceOnlyValid = true) {
    return element != null && element !== ''
      && Validator.isStringType(element)
      && (isWhitespaceOnlyValid || element.trim() !== '');
  }

  static isStringType(element) {
    return typeof element === 'string' || element instanceof String;
  }
  static isValidLink(link) {
    return link != null && Validator.isValidString(link.url);
  }

  static isMiddleInitialValid(middleInitial, regex = _anySingleChar) {
    return Validator.hasValidFormat(middleInitial, 1, 0, regex);
  }

  static isMiddleNameValid(middleName, regex = _anySingleChar) {
    if (middleName === '') {
      return true;
    }
    return Validator.hasMatch(middleName, regex);
  }

  static isFirstNameValid(firstName, regex = _anyOneCharNonLineTerminator) {
    return Validator.hasMatch(firstName, regex);
  }

  static isLastNameValid(lastName, regex = _anyOneCharNonLineTerminator) {
    return Validator.hasMatch(lastName, regex);
  }

  static isPhoneNumberValid(phoneNumber, regex = _phoneRegex) {
    if (Validator.isValidString(phoneNumber) && phoneNumber.length >= 10) {
      return Validator.hasMatch(phoneNumber, regex);
    }
    return false;
  }

  static isAddressValid(address, isRequired, regex) {
    if (!Validator.isValidString(address)) {
      return !isRequired;
    }
    const minLen = isRequired ? 1 : 0;
    const regexToUse = (isRequired && regex) || (isRequired ? /^.+$/ : /.*/);
    return Validator.hasValidFormat(address, 255, minLen, regexToUse);
  }

  static isEmailValid(email, regex = _emailRegex) {
    return Validator.isValidString(email) && Validator.hasValidFormat(email, 200, 5, regex);
  }

  static isZipCodeValid(zipCode, regex = _zipRegex) {
    if (!Validator.isValidString(zipCode)) {
      return false;
    }
    return Validator.hasMatch(zipCode, regex);
  }

  static hasMatch(stringExpr, regex) {
    if (Validator.isValidString(stringExpr)) {
      const res = stringExpr.match(regex);
      if (res && res.length > 0) {
        return true;
      }
    }
    return false;
  }

  static hasValidFormat(value, maxLen, minLen, regex) {
    if (minLen === 0 && value == null) {
      return false;
    }
    if (minLen === 0 && value.length === 0) {
      return true;
    }
    if (minLen > 0 && !Validator.isValidString(value)) {
      return false;
    }
    if (value.length < minLen || value.length > maxLen) {
      return false;
    }
    return Validator.hasMatch(value, regex);
  }

  static areDatesEqual(date1, date2) {
    if (!date1 && !date2) return true;
    return Validator.isValidDate(date1) && Validator.isValidDate(date2) && (date1.getTime() === date2.getTime());
  }

  static isCVV(cvvNumber, cvvExpectedLength) {
    if (!Validator.isInt(cvvExpectedLength) || cvvExpectedLength < 1) return false;
    if (cvvNumber == null) return false;
    // pre-compiled regex are less expensive so write one for 3 and 4
    if (cvvExpectedLength === 3) return _threeDigitRegex.exec(cvvNumber) !== null;
    if (cvvExpectedLength === 4) return _fourDigitRegex.exec(cvvNumber) !== null;
    if (cvvExpectedLength === 6) return _sixDigitRegex.exec(cvvNumber) !== null;
    // some extraneous cvv length, use a RegExp
    return new RegExp(`^\\d{${cvvExpectedLength}}$`).exec(cvvNumber) !== null;
  }

  static isValidEnumValue(value, enumType) {
    if (this.isValidStringEnumValue(value, enumType)) {
      return true;
    }

    try {
      const namedConstant = GenericParser.parseAndConvertEnumValue(value);
      if (namedConstant && GenericParser.parseEnum(namedConstant, enumType)) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  static isValidStringEnumValue(value, enumType) {
    return Object.keys(enumType).includes(value);
  }

  static isValidDate(value) {
    try {
      if (Object.prototype.toString.call(value) === '[object Date]') {
        value.toISOString();
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
}

export default Validator;
