/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from './../awsdk_dataobject';
import GenericParser from '../../internal/parser/generic_parser';

/**
 * AWSDKRelationshipToSubscriberCode class represents a relationship object between an person covered by a health plan insurance and the policy owner.
 * @property {String} displayName the display name for the relationship code.
 * @property {String} localizedDisplayName the localized display name for the relationship code.
 * @property {Number} warehouseValue the internal numeric value used to represent the relationship code.
 * @property {Boolean} isValidForMinorAccount is the relationship code valid for minor's.
 * @extends model.AWSDKDataObject
 */
class AWSDKRelationshipToSubscriberCode extends AWSDKDataObject {
  get displayName() {
    return this.__data.displayName;
  }

  get localizedDisplayName() {
    return this.__data.translatedDisplayName || this.__data.localizedDisplayName;
  }

  get warehouseValue() {
    return this.__data.warehouseValue;
  }
  get isValidForMinorAccount() {
    return GenericParser.parseBoolean(this.__data.isValidForMinorAccount);
  }
}

export default AWSDKRelationshipToSubscriberCode;
