/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKProviderVisibility.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKProviderVisibility = {
  /**
   * The provider appears offline to consumers, but may not actually be offline. For example the provider can be available by appointment only.
   */
  OFFLINE: 'OFFLINE',
  /**
   * The provider is logged in via phone, but busy.
   */
  PHONE_BUSY: 'PHONE_BUSY',
  /**
   * The provider is available for phone conversations, but not web.
   */
  PHONE_AVAILABLE: 'PHONE_AVAILABLE',
  /**
   * The provider is logged in via web, but busy.
   */
  WEB_BUSY: 'WEB_BUSY',
  /**
   * The provider is available for web conversations.
   */
  WEB_AVAILABLE: 'WEB_AVAILABLE',
};
Object.freeze(AWSDKProviderVisibility);
export default AWSDKProviderVisibility;
