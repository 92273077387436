/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import Service from './service';
import AWSDKConsumer from '../model/consumer/awsdk_consumer';
import AWSDKError from '../error/awsdk_error';
import Validator from '../internal/validator/validator';
import AWSDKProvider from '../model/provider/awsdk_provider';
import AWSDKResponse from '../internal/model/response/awsdk_response';

/**
 * This service handles everything related to IVR (Interactive Voice Response) calls
 *
 * @since 1.2.0
 * @hideconstructor
 * @extends service.Service
 */
class IVRService extends Service {
  constructor(props) {
    super(props);
    this.__systemConfiguration = props.systemConfiguration;
  }

  /**
   * Initiates an IVR call between a {@link model.AWSDKConsumer|AWSDKConsumer} and a {@link model.AWSDKProvider|AWSDKProvider}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} initiating the call
   * @param {model.AWSDKProvider} provider the {@link model.AWSDKProvider|AWSDKProvider} to call
   * @param {String} [overridePhoneNumber] an optional phone number to override the consumer's phone number
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>The provided overridePhoneNumber is not a valid format</td></tr>
   * </tbody>
   * </table>
   * @since 1.2.0
   */
  initiateCall(consumer, provider, overridePhoneNumber) {
    const currentFunction = 'IVRService.initiateCall';
    this.__logger.debug(currentFunction, 'Started', consumer, provider, overridePhoneNumber);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(provider instanceof AWSDKProvider)) {
      const error = AWSDKError.AWSDKIllegalArgument('provider is null or not an instance of AWSDKProvider');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (overridePhoneNumber && !Validator.isPhoneNumberValid(overridePhoneNumber)) {
      const error = AWSDKError.AWSDKValidationError('overridePhoneNumber');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(this.__links, 'ivrRequestCall');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "ivrRequestCall" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    options.form.set('memberId', consumer.id.encryptedId);
    options.form.set('providerId', provider.id.encryptedId);
    if (overridePhoneNumber) options.form.set('phoneNumber', overridePhoneNumber);

    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
}

export default IVRService;
