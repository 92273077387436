/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKConsumerInitiatedIVRStatus.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKConsumerInitiatedIVRStatus = {
  /**
   * Consumer chose to initiate IVR callback
   */
  IVR_REQUESTED: 'IVR_REQUESTED',
  /**
   * The IVR request has timed out
   */
  IVR_TIMEOUT: 'IVR_TIMEOUT',
  /**
   * Set when outdial request is sent to IVR
   */
  IVR_DIALING_CONSUMER: 'IVR_DIALING_CONSUMER',
  /**
   * Consumer didn't answer or answered and didn't enter DTMF (Dual-tone multi-frequency) (I.E.
   * pressed a key on the telephone keypad).
   */
  IVR_CONSUMER_UNREACHABLE: 'IVR_CONSUMER_UNREACHABLE',
  /**
   * Consumer has opted-out of joining the IVR call after an attempt to establish this call has
   * already failed.
   */
  IVR_CONSUMER_FAILED: 'IVR_CONSUMER_FAILED',
  /**
   * Consumer has successfully connected to IVR and is now attempting to reach out to the provider.
   */
  IVR_DIALING_PROVIDER: 'IVR_DIALING_PROVIDER',
  /**
   * Provider didn't answer or answered and didn't enter DTMF (Dual-tone multi-frequency) (I.E.
   * pressed a key on the telephone keypad).
   */
  IVR_PROVIDER_UNREACHABLE: 'IVR_PROVIDER_UNREACHABLE',
  /**
   * Provider has opted-out of joining the IVR call
   */
  IVR_PROVIDER_FAILED: 'IVR_PROVIDER_FAILED',
  /**
   * Both Users Have Successfully Connected
   */
  IVR_CONNECTED: 'IVR_CONNECTED',
};
Object.freeze(AWSDKConsumerInitiatedIVRStatus);
export default AWSDKConsumerInitiatedIVRStatus;
