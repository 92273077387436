/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKEndReason.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKEndReason = {
  /**
   * The visit expired.
   */
  VISIT_EXPIRED: 'VISIT_EXPIRED',
  /**
   * The consumer was disconnected after the payment threshold.
   */
  CONSUMER_DISCONNECT_POST_THRESHOLD: 'CONSUMER_DISCONNECT_POST_THRESHOLD',
  /**
   * The consumer was disconnected before the payment threshold.
   */
  CONSUMER_DISCONNECT_PRE_THRESHOLD: 'CONSUMER_DISCONNECT_PRE_THRESHOLD',
  /**
   * The consumer was forcefully disconnected by an admin.
   */
  CONSUMER_FORCED_DISCONNECT: 'CONSUMER_FORCED_DISCONNECT',
  /**
   * The consumer canceled the visit.  This can happen by entering the visit
   * console (for a scheduled or unscheduled visit) and canceling before
   * the provider enters.
   */
  CONSUMER_CANCEL: 'CONSUMER_CANCEL',
  /**
   * The consumer failed to authenticate via IVR (didn't pick up the phone, or wrong PIN).
   */
  CONSUMER_IVR_AUTH_FAILED: 'CONSUMER_IVR_AUTH_FAILED',
  /**
   * The consumer ended the visit.
   */
  CONSUMER_END: 'CONSUMER_END',
  /**
   * The initiator logged out during pre-visit screening.
   */
  INITIATOR_LOGOUT_PRE_VISIT: 'INITIATOR_LOGOUT_PRE_VISIT',
  /**
   * The initiator logged out after starting the visit with the provider.
   */
  INITIATOR_LOGOUT_AFTER_START: 'INITIATOR_LOGOUT_AFTER_START',
  /**
   * Consulting provider gracefully ended an visit. This is possible only if the visit is zero-cost to
   * the consumer. Otherwise, the end reason would be PROVIDER_BAIL.
   */
  PROVIDER_END: 'PROVIDER_END',
  /**
   * The provider declined the visit.
   */
  PROVIDER_DECLINE: 'PROVIDER_DECLINE',
  /**
   * The provider declined and transferred the visit.
   */
  PROVIDER_DECLINE_AND_TRANSFER: 'PROVIDER_DECLINE_AND_TRANSFER',
  /**
   * The provider bailed out of the visit.
   */
  PROVIDER_BAIL: 'PROVIDER_BAIL',
  /**
   * The provider was disconnected.
   */
  PROVIDER_DISCONNECT: 'PROVIDER_DISCONNECT',
  /**
   * The provider did not respond in time.
   */
  PROVIDER_RESPONSE_TIMEOUT: 'PROVIDER_RESPONSE_TIMEOUT',
  /**
   * The provider logged out.
   */
  PROVIDER_LOGOUT: 'PROVIDER_LOGOUT',
  /**
   * The provider failed to authenticate via IVR.
   */
  PROVIDER_IVR_AUTH_FAILED: 'PROVIDER_IVR_AUTH_FAILED',
  /**
   * The assistant declined on behalf of the provider.
   */
  ASSISTANT_DECLINE: 'ASSISTANT_DECLINE',
  /**
   * The assistant declined and transferred on behalf of the provider.
   */
  ASSISTANT_DECLINE_AND_TRANSFER: 'ASSISTANT_DECLINE_AND_TRANSFER',
  /**
   * Ejected from waiting room due to provider disconnection.
   */
  WAITING_ROOM_EXPIRED: 'WAITING_ROOM_EXPIRED',
  /**
   * An unexpected exception occurred while calculating the visit cost asynchronously.
   */
  ASYNC_COST_CALC_EXCEPTION: 'ASYNC_COST_CALC_EXCEPTION',
  /**
   * Consumer has chosen to transfer to a different provider.
   */
  CONSUMER_TRANSFER: 'CONSUMER_TRANSFER',
};
Object.freeze(AWSDKEndReason);
export default AWSDKEndReason;
