/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKEntity from './../awsdk_entity';
import AWSDKPayerInfo from './awsdk_payer_info';
import GenericParser from '../../internal/parser/generic_parser';

/**
 * AWSDKHealthPlan class represents a health plan object, which encapsulates information about a health plan.
 * @property {String} name the health plan name.
 * @property {String} qualifier the health plan qualifier.
 * @property {Boolean} usesSuffix returns TRUE if the health plan uses suffixes.
 * @property {Boolean} feedControlled returns TRUE if the health plan is feed controlled.
 * @property {model.AWSDKPayerInfo} payerInfo information about the payer.
 * @extends model.AWSDKEntity
 */
class AWSDKHealthPlan extends AWSDKEntity {
  get name() {
    return this.__data.name;
  }
  get qualifier() {
    return this.__data.qualifier;
  }
  get usesSuffix() {
    return GenericParser.parseBoolean(this.__data.usesSuffix);
  }

  get feedControlled() {
    return GenericParser.parseBoolean(this.__data.feedControlled);
  }

  get payerInfo() {
    return GenericParser.parseObject(this.__data.payerInfo, AWSDKPayerInfo);
  }
}

export default AWSDKHealthPlan;
