/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKConsumer from '../consumer/awsdk_consumer';
import AWSDKDisclaimer from '../awsdk_disclaimer';
import AWSDKResponse from '../../internal/model/response/awsdk_response';
import GenericParser from '../../internal/parser/generic_parser';
import AWSDKServerLogParam from '../../internal/model/awsdk_server_log_param';
import AWSDKTwoFactorInfo from './awsdk_two_factor_info';

/**
 * AWSDKAuthentication class represents an authentication object that encapsulates common AWSDK authentication fields, which simplify authentication <br>
 * against an instance of the AWSDK.
 * @property {Boolean} needsToCompleteRegistration  True if the user was authenticated, but needs to complete the registration process
 * @property {Boolean} credentialsSystemGenerated True if the user's credentials where generated by the system
 * @property {model.AWSDKConsumer} consumer the consumer data
 * @property {model.AWSDKTwoFactorInfo} twoFactorInfo the two-factor login information
 * @property {Boolean} fullyAuthenticated True if the user is fully authenticated
 * @extends model.AWSDKResponse
 */
class AWSDKAuthentication extends AWSDKResponse {
  get needsToCompleteRegistration() {
    return this.__data.authentication ? GenericParser.parseBoolean(this.__data.authentication.partiallySdkEnrolled) : false;
  }

  get credentialsSystemGenerated() {
    return this.__data.authentication ? GenericParser.parseBoolean(this.__data.authentication.credentialsSystemGenerated) : false;
  }

  get consumer() {
    return (this.__data.authentication && this.__data.authentication.member) ? GenericParser.parseObject(this.__data.authentication.member, AWSDKConsumer) : null;
  }

  get authenticationLinks() {
    return this.__data.authentication ? this.__data.authentication.links : [];
  }

  get outstandingDisclaimer() {
    return this.__data.authentication && this.__data.authentication.outstandingDisclaimer ? GenericParser.parseObject(this.__data.authentication.outstandingDisclaimer, AWSDKDisclaimer) : null;
  }

  /**
   * @since 3.3.0
   */
  get fullyAuthenticated() {
    return this.__data.authentication ? GenericParser.parseBoolean(this.__data.authentication.fullyAuthenticated) : false;
  }

  /**
   * @since 3.3.0
   */
  get twoFactorInfo() {
    // if there is no two factor information, return AWSDKTwoFactorInfo with requiredAction === AWSDKTwoFactorRequiredAction.NONE
    return this.__data.authentication && this.__data.authentication.twoFactorAuthentication ?
      GenericParser.parseObject(this.__data.authentication.twoFactorAuthentication, AWSDKTwoFactorInfo) :
      new AWSDKTwoFactorInfo();
  }

  /**
   * A convenience method to get a {@link model.AWSDKServerLogParam|AWSDKServerLogParam} object
   * @returns {Object} the server log param object
   * @since 1.3.0
   */
  getServerLogParam() {
    return this.consumer ? new AWSDKServerLogParam(this.consumer.id.encryptedId, 'member') : null;
  }

  /*
   * Override toString to avoid storing sdkUserAuthKey (should not be needed on subsequent usage)
   */
  toString() {
    const dataClone = Object.assign({}, this.__data);
    delete dataClone.authentication.sdkUserAuthKey;
    return JSON.stringify(dataClone);
  }
}

export default AWSDKAuthentication;
