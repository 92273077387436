/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKCreditCardTypesResponse from './../model/response/awsdk_credit_card_types_response';
import AWSDKCountriesResponse from './../model/response/awsdk_countries_response';
import AWSDKError from './../../error/awsdk_error';
import AWSDKErrorCode from './../../error/awsdk_error_code';
import AWSDKHealthPlansResponse from './../model/response/awsdk_health_plans_response';
import AWSDKInitializationResponse from './../model/response/awsdk_initialization_response';
import AWSDKLanguagesResponse from './../model/response/awsdk_languages_response';
import AWSDKRelationshipsResponse from './../model/response/awsdk_relationships_response';
import AWSDKSystemConfigurationResponse from './../model/response/awsdk_system_configuration_response';
import AWSDKValidateSdkApiKeyResponse from '../model/response/validate_sdkapikey_response';
import AWSDKTopicTypesResponse from '../model/response/awsdk_topic_types_response';
import AWSDKProviderTypesResponse from '../model/response/awsdk_provider_types_response';
import AWSDKTrackerTemplatesList from '../model/response/awsdk_tracker_templates_list';
import Validator from '../validator/validator';
import Service from './../../service/service';
import AWSDKModalitiesResponse from '../model/response/awsdk_modalities_response';
import AWSDKPlatformTypeResponse from '../model/response/awsdk_platform_type_response';

/**
 * This is the InitializationService class.
 * @since 1.0.0
 * @hideconstructor
 * @extends service.Service
 */
class InitializationService extends Service {
  /**
   * Validate the sdk api key and return links
   * @since 1.0.0
   * @returns a Promise.<AWSDKInitSdkResponse>
   */
  validateSdkApiKey(url) {
    const currentFunction = 'validateSdkApiKey';
    this.__logger.debug(currentFunction, 'Started');
    const options = this.generateOptions('POST', url);
    options.auth = this.getSdkAuth();
    options.form.append('sdkApiKey', this.__config.sdkApiKey);
    return this.executeRequest(options, AWSDKValidateSdkApiKeyResponse)
      .then((validationResponse) => {
        // The Amwell platform version is first seen here
        // add it manually for later comparison
        this.__config.amWellPlatformVersion = validationResponse.amWellPlatformVersion;
        return Promise.resolve(validationResponse);
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        let resolvedError = error;
        if (error instanceof AWSDKError
          && error.errorCode === AWSDKErrorCode.authenticationAccessDenied) {
          resolvedError = AWSDKError.AWSDKInitializationError();
        }
        throw resolvedError;
      });
  }
  /**
   * Validate the sdk api key and return links
   * @since 1.0.0
   * @returns a Promise.<AWSDKInitSdkResponse>
   */
  initialization() {
    const link = this.findNamedLink(this.__links, 'initialization');
    if (!Validator.isValidLink(link)) {
      return Promise.reject(AWSDKError.AWSDKInitializationError());
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKInitializationResponse);
  }

  getSystemConfiguration() {
    const currentFunction = 'getSystemConfiguration';
    this.__logger.debug(currentFunction, 'Started');
    const link = this.findNamedLink(this.__links, 'configuration');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "configuration" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKSystemConfigurationResponse)
      .then((systemConfigurationResponse) => {
        this.__logger.debug(currentFunction, 'Got response', systemConfigurationResponse);
        return systemConfigurationResponse.systemConfiguration;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  getLanguages() {
    const currentFunction = 'getLanguages';
    this.__logger.debug(currentFunction, 'Started');
    const link = this.findNamedLink(this.__links, 'languages');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "languages" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKLanguagesResponse)
      .then((languagesResponse) => {
        this.__logger.debug(currentFunction, 'Got response', languagesResponse);
        return languagesResponse.languages;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  getProviderTypes() {
    const currentFunction = 'getProviderTypes';
    this.__logger.debug(currentFunction, 'Started');
    const link = this.findNamedLink(this.__links, 'providerTypes');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "providerTypes" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKProviderTypesResponse)
      .then((providerTypesResponse) => {
        this.__logger.debug(currentFunction, 'Got response', providerTypesResponse);
        return providerTypesResponse.providerTypes;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  getTopicTypes() {
    const currentFunction = 'getTopicTypes';
    this.__logger.debug(currentFunction, 'Started');
    const link = this.findNamedLink(this.__links, 'topicTypes');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "topicTypes" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKTopicTypesResponse)
      .then((topicTypesResponse) => {
        this.__logger.debug(currentFunction, 'Got response', topicTypesResponse);
        return topicTypesResponse.topicTypes;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  getCreditCardTypes() {
    const currentFunction = 'getCreditCardTypes';
    this.__logger.debug(currentFunction, 'Started');
    const link = this.findNamedLink(this.__links, 'creditCards');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "creditCards" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKCreditCardTypesResponse)
      .then((creditCardTypesResponse) => {
        this.__logger.debug(currentFunction, 'Got response', creditCardTypesResponse);
        return creditCardTypesResponse.creditCardTypes;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  getHealthPlans() {
    const currentFunction = 'getHealthPlans';
    this.__logger.debug(currentFunction, 'Started');
    const link = this.findNamedLink(this.__links, 'healthPlans');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "healthPlans" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKHealthPlansResponse)
      .then((healthPlansResponse) => {
        this.__logger.debug(currentFunction, 'Got response', healthPlansResponse);
        return healthPlansResponse.healthPlans;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  getRelationshipsToSubscriber() {
    const currentFunction = 'getRelationshipsToSubscriber';
    this.__logger.debug(currentFunction, 'Started');
    const link = this.findNamedLink(this.__links, 'relationships');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "relationships" link entry');
      this.__logger.error('getRelationshipsToSubscriber', 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKRelationshipsResponse)
      .then((relationshipsResponse) => {
        this.__logger.debug(currentFunction, 'Got response', relationshipsResponse);
        return relationshipsResponse.relationshipsToSubscriber;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  getCountries() {
    const currentFunction = 'getCountries';
    this.__logger.debug(currentFunction, 'Started');
    const link = this.findNamedLink(this.__links, 'countries');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "countries" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKCountriesResponse)
      .then((countriesResponse) => {
        this.__logger.debug(currentFunction, 'Got response', countriesResponse);
        return countriesResponse.countries;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Retrieves the list of available modalities that can be offered for a visit
   * @since 1.4.0
   * @returns an array of Modality <AWSDKModality>
   */
  getAvailableModalities() {
    const currentFunction = 'getAvailableModalities';
    this.__logger.debug(currentFunction, 'Started');
    const link = this.findNamedLink(this.__links, 'availableModalities');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have an "availableModalities" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKModalitiesResponse)
      .then((modalitiesResponse) => {
        this.__logger.debug(currentFunction, 'Got response', modalitiesResponse);
        return modalitiesResponse.availableModalities;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Retrieves an array of {@link model.AWSDKPlatformType}
   * @returns {Promise<model.AWSDKPlatformType[]|AWSDKError} a Promise that will resolve to an array of {@link model.AWSDKPlatformType|AWSDKPlatformType} or
   * rejected with an {@link error.AWSDKError|AWSDKError}
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.internalError}</td><td>Error if the AWSDK found an issue with itself</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument}</td><td>Error if a bad argument is passed into the method</td></tr>
   * </table>
   * @since 1.4.0
   */
  getPlatformTypes() {
    const currentFunction = 'getPlatformTypes';
    this.__logger.debug(currentFunction, 'Started');
    const link = this.findNamedLink(this.__links, 'platformTypes');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "platformTypes" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    return this.executeRequest(options, AWSDKPlatformTypeResponse)
      .then((platformTypeList) => {
        this.__logger.debug(currentFunction, 'Got response', platformTypeList);
        return platformTypeList.platformTypes;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Retrieves an array of {@link model.AWSDKTrackerTemplate|AWSDKTrackerTemplate}
   * @param {String} searchTerm the string search term to be used in the search
   * @returns {Promise<model.AWSDKTrackerTemplate[]|error.AWSDKError>} a Promise that resolves to an array of {@link model.AWSDKTrackerTemplate|AWSDKTrackerTemplate} or
   * rejected with an instance of {@link error.AWSDKError|AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.internalError}</td><td>Error if the AWSDK found an issue with itself</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument}</td><td>Error if a bad argument is passed into the method</td></tr>
   * </table>
   * @since 1.4.0
   */
  searchTrackerTemplates(searchTerm) {
    const currentFunction = 'searchTrackerTemplates';
    this.__logger.debug(currentFunction, 'Started', searchTerm);
    const link = this.findNamedLink(this.__links, 'trackerTemplateSearch');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "trackerTemplateSearch" link entry');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (searchTerm && !typeof searchTerm === 'string') {
      const error = AWSDKError.AWSDKIllegalArgument('searchTerm param must be of type string when included');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getSdkAuth();
    if (searchTerm) {
      options.form.set('tracker', searchTerm);
    }
    return this.executeRequest(options, AWSDKTrackerTemplatesList)
      .then((trackerTemplatesResponse) => {
        this.__logger.debug(currentFunction, 'Got response', trackerTemplatesResponse);
        return trackerTemplatesResponse.trackerTemplates;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  setLinks(links) {
    this.__links = links;
  }

  setConfig(config) {
    this.__config = config;
  }
}

export default InitializationService;
