/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKRecoverEmailStatus.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKRecoverEmailStatus = {
  /**
   * Email was successfully recovered.
   */
  EMAIL_RECOVERED: 'EMAIL_RECOVERED',
  /**
   * Username was emailed to the user.
   */
  USERNAME_EMAILED: 'USERNAME_EMAILED',
};
Object.freeze(AWSDKRecoverEmailStatus);
export default AWSDKRecoverEmailStatus;
