/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from './awsdk_dataobject';
import AWSDKEntityId from '../internal/model/awsdk_entity_id';
import GenericParser from '../internal/parser/generic_parser';
/**
 * AWSDKEntity class represents the base class for all objects with ids and links
 * @property {model.AWSDKEntityId} id The id of the object
 * @extends model.AWSDKDataObject
 */
class AWSDKEntity extends AWSDKDataObject {
  get id() {
    return GenericParser.parseObject(this.__data.id, AWSDKEntityId);
  }

  get links() {
    return this.__data.links;
  }
}

export default AWSDKEntity;
