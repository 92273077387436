/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKResponse from './awsdk_response';
import GenericParser from '../../parser/generic_parser';

class AWSDKValidateSdkApiKeyResponse extends AWSDKResponse {
  get valid() {
    return this.__data.sdkAuthentication ? GenericParser.parseBoolean(this.__data.sdkAuthentication.valid) : false;
  }

  get links() {
    return this.__data.links;
  }

  /**
   * The version of the Amwell platform that we are initializing against
   * @type {String}
   * @private
   */
  get amWellPlatformVersion() {
    return this.__data.amWellPlatformVersion;
  }
}

export default AWSDKValidateSdkApiKeyResponse;
