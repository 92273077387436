/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKPharmacy from '../../../model/pharmacy/awsdk_pharmacy';
import AWSDKResponse from './awsdk_response';
import GenericParser from '../../parser/generic_parser';

/**
 * Simple wrapper around a collection of {@link model.AWSDKPharmacy}s
 * @property {model.AWSDKPharmacy[]} pharmacies the {@link model.AWSDKPharmacy}s
 * @extends model.AWSDKResponse
 */
class AWSDKPharmaciesResponse extends AWSDKResponse {
  get pharmacies() {
    return this.__data.pharmacies.map(item => GenericParser.parseObject(item, AWSDKPharmacy));
  }
}

export default AWSDKPharmaciesResponse;
