/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import GenericParser from '../../internal/parser/generic_parser';

/**
 * The AWSDKConsumerOverrideDetails class encapsulates any consumer specific details to be used with cart-based visits.
 * @property {String} mrnId the consumer's medical record number id.
 * @property {String} firstName the consumer's first name
 * @property {String} lastName the consumer's last name
 * @property {String} middleNameOrInitial the consumer's middle name or initial
 * @property {model.AWSDKGender} gender the consumer's gender
 * @property {String} email the consumer's email
 * @property {Date} dob the consumer's date of birth
 * @property {String} address1 the consumer's first address line
 * @property {String} address2 the consumer's second address line
 * @property {String} city the consumer's city
 * @property {model.AWSDKState} state the consumer's state
 * @property {String} zipCode the consumer's zip code
 * @property {String} phone the consumer's phone number
 * @property {String[]} brandings an Array of Strings representing a consumer's branding attributes.
 * @since 1.3.1
 */
class AWSDKConsumerOverrideDetails {
  set mrnId(mrnId) {
    this.__mrnId = mrnId;
  }

  get mrnId() {
    return this.__mrnId;
  }

  set firstName(firstName) {
    this.__firstName = firstName;
  }

  get firstName() {
    return this.__firstName;
  }

  set lastName(lastName) {
    this.__lastName = lastName;
  }

  get lastName() {
    return this.__lastName;
  }

  set middleNameOrInitial(middleNameOrInitial) {
    this.__middleNameOrInitial = middleNameOrInitial;
  }

  get middleNameOrInitial() {
    return this.__middleNameOrInitial;
  }

  set gender(gender) {
    this.__gender = gender;
  }

  get gender() {
    return this.__gender;
  }

  set email(email) {
    this.__email = email;
  }

  get email() {
    return this.__email;
  }

  set dob(dob) {
    this.__dob = dob;
  }

  get dob() {
    return this.__dob;
  }

  set address1(address1) {
    this.__address1 = address1;
  }

  get address1() {
    return this.__address1;
  }

  set address2(address2) {
    this.__address2 = address2;
  }

  get address2() {
    return this.__address2;
  }

  set city(city) {
    this.__city = city;
  }

  get city() {
    return this.__city;
  }

  set state(state) {
    this.__state = state;
  }

  get state() {
    return this.__state;
  }

  set zipCode(zipCode) {
    this.__zipCode = zipCode;
  }

  get zipCode() {
    return this.__zipCode;
  }

  set phone(phone) {
    this.__phone = phone;
  }

  get phone() {
    return this.__phone;
  }

  /**
   * @since 1.5.0
   */
  get brandings() {
    return this.__brandings;
  }

  /**
   * @since 1.5.0
   */
  set brandings(brandingArr) {
    this.__brandings = brandingArr;
  }

  /**
   * A string representing the gender identity key
   * @type {string}
   */
  get genderIdentityKey() {
    return this.__genderIdentityKey;
  }

  /**
   * @since 2.5.0
   */
  set genderIdentityKey(key) {
    this.__genderIdentityKey = key;
  }

  /**
   * @private
   */
  __getAsRequestBody(systemConfiguration) {
    let dob = null;
    let genderIdentityKey = null;
    if (this.__dob instanceof Date) {
      dob = this.__dob.toISOString().split('T')[0];
    }
    if (systemConfiguration && systemConfiguration.genderSupportEnabled) {
      genderIdentityKey = this.__genderIdentityKey;
    }

    return {
      mrnId: this.__mrnId,
      firstName: this.__firstName,
      lastName: this.__lastName,
      middleName: this.__middleNameOrInitial,
      gender: this.__gender && GenericParser.parseAndConvertEnumValue(this.__gender),
      email: this.__email,
      dob,
      genderIdentityKey,
      address1: this.__address1,
      address2: this.__address2,
      city: this.__city,
      state: this.__state && this.__state.code,
      zipCode: this.__zipCode,
      phone: this.__phone,
      brandings: this.__brandings,
    };
  }
}


export default AWSDKConsumerOverrideDetails;
