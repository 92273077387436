/*!
 * Amwell Consumer Web SDK
 *
 * Copyright © 2020 Amwell.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from Amwell.
 */

/**
 * Enum for AWSDKIntegrationContext.
 * @readonly
 * @enum {string}
 * @since 2.8.0
 */
const AWSDKIntegrationContext = {
  /**
   * The Orbita integration context
   */
  ORBITA: 'ORBITA',
};

Object.freeze(AWSDKIntegrationContext);
export default AWSDKIntegrationContext;
