/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKDeviceLiveStreamType to distinguish between different live streams.
 * @readonly
 * @enum {string}
 * @since 2.6.0
 */
const AWSDKDeviceLiveStreamType = {
  /**
     * Tyto Device
     */
  TYTO_LIVESTREAM: 'TYTO_LIVESTREAM',
};
Object.freeze(AWSDKDeviceLiveStreamType);
export default AWSDKDeviceLiveStreamType;
