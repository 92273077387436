/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKResponse from './awsdk_response';
import GenericParser from '../../parser/generic_parser';
import AWSDKProviderDetails from '../../../model/provider/awsdk_provider_details';
/**
 * Represents a response object wrapper to a single {@link model.AWSDKProviderDetails|AWSDKProviderDetails} object type
 * @property {@link model.AWSDKProviderDetails|AWSDKProviderDetails} provider the provider details for a given provider
 * @extends model.AWSDKResponse
 */
class AWSDKProviderDetailsResponse extends AWSDKResponse {
  get providerDetails() {
    return GenericParser.parseObject(this.__data.provider, AWSDKProviderDetails);
  }
}

export default AWSDKProviderDetailsResponse;
