/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from './awsdk_dataobject';


/**
 * This class represents the different topic types available for a secure message.
 *
 * @property {String} name the name of the topic type
 * @property {String} key the unique key of the topic type
 *
 * @since 1.1.0
 * @extends model.AWSDKDataObject
 */
class AWSDKTopicType extends AWSDKDataObject {
  get name() {
    return this.__data.name;
  }
  get key() {
    return this.__data.key;
  }
}

export default AWSDKTopicType;
