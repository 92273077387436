/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from './../awsdk_dataobject';
import GenericParser from '../../internal/parser/generic_parser';

/**
 * AWSDKPayerInfo class represents a payer information object encapsulating the information about a payer in a health plan.
 * @property {String} subscriberIdPattern the subscriber id pattern for this health plan.
 * @property {String} subscriberIdPatternErrorMessage the error message to show if the subscriber id does not match the pattern.
 * @property {Boolean} isSuppressCharge returns TRUE if the health plan is configured to suppress payment when a timeout occurs during an eligibility check.
 * @property {Boolean} showPrimarySubscriberQuestion returns TRUE if the primary subscriber question should be displayed.
 * @extends model.AWSDKDataObject
 */
class AWSDKPayerInfo extends AWSDKDataObject {
  get subscriberIdPattern() {
    return this.__data.subscriberIdPattern;
  }

  get subscriberIdPatternErrorMessage() {
    return this.__data.subscriberIdPatternErrorMessage;
  }

  get isSuppressCharge() {
    return GenericParser.parseBoolean(this.__data.suppressCharge);
  }

  /**
   * @since 1.5.0
   */
  get showPrimarySubscriberQuestion() {
    return GenericParser.parseBoolean(this.__data.showPrimarySubscriberQuestion);
  }
}

export default AWSDKPayerInfo;
