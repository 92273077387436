/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKDataObject from '../awsdk_dataobject';
import AWSDKRecoverEmailStatus from './awsdk_recover_email_status';
import GenericParser from '../../internal/parser/generic_parser';
/**
 * AWSDKRecoverEmail class has the status of the recover email request with the redacted email if the status is EMAIL_RECOVERED.
 * @property {model.AWSDKRecoverEmailStatus} status The status of the object
 * @property {String} email The value of the object
 * @extends model.AWSDKDataObject
 */
class AWSDKRecoverEmail extends AWSDKDataObject {
  get status() {
    return GenericParser.parseEnum(this.__data.status, AWSDKRecoverEmailStatus);
  }

  get email() {
    return this.__data.email;
  }
}

export default AWSDKRecoverEmail;
