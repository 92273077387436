/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKAgendaItemFollowUpType.
 * @readonly
 * @enum {string}
 * @since 1.2.1
 */
const AWSDKAgendaItemFollowUpType = {
  /**
   * Visit.
   */
  Visit: 'Visit',
  /**
   * Administered Intake.
   */
  AdministeredIntake: 'AdministeredIntake',
  /**
   * Tracker Reminder.
   */
  TrackerReminder: 'TrackerReminder',
  /**
   * Media.
   */
  Media: 'Media',
};
Object.freeze(AWSDKAgendaItemFollowUpType);
export default AWSDKAgendaItemFollowUpType;
