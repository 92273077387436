/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKPostVisitFollowUpItemType.
 * @readonly
 * @enum {string}
 * @since 1.2.1
 */
const AWSDKPostVisitFollowUpItemType = {
  /**
   * Appointment.
   */
  APPOINTMENT: 'APPOINTMENT',
  /**
   * Referral.
   */
  REFERRAL: 'REFERRAL',
  /**
   * Sick slip.
   */
  SICKSLIP: 'SICKSLIP',
};
Object.freeze(AWSDKPostVisitFollowUpItemType);
export default AWSDKPostVisitFollowUpItemType;
