/*!
 * Amwell Consumer Web SDK
 *
 * Copyright © 2020 Amwell.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from Amwell.
 */
import AWSDKEntity from '../awsdk_entity';
import GenericParser from '../../internal/parser/generic_parser';
import AWSDKIntegrationContext from './awsdk_integration_context';
import AWSDKIntegrationStatus from './awsdk_integration_status';


/**
 *
 * An integration between the Amwell telehealth platform and a third party context/platform
 *
 * @property {model.AWSDKIntegrationContext} context the context this integration is for
 * @property {String} contextIdentifier the identifier for the context
 * @property {model.AWSDKIntegrationStatus} status the status of the integration
 * @property {Object} config a key value map of configuration for this integration
 *
 * @since 2.8.0
 * @extends model.AWSDKEntity
 */
class AWSDKIntegration extends AWSDKEntity {
  get context() {
    return GenericParser.parseEnum(this.__data.context, AWSDKIntegrationContext);
  }
  get contextIdentifier() {
    return this.__data.contextIdentifier;
  }
  get status() {
    return GenericParser.parseEnum(this.__data.status, AWSDKIntegrationStatus);
  }
  get config() {
    return this.__data.config;
  }
}

export default AWSDKIntegration;
