/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKEntity from './../awsdk_entity';
import GenericParser from '../../internal/parser/generic_parser';
import Util from '../../internal/util/util';
import AWSDKPracticeInfo from './../practice/awsdk_practice_info';
import AWSDKGender from '../consumer/awsdk_gender';
import AWSDKProviderVisibility from './awsdk_provider_visibility';
import AWSDKProviderType from './awsdk_provider_type';
/**
 * AWSDKProvider class which represents a Provider object
 * @property {Number} waitingRoomCount the number of patients currently in this provider's waiting room.
 * @property {Number} rating the provider's rating.
 * @property {model.AWSDKProviderVisibility} visibility the provider's visibility.
 * @property {Boolean} hasImage boolean true if the provider has an image logo, false otherwise
 * @property {String} firstName the provider's first name
 * @property {String} lastName the provider's last name
 * @property {String} fullName the provider's full name
 * @property {model.AWSDKProviderType} specialty the provider's specialty
 * @property {String} sourceId the source id for this provider
 * @property {model.AWSDKPracticeInfo} practice the practice associated with this provider
 * @property {String} href the url to this provider's details
 * @property {String} middleInitial the Provider's middle initial
 * @property {Boolean} canPrescribe boolean true if the Provider can write prescriptions, false otherwise
 * @property {model.AWSDKGender} genderEnum the provider's {@link model.AWSDKGender|AWSDKGender}
 * @property {String} localizedGender the provider's localized gender
 * @property {String} logoUrl the url of this provider's logo image
 * @property {Boolean} isNTN boolean true if the provider is in the National Telehealth Network (NTN), false otherwise. @since 2.7.0
 * @property {Boolean} canAcceptSecureMessage true if the provider is able to accept secure messages
 * @extends model.AWSDKEntity
 */

class AWSDKProvider extends AWSDKEntity {
  get middleInitial() {
    return this.__data.middleInitial;
  }

  get canPrescribe() {
    return GenericParser.parseBoolean(this.__data.canPrescribe);
  }

  get genderEnum() {
    return GenericParser.parseEnum(this.__data.genderEnum, AWSDKGender);
  }

  get localizedGender() {
    return this.__data.localizedGenderDisplayName;
  }

  get waitingRoomCount() {
    return this.__data.waitingRoomCount;
  }

  get rating() {
    return this.__data.rating;
  }

  get visibility() {
    return GenericParser.parseEnum(this.__data.visibility, AWSDKProviderVisibility);
  }

  get sourceId() {
    return this.__data.sourceId;
  }

  get firstName() {
    return this.__data.firstName;
  }

  get lastName() {
    return this.__data.lastName;
  }

  get fullName() {
    return this.__data.fullName;
  }

  get specialty() {
    return GenericParser.parseObject(this.__data.specialty, AWSDKProviderType);
  }

  get hasImage() {
    return GenericParser.parseBoolean(this.__data.hasImage);
  }

  get practice() {
    return GenericParser.parseObject(this.__data.practice, AWSDKPracticeInfo);
  }

  get href() {
    return this.__data.href;
  }

  get logoUrl() {
    if (this.hasImage) {
      return Util.findNamedLinkUrl(this.links, 'logo');
    }
    return null;
  }

  get isNTN() {
    return GenericParser.parseBoolean(this.__data.isNTN);
  }

  /**
   * @since 3.5.0
   */
  get canAcceptSecureMessage() {
    return GenericParser.parseBoolean(this.__data.canAcceptSecureMessage);
  }
}

export default AWSDKProvider;
