/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKGender.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKGender = {
  /**
   * The FEMALE gender of a consumer
   */
  FEMALE: 'FEMALE',
  /**
   * The MALE gender of a consumer
   */
  MALE: 'MALE',
  /**
   * The UNKNOWN gender of a consumer
   */
  UNKNOWN: 'UNKNOWN',
};
Object.freeze(AWSDKGender);
export default AWSDKGender;
