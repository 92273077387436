/*
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import compareVersions from 'compare-versions';
import version from '../version';
import AWSDKAuthentication from '../model/authentication/awsdk_authentication';
import AWSDKError from '../error/awsdk_error';
import AWSDKConsumer from '../model/consumer/awsdk_consumer';
import Form from '../internal/form/form';
import ResponseParser from '../internal/parser/response_parser';
import Validator from '../internal/validator/validator';
import Util from '../internal/util/util';


class Service {
  constructor(props = {}) {
    this.__config = props.config ? props.config : [];
    this.__links = props.links ? props.links : [];
    this.__authKeys = props.authKeys ? props.authKeys : [];
    this.__logger = props.logger;
    this.__serverLogger = props.serverLogger;
  }

  generateOptions(method, url, useForm = true) {
    const snapshotVersionSuffix = version.sdkVersion.indexOf('-');
    let clientVersion = version.sdkVersion;
    if (snapshotVersionSuffix !== -1) {
      clientVersion = version.sdkVersion.substring(0, snapshotVersionSuffix);
    }
    const options = {
      method,
      url,
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        'X-Client-Version': clientVersion,
        'X-Internal-Build': this.__config.internalBuild ? this.__config.internalBuild : false,
        'X-Supported-OnlineCare-Versions': version.supportedOnlineCareVersion,
      },
    };

    if (this.__isCallerIdSupported()) {
      options.headers['X-Caller-Id'] = this.__config.uuid;
    }
    if (this.__config.locale) {
      options.headers['Accept-Language'] = this.__localeToAcceptLanguage(this.__config.locale);
    }

    if (useForm) {
      options.form = new Form();
      if (method !== 'GET' && method !== 'DELETE') {
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
      }
    }
    return options;
  }

  /**
   * Checks to see if the Amwell platform version supports the caller ID header
   *
   * @returns {boolean} returns true if the Amwell platform version supports the caller ID header
   * @private
   */
  __isCallerIdSupported() {
    return this.__isAmWellPlatformVersionNewerThan('4.6.2');
  }

  /**
   * Returns true if __config.amWellPlatformVersion is defined, and newer than the provided compareVersion
   *
   * @param {string} compareVersion The version number of the Amwell platform to compare against the actual version number.
   * @returns {boolean} returns true if actual the Amwell platform version number is newer than the passed in version number.
   * @private
   */
  __isAmWellPlatformVersionNewerThan(compareVersion) {
    if (this.__config.amWellPlatformVersion === undefined) {
      return false;
    }

    // the Amwell platform versions are sent with a leading letter "O"
    const trimmedAmWellPlatformVersion = this.__config.amWellPlatformVersion.replace(/^O+/, '');
    return compareVersions(trimmedAmWellPlatformVersion, compareVersion) > 0;
  }

  getSdkAuth() {
    const encodedUserPass = btoa(`${this.__config.sdkApiKey}:`);
    return `Basic ${encodedUserPass}`;
  }

  getUserAuth(consumer) {
    const authEntry = this.getUserAuthEntry(consumer);
    let auth = null;
    if (authEntry != null && authEntry.token) {
      auth = `Bearer ${authEntry.token}`;
    }
    return auth;
  }

  getUserAuthFrom(authentication) {
    return this.getUserAuth(authentication.consumer) || `Bearer ${authentication.authToken}`;
  }

  getUserAuthEntry(consumer) {
    let authEntry = null;
    if (consumer instanceof AWSDKConsumer) {
      const consumerId = this.__fetchAuthConsumerPersistentId(consumer);
      authEntry = this.findNamedLink(this.__authKeys, consumerId);
    }
    return authEntry;
  }

  addUserAuthEntry(authenticate) {
    if (authenticate instanceof AWSDKAuthentication) {
      this.updateUserAuthEntry(authenticate.consumer, authenticate.authToken);
    }
  }

  updateUserAuthEntry(consumer, token) {
    if (consumer instanceof AWSDKConsumer) {
      const consumerId = this.__fetchAuthConsumerPersistentId(consumer);
      const authEntry = this.findNamedLink(this.__authKeys, consumerId);
      if (authEntry != null) {
        authEntry.token = token;
      } else {
        this.__authKeys.push({ name: consumerId, token });
      }
    }
  }

  removeUserAuthEntry(consumer) {
    if (consumer instanceof AWSDKConsumer) {
      const consumerId = this.__fetchAuthConsumerPersistentId(consumer);
      const removeIndex = this.findNamedLinkIndex(this.__authKeys, consumerId);
      if (removeIndex >= 0) {
        this.__authKeys.splice(removeIndex, 1);
      }
    }
  }

  /**
   * @private
   * @returns {string} returns the string representing the persistentId of the consumer of it's legal guardian if a dependent
   */
  __fetchAuthConsumerPersistentId(consumer) {
    if (consumer.isDependent) {
      const parentId = this.__fetchParentId(consumer);
      if (parentId) {
        return parentId.persistentId;
      }
      return null;
    }
    return consumer.id.persistentId;
  }
  /**
   * @private
   * @returns {model.AWSDKEntityId} the id of this parent or empty object if none is found
   */
  __fetchParentId(consumer) {
    const mappedEntries = Array.isArray(this.__authKeys) && this.__authKeys.length > 0 ? this.__authKeys.map(item => item.name) : []; // prevent map from being called on each iteration of find
    return consumer.proxies.map(proxy => proxy.id)
      .find(item => mappedEntries.indexOf(item.persistentId) >= 0);
  }

  findNamedLink(links, linkName) {
    if (links === undefined || !Array.isArray(links) || links.length === 0) {
      return null;
    }
    return links.find(item => item.name === linkName);
  }

  findNamedLinkIndex(links, linkName) {
    if (links === undefined || !Array.isArray(links) || links.length === 0) {
      return -1;
    }
    return links.map(item => item.name).indexOf(linkName);
  }

  executeRequest(options, Klass) {
    const optHeaders = options.headers;
    const requestHeaders = new Headers(optHeaders);
    requestHeaders.set('Authorization', options.auth);
    const init = {
      method: options.method,
      mode: 'cors',
      headers: requestHeaders,
      credentials: 'omit',
    };
    let url = options.url;
    if (options.method !== 'GET' && options.method !== 'DELETE') {
      init.body = options.form ? options.form.toString() : options.body;
    } else if (options.form) {
      // for a 'GET' and a 'DELETE' append the form as parameters to the url
      const queryString = options.form.toString();
      if (queryString.length > 0) {
        url += '?';
        url += options.form.toString();
      }
    }
    return fetch(url, init)
      .then((response) => {
        this.__logger.debug('service.executeRequest', 'Parsing response');
        return ResponseParser.parse(response, Klass);
      })
      .catch((err) => {
        this.__logger.debug('service.executeRequest', 'error', err);
        if (err instanceof AWSDKError) {
          throw err;
        }
        throw new AWSDKError(err);
      });
  }

  __localeToAcceptLanguage(locale) {
    if (!locale) return null;
    const arr = locale.split('_');
    let acceptLanguage = '';
    if (arr) {
      acceptLanguage = `${arr[0]}-${arr[1]}`;
    } else {
      acceptLanguage = locale;
    }
    return acceptLanguage;
  }

  /**
   * @private
   */
  __buildPaginatedRequest(consumer, options, url) {
    const currentFunction = 'service.__buildPaginatedRequest';
    if (options.pageSize && (!Validator.isInt(options.pageSize) || options.pageSize < 1)) {
      const error = AWSDKError.AWSDKIllegalArgument('options.pageSize must be a positive integer');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    if (options.startDate && !Validator.isValidDate(options.startDate)) {
      const error = AWSDKError.AWSDKIllegalArgument('options.startDate must be a Date');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    if (options.endDate && !Validator.isValidDate(options.endDate)) {
      const error = AWSDKError.AWSDKIllegalArgument('options.endDate must be a Date');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    if (options.startDate && options.endDate && options.startDate > options.endDate) {
      const error = AWSDKError.AWSDKIllegalArgument('options.startDate must be before options.endDate');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }

    const request = this.generateOptions('POST', url, false);
    request.auth = this.getUserAuth(consumer);
    if (!request.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, error);
      return Promise.reject(error);
    }

    if (options.startDate) {
      options.startDate = Util.formatISODateTime(options.startDate);
    }
    if (options.endDate) {
      options.endDate = Util.formatISODateTime(options.endDate);
    }

    request.headers['Content-Type'] = 'application/json';
    request.body = JSON.stringify(options);
    return Promise.resolve(request);
  }
}

export default Service;
