/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import deprecated from 'awcoresdk/lib/util/deprecated';
import AWSDKAppointment from '../model/appointment/awsdk_appointment';
import AWSDKAppointmentResponse from '../internal/model/response/awsdk_appointment_response';
import AWSDKAppointmentReadinessResponse from '../internal/model/response/awsdk_appointment_readiness_response';
import AWSDKAppointmentsResponse from '../internal/model/response/awsdk_appointments_response';
import AWSDKConsumer from '../model/consumer/awsdk_consumer';
import AWSDKError from '../error/awsdk_error';
import AWSDKProvider from '../model/provider/awsdk_provider';
import AWSDKReminderOption from '../model/appointment/awsdk_reminder_option';
import AWSDKResponse from '../internal/model/response/awsdk_response';
import Service from './service';
import Validator from '../internal/validator/validator';
import Util from '../internal/util/util';
import AWSDKAppointmentUpdateRequest from '../model/appointment/awsdk_appointment_update_request';
import AWSDKAppointmentReadinessRequest from '../model/appointment/awsdk_appointment_readiness_request';
import AWSDKVisit from '../model/visit/awsdk_visit';
import AWSDKVisitReportDetail from '../model/visit/awsdk_visit_report_detail';


/**
 * This service handles everything related to an {@link model.AWSDKAppointment|AWSDKAppointment} and supporting infrastructure
 *
 * @since 1.1.0
 * @hideconstructor
 * @extends service.Service
 */
class AppointmentService extends Service {
  constructor(props) {
    super(props);
    this.__systemConfiguration = props.systemConfiguration;
  }

  /**
   * Retrieves a list of {@link model.AWSDKAppointment|AWSDKAppointment} objects pertaining to a {@link model.AWSDKConsumer|AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose appointments to retrieve
   * @returns {Promise<model.AWSDKAppointment[]|error.AWSDKError>} a Promise that resolves to a list of {@link model.AWSDKAppointment|AWSDKAppointment} or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  getAppointments(consumer) {
    const currentFunction = 'AppointmentService.getAppointments';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'appointments');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "appointments" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKAppointmentsResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.appointments;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * This method will get an updated Appointment
   * @param {model.AWSDKAppointment} appointment is the object that was created by a call to caretalks.
   * @returns {Promise<model.AWSDKAppointment|error.AWSDKError>} Returns a promise that will be resolved to an {@link model.AWSDKAppointment|AWSDKAppointment} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </table>
   * @since 1.1.0
   */
  getAppointment(appointment) {
    const currentFunction = 'AppointmentService.getAppointment';
    this.__logger.debug(currentFunction, 'Started', appointment);
    if (appointment == null) {
      const error = AWSDKError.AWSDKIllegalArgument('appointment argument is null');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(appointment instanceof AWSDKAppointment)) {
      const error = AWSDKError.AWSDKIllegalArgument('appointment argument is not of type AWSDKAppointment');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', appointment.href, false);
    options.auth = this.getUserAuth(appointment.consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKAppointmentResponse)
      .then((appointmentResponse) => {
        this.updateUserAuthEntry(appointment.consumer, appointmentResponse.authToken);
        this.__logger.debug(currentFunction, 'Got response', appointmentResponse);
        const updatedAppointment = appointmentResponse.appointment;
        return updatedAppointment;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'error', error);
        throw error;
      });
  }

  /**
   * Returns an array of {@link model.AWSDKReminderOption|AWSDKReminderOption} that can be used when scheduling an appointment
   * @since 1.1.0
   * @type {model.AWSDKReminderOption}
   */
  get reminderOptions() {
    return this.__systemConfiguration.reminderOptions;
  }

  __findReminderOption(value) {
    return this.reminderOptions.find(reminder => reminder.value === value);
  }

  /**
   * Schedule an appointment for the given {@link model.AWSDKConsumer} with the given {@link mode.AWSDKProvider} at the given appointment date.<br>
   * Retrieve the Provider's available dates via {@link ProviderService#findFutureAvailableProviders} or {@link ProviderService#getProviderAvailability}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} to schedule the appointment for
   * @param {Object} options additional options, some required, for scheduling the visit
   * @param {model.AWSDKProvider} options.provider the {@link model.AWSDKProvider|AWSDKProvider} to schedule the appointment with
   * @param {Date} options.appointmentDate requested Date for appointment
   * @param {String} [options.phoneNumber] contact phone number
   * @param {model.AWSDKReminder|String} [options.consumerReminder] a {@link model.AWSDKReminderOption|AWSDKReminderOption} or valid value.
   * @param {model.AWSDKReminder|String} [options.providerReminder] a {@link model.AWSDKReminderOption|AWSDKReminderOption} or valid value.
   * @param {model.AWSDKVisit|model.AWSDKVisitReportDetail} [options.reconnectFromVisit] the {@link model.AWSDKVisit|AWSDKVisit} or {@link model.AWSDKVisitReportDetail|AWSDKVisitReportDetail} to use for reconnecting
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotFound|AWSDKErrorCode.providerNotFound}</td><td>The American Well telehealth platform cannot find the provider</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.dependentNotFound|AWSDKErrorCode.dependentNotFound}</td><td>The American Well telehealth platform cannot find the dependent</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.invalidReminderOption|AWSDKErrorCode.invalidReminderOption}</td><td>The consumer or provider reminder does not match an allowable reminder option</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.invalidDate|AWSDKErrorCode.invalidDate}</td><td>The appointment date is invalid</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.invalidDateFormat|AWSDKErrorCode.invalidDateFormat}</td><td>The appointment date is invalid</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.invalidEnum|AWSDKErrorCode.invalidEnum}</td><td>The consumer or provider reminder would be in the past</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.invalidEnumFormat|AWSDKErrorCode.invalidEnumFormat}</td><td>The consumer or provider reminder is not a valid value</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.scheduleAppointmentFailed|AWSDKErrorCode.scheduleAppointmentFailed}</td><td>The American Well telehealth platform failed to schedule the appointment</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>The provider phone number is in an invalid format</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitInvalid|AWSDKErrorCode.visitInvalid}</td><td>The visit used for reconnect has an invalid disposition</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.providerNotLicensedForConsumerLocation|AWSDKErrorCode.providerNotLicensedForConsumerLocation}</td><td>The provider is not licensed for the consumer's current location</td></tr>
   * </table>
   * <p><br>Supported Visit Dispositions:</br>
   * <ul>
   * <li>{@link model.AWSDKDisposition.ConsumerDisconnected|ConsumerDisconnected}</li>
   * <li>{@link model.AWSDKDisposition.ConsumerCanceled|ConsumerCanceled}</li>
   * <li>{@link model.AWSDKDisposition.Deleted|Deleted}</li>
   * <li>{@link model.AWSDKDisposition.ProviderResponseTimeout|ProviderResponseTimeout}</li>
   * <li>{@link model.AWSDKDisposition.Bailed|Bailed}</li>
   * <li>{@link model.AWSDKDisposition.ProviderDisconnected|ProviderDisconnected}</li>
   * <li>{@link model.AWSDKDisposition.ProviderCanceled|ProviderCanceled}</li>
   * <li>{@link model.AWSDKDisposition.Expired|Expired}</li>
   * </ul>
   * </p>
   * @since 2.3.0
   */
  schedule(consumer, options) {
    const currentFunction = 'VisitService.schedule';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer must be an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (options == null) {
      const error = AWSDKError.AWSDKIllegalArgument('options must be an object literal');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const { provider, appointmentDate, phoneNumber, consumerReminder, providerReminder, reconnectFromVisit } = options;
    if (!(provider instanceof AWSDKProvider)) {
      const error = AWSDKError.AWSDKIllegalArgument('provider argument must be an instance of AWSDKProvider');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (!(appointmentDate instanceof Date)) {
      const error = AWSDKError.AWSDKIllegalArgument('appointmentDate must be an instance of Date');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (Validator.isValidString(phoneNumber) && !Validator.isPhoneNumberValid(phoneNumber)) {
      const error = AWSDKError.AWSDKValidationError(phoneNumber);
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    if (reconnectFromVisit != null && (!(reconnectFromVisit instanceof AWSDKVisit || reconnectFromVisit instanceof AWSDKVisitReportDetail))) {
      const error = AWSDKError.AWSDKIllegalArgument('reconnectFromVisit argument must be an instance of AWSDKVisit or AWSDKVisitReportDetail');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const consumerReminderOption = this.__resolveReminderOption(consumerReminder);
    if (consumerReminder != null && consumerReminderOption == null) {
      const error = AWSDKError.AWSDKInvalidReminderOption('consumerReminder is not a valid reminderOption');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const providerReminderOption = this.__resolveReminderOption(providerReminder, 'providerReminder', currentFunction);
    if (providerReminder != null && providerReminderOption == null) {
      const error = AWSDKError.AWSDKInvalidReminderOption('providerReminder is not a valid reminderOption');
      this.__logger.error(currentFunction, 'error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'scheduleAppointment');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "scheduleAppointment" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const opts = this.generateOptions('POST', link.url, true);
    opts.auth = this.getUserAuth(consumer);
    if (!opts.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (consumer.isDependent) {
      opts.form.set('memberId', this.__fetchParentId(consumer).encryptedId);
      opts.form.set('dependentId', consumer.id.encryptedId);
    } else {
      opts.form.set('memberId', consumer.id.encryptedId);
    }
    opts.form.set('providerId', provider.id.encryptedId);
    opts.form.set('appointmentDate', Util.formatISODateTime(appointmentDate));
    if (phoneNumber != null) {
      opts.form.set('phoneNumber', phoneNumber);
    }
    if (consumerReminderOption != null) {
      opts.form.set('memberReminder', consumerReminderOption.value);
    }
    if (providerReminderOption != null) {
      opts.form.set('providerReminder', providerReminderOption.value);
    }
    if (reconnectFromVisit != null && reconnectFromVisit instanceof AWSDKVisit) {
      opts.form.set('reconnectToEngagementId', reconnectFromVisit.id.encryptedId);
    } else if (reconnectFromVisit != null && reconnectFromVisit instanceof AWSDKVisitReportDetail) {
      opts.form.set('reconnectToEngagementId', reconnectFromVisit.__getEngagementId().encryptedId);
    }
    opts.headers.Accept = 'application/vnd.amwell-v3+json, application/json';
    return this.executeRequest(opts, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        this.__logger.info(currentFunction, 'Completed');
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * @private
   */
  __resolveReminderOption(reminderOption) {
    if (reminderOption instanceof AWSDKReminderOption) {
      return reminderOption;
    }
    return this.__findReminderOption(reminderOption);
  }

  /**
   * Retrieves an {@link model.AWSDKAppointmentReadiness|AWSDKAppointmentReadiness} object pertaining to a {@link model.AWSDKConsumer|AWSDKConsumer}
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose appointment readiness to retrieve
   * @returns {Promise<model.AWSDKAppointmentReadiness|error.AWSDKError>} a Promise that resolves to an {@link model.AWSDKAppointmentReadiness|AWSDKAppointmentReadiness} or is rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  getAppointmentReadiness(consumer) {
    const currentFunction = 'AppointmentService.getAppointmentReadiness';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'appointmentReadiness');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "appointmentReadiness" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKAppointmentReadinessResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.appointmentReadiness;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
  * Finds an {@link model.AWSDKAppointment|AWSDKAppointment} object for a given visit id
  * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose appointment to retrieve
  * @param {String} visitId the visit id
  * @returns {Promise<model.AWSDKAppointment|error.AWSDKError>} a Promise that resolves to an {@link model.AWSDKAppointment|AWSDKAppointment} or is rejected with a {@link error.AWSDKError|AWSDKError}
  * <p><br>Potential Error Codes<br>
  * <table summary="ErrorCodes" border="1">
  * <thead>
  * <tr><th>Error Code</th><th>reason</th></tr>
  * </thead>
  * <tbody>
  * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
  * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
  * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
  * <tr><td>{@link error.AWSDKErrorCode.appointmentNotFound|AWSDKErrorCode.appointmentNotFound}</td><td>The AWSDK could not find the appointment.</td></tr>
  * </tbody>
  * </table>
  * @since 1.1.0
  */
  findAppointment(consumer, visitId) {
    const currentFunction = 'AppointmentService.findAppointment';
    this.__logger.debug(currentFunction, 'Started', consumer, visitId);
    if (!Validator.isValidString(visitId)) {
      const error = AWSDKError.AWSDKIllegalArgument('visitId argument is not a valid string');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.__findAppointment(consumer, visitId, null);
  }

  /**
   * Finds an {@link model.AWSDKAppointment|AWSDKAppointment} object for a given source id
   * @param {model.AWSDKConsumer} consumer the {@link model.AWSDKConsumer|AWSDKConsumer} whose appointment to retrieve
   * @param {String} sourceId the source id
   * @returns {Promise<model.AWSDKAppointment|error.AWSDKError>} a Promise that resolves to an {@link model.AWSDKAppointment|AWSDKAppointment} or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.appointmentNotFound|AWSDKErrorCode.appointmentNotFound}</td><td>The AWSDK could not find the appointment.</td></tr>
   * </tbody>
   * </table>
   * @since 1.2.0
   */
  findAppointmentBySourceId(consumer, sourceId) {
    const currentFunction = 'AppointmentService.findAppointmentBySourceId';
    this.__logger.debug(currentFunction, 'Started', consumer, sourceId);
    if (!Validator.isValidString(sourceId)) {
      const error = AWSDKError.AWSDKIllegalArgument('sourceId argument is not a valid string');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.__findAppointment(consumer, null, sourceId);
  }

  __findAppointment(consumer, visitId, sourceId) {
    const currentFunction = 'AppointmentService.__findAppointment';
    this.__logger.debug(currentFunction, 'Started', consumer, visitId, sourceId);
    if (consumer == null) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'appointmentDeepLink');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "appointmentDeepLink" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('GET', link.url);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    if (visitId) {
      options.form.set('visitId', visitId);
    } else if (sourceId) {
      options.form.set('visitSourceId', sourceId);
    }

    return this.executeRequest(options, AWSDKAppointmentResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.appointment;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Cancels a scheduled {@link model.AWSDKAppointment|AWSDKAppointment}
   * @param {model.AWSDKAppointment} appointment the {@link model.AWSDKAppointment|AWSDKAppointment} to cancel
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.visitNotFound|AWSDKErrorCode.visitNotFound}</td><td>The AWSDK could not find the visit.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.responseError|AWSDKErrorCode.responseError}</td><td>A bad request was made to the server.</td></tr>
   * </tbody>
   * </table>
   * @since 1.1.0
   */
  cancelAppointment(appointment) {
    const currentFunction = 'AppointmentService.cancelAppointment';
    this.__logger.debug(currentFunction, 'Started', appointment);
    if (appointment == null) {
      const error = AWSDKError.AWSDKIllegalArgument('appointment argument is null');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(appointment instanceof AWSDKAppointment)) {
      const error = AWSDKError.AWSDKIllegalArgument('appointment argument is not of type AWSDKAppointment');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(appointment.links, 'cancelAppt');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('appointment does not have a valid "cancelAppt" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('POST', link.url);
    options.auth = this.getUserAuth(appointment.consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(appointment.consumer, response.authToken);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Get an instance of {@link model.AWSDKAppointmentUpdateRequest|AWSDKAppointmentUpdateRequest} to use when updating an existing appointment. <br>
   * @param {model.AWSDKAppointment} [appointment the appointment to be updated
   * @returns {model.AWSDKAppointmentUpdateRequest} returns an instance of a {@link model.AWSDKAppointmentUpdateRequest|AWSDKAppointmentUpdateRequest} object
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not valid or is not of the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 1.4.0
   */
  getNewAppointmentUpdateRequest(appointment) {
    const currentFunction = 'AppointmentService.getNewAppointmentUpdateRequest';
    if (!(appointment instanceof AWSDKAppointment)) {
      const error = AWSDKError.AWSDKIllegalArgument('appointment argument is not of type AWSDKAppointment', 'appointment');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    if (!(appointment.consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKInternalError('appointment.consumer is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      throw error;
    }
    return new AWSDKAppointmentUpdateRequest(appointment);
  }

  /**
   * Updates an appointment given an {@link model.AWSDKAppointmentUpdateRequest|AWSDKAppointmentUpdateRequest}.<br>
   * @param {model.AWSDKAppointmentUpdateRequest} appointmentUpdateRequest the {@link model.AWSDKAppointmentUpdateRequest|AWSDKAppointmentUpdateRequest} containing the update request
   * @returns {Promise<boolean|error.AWSDKError>} a promise that will resolve to a boolean indicating success, or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>The provided phone number is in an invalid format</td></tr>
   * </table>
   * @since 1.4.0
   */
  updateAppointment(appointmentUpdateRequest) {
    const currentFunction = 'AppointmentService.updateAppointment';
    this.__logger.debug(currentFunction, 'Started', appointmentUpdateRequest);
    if (!(appointmentUpdateRequest instanceof AWSDKAppointmentUpdateRequest)) {
      const error = AWSDKError.AWSDKIllegalArgument('appointmentUpdateRequest argument is not of type AWSDKAppointmentUpdateRequest', 'appointmentUpdateRequest');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(appointmentUpdateRequest.__appointment instanceof AWSDKAppointment)) {
      const error = AWSDKError.AWSDKInternalError('appointment is not of type AWSDKAppointment', 'appointment');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const consumer = appointmentUpdateRequest.__appointment.consumer;
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is not of type AWSDKConsumer', 'consumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (Validator.isValidString(appointmentUpdateRequest.initiatorOverridePhoneNumber) && !Validator.isPhoneNumberValid(appointmentUpdateRequest.initiatorOverridePhoneNumber)) {
      const error = AWSDKError.AWSDKValidationError(appointmentUpdateRequest.initiatorOverridePhoneNumber);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = appointmentUpdateRequest.__appointment.href;
    if (!Validator.isValidString(link)) {
      const error = AWSDKError.AWSDKInternalError('The appointmentUpdateRequest\'s appointment does not contain a valid href property');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link, false);
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.headers['Content-Type'] = 'application/json';
    options.headers.Accept = 'application/vnd.amwell-v11.2+json, application/json';
    options.body = appointmentUpdateRequest.getAsJSONString();
    return this.executeRequest(options, AWSDKResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(appointmentUpdateRequest.consumer, response.authToken);
        return true;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }
  /**
   * This method returns an instance of the {@link model.AWSDKAppointmentReadinessRequest|AWSDKAppointmentReadinessRequest}
   * @returns {model.AWSDKAppointmentReadinessRequest} an instance of the appointment readiness request object
   */
  getNewAppointmentReadinessRequest() {
    return new AWSDKAppointmentReadinessRequest();
  }

  /**
   * This method updates the appointment readiness for a given consumer
   * @param {model.AWSDKConsumer} consumer the consumer to update appointment readiness for
   * @param {model.AWSDKAppointmentReadinessRequest} appointmentReadinessRequest the appointment readiness request
   * @returns {Promise<model.AWSDKAppointmentReadiness|error.AWSDKError>} returns a promise that will be resolved to a {@link model.AWSDKAppointmentReadiness|AWSDKAppointmentReadiness} <br>
   * or rejected with an {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.validationErrors|AWSDKErrorCode.validationErrors}</td><td>If any of the appointmentReadinessRequest values are invalid. The errors property will return the array of validation errors.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>The consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>If appointmentReadinessRequest is not an instance of AWSDKAppointmentReadinessRequest</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   */
  updateReadiness(consumer, appointmentReadinessRequest) {
    const currentFunction = 'AppointmentService.updateReadiness';
    this.__logger.debug(currentFunction, 'Started', appointmentReadinessRequest);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument must be of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!(appointmentReadinessRequest instanceof AWSDKAppointmentReadinessRequest)) {
      const error = AWSDKError.AWSDKIllegalArgument('appointmentReadinessRequest argument must be of type AWSDKAppointmentReadinessRequest');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const errors = this.validateAppointmentReadinessRequest(appointmentReadinessRequest);
    if (errors.length > 0) {
      const error = AWSDKError.AWSDKValidationErrors(errors);
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'appointmentReadiness');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "appointmentReadiness" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const options = this.generateOptions('PUT', link.url, false);
    options.headers['Content-Type'] = 'application/json';
    options.auth = this.getUserAuth(consumer);
    if (!options.auth) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    options.headers.Accept = 'application/vnd.amwell-v11.2+json';
    options.body = appointmentReadinessRequest.__getAsRequestBody();
    return this.executeRequest(options, AWSDKAppointmentReadinessResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.appointmentReadiness;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        throw error;
      });
  }

  /**
   * Call this method directly to validate the fields of an {@link model.AWSDKAppointmentReadinessRequest|AWSDKAppointmentReadinessRequest} before calling
   * {@see service.AppointmentService#updateReadiness|updateReadiness}. Please note that this method will be called whenever {@see service.AppointmentService#updateReadiness|updateReadiness}
   * is called
   * @returns {error.AWSDKError[]} an array of {@link error.AWSDKError|AWSDKError} that details any validation errors.
   * <br>Potential validation errors:<br>
   * <table summary="validation" border="1">
   * <tr>
   * <th>Field</th>
   * <th>Validation Reason</th>
   * <th>Notes</th>
   * </tr>
   * <tr>
   * <td>datePassedTechCheck</td>
   * <td>invalid value</td>
   * <td>Set to a valid date</td>
   * </tr>
   * <tr>
   * <td>datePassedTechCheck</td>
   * <td>date in future</td>
   * <td>Set date to valid value in the past</td>
   * </tr>
   * <tr>
   * <td>downloadSpeedMbps</td>
   * <td>invalid value</td>
   * <td>Set to non-negative decimal number</td>
   * </tr>
   * <tr>
   * <td>uploadSpeedMbps</td>
   * <td>out of range value</td>
   * <td>Set to non-negative decimal number</td>
   * </tr>
   * <tr>
   * <td>latencyMs</td>
   * <td>invalid value</td>
   * <td>Set to an integer</td>
   * </tr>
   * <tr>
   * <td>jitter</td>
   * <td>invalid value</td>
   * <td>Set to non-negative integer</td>
   * </tr>
   * <tr>
   * <td>microphoneDeviceName</td>
   * <td>invalid value</td>
   * <td>Set to non-empty string</td>
   * </tr>
   * <tr>
   * <td>speakerDeviceName</td>
   * <td>invalid value</td>
   * <td>Set to non-empty string</td>
   * </tr>
   * <tr>
   * <td>cameraDeviceName</td>
   * <td>invalid value</td>
   * <td>Set to non-empty string</td>
   * </tr>
   * <tr>
   * <td>sourceId</td>
   * <td>invalid value</td>
   * <td>Set to non-empty string</td>
   * </tr>
   * </table>
   * @since 1.4.0
   */
  validateAppointmentReadinessRequest(appointmentReadinessRequest) {
    this.__logger.info('appointmentService in validateAppointmentReadinessRequest()');
    this.__logger.debug('Found appointmentReadinessRequest ', appointmentReadinessRequest);
    const errors = [];
    if (!(appointmentReadinessRequest instanceof AWSDKAppointmentReadinessRequest)) {
      throw AWSDKError.AWSDKIllegalArgument('parameter "appointmentReadinessRequest" must be an instance of AWSDKAppointmentReadinessRequest');
    }
    this.__validateAppointmentReadinessRequest(appointmentReadinessRequest, errors);
    return errors;
  }

  /**
   * @private
   *
   */
  __validateAppointmentReadinessRequest(appointmentReadinessRequest, errors) {
    let fieldName;
    let reason;
    let recoverySuggestion;
    if (appointmentReadinessRequest.datePassedTechCheck) {
      if (!(appointmentReadinessRequest.datePassedTechCheck instanceof Date)) {
        fieldName = 'datePassedTechCheck';
        reason = 'invalid value';
        recoverySuggestion = 'Set to valid date';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
      } else if (Date.now() - appointmentReadinessRequest.datePassedTechCheck < 0) {
        fieldName = 'datePassedTechCheck';
        reason = 'date in future';
        recoverySuggestion = 'Set date to valid value in the past';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
      }
    }
    if (appointmentReadinessRequest.downloadSpeedMbps) {
      if (typeof appointmentReadinessRequest.downloadSpeedMbps !== 'number') {
        fieldName = 'downloadSpeedMbps';
        reason = 'invalid value';
        recoverySuggestion = 'Set to a valid number';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
      } else if (appointmentReadinessRequest.downloadSpeedMbps < 0) {
        fieldName = 'downloadSpeedMbps';
        reason = 'out of range value';
        recoverySuggestion = 'Set to a positive number';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
      }
    }
    if (appointmentReadinessRequest.uploadSpeedMbps) {
      if (typeof appointmentReadinessRequest.uploadSpeedMbps !== 'number') {
        fieldName = 'uploadSpeedMbps';
        reason = 'invalid value';
        recoverySuggestion = 'Set to a valid number';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
      } else if (appointmentReadinessRequest.uploadSpeedMbps < 0) {
        fieldName = 'uploadSpeedMbps';
        reason = 'out of range value';
        recoverySuggestion = 'Set to a positive number';
        errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
      }
    }
    if (appointmentReadinessRequest.latencyMs && typeof appointmentReadinessRequest.latencyMs !== 'number') {
      fieldName = 'latencyMs';
      reason = 'invalid value';
      recoverySuggestion = 'Set to a valid number';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
    }
    if (appointmentReadinessRequest.jitter && typeof appointmentReadinessRequest.jitter !== 'number') {
      fieldName = 'jitter';
      reason = 'invalid value';
      recoverySuggestion = 'Set to a valid number';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
    }
    if (appointmentReadinessRequest.microphoneDeviceName != null && !Validator.isValidString(appointmentReadinessRequest.microphoneDeviceName)) {
      fieldName = 'microphoneDeviceName';
      reason = 'invalid value';
      recoverySuggestion = 'Set to a valid string';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
    }
    if (appointmentReadinessRequest.speakerDeviceName != null && !Validator.isValidString(appointmentReadinessRequest.speakerDeviceName)) {
      fieldName = 'speakerDeviceName';
      reason = 'invalid value';
      recoverySuggestion = 'Set to a valid string';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
    }
    if (appointmentReadinessRequest.cameraDeviceName != null && !Validator.isValidString(appointmentReadinessRequest.cameraDeviceName)) {
      fieldName = 'cameraDeviceName';
      reason = 'invalid value';
      recoverySuggestion = 'Set to a valid string';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
    }
    if (appointmentReadinessRequest.sourceId != null && !Validator.isValidString(appointmentReadinessRequest.sourceId)) {
      fieldName = 'sourceId';
      reason = 'invalid value';
      recoverySuggestion = 'Set to a valid string';
      errors.push(AWSDKError.AWSDKFieldValidationError(AWSDKAppointmentReadinessRequest, fieldName, reason, recoverySuggestion));
    }
  }
}

export default AppointmentService;
