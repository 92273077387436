/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import GenericParser from '../../internal/parser/generic_parser';
import AWSDKPaginated from '../awsdk_paginated';
import AWSDKHealthDocumentRecord from './awsdk_health_document_record';

/**
 * A paginated wrapper around a collection of {@link model.AWSDKPaginatedHealthDocumentRecords}s
 * @property {model.AWSDKHealthDocumentRecord[]} healthDocumentRecords the list of health document records in this page
 * @extends model.AWSDKPaginated
 * @since 2.8.0
 */
class AWSDKPaginatedHealthDocumentRecords extends AWSDKPaginated {
  get healthDocumentRecords() {
    return (Array.isArray(this.__data.list) && this.__data.list.map(item => GenericParser.parseObject(item, AWSDKHealthDocumentRecord))) || [];
  }
}

export default AWSDKPaginatedHealthDocumentRecords;
