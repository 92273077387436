/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKVitalsUpdate from './awsdk_vitals_update';

/**
 * Represents the particular vitals measurements of a {@link model.AWSDKConsumer}
 * @property {Number} systolic the consumer's reported systolic value
 * @property {Number} diastolic the consumer's systolic value
 * @property {Number} temperature the consumer's temperature
 * @property {Number} weightMajor the consumer's weight in its major unit
 * @property {Number} weightMinor the consumer's weight in its minor unit
 * @property {Number} heightMajor the consumer's height in its major unit
 * @property {Number} pulse the consumer's pulse in bpm
 * @property {Number} bmi the consumer's calculated BMI in kg / m^2
 * @extends model.AWSDKVitalsUpdate
 *
 */
class AWSDKVitals extends AWSDKVitalsUpdate {
  /**
   * @since 4.2.0
   */
  get bmi() {
    return this.__data.bmi;
  }
}

export default AWSDKVitals;
