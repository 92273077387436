/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from '../awsdk_dataobject';
import GenericParser from '../../internal/parser/generic_parser';
import AWSDKTwoFactorRequiredAction from './awsdk_two_factor_required_action';
import AWSDKTwoFactorConfiguration from './awsdk_two_factor_configuration';

/**
 * AWSDKTwoFactorInfo class represents the two-factor authorization information.<br>
 * If two-factor setup is needed, calls to {@link AuthenticationService#setupTwoFactorAuthentication|AuthenticationService.setupTwoFactorAuthentication},
 * then {@link AuthenticationService#validateTwoFactorAuthenticationCode|AuthenticationService.validateTwoFactorAuthenticationCode} are required to be fully authenticated.<br>
 * If two-factor login is needed, calls to {@link AuthenticationService#sendTwoFactorAuthenticationCode|AuthenticationService.sendTwoFactorAuthenticationCode},
 * then {@link AuthenticationService#validateTwoFactorAuthenticationCode|AuthenticationService.validateTwoFactorAuthenticationCode} are required to be fully authenticated.<br>
 *
 * @property {model.AWSDKTwoFactorRequiredAction} requiredAction Describes the next required action for two-factor.
 * @property {model.AWSDKTwoFactorConfiguration} configuration The current requirement configuration for two-factor authentication.
 * @property {number} phoneNumberLastFourDigits the last 4 digits of the phone number that the verification code was sent to
 * @property {string} twoFactorTrustedDeviceToken the trusted device token to bypass two factor authentication. Can be requested using rememberThisDevice flag along with code verification
 * @extends model.AWSDKDataObject
 * @since 3.3.0
 */
class AWSDKTwoFactorInfo extends AWSDKDataObject {
  constructor(data = {}) {
    super(data);
  }

  get requiredAction() {
    return this.__data.requiredAction ? GenericParser.parseEnum(this.__data.requiredAction, AWSDKTwoFactorRequiredAction) : AWSDKTwoFactorRequiredAction.NONE;
  }

  get configuration() {
    return GenericParser.parseEnum(this.__data.configuration, AWSDKTwoFactorConfiguration);
  }

  get phoneNumberLastFourDigits() {
    return this.__data.twoFactorAuthPhoneLastFourDigits;
  }

  get twoFactorTrustedDeviceToken() {
    return this.__data.twoFactorTrustDeviceToken;
  }
  /**
   * @private
   */
  get links() {
    return this.__data.links;
  }

  /**
   * @private
   */
  set authToken(authToken) {
    this.__authToken = authToken;
  }

  /**
   * @private
   */
  get authToken() {
    return this.__authToken;
  }
}

export default AWSDKTwoFactorInfo;
