/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKDataObject from './awsdk_dataobject';

/**
 * The base class for a Page of results.
 * @property {integer} startIndex the starting index of this page
 * @property {integer} pageSize the size of this page of results
 * @property {Object} nextPageOptions the options object to use to fetch the next page of results
 * @property {Object} previousPageOptions the options object to use to fetch the previous page of results
 * @since 2.8.0
 */
class AWSDKPaginated extends AWSDKDataObject {
  get startIndex() {
    return this.__data.startIndex;
  }
  get pageSize() {
    return this.__data.limit;
  }

  get nextPageOptions() {
    return this.__data.nextPageRequest;
  }

  get previousPageOptions() {
    return this.__data.previousPageRequest;
  }
}

export default AWSDKPaginated;
