/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKDataObject from '../awsdk_dataobject';
import AWSDKProvider from './awsdk_provider';
import GenericParser from '../../internal/parser/generic_parser';

/**
 * AWSDKProviderAvailability class which represents a Provider with available time slot for appointments
 * @property {model.AWSDKProvider} provider The provider.
 * @property {Date[]} availableAppointmentTimeSlots The available appointment time slots.
 * @since 1.1.0
 * @extends model.AWSDKDataObject
 */
class AWSDKProviderAvailability extends AWSDKDataObject {
  get provider() {
    return GenericParser.parseObject(this.__data.providerSearchResult, AWSDKProvider);
  }
  get availableAppointmentTimeSlots() {
    return this.__data.availableAppointmentTimeSlots.map(item => GenericParser.parseDate(item));
  }
}

export default AWSDKProviderAvailability;
