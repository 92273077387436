/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from './../awsdk_dataobject';
import GenericParser from '../../internal/parser/generic_parser';

/**
 * The AWSDKHealthSummaryRecord class represents entries in the health summary
 * @property {String} code The code of the item of record
 * @property {String} description The description of the item of record
 * @property {Date} firstOccurrence The first occurrence of the record
 * @property {Date} lastOccurrence The last occurrence of the record
 * @property {Boolean} selfReported If the record was reported by the consumer
 * @extends model.AWSDKDataObject
 */
class AWSDKHealthSummaryRecord extends AWSDKDataObject {
  get selfReported() {
    return GenericParser.parseBoolean(this.__data.selfReported);
  }
  get code() {
    return this.__data.term ? this.__data.term.code : null;
  }
  get description() {
    return this.__data.term ? this.__data.term.description : null;
  }
  get firstOccurrence() {
    return GenericParser.parseDate(this.__data.firstOccurrence);
  }
  get lastOccurrence() {
    return GenericParser.parseDate(this.__data.lastOccurrence);
  }
}

export default AWSDKHealthSummaryRecord;
