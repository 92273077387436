/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from '../../../model/awsdk_dataobject';

class AWSDKResponse extends AWSDKDataObject {
  get authToken() {
    return this.__data.authToken;
  }
}

export default AWSDKResponse;
