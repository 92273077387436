/*!
 * Amwell Consumer Web SDK
 *
 * Copyright © 2020 Amwell.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from Amwell.
 */

/**
 * Enum for AWSDKIntegrationStatus.
 * @readonly
 * @enum {string}
 * @since 2.8.0
 */
const AWSDKIntegrationStatus = {
  /**
   * The integration is added but no action has been taken
   */
  QUEUED: 'QUEUED',
  /**
   * The integration is ready to be started
   */
  READY: 'READY',
  /**
   * Interaction with this integration is still in progress
   */
  INPROGRESS: 'INPROGRESS',
  /**
   * The interaction with this integration is completed
   */
  COMPLETED: 'COMPLETED',
  /**
   * The integration was bailed on by the consumer
   */
  BAILED: 'BAILED',
  /**
   * The integration was interrupted
   */
  INTERRUPTED: 'INTERRUPTED',
  /**
   * There was an error when communicating with the integration
   */
  ERROR: 'ERROR',
  /**
   * The integration is reset and re-queued
   */
  RE_QUEUED: 'RE_QUEUED',
};

Object.freeze(AWSDKIntegrationStatus);
export default AWSDKIntegrationStatus;
