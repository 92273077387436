/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKEntity from './../awsdk_entity';
import AWSDKHealthPlan from './awsdk_health_plan';
import AWSDKRelationshipToSubscriberCode from './awsdk_relationship_to_subscriber_code';
import GenericParser from '../../internal/parser/generic_parser';
import AWSDKEligibilityCheck from './awsdk_eligibility_check';
/**
 * AWSDKSubscription class represents the subscription object pertaining to a given health plan insurance.
 * @property {model.AWSDKHealthPlan} healthPlan the health plan.
 * @property {String} subscriberId the health insurance subscriber id.
 * @property {String} subscriberSuffix the health insurance subscriber suffix.
 * @property {Date} startDate the health insurance start date
 * @property {Date} endDate the health insurance end date
 * @property {model.AWSDKRelationshipToSubscriberCode} relationshipToSubscriberCode what is relationship with the primary subscriber
 * @property {String} primarySubscriberFirstName the primary subscriber's first name.
 * @property {String} primarySubscriberLastName  the primary subscriber's last name.
 * @property {Date} primarySubscriberDateOfBirth the primary subscriber's date of birth.
 * @extends model.AWSDKEntity
 */
class AWSDKSubscription extends AWSDKEntity {
  get healthPlan() {
    return GenericParser.parseObject(this.__data.healthPlan, AWSDKHealthPlan);
  }

  get subscriberId() {
    return this.__data.subscriberId;
  }

  get subscriberSuffix() {
    return this.__data.subscriberSuffix;
  }

  get startDate() {
    return GenericParser.parseDate(this.__data.startDate);
  }

  get endDate() {
    return GenericParser.parseDate(this.__data.endDate);
  }

  get relationshipToSubscriberCode() {
    return GenericParser.parseObject(this.__data.relationshipToSubscriberCode, AWSDKRelationshipToSubscriberCode);
  }

  get primarySubscriberFirstName() {
    return this.__data.primarySubscriberFirstName;
  }

  get primarySubscriberLastName() {
    return this.__data.primarySubscriberLastName;
  }

  get primarySubscriberDateOfBirth() {
    return GenericParser.parseDate(this.__data.primarySubscriberDateOfBirth);
  }

  get eligibilityCheck() {
    return GenericParser.parseObject(this.__data.eligibilityResponse, AWSDKEligibilityCheck);
  }

  set healthPlan(healthPlan) {
    this.__data.healthPlan = healthPlan;
  }

  set subscriberId(subscriberId) {
    this.__data.subscriberId = subscriberId;
  }

  set subscriberSuffix(subscriberSuffix) {
    this.__data.subscriberSuffix = subscriberSuffix;
  }

  set startDate(startDate) {
    this.__data.startDate = startDate;
  }

  set endDate(endDate) {
    this.__data.endDate = endDate;
  }

  set relationshipToSubscriberCode(relationshipToSubscriberCode) {
    this.__data.relationshipToSubscriberCode = relationshipToSubscriberCode;
  }

  set primarySubscriberFirstName(primarySubscriberFirstName) {
    this.__data.primarySubscriberFirstName = primarySubscriberFirstName;
  }

  set primarySubscriberLastName(primarySubscriberLastName) {
    this.__data.primarySubscriberLastName = primarySubscriberLastName;
  }

  set primarySubscriberDateOfBirth(primarySubscriberDateOfBirth) {
    this.__data.primarySubscriberDateOfBirth = primarySubscriberDateOfBirth;
  }
}

export default AWSDKSubscription;
