/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKErrorCode from './awsdk_error_code';

/**
 * This class represents a wrapper class for any Error encountered by the AWSDK library during interaction with the server or internal to the AWSDK. <br>
 * Its purpose is to provide a more digestible data type for error encapsulation and handling.
 * @property {error.AWSDKErrorCode} errorCode the {@link error.AWSDKErrorCode|AWSDKErrorCode} associated with this error object.
 * @property {String} message String message clarifying the error
 * @property {String} reason String explanation for the cause of the error
 * @property {String} recoverySuggestion String suggesting a possible recovery strategy, if applicable.
 * @property {Number} httpResponseCode Integer representing the http error code associated with the error
 * @property {String} fieldName the name of the field that caused the error being thrown, if any.
 * @property {error.AWSDKError[]} errors an array of errors found during validation.
 * @property {String} stackTrace a String representing the state of the stack at the time the error was thrown.
 */
class AWSDKError {
  constructor(error, message, reason, recoverySuggestion, httpResponseCode) {
    if (error instanceof Error) {
      this.__errorCode = AWSDKErrorCode.otherError;
      this.__error = error;
    } else if (AWSDKErrorCode.__codes.includes(error)) {
      this.__errorCode = error;
      this.__error = new Error(message);
    } else {
      this.__errorCode = AWSDKErrorCode.otherError;
      this.__error = new Error(message);
    }
    this.__reason = reason;
    this.__recoverySuggestion = recoverySuggestion;
    this.__httpResponseCode = httpResponseCode;
  }
  static AWSDKAuthenticationAccessDenied() {
    return new AWSDKError(AWSDKErrorCode.authenticationAccessDenied,
      'Access denied to the resource',
      '',
      'Retry authentication again with the correct credentials',
      401);
  }
  static AWSDKAuthenticationSessionExpired() {
    return new AWSDKError(AWSDKErrorCode.authenticationSessionExpired,
      '',
      '',
      'Re-authenticate the user',
      401);
  }
  static AWSDKCertificatePinningError() {
    return new AWSDKError(AWSDKErrorCode.certificatePinningError,
      'Certificate on request does not match the required certificate hash');
  }
  static AWSDKCreditCardDeclinedError() {
    return new AWSDKError(AWSDKErrorCode.creditCardDeclinedError,
      'Credit card used as payment method has been declined',
      '',
      '',
      400);
  }
  static AWSDKCreditCardInvalidCVV() {
    return new AWSDKError(AWSDKErrorCode.creditCardInvalidCVV,
      'Invalid CVV code for credit card used as payment method',
      '',
      '',
      400);
  }
  static AWSDKCreditCardInvalidZipCode() {
    return new AWSDKError(AWSDKErrorCode.creditCardInvalidZipCode,
      'Invalid zip code for credit card used as payment method',
      '',
      '',
      400);
  }
  static AWSDKCreditCardValidationError() {
    return new AWSDKError(AWSDKErrorCode.creditCardValidationError,
      'Credit card used as payment method validation has failed',
      '',
      '',
      400);
  }
  static AWSDKConsumerAlreadyInVisit() {
    return new AWSDKError(AWSDKErrorCode.consumerAlreadyInVisit,
      'Consumer already in a visit',
      'The consumer is already in a visit',
      '',
      409);
  }
  static AWSDKVisitNotFound() {
    return new AWSDKError(AWSDKErrorCode.visitNotFound,
      'Visit was not found',
      '',
      'Retry with the correct visit',
      404);
  }
  static AWSDKConsumerBusy() {
    return new AWSDKError(AWSDKErrorCode.consumerBusy,
      'The consumer is busy',
      '',
      '',
      409);
  }
  static AWSDKConsumerNotAuthenticated() {
    return new AWSDKError(AWSDKErrorCode.consumerNotAuthenticated,
      'Consumer was not authenticated',
      '',
      'consumer must be authenticated');
  }
  static AWSDKConsumerNotFound() {
    return new AWSDKError(AWSDKErrorCode.consumerNotFound,
      'Consumer was not found',
      '',
      'Retry with the correct consumer',
      404);
  }
  static AWSDKCostCalculationNotFinishedError() {
    return new AWSDKError(AWSDKErrorCode.costCalculationNotFinishedError,
      'Visit cost calculation has not finished',
      'Visit cost calculation has not finished',
      'Try again once cost calculation has completed');
  }
  static AWSDKDependentNotFound() {
    return new AWSDKError(AWSDKErrorCode.dependentNotFound,
      'Dependent was not found',
      '',
      'Retry with the correct dependent',
      404);
  }
  static AWSDKDependentOverage() {
    return new AWSDKError(AWSDKErrorCode.dependentOverage,
      'Dependent over the age limit',
      '',
      'Retry with a younger dependent',
      400);
  }
  static AWSDKDependentMatchFound() {
    return new AWSDKError(AWSDKErrorCode.dependentMatchFound,
      'Dependent already registered with American Well telehealth platform',
      '',
      'Retry with a different dependent',
      400);
  }
  static AWSDKDependentDobInFuture() {
    return new AWSDKError(AWSDKErrorCode.dependentDobInFuture,
      'Dependent\'s date of birth is in the future',
      '',
      'Retry with a date of birth not in the future',
      400);
  }
  static AWSDKFirstAvailableConfigurationNotFound() {
    return new AWSDKError(AWSDKErrorCode.firstAvailableConfigurationNotFound,
      'first available configuration was not found',
      '',
      'Retry with the correct first available configuration',
      404);
  }
  static AWSDKEmptyResponseError() {
    return new AWSDKError(AWSDKErrorCode.emptyResponseError,
      'No Response from server');
  }
  static AWSDKFeedbackNotSubmittedError() {
    return new AWSDKError(AWSDKErrorCode.feedbackNotSubmittedError,
      'Feedback was not able to be submitted',
      'Feedback was not able to be submitted',
      'Try again with a valid feedback or a visit that has been completed');
  }
  static AWSDKFieldValidationError(objectType, fieldName, reason, recoverySuggestion) {
    const error = new AWSDKError(AWSDKErrorCode.fieldValidationError,
      objectType ? `Field ${fieldName} in ${objectType} failed validation` : `Field ${fieldName} failed validation`,
      reason,
      recoverySuggestion);
    error.fieldName = fieldName;
    return error;
  }
  static AWSDKIllegalArgument(message, fieldName) {
    const error = new AWSDKError(AWSDKErrorCode.illegalArgument,
      message,
      'Coding issue',
      'Correct the code to pass the correct argument');
    error.fieldName = fieldName;
    return error;
  }
  static AWSDKInternalError(message) {
    return new AWSDKError(AWSDKErrorCode.internalError,
      message,
      'there is an issue within the sdk code or data',
      '');
  }
  static AWSDKInitializationError() {
    return new AWSDKError(AWSDKErrorCode.uninitialized,
      'AWSDK was not properly initialized',
      'Potential incorrect URL, SDKAuthKey, or network failure when initializing AWSDKService',
      'Retry AWSDKService initialization and ensure you receive a success');
  }
  static AWSDKInvalidCouponError(message) {
    return new AWSDKError(AWSDKErrorCode.invalidCouponError,
      'Coupon validation failed',
      `Coupon validation failed because ${message}`,
      'Try again with valid coupon code');
  }
  static AWSDKInvalidDate() {
    return new AWSDKError(AWSDKErrorCode.invalidDate,
      'Date validation failed',
      '',
      'Try again with a valid date');
  }
  static AWSDKInvalidDateFormat() {
    return new AWSDKError(AWSDKErrorCode.invalidDateFormat,
      'Date format validation failed',
      '',
      'Contact support');
  }
  static AWSDKInvalidEnum() {
    return new AWSDKError(AWSDKErrorCode.invalidEnum,
      'Enum validation failed',
      '',
      'Try again with valid Enum value');
  }
  static AWSDKInvalidEnumFormat() {
    return new AWSDKError(AWSDKErrorCode.invalidEnumFormat,
      'Enum format validation failed',
      '',
      'Contact support');
  }
  static AWSDKInvalidPassword() {
    return new AWSDKError(AWSDKErrorCode.invalidPassword,
      'Invalid Password',
      'Password does not meet telehealth platform complexity requirements',
      'Please re-enter your password');
  }
  static AWSDKInvalidReminderOption() {
    return new AWSDKError(AWSDKErrorCode.invalidReminderOption,
      'Reminder option validation failed',
      '',
      'Try again with a valid reminder option');
  }
  static AWSDKInvalidServiceKeyError() {
    return new AWSDKError(AWSDKErrorCode.invalidServiceKeyError,
      'Invalid service key',
      '',
      'Try again with valid Service key');
  }
  static AWSDKLocaleNotSupported() {
    return new AWSDKError(AWSDKErrorCode.localeNotSupported,
      'Locale is not supported by American Well telehealth platform',
      'Locale is not supported by American Well telehealth platform',
      'Try again with a supported locale');
  }
  static AWSDKNoPaymentInformationFound(errorMessage) {
    return new AWSDKError(AWSDKErrorCode.noPaymentInformationFound,
      'Request returned a httpStatus of 404 -- NOT_FOUND',
      errorMessage,
      'Update the payment information on file',
      404);
  }
  static AWSDKParsingError(errorMessage) {
    return new AWSDKError(AWSDKErrorCode.parsingError,
      'Trouble with parsing the json',
      `Error ${errorMessage} returned when parsing`);
  }
  static AWSDKPrivacyPolicyDisclaimerMissing() {
    return new AWSDKError(AWSDKErrorCode.privacyPolicyDisclaimerMissing,
      'Server is missing a consumer privacy policy disclaimer',
      '',
      'Update the server configuration to add a consumer privacy policy disclaimer');
  }
  static AWSDKProviderBusy() {
    return new AWSDKError(AWSDKErrorCode.providerBusy,
      'The provider is busy and no longer available',
      '',
      'Retry with the correct provider',
      409);
  }
  static AWSDKProviderNotAvailable(httpResponseCode) {
    return new AWSDKError(AWSDKErrorCode.providerNotAvailable,
      'Provider is not available',
      '',
      'Retry with the correct provider',
      httpResponseCode);
  }
  static AWSDKProviderNotFound() {
    return new AWSDKError(AWSDKErrorCode.providerNotFound,
      'Provider was not found',
      '',
      'Retry with the correct provider',
      404);
  }
  static AWSDKProviderNotLicensedForConsumerLocation() {
    return new AWSDKError(AWSDKErrorCode.providerNotLicensedForConsumerLocation,
      'Provider is not licensed for consumer location',
      '',
      'Retry with a provider that is licensed in the consumer location',
      409);
  }
  static AWSDKProviderVideoNotEnabled() {
    return new AWSDKError(AWSDKErrorCode.providerVideoNotEnabled,
      'Provider does not have enhanced video enabled',
      '',
      'Retry with a different provider',
      409);
  }
  static AWSDKRatingNotSubmittedError() {
    return new AWSDKError(AWSDKErrorCode.ratingNotSubmittedError,
      'Rating was not able to be submitted',
      'Rating was not able to be submitted',
      'Try again with a valid ratings or a visit that has been completed');
  }
  static AWSDKNpsRatingNotSubmittedError() {
    return new AWSDKError(AWSDKErrorCode.npsRatingNotSubmittedError,
      'NPS rating was not able to be submitted',
      'NPS rating was not able to be submitted',
      'Try again with a valid NPS rating or a visit that has been completed');
  }
  static AWSDKResponseError(errorMessage, olcError, statusCode) {
    return new AWSDKError(AWSDKErrorCode.responseError,
      errorMessage,
      `olcError ${olcError}`,
      '',
      statusCode);
  }
  static AWSDKScheduleAppointmentFailed() {
    return new AWSDKError(AWSDKErrorCode.scheduleAppointmentFailed,
      'unable to schedule appointment',
      '',
      '',
      409);
  }
  static AWSDKStartVisitFailed() {
    return new AWSDKError(AWSDKErrorCode.startVisitFailed,
      'unable to start the visit',
      '',
      '',
      409);
  }
  static AWSDKUpdateConsumerAuthKeyFailed(httpResponseCode) {
    return new AWSDKError(AWSDKErrorCode.updateConsumerAuthKeyFailed,
      'Attempt to update consumerAuthKey failed',
      '',
      '',
      httpResponseCode);
  }
  static AWSDKValidationConsumerUnderage() {
    return new AWSDKError(AWSDKErrorCode.validationConsumerUnderage,
      'Cannot register underage consumer',
      '',
      'Either correct the date of birth or register the consumer as a dependent',
      400);
  }
  static AWSDKValidationEmailInUse() {
    return new AWSDKError(AWSDKErrorCode.validationEmailInUse,
      'There\'s already a user in the American Well telehealth platform with the given email address',
      '',
      'Try again with a different email address');
  }
  static AWSDKValidationSourceIdInUse() {
    return new AWSDKError(AWSDKErrorCode.validationSourceIdInUse,
      'There\'s already a consumer in the American Well telehealth platform with the given source id',
      '',
      'Try again with a different source id');
  }
  static AWSDKValidationRequiredParameterMissing(parameterName = '') {
    const error = new AWSDKError(AWSDKErrorCode.validationRequiredParameterMissing,
      'Missing required parameter',
      `Parameter ${parameterName} is required but is empty`,
      'Try again with required parameter');
    error.fieldName = parameterName;
    return error;
  }
  static AWSDKValidationIncompleteAddress(objectName = '', objectValue = '') {
    const error = new AWSDKError(AWSDKErrorCode.validationIncompleteAddress,
      'The provided address is incomplete or malformed.',
      '',
      'Try again with a complete address.');
    error.fieldName = objectName;
    error.fieldValue = objectValue;
    return error;
  }
  static AWSDKValidationError(parameterName = '', parameterValue = '') {
    const error = new AWSDKError(AWSDKErrorCode.validationError,
      'Validation failed for parameter',
      `Parameter ${parameterName} failed validation`,
      'Try again with valid parameter');
    error.fieldName = parameterName;
    error.fieldValue = parameterValue;
    return error;
  }
  static AWSDKValidationErrors(validationErrors) {
    const error = new AWSDKError(AWSDKErrorCode.validationErrors,
      'Validation failed',
      'Multiple validation errors',
      'Try again after correcting all errors');
    error.errors = validationErrors;
    return error;
  }
  static AWSDKVisitConsumerCallbackInvalid() {
    return new AWSDKError(AWSDKErrorCode.visitConsumerCallbackInvalid,
      'Invalid consumer callback number on visit',
      'The visit does not have a valid consumer callback number',
      'Update the consumer callback number',
      409);
  }
  static AWSDKVisitExpired() {
    return new AWSDKError(AWSDKErrorCode.visitExpired,
      'The visit has expired',
      'The consumer was late for a scheduled visit',
      'Reschedule the visit',
      410);
  }
  static AWSDKVisitInvalid() {
    return new AWSDKError(AWSDKErrorCode.visitInvalid,
      'The visit is invalid',
      'The visit disposition is not valid',
      'Reschedule the visit',
      410);
  }
  static AWSDKVisitSummaryNotSentError() {
    return new AWSDKError(AWSDKErrorCode.visitSummaryNotSentError,
      'Visit summary was not able to be sent',
      'Visit summary was not able to be sent',
      'Try again with a valid email address or a visit that has been completed');
  }
  static AWSDKWaitingRoomAccessDenied() {
    return new AWSDKError(AWSDKErrorCode.waitingRoomAccessDenied,
      'Provider does not accept waiting room requests from consumer',
      '',
      '',
      409);
  }
  static AWSDKMedicationSearchTextTooShort(fieldName, minLen) {
    return new AWSDKError(AWSDKErrorCode.medicationSearchTextTooShort,
      `The field ${fieldName} does not meet length requirements`,
      `Field needs to be be at least ${minLen} characters long`,
      `Try again with a string longer than ${minLen} characters`);
  }
  static AWSDKConsumerAlreadyRegistered() {
    return new AWSDKError(AWSDKErrorCode.consumerAlreadyRegistered,
      'The consumer is already completely registered',
      '',
      '');
  }
  static AWSDKAttachmentSizeTooLarge(fieldName, maxLen) {
    return new AWSDKError(AWSDKErrorCode.attachmentSizeTooLarge,
      `The field ${fieldName} is too large`,
      `Field needs to be at most ${maxLen}kb in size`,
      `Try again with data smaller than ${maxLen}kb`);
  }
  static AWSDKUnsupportedMimeType(fieldName) {
    return new AWSDKError(AWSDKErrorCode.unsupportedMimeType,
      `The field ${fieldName} has a mime-type that is unsupported by the American Well telehealth platform`,
      'Field needs to have a mime-type that is supported.',
      'Please check SystemConfiguration for supported types and try again');
  }
  static AWSDKFailedVirusScan(fieldName) {
    return new AWSDKError(AWSDKErrorCode.failedVirusScan,
      `The field ${fieldName} has a virus detected by the American Well telehealth platform`,
      'Field needs to virus free in order to be attached.',
      'Please scan your file locally and try again');
  }
  static AWSDKFileExists(fieldName) {
    return new AWSDKError(AWSDKErrorCode.fileExists,
      `The field ${fieldName} exists already on the American Well telehealth platform`,
      'Field needs to different file to be attached.',
      'Please choose a different file and try again');
  }
  static AWSDKFileReadError(fieldName) {
    return new AWSDKError(AWSDKErrorCode.fileReadError,
      `The field ${fieldName} can not be read by the American Well telehealth platform`,
      'Field needs a different file to be attached. This one may be corrupted.',
      'Please choose a different file and try again');
  }
  static AWSDKHealthPlanIsFeedControlled() {
    return new AWSDKError(AWSDKErrorCode.healthPlanIsFeedControlled,
      'The consumer\'s health plan is feed controlled',
      '',
      '');
  }
  static AWSDKEligibilityCheckError(message) {
    return new AWSDKError(AWSDKErrorCode.eligibilityCheckError,
      `The eligibility check returned an error : ${message}`,
      '',
      '');
  }
  static AWSDKInaccurateSubscriberInfo() {
    return new AWSDKError(AWSDKErrorCode.inaccurateSubscriberInfo,
      'Inaccurate subscriber information was provided by the user and rejected by the eligibility check',
      '',
      '');
  }
  static AWSDKHealthPlanSubscriptionEnrollmentError(message) {
    return new AWSDKError(AWSDKErrorCode.healthPlanSubscriptionEnrollmentError,
      message,
      '',
      '');
  }
  static AWSDKNoProvidersAvailable() {
    return new AWSDKError(AWSDKErrorCode.noProvidersAvailable,
      'No providers were found during the First Available search',
      '',
      '');
  }
  static AWSDKPollingTimeout() {
    return new AWSDKError(AWSDKErrorCode.pollingTimeout,
      'Poll exceeded its allotted time',
      '',
      '');
  }
  static AWSDKIllegalUsage(descriptionOfIllegalUse, descriptionOfCorrectUse) {
    return new AWSDKError(AWSDKErrorCode.illegalUsage,
      'An API of the AWSDK was invoked improperly',
      descriptionOfIllegalUse,
      descriptionOfCorrectUse);
  }
  static AWSDKConsumerRecipientError() {
    return new AWSDKError(AWSDKErrorCode.consumerRecipientError,
      'The recipient of a secure message from a consumer cannot be a consumer',
      '',
      '');
  }
  static AWSDKDoesNotAcceptSecureMessages() {
    return new AWSDKError(AWSDKErrorCode.doesNotAcceptSecureMessages,
      'The recipient of this secure message does not accept secure messages',
      '',
      '');
  }
  static AWSDKEmailAddressNotFound() {
    return new AWSDKError(AWSDKErrorCode.emailAddressNotFound,
      'The email address associated with the request was not found',
      '',
      'Provide the correct email address',
      400);
  }
  /**
   * @since 3.6.2
   */
  static AWSDKValidationEmailInvalid() {
    return new AWSDKError(AWSDKErrorCode.emailInvalid,
      'Email address is invalid',
      '',
      'Provide a valid email address',
      400);
  }
  static AWSDKVersionIncompatible() {
    return new AWSDKError(AWSDKErrorCode.versionIncompatible,
      'The Telehealth platform is incompatible with this version of the sdk',
      '',
      'Either upgrade the Telehealth platform or use different Telehealth Platform that is compatible');
  }
  static AWSDKModifiedProtectedField(modifiedFields) {
    return new AWSDKError(AWSDKErrorCode.modifiedProtectedField,
      `The protected fields ${modifiedFields} were modified.`,
      '',
      'Try again without modifying the protected fields');
  }
  static AWSDKNoPreferredPharmacyFound() {
    return new AWSDKError(AWSDKErrorCode.noPreferredPharmacyFound,
      'No preferred pharmacy was found for the consumer',
      '',
      '');
  }
  static AWSDKNoShippingAddressFound() {
    return new AWSDKError(AWSDKErrorCode.noShippingAddressFound,
      'No shipping address was found for the consumer',
      '',
      '');
  }
  static AWSDKSpeedPassDisabled() {
    return new AWSDKError(AWSDKErrorCode.speedPassDisabled,
      'SpeedPass is not enabled on the system',
      '',
      '');
  }
  static AWSDKConsumerNotEligibleForSpeedPass() {
    return new AWSDKError(AWSDKErrorCode.consumerNotEligibleForSpeedPass,
      'Consumer is not eligible for speedPass',
      '',
      '');
  }
  static AWSDKInvalidParameter(message) {
    return new AWSDKError(AWSDKErrorCode.invalidParameter,
      message,
      'Parameter was invalid',
      'Provide a valid parameter',
      400);
  }
  /**
   * @since 1.4.0
   */
  static AWSDKInvalidTimeZone() {
    return new AWSDKError(AWSDKErrorCode.invalidTimeZone,
      'The timezone is invalid',
      'The timezone is not a valid canonical timezone (Examples: America/New_York, Etc/UTC)',
      'Provide a valid canonical timezone',
      400);
  }
  /**
   * @since 2.0.0
   */
  static AWSDKGuestEmailAlreadyInvited() {
    return new AWSDKError(AWSDKErrorCode.guestEmailAlreadyInvited,
      'The guest email has already been invited',
      '',
      'Provide another email address',
      400);
  }
  /**
   * @since 3.0.0
   */
  static AWSDKUnsupportedVideoPlatform() {
    return new AWSDKError(AWSDKErrorCode.unsupportedVideoPlatform,
      'The requested video platform is not supported');
  }
  /**
   * @since 3.3.0
   */
  static AWSDKTwoFactorInvalidCode() {
    return new AWSDKError(AWSDKErrorCode.twoFactorAuthenticationInvalidCode,
      'Incorrect verification code',
      '',
      'Provide valid two-factor authentication code',
      400);
  }
  /**
   * @since 3.3.0
   */
  static AWSDKTwoFactorExpiredCode() {
    return new AWSDKError(AWSDKErrorCode.twoFactorAuthenticationExpiredCode,
      'Expired verification code',
      '',
      'Request a new two-factor authentication code',
      400);
  }
  /**
   * @since 3.3.0
   */
  static AWSDKTwoFactorMaxRetryReached() {
    return new AWSDKError(AWSDKErrorCode.twoFactorAuthenticationMaxRetryReached,
      'Limit for incorrect two-factor code validation attempts has been reached',
      '',
      'Request a new two-factor authentication code',
      400);
  }
  /**
   * @since 3.3.0
   */
  static AWSDKTwoFactorInvalidPhoneNumber() {
    return new AWSDKError(AWSDKErrorCode.twoFactorAuthenticationInvalidPhoneNumber,
      'The phone number provided for two-factor authentication does not have a valid format',
      '',
      'Provide a phone number with a valid format',
      400);
  }
  /**
   * @since 3.3.0
   */
  static AWSDKUnsupportedOperation() {
    return new AWSDKError(AWSDKErrorCode.unsupportedOperation,
      'The operation is not supported, most likely due to server configurations',
      '',
      'Validate whether or not you should be able to perform this action, based on current server configurations');
  }
  /**
   * @since 3.4.0
   */
  static AWSDKRequiredHeightAndWeightMeasurementsMissing() {
    return new AWSDKError(AWSDKErrorCode.requiredHeightAndWeightMeasurementsMissing,
      'Required height and weight measurements missing',
      'Required height and weight measurements for minor patient (<19) must be entered before a visit can be started',
      'Enter height and weight in the measurements',
      409);
  }
  /**
   * @since 3.5.0
   */
  /**
   * Typically returning a http response code 404 indicates a resource is not available on the server.
   * Caretalks is returning a 404 in the case of the SDK failing to provide authentication information to the server.
   * Although unconventional - we may maintain this standard to prevent breaking changes against legacy implementations.
   */
  static AWSDKAuthenticationInfoMissing() {
    return new AWSDKError(AWSDKErrorCode.authenticationInfoMissing,
      'Missing authentication information',
      404);
  }
  /**
   * @since 3.5.0
   */
  static AWSDKAuthorizationForbidden() {
    return new AWSDKError(AWSDKErrorCode.authorizationForbidden,
      'Authorization denied',
      403);
  }
  /**
   * @since 3.5.0
   */
  static AWSDKWaitingRoomPastVideoCallbackThreshold() {
    return new AWSDKError(AWSDKErrorCode.waitingRoomPastVideoCallbackThreshold,
      'Waiting room count is less than the required threshold to use the Video Callback feature',
      400);
  }
  /**
   * @since 3.5.0
   */
  static AWSDKVideoCallbackIVRException() {
    return new AWSDKError(AWSDKErrorCode.videoCallbackIVRException,
      'The Amwell Home platform wasn\'t able to use the provided phone number to initiate the video callback workflow',
      400);
  }
  /**
   * @since 4.0.0
   */
  static AWSDKRequiredAddressMissing() {
    return new AWSDKError(AWSDKErrorCode.requiredAddressMissing,
      'The required consumer\'s address is missing',
      'The Amwell Home platform requires either a consumer\'s primary or shipping address in order to initiate the visit',
      'Update the Consumer profile to include address',
      409);
  }

  get stackTrace() {
    return this.__error.stackTrace || this.__error.stack;
  }
  get errorCode() {
    return this.__errorCode;
  }
  get message() {
    return this.__error.message;
  }
  get reason() {
    return this.__reason;
  }
  get recoverySuggestion() {
    return this.__recoverySuggestion;
  }
  get httpResponseCode() {
    return this.__httpResponseCode;
  }
  set httpResponseCode(httpResponseCode) {
    this.__httpResponseCode = httpResponseCode;
  }
  get errors() {
    return this.__errors;
  }
  set errors(errors) {
    this.__errors = errors || [];
  }
  get fieldName() {
    return this.__fieldName;
  }
  set fieldName(fieldName) {
    this.__fieldName = fieldName;
  }
  get fieldValue() {
    return this.__fieldValue;
  }
  set fieldValue(fieldValue) {
    this.__fieldValue = fieldValue;
  }
}

export default AWSDKError;
