/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import deprecated from 'awcoresdk/lib/util/deprecated';

import AWSDKAddress from '../awsdk_address';
import AWSDKEntity from './../awsdk_entity';
import AWSDKState from '../awsdk_state';
import AWSDKSubscription from '../insurance/awsdk_subscription';
import GenericParser from '../../internal/parser/generic_parser';
import AWSDKError from '../../error/awsdk_error';
import AWSDKGender from './awsdk_gender';
import AWSDKGenderIdentity from './awsdk_gender_identity';

/**
 * The AWSDKConsumer class represents a Consumer object.
 * This class represents both a consumerInfo and a consumerDetails, since the difference between the two is minimal and we, for the most part, only care about consumer details
 * Please note that the consumer object returned with an authentication call lacks certain fields.
 * @property {model.AWSDKAddress} address the consumer's address.
 * @property {model.AWSDKSubscription} subscription the consumer's health plan subscription.
 * @property {model.AWSDKState} legalResidence the consumer's legal residence.
 * @property {String} preferredLocale the consumer's preferred locale.
 * @property {Date} dob the consumer's date of birth
 * @property {String} age the consumer's age
 * @property {String} email the consumer's email address
 * @property {String} firstName the consumer's first name
 * @property {String} lastName the consumer's last name
 * @property {String} middleInitial the consumer's middle initial
 * @property {String} fullName the consumer's full name (first, middle and last)
 * @property {Boolean} registered boolean true if the consumer is registered, false otherwise
 * @property {String} gender the consumer's gender
 * @property {String} localizedGender the consumer's localized gender
 * @property {String} phone the consumer's phone number
 * @property {String} formattedPhone the formatted consumer's phone number
 * @property {String} memberType the type of consumer
 * @property {Boolean} appointmentReminderTextsEnabled boolean true if appointment reminder texts are enabled, false otherwise
 * @property {String} pin the consumer's pin
 * @property {String} feedType the type of feed that added this Consumer to storage
 * @property {model.AWSDKGender} genderEnum the consumer's {@link model.AWSDKGender|AWSDKGender}
 * @property {String} href the link to this Consumer's details
 * @property {String} formularyRestriction any formulary restrictions this provider might have
 * @property {String} patientMrnId the patient's medical record number id
 * @property {Number} lastLogin the last time this consumer logged in
 * @property {Boolean} eligibleForVisit boolean true if the Consumer is eligible for a visit
 * @property {model.AWSDKConsumer} parent if this is a dependent then this property will reference the parent {@link model.AWSDKConsumer|Consumer} object, null otherwise.
 * @property {String} sourceId an ID for the consumer that can be used to associate this account with an account in an external system
 * @property {model.AWSDKConsumer[]} proxies the legal guardians for this Consumer. If a dependent this will be the parents/legal guardians, otherwise it will be an empty array.
 * @property {Boolean} isDependent returns a boolean that specifies whether or not this consumer is a dependent
 * @property {String} timeZone the canonical time zone for this consumer. Example: America/New_York
 * @property {String} sdkUserId the sdkUserId which associates an external ID with this consumer and the SDK configuration in use
 * @property {String[]} brandings an Array of Strings representing a consumer's branding attributes.
 * @property {model.AWSDKGenderIdentity} genderIdentity an object representing the more inclusive gender types supported by a telehealth platform
 * @extends model.AWSDKEntity
 */
class AWSDKConsumer extends AWSDKEntity {
  get address() {
    return GenericParser.parseObject(this.__data.address, AWSDKAddress);
  }

  get subscription() {
    return GenericParser.parseObject(this.__data.subscription, AWSDKSubscription);
  }

  get legalResidence() {
    return GenericParser.parseObject(this.__data.legalResidence, AWSDKState);
  }

  get preferredLocale() {
    return this.__data.preferredLocale;
  }

  get dob() {
    return GenericParser.parseDate(this.__data.dob);
  }

  get age() {
    return this.__data.age;
  }

  get email() {
    return this.__data.email;
  }

  get firstName() {
    return this.__data.firstName;
  }

  get lastName() {
    return this.__data.lastName;
  }

  get middleInitial() {
    return this.__data.middleInitial;
  }

  get middleName() {
    return this.__data.middleName;
  }

  get fullName() {
    return this.__data.fullName;
  }

  get registered() {
    return GenericParser.parseBoolean(this.__data.enrolled);
  }

  get gender() {
    return this.__data.gender;
  }

  get localizedGender() {
    return this.__data.localizedGenderDisplayName;
  }

  get genderEnum() {
    return GenericParser.parseEnum(this.__data.genderEnum, AWSDKGender);
  }

  get phone() {
    return this.__data.phone;
  }

  get formattedPhone() {
    return this.__data.formattedPhone;
  }

  get memberType() {
    return this.__data.memberType;
  }

  get appointmentReminderTextsEnabled() {
    return GenericParser.parseBoolean(this.__data.appointmentReminderTextsEnabled);
  }

  get href() {
    return this.__data.href;
  }

  get pin() {
    return this.__data.pin;
  }

  get feedType() {
    return this.__data.feedType;
  }

  get formularyRestriction() {
    return this.__data.formularyRestriction;
  }

  get patientMrnId() {
    return this.__data.patientMrnId;
  }

  get lastLogin() {
    return this.__data.lastLogin;
  }

  get eligibleForVisit() {
    return GenericParser.parseBoolean(this.__data.eligibleForVisit);
  }

  /**
   * @since 1.2.2
   */
  get sourceId() {
    return this.__data.sourceId;
  }

  /**
   * @since 1.3.0
   */
  get proxies() {
    return (Array.isArray(this.__data.proxies) && this.__data.proxies.map(element => GenericParser.parseObject(element, AWSDKConsumer))) || [];
  }

  set proxies(proxies) {
    if (Array.isArray(proxies) && proxies.every(item => item instanceof AWSDKConsumer)) {
      this.__data.proxies = proxies.map(item => item.__data);
    } else {
      this.__data.proxies = null;
    }
  }

  /**
   * @since 1.3.1
   */
  get isDependent() {
    return this.proxies.length > 0;
  }

  /**
   * @since 1.4.0
   */
  get timeZone() {
    return this.__data.timeZone;
  }

  /**
  * @since 1.5.0
  */
  get brandings() {
    return this.__data.brandings;
  }

  /**
   * @since 2.0.0
   */
  get sdkUserId() {
    return this.__data.sdkUserId;
  }

  /**
   * A {@link model.AWSDKGenderIdentity} if the telehealth platform supports it
   * @since 2.5.0
   * @type {model.AWSDKGenderIdentity}
   */
  get genderIdentity() {
    return GenericParser.parseObject(this.__data.genderIdentity, AWSDKGenderIdentity);
  }
}

export default AWSDKConsumer;
