/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKTwoFactorRequiredAction.
 * @readonly
 * @enum {string}
 * @since 3.3.0
 */
const AWSDKTwoFactorRequiredAction = {
  /**
   * There are no two-factor authentication steps that need to be taken at this time.
   */
  NONE: 'none',

  /**
   * The consumer needs to set up two-factor authentication before they can proceed.
   */
  SETUP: 'setup',

  /**
   * The consumer needs to login using two-factor authentication before they can proceed.
   */
  LOGIN: 'login',
};

Object.freeze(AWSDKTwoFactorRequiredAction);
export default AWSDKTwoFactorRequiredAction;
