/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKDataObject from './awsdk_dataobject';
import AWSDKState from './awsdk_state';
import GenericParser from '../internal/parser/generic_parser';

/**
 * AWSDKCountry class represents a physical country
 * @property {String} code the country code
 * @property {String} name the country name
 * @property {model.AWSDKState[]} states the array of states in the country
 * @extends model.AWSDKDataObject
 */
class AWSDKCountry extends AWSDKDataObject {
  get code() {
    return this.__data.code;
  }

  get name() {
    return this.__data.name;
  }

  get states() {
    return this.__data.states.map(item => GenericParser.parseObject(item, AWSDKState));
  }
}

export default AWSDKCountry;
