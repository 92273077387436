/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKDataObject from '../awsdk_dataobject';
/**
 * AWSDKCreditCardType class represents a credit card type (Amex, Visa, Mastercard, etc) used for payment. It encapsulates information about a type of credit card <br>
 * that can be used to validate the credit card client side.
 * @property {String} name The name of the credit card type
 * @property {String} type The type of credit card. A case-insensitive form of the name property.
 * @property {String} cvvLength The length of the cvv code
 * @property {String} regex The regex to validate the credit card number for this type.
 * @extends model.AWSDKDataObject
 */
class AWSDKCreditCardType extends AWSDKDataObject {
  get name() {
    return this.__data.name;
  }
  get type() {
    return this.__data.type;
  }
  get cvvLength() {
    return this.__data.cvvLength;
  }
  get regex() {
    return this.__data.regex;
  }
}

export default AWSDKCreditCardType;
