/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from '../awsdk_dataobject';

/**
 * AWSDKSubscriptionUpdate class represents the subscription update object pertaining to a given health plan insurance.
 * @property {model.AWSDKHealthPlan} healthPlan the health plan.
 * @property {String} subscriberId the health insurance subscriber id.
 * @property {String} subscriberSuffix the health insurance subscriber suffix.
 * @property {model.AWSDKRelationshipToSubscriberCode} relationshipToSubscriberCode what is relationship with the primary subscriber
 * @property {String} primarySubscriberFirstName the primary subscriber's first name.
 * @property {String} primarySubscriberLastName  the primary subscriber's last name.
 * @property {Date} primarySubscriberDateOfBirth the primary subscriber's date of birth.
 * @property {String} eligibilityRequestId the requestId of the eligibility check.
 * @property {boolean} ignoreEligibilityChecks set this to FALSE unless you have EDI enabled and want to ignore it to force saving of data.
 * @extends model.AWSDKDataObject
 */
class AWSDKSubscriptionUpdate extends AWSDKDataObject {
  constructor(ignoreEligibilityChecks) {
    super();
    this.__data.ignoreEligibilityChecks = ignoreEligibilityChecks;
  }

  get healthPlan() {
    return this.__data.healthPlan;
  }

  get subscriberId() {
    return this.__data.subscriberId;
  }

  get subscriberSuffix() {
    return this.__data.subscriberSuffix;
  }

  get relationshipToSubscriberCode() {
    return this.__data.relationshipToSubscriberCode;
  }

  get primarySubscriberFirstName() {
    return this.__data.primarySubscriberFirstName;
  }

  get primarySubscriberLastName() {
    return this.__data.primarySubscriberLastName;
  }

  get primarySubscriberDateOfBirth() {
    return this.__data.primarySubscriberDateOfBirth;
  }

  get eligibilityRequestId() {
    return this.__data.eligibilityRequestId;
  }

  get ignoreEligibilityChecks() {
    // not using parse boolean because 'undefined' is meaningful here
    return this.__data.ignoreEligibilityChecks;
  }

  set healthPlan(heathPlan) {
    this.__data.healthPlan = heathPlan;
  }

  set subscriberId(subscriberId) {
    this.__data.subscriberId = subscriberId;
  }

  set subscriberSuffix(subscriberSuffix) {
    this.__data.subscriberSuffix = subscriberSuffix;
  }

  set relationshipToSubscriberCode(relationshipToSubscriberCode) {
    this.__data.relationshipToSubscriberCode = relationshipToSubscriberCode;
  }

  set primarySubscriberFirstName(primarySubscriberFirstName) {
    this.__data.primarySubscriberFirstName = primarySubscriberFirstName;
  }

  set primarySubscriberLastName(primarySubscriberLastName) {
    this.__data.primarySubscriberLastName = primarySubscriberLastName;
  }

  set primarySubscriberDateOfBirth(primarySubscriberDateOfBirth) {
    this.__data.primarySubscriberDateOfBirth = primarySubscriberDateOfBirth;
  }

  set eligibilityRequestId(eligibilityRequestId) {
    this.__data.eligibilityRequestId = eligibilityRequestId;
  }

  set ignoreEligibilityChecks(ignoreEligibilityChecks) {
    this.__data.ignoreEligibilityChecks = ignoreEligibilityChecks;
  }
}

export default AWSDKSubscriptionUpdate;
