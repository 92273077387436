import AWCoreSDKVisitLoggingDataObject from 'awcoresdk/lib/visit_logging_data_object';


/**
 * Data object used to pass visit information into the common sdk for logging purposes.
 *
 * @private
 * @extends AWCoreSDKVisitLoggingDataObject
 */
class AWSDKVisitLoggingDataObject extends AWCoreSDKVisitLoggingDataObject {
  /**
   * Constructor for AWSDKVisitLoggingDataObject
   *
   * @param {string} sdkVersion Current version of the provider or consumer SDK.
   * @param {string} language The accept-language used in the header..
   * @param {model.AWSDKVisit} visit The visit.
   * @private
   */
  constructor(sdkVersion, language, visit) {
    super();
    this._language = language;
    this._sdkVersion = sdkVersion;
    this._visit = visit;
  }

  get language() {
    return this._language;
  }

  get disposition() {
    return this._visit.disposition.toString();
  }

  get consumerId() {
    return this._visit.consumer.id.encryptedId;
  }

  get providerId() {
    return this._visit.assignedProvider.id.encryptedId;
  }

  get visitId() {
    return this._visit.id.encryptedId;
  }

  get sdkVersion() {
    return this._sdkVersion;
  }
}

export default AWSDKVisitLoggingDataObject;
