/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from './awsdk_dataobject';
import AWSDKReminderOption from './appointment/awsdk_reminder_option';
import GenericParser from '../internal/parser/generic_parser';
import AWSDKConsumerMiddleNameHandling from './consumer/awsdk_consumer_middle_name_handling';
import AWSDKGenderIdentity from './consumer/awsdk_gender_identity';
import AWSDKTwoFactorConfiguration from './authentication/awsdk_two_factor_configuration';

/**
 * This class represents a systemConfiguration object with all the flags supported by that configuration
 * @extends model.AWSDKDataObject
 */
class AWSDKSystemConfiguration extends AWSDKDataObject {
  /**
   * Returns the configured {@link model.AWSDKConsumerMiddleNameHandling|AWSDKConsumerMiddleNameHandling}
   * @since 1.1.0
   * @type {model.AWSDKConsumerMiddleNameHandling}
   */
  get consumerMiddleNameHandling() {
    return GenericParser.parseEnum(this.__data.consumerMiddleNameHandling, AWSDKConsumerMiddleNameHandling);
  }

  /**
   * TRUE if the server requires the consumer address
   * @since 1.0.0
   * @type {Boolean}
   */
  get consumerAddressRequired() {
    return GenericParser.parseBoolean(this.__data.memberAddressRequired);
  }

  /**
   * TRUE if the server requires the collection of consumer health insurance info
   * @since 1.0.0
   * @type {Boolean}
   */
  get consumerHealthInsuranceCollected() {
    return GenericParser.parseBoolean(this.__data.memberHealthInsuranceCollected);
  }
  /**
   * The login age restriction
   * @since 1.1.0
   * @type {Number}
   */
  get loginAgeRestriction() {
    return this.__data.loginAgeRestriction;
  }
  /**
   * TRUE if the server allows collection of service key information
   * @since 1.0.0
   * @type {Boolean}
   */
  get serviceKeyCollected() {
    return GenericParser.parseBoolean(this.__data.serviceKeyCollected);
  }

  /**
   * The maximum allowed size of an attachment for a secure message in KB
   * @since 1.0.0
   * @type {Number}
   */
  get secureMessageAttachmentMaxSizeKB() {
    return this.__data.secureMessageAttachmentMaxSizeKB;
  }

  /**
   * A list of file extensions that will be rejected by the server
   * Note: it's recommended to use {@link #getMimeTypeWhiteList()} to determine which
   * file types are allowed by the server.<br>
   *
   * @since 1.0.0
   * @type {String}
   */
  get attachmentExtensionRejectList() {
    return this.__data.attachmentExtensionRejectList;
  }

  /**
   * Number of MS representing margin around a
   * {@link VisitSchedule#getScheduledStartTime()}
   * @since 1.0.0
   * @type {Number}
   */
  get scheduledVisitMarginMs() {
    return this.__data.scheduledEngagementMarginMs;
  }

  /**
   * TRUE if the system is configured to allow multiple guests to join a video visit
   * @since 1.0.0
   * @type {Boolean}
   */
  get multipleVideoParticipantsEnabled() {
    return GenericParser.parseBoolean(this.__data.multipleVideoParticipantsEnabled);
  }

  /**
   * The number of maximum guests the consumer can invite to a video visit if {@link
   * #multipleVideoParticipantsEnabled()} = true
   * @since 1.0.0
   * @type {Number}
   */
  get maxVideoInvites() {
    return this.__data.maxVideoInvites;
  }

  /**
   * TRUE if the app should prompt for provider rating for visit summary
   * @since 1.0.0
   * @type {Boolean}
   */
  get showProviderRating() {
    return GenericParser.parseBoolean(this.__data.showProviderRating);
  }

  /**
   * TRUE if collection of ratings after a visit is optional
   * @since 1.0.0
   * @type {Boolean}
   */
  get endVisitRatingsOptional() {
    return GenericParser.parseBoolean(this.__data.endVisitRatingsOptional);
  }

  /**
   * List of locale string identifiers supported by the server
   * @since 1.0.0
   * @type {String[]}
   */
  get supportedLocales() {
    return this.__data.supportedLocales;
  }

  /**
   * The users currency code provided by the server
   * @since 1.0.0
   * @type {String}
   */
  get currencyCode() {
    return this.__data.currencyCode;
  }

  /**
   * List of mimeTypes that are supported as attachments
   * @since 1.0.0
   * @type {String[]}
   */
  get mimeTypeWhitelist() {
    return this.__data.mimeTypeWhitelist;
  }

  /**
   * TRUE if the other visit topic option is available. false otherwise.
   * @since 1.0.0
   * @type {Boolean}
   */
  get otherTopicEnabled() {
    return GenericParser.parseBoolean(this.__data.otherTopicEnabled);
  }

  /**
   * TRUE if the multi-country is enabled on the server.
   * @since 1.0.0
   * @type {Boolean}
   */
  get isMultiCountry() {
    return GenericParser.parseBoolean(this.__data.isMultiCountry);
  }

  /**
   * TRUE if users should be able to cancel appointments and false if the user should not.
   * @since 1.0.0
   * @type {Boolean}
   */
  get cancelAppointmentEnabled() {
    return GenericParser.parseBoolean(this.__data.cancelAppointmentEnabled);
  }

  /**
   * Optional text to be displayed if canceling appointments is disabled as returned by
   * {@link #isCancelAppointmentEnabled}
   * @since 1.0.0
   * @type {String}
   */
  get optionalCancelAppointmentText() {
    return this.__data.optionalCancelAppointmentText;
  }

  /**
   * TRUE if Appointment Readiness has been enabled on the server. False otherwise.
   * @since 1.0.0
   * @type {Boolean}
   */
  get appointmentReadinessEnabled() {
    return GenericParser.parseBoolean(this.__data.appointmentReadinessEnabled);
  }

  /**
   * The list of {@link model.AWSDKReminderOption|AWSDKReminderOption}
   * @since 1.1.0
   * @type {model.AWSDKReminderOption[]}
   */
  get reminderOptions() {
    return this.__data.reminderOptions.map(item => GenericParser.parseObject(item, AWSDKReminderOption));
  }

  /**
   * The list of protected field names
   * @since 1.1.0
   * @type {String[]}
   */
  get protectedFieldNames() {
    return this.__data.protectedFieldNames;
  }

  /**
   * The password invalid characters message string
   * @since 1.2.0
   * @type {String}
   */
  get passwordContainsInvalidCharactersMessage() {
    return this.__data.passwordContainsInvalidCharactersMessage;
  }

  /**
   * A boolean of if the password must contain letters
   * @since 1.2.0
   * @type {Boolean}
   */
  get passwordMustContainLetters() {
    return GenericParser.parseBoolean(this.__data.passwordMustContainLetters);
  }

  /**
   * The password must contain letters message string
   * @since 1.2.0
   * @type {String}
   */
  get passwordRequiresLettersMessage() {
    return this.__data.passwordRequiresLettersMessage;
  }

  /**
   * Returns a boolean of if the password must contain numbers
   * @since 1.2.0
   * @type {Boolean}
   */
  get passwordMustContainNumbers() {
    return GenericParser.parseBoolean(this.__data.passwordMustContainNumbers);
  }

  /**
   * The password must contain numbers message string
   * @since 1.2.0
   * @type {String}
   */
  get passwordRequiresNumbersMessage() {
    return this.__data.passwordRequiresNumbersMessage;
  }

  /**
   * The number of special characters required
   * @since 1.2.0
   * @type {Number}
   */
  get minimumNumberOfSpecialCharactersInPassword() {
    return this.__data.minimumNumberOfSpecialCharactersInPassword;
  }

  /**
   * A boolean of if a password must contain special characters
   * @since 1.2.0
   * @type {Boolean}
   */
  get passwordMustContainSpecialCharacters() {
    return this.__data.minimumNumberOfSpecialCharactersInPassword > 0;
  }

  /**
   * A string of a list of special characters allowed in a password
   * @since 1.2.0
   * @type {String}
   */
  get specialCharacterSetAllowedInPassword() {
    return this.__data.specialCharacterSetAllowedInPassword;
  }

  /**
   * The password must contain special characters message string
   * @since 1.2.0
   * @type {String}
   */
  get passwordRequiresSpecialCharactersMessage() {
    return this.__data.passwordRequiresSpecialCharactersMessage;
  }

  /**
   * The password reset expiration time
   * @since 1.2.0
   * @type {Number}
   */
  get passwordResetExpirationTime() {
    return this.__data.passwordResetExpirationTime;
  }

  /**
   * The minimum length for a password
   * @since 1.2.0
   * @type {Number}
   */
  get minPasswordLength() {
    return this.__data.minPasswordLength;
  }

  /**
   * The maximum length for a password
   * @since 1.2.0
   * @type {Number}
   */
  get maxPasswordLength() {
    return this.__data.maxPasswordLength;
  }

  /**
   * The password too long message string
   * @since 1.2.0
   * @type {String}
   */
  get passwordTooLongMessage() {
    return this.__data.passwordTooLongMessage;
  }

  /**
   * The password too short message string
   * @since 1.2.0
   * @type {String}
   */
  get passwordTooShortMessage() {
    return this.__data.passwordTooShortMessage;
  }

  /**
   * The password regular expression validation string
   * @since 1.2.0
   * @type {String}
   */
  get passwordRegularExpression() {
    return this.__data.passwordRegularExpression;
  }

  /**
   * TRUE if speedPass is enabled
   * @since 1.2.0
   * @type {Boolean}
   */
  get speedPassActive() {
    return GenericParser.parseBoolean(this.__data.speedPassActive);
  }

  /**
   * TRUE if electronicFaxEnabled is enabled
   * @type {Boolean}
   * @since 1.4.0
   */
  get electronicFaxEnabled() {
    return GenericParser.parseBoolean(this.__data.electronicFaxEnabled);
  }

  /**
   * Used for Test My Computer workflows. Intentionally not exposed to third party developers as this is only used internally.
   * @private
   * @type {Object}
   * @since 2.0.0
   */
  get speedTestConfiguration() {
    return this.__data.speedTestConfiguration;
  }

  /**
   * TRUE if option to send visit summary is to be shown, FALSE otherwise
   * @type {Boolean}
   * @since 2.0.0
   */
  get showOptionToSendVisitSummary() {
    return GenericParser.parseBoolean(this.__data.showOptionToSendVisitSummary);
  }

  /**
   * TRUE if the chat tab should be hidden during the visit
   * @type {Boolean}
   * @since 2.0.0
   */
  get alwaysHideChatTabEnabled() {
    return GenericParser.parseBoolean(this.__data.alwaysHideChatTabEnabled);
  }

  /**
   * TRUE if the notes tab should be hidden during the visit
   * @type {Boolean}
   * @since 2.0.0
   */
  get alwaysHideNotesTabEnabled() {
    return GenericParser.parseBoolean(this.__data.alwaysHideNotesTabEnabled);
  }

  /**
   * The audio file URI for the test my computer 'speaker' test
   * @private
   */
  get audioFileUriForTestMyComputerTest() {
    return this.__data.audioFileUriForTestMyComputerTest;
  }

  /**
   * TRUE if screen sharing is enabled on the American Well telehealth platform
   * @type {Boolean}
   * @since 2.5.0
   */
  get screenSharingEnabled() {
    return GenericParser.parseBoolean(this.__data.screenSharingEnabled);
  }


  /**
   * TRUE if browser filtering for screen sharing is disabled.  If a browser is not one of the
   * approved browsers for this feature, this setting will disable screensharing.
   * @type {Boolean}
   * @since 2.5.0
   */
  get screenSharingBrowserFilteringDisabled() {
    return GenericParser.parseBoolean(this.__data.screenSharingBrowserFilteringDisabled);
  }

  /**
   * The customer support phone number for the platform.
   * @type {string}
   * @since 2.0.0
   */
  get formattedCustomerSupportPhone() {
    return this.__data.formattedCustomerSupportPhone;
  }

  /**
   * A setter for the modality vendors configured on the initialization object.
   * @since 2.2.0
   * @type {Array}
   * @private
   */
  set modalityVendors(modalityVendors) {
    this.__modalityVendors = modalityVendors;
  }

  /**
   * The modality vendors configured for this system.
   * @since 2.2.0
   * @type {Array}
   * @private
   */
  get modalityVendors() {
    return this.__modalityVendors;
  }

  /**
   * TRUE if the server is configured to use WebRTC where possible.
   * @type {Boolean}
   * @since 2.1.0
   */
  get webRTCEnabled() {
    return GenericParser.parseBoolean(this.__data.webRTCEnabled);
  }

  /**
   * The server specific URL to download the electron telehealth client
   * @type {string}
   * @since 2.1.0
   * @private
   */
  get electronDownloadUrl() {
    return this.__data.electronDownloadUrl;
  }

  /**
   * The URI to launch the electron telehealth client in test my computer mode
   * @type {string}
   * @since 2.1.0
   * @private
   */
  get electronVideoTestUri() {
    return this.__data.electronVideoTestUri;
  }

  /**
   * The configuration for the TURN servers that the platform supports
   * @private
   * @type {Object[]}
   * @since 2.1.2
   */
  get turnServerConfiguration() {
    return this.__data.turnServerConfiguration;
  }

  /**
   * The maximum bandwidth to allow Pexip web clients to use. Measured in Kbps.
   * @private
   * @type {Number}
   * @since 2.6.0
   */
  get maxWebVideoBandwidthKbps() {
    return this.__data.maxWebVideoBandwidthKbps;
  }

  /**
   * The maximum bandwidth to allow Pexip mobile clients to use. Measured in Kbps.
   * @private
   * @type {Number}
   * @since 2.6.0
   */
  get maxMobileVideoBandwidthKbps() {
    return this.__data.maxMobileVideoBandwidthKbps;
  }

  /**
   * The ICE timeout value passed into WebRTC configuration
   * @private
   * @type {Number}
   * @since 2.6.0
   */
  get iceWebTimeoutSeconds() {
    return this.__data.iceWebTimeoutSeconds;
  }

  /**
   * TRUE if the telehealth platform supports gender identity feature
   * @type {boolean}
   * @since 2.5.0
   */
  get genderSupportEnabled() {
    return GenericParser.parseBoolean(this.__data.genderSupportEnabled);
  }

  /**
   * Returns the configured {@link model.AWSDKTwoFactorConfiguration|AWSDKTwoFactorConfiguration}
   * @since 3.3.0
   * @type {model.AWSDKTwoFactorConfiguration}
   */
  get twoFactorConfiguration() {
    return GenericParser.parseEnum(this.__data.twoFactorAuth, AWSDKTwoFactorConfiguration);
  }

  /**
   * An Array of {@link model.AWSDKGenderIdentity|AWSDKGenderIdentity} representing the supported genderIdentities on the platform
   * @type {model.AWSDKGenderIdentity[]}
   * @since 2.5.0
   */
  get genderIdentities() {
    return (Array.isArray(this.__data.genderIdentities) && this.__data.genderIdentities.map(item => GenericParser.parseObject(item, AWSDKGenderIdentity))) || [];
  }

  /**
   * The maximum total value for a height measurement
   * @type {Number}
   * @since 3.4.0
   */
  get maxTotalHeightMeasurement() {
    return this.__data.maxTotalHeightMeasurement;
  }

  /**
   * The value that the total height measurement must be greater than
   * @type {Number}
   * @since 3.4.0
   */
  get heightMeasurementTotalMustBeGreaterThan() {
    return this.__data.heightMeasurementTotalMustBeGreaterThan;
  }

  /**
   * The maximum height measurement for minor units
   * @type {Number}
   * @since 3.4.0
   */
  get maxHeightMinorMeasurement() {
    return this.__data.maxHeightMinorMeasurement;
  }

  /**
   * The minimum height measurement for minor units
   * @type {Number}
   * @since 3.4.0
   */
  get minHeightMinorMeasurement() {
    return this.__data.minHeightMinorMeasurement;
  }

  /**
   * The maximum total value for a weight measurement
   * @type {Number}
   * @since 3.4.0
   */
  get maxTotalWeightMeasurement() {
    return this.__data.maxTotalWeightMeasurement;
  }

  /**
   * The value that the total weight measurement must be greater than
   * @type {Number}
   * @since 3.4.0
   */
  get weightMeasurementTotalMustBeGreaterThan() {
    return this.__data.weightMeasurementTotalMustBeGreaterThan;
  }

  /**
   * The maximum weight measurement for minor units
   * @type {Number}
   * @since 3.4.0
   */
  get maxWeightMinorMeasurement() {
    return this.__data.maxWeightMinorMeasurement;
  }

  /**
   * The minimum weight measurement for minor units
   * @type {Number}
   * @since 3.4.0
   */
  get minWeightMinorMeasurement() {
    return this.__data.minWeightMinorMeasurement;
  }

  /**
   * The minimum temperature allowed
   * @type {Number}
   * @since 4.2.0
   */
  get minTemperatureMeasurement() {
    return this.__data.minTemperatureMeasurement;
  }

  /**
   * The maximum temperature allowed
   * @type {Number}
   * @since 4.2.0
   */
  get maxTemperatureMeasurement() {
    return this.__data.maxTemperatureMeasurement;
  }

  /**
   * The minimum pulse/heart rate allowed
   * @type {Number}
   * @since 4.2.0
   */
  get minPulseMeasurement() {
    return this.__data.minPulseMeasurement;
  }

  /**
   * The maximum pulse/heart rate allowed
   * @type {Number}
   * @since 4.2.0
   */
  get maxPulseMeasurement() {
    return this.__data.maxPulseMeasurement;
  }

  /**
   * The minimum systolic blood pressure allowed
   * @type {Number}
   * @since 4.2.0
   */
  get minSystolicMeasurement() {
    return this.__data.minSystolicMeasurement;
  }

  /**
   * The maximum systolic blood pressure allowed
   * @type {Number}
   * @since 4.2.0
   */
  get maxSystolicMeasurement() {
    return this.__data.maxSystolicMeasurement;
  }

  /**
   * The minimum diastolic blood pressure allowed
   * @type {Number}
   * @since 4.2.0
   */
  get minDiastolicMeasurement() {
    return this.__data.minDiastolicMeasurement;
  }

  /**
   * The maximum diastolic blood pressure allowed
   * @type {Number}
   * @since 4.2.0
   */
  get maxDiastolicMeasurement() {
    return this.__data.maxDiastolicMeasurement;
  }

  /**
   * Indicates whether or not Quest lab functionality is enabled
   * @type {boolean}
   * @since 4.0.0
   */
   get questLabEnabled() {
    return GenericParser.parseBoolean(this.__data.questLabEnabled);
  }

  /**
   * Indicates whether or not Quest lab functionality was ever enabled. This field may be true even when
   * providers on the platform are unable to order new Quest lab orders, but a consumer may have historical
   * Quest lab orders in the system.  Consumer lab orders can be retrieved with `consumerService.searchConsumerLabOrders`
   * @type {boolean}
   * @since 4.1.0
   */
   get questEverEnabled() {
    return GenericParser.parseBoolean(this.__data.questEverEnabled);
  }

  /**
   * Web scheduling URL for Quest Diagnostics
   * @type {String}
   * @since 4.0.0
   */
  get questWebScheduleUrl() {
    return this.__data.questWebScheduleUrl;
  }

}
export default AWSDKSystemConfiguration;
