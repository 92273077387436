/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKFirstAvailableStatus.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKFirstAvailableStatus = {
  /**
   * The task has started, but no providers have been contacted yet.
   */
  CREATED: 'CREATED',

  /**
   * Awaiting permission from the consumer to contact a provider.
   */
  AWAITING_CONTACT_PERMISSION: 'AWAITING_CONTACT_PERMISSION',

  /**
   * We're contacting a provider and awaiting a response.
   */
  CONTACTING_PROVIDER: 'CONTACTING_PROVIDER',

  /**
   * The provider has accepted the consumer for a conversation. The consumer has a finite time to respond.
   */
  PROVIDER_ACCEPTED: 'PROVIDER_ACCEPTED',

  /**
   * The consumer has acquired a waiting room entry ticket for a provider who accepted them for a conversation.
   */
  TICKET_ACQUIRED: 'TICKET_ACQUIRED',

  /**
   * All eligible providers have been contacted, at least one has not responded, and we are allowing extra time
   * for a response.
   */
  COOL_DOWN: 'COOL_DOWN',

  /**
   * There are no candidate providers left to be contacted.
   */
  PROVIDER_LIST_EXHAUSTED: 'PROVIDER_LIST_EXHAUSTED',
};
Object.freeze(AWSDKFirstAvailableStatus);
export default AWSDKFirstAvailableStatus;
