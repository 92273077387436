/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKDisclaimerEntityType.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKDisclaimerEntityType = {
  /**
   * The disclaimer type
   */
  Disclaimer: 'Disclaimer',
  /**
   * The legal entity type
   */
  LegalEntity: 'LegalEntity',
  /**
   * The specialty legal entity type
   */
  SpecialtyLegalEntity: 'SpecialtyLegalEntity',
};
Object.freeze(AWSDKDisclaimerEntityType);
export default AWSDKDisclaimerEntityType;
