/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKDisposition.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKDisposition = {
  /**
   * This state is meant to be temporary. Allows agenda items created by the provider in the console, to be
   * deleted in provider wrap-up. Also used when assessments are creating items and then later rolls them up
   * together (in the case of visits).
   */
  Unpublished: 'Unpublished',
  /**
   * Will cause an item to show up on a consumer's To-Do list.
   */
  Unscheduled: 'Unscheduled',
  /**
   * A visit which is on the provider's calendar.
   */
  Scheduled: 'Scheduled',
  /**
   * Consumer is waiting for a provider to become available.
   */
  Parked: 'Parked',
  /**
   * Consumer canceled the visit. Can happen by leaving the visit console prior
   * to the provider entering or canceling the appointment from the email.
   */
  ConsumerCanceled: 'ConsumerCanceled',
  /**
   * Provider declines the visit either on the drape or on the review connection details page.
   */
  Declined: 'Declined',
  /**
   * Provider fails respond in time to either the drape or review connection details.
   */
  ProviderResponseTimeout: 'ProviderResponseTimeout',
  /**
   * Initiator is waiting for the provider and may be chatting with an assistant.
   */
  PreVisitScreening: 'PreVisitScreening',
  /**
   * Visit is currently happening between provider and consumer.
   */
  InProgress: 'InProgress',
  /**
   * Provider has finished conversation and consumer is in post-visit with an assistant.
   */
  PostVisitConversation: 'PostVisitConversation',
  /**
   * Provider clicks the cancel button DURING an visit.
   */
  Bailed: 'Bailed',
  /**
   * Provider Disconnected.
   */
  ProviderDisconnected: 'ProviderDisconnected',
  /**
   * Visit has finished, but provider is completing the details.
   */
  ProviderWrapup: 'ProviderWrapup',
  /**
   * Consulting provider wrapped up but primary provider hasn't. Limbo state lasts 24 hours max, P2P only
   */
  ConsultingProviderWrappedUp: 'ConsultingProviderWrappedUp',
  /**
   * Completed.
   */
  Completed: 'Completed',
  /**
   * A failure occurred during background processing and the object should be considered invalid.
   */
  Error: 'Error',
  /**
   * Collapsed and "Deleted". This item has been collapsed into another agenda item, and access to it has been
   * removed. This disposition serves to "soft" delete an item and remaining references to it in the database
   * without removing the actual row.
   */
  CollapseDeleted: 'CollapseDeleted',
  /**
   * Visit is deleted by consumer.
   */
  Deleted: 'Deleted',
  /**
   * Provider Canceled a scheduled visit.
   */
  ProviderCanceled: 'ProviderCanceled',
  /**
   * Consumer Disconnected (pre-threshold). The threshold is the billing threshold. So if a consumer gets disconnected
   * before 5 mins, they don't have to pay - after they do.
   */
  ConsumerDisconnected: 'ConsumerDisconnected',
  /**
   * The Consumer missed a visit scheduled by a provider or assistant.
   */
  Expired: 'Expired',
};

/**
 * @private
 */
AWSDKDisposition.__dispositions = Object.values(AWSDKDisposition);
Object.freeze(AWSDKDisposition);
export default AWSDKDisposition;
