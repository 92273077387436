/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKMessageType.
 * @readonly
 * @enum {string}
 * @since 1.2.0
 */
const AWSDKMessageType = {
  /**
   * The inbox message type
   */
  Inbox: 'Inbox',
  /**
   * The sent message type.
   */
  Sent: 'Sent',
};
Object.freeze(AWSDKMessageType);
export default AWSDKMessageType;
