/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKDataObject from './../awsdk_dataobject';
import AWSDKHealthSummaryRecord from '../health/awsdk_health_summary_record';
import GenericParser from '../../internal/parser/generic_parser';
import AWSDKHealthSummaryPrescriptionRecord from '../health/awsdk_health_summary_prescription_record';

/**
 * The AWSDKHealthSummary class represents a summary of previously gathered health information records for a {@link model.AWSDKConsumer|AWSDKConsumer}
 * @property {model.AWSDKHealthSummaryRecord[]} allergies an array of {@link model.AWSDKHealthSummaryRecord|AWSDKHealthSummaryRecord} objects for allergies
 * @property {model.AWSDKHealthSummaryRecord[]} diagnoses an array of {@link model.AWSDKHealthSummaryRecord|AWSDKHealthSummaryRecord} objects for diagnoses
 * @property {model.AWSDKHealthSummaryRecord[]} procedures an array of {@link model.AWSDKHealthSummaryRecord|AWSDKHealthSummaryRecord} objects for procedures
 * @property {model.AWSDKHealthSummaryPrescriptionRecord[]} prescriptions an array of {@link model.AWSDKHealthSummaryPrescriptionRecord|AWSDKHealthSummaryPrescriptionRecord} objects for prescriptions
 * @extends model.AWSDKDataObject
 */
class AWSDKHealthSummary extends AWSDKDataObject {
  get allergies() {
    return this.__data.allergies.map(item => GenericParser.parseObject(item, AWSDKHealthSummaryRecord));
  }

  get diagnoses() {
    return this.__data.diagnoses.map(item => GenericParser.parseObject(item, AWSDKHealthSummaryRecord));
  }

  get prescriptions() {
    return this.__data.prescriptions.map(item => GenericParser.parseObject(item, AWSDKHealthSummaryPrescriptionRecord));
  }

  get procedures() {
    return this.__data.procedures.map(item => GenericParser.parseObject(item, AWSDKHealthSummaryRecord));
  }
}

export default AWSDKHealthSummary;
