/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKTwoFactorConfiguration.
 * @readonly
 * @enum {string}
 * @since 3.3.0
 */
const AWSDKTwoFactorConfiguration = {
  /**
   * Two-Factor authentication is required.
   */
  REQUIRED: 'required',

  /**
   * Two-Factor authentication is optional.
   */
  OPTIONAL: 'optional',

  /**
   * Two-Factor authentication is disabled.
   */
  DISABLED: 'disabled',
};

Object.freeze(AWSDKTwoFactorConfiguration);
export default AWSDKTwoFactorConfiguration;
