/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2020 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import QRCode from 'qrcode';
import Service from './service';
import AWSDKConsumer from '../model/consumer/awsdk_consumer';
import AWSDKError from '../error/awsdk_error';
import Validator from '../internal/validator/validator';
import AWSDKDevicePairingDetailsResponse from '../internal/model/response/awsdk_device_livestream_pairing_details_response';
import AWSDKDevicePairingStatusResponse from '../internal/model/response/awsdk_device_livestream_pairing_status_response';
import AWSDKDeviceLiveStreamType from '../model/device_live_streaming/awsdk_device_live_stream_type';
/**
 * This service handles everything related to device live streaming integration
 *
 * @since 2.6.0
 * @hideconstructor
 */
class DeviceLiveStreamService extends Service {
  constructor(props) {
    super(props);
    this.__systemConfiguration = props.systemConfiguration;
  }

  /**
   * This method returns the device pairing status, as well as if the device is connected to an online network such as WIFI or cellular.<br>
   *
   * @param {model.AWSDKConsumer} consumer  {@link model.AWSDKConsumer|AWSDKConsumer} to retrieve the device pairing status for.
   * @param {model.AWSDKDeviceLiveStreamType} type device type.
   * @returns {Promise<model.AWSDKDevicePairingStatus|error.AWSDKError>} a promise that resolves to a {@link model.AWSDKDevicePairingStatus|AWSDKDevicePairingStatus} or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.AWSDKInvalidEnum|AWSDKErrorCode.AWSDKInvalidEnum}</td><td>The value of a provided enum is invalid</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 2.6.0
   */
  getDevicePairingStatus(consumer, type) {
    const currentFunction = 'DeviceLiveStreamService.getDevicePairingStatus';
    this.__logger.debug(currentFunction, 'Started', consumer);
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer argument is not of type AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidEnumValue(type, AWSDKDeviceLiveStreamType)) {
      const error = AWSDKError.AWSDKInvalidEnum();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'deviceLiveStreamPairingStatus');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('consumer does not have a valid "deviceLiveStreamStatusPairing" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const options = this.generateOptions('GET', link.url, false);
    options.auth = this.getUserAuth(consumer);
    if (options.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.executeRequest(options, AWSDKDevicePairingStatusResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        return response.devicePairingStatus;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      });
  }

  /**
   * Build the QR Code string optionally based on the user's network SSID and SSID Password.
   *
   * @param {model.AWSDKConsumer} consumer the consumer to build the QR Code for
   * @param {Object} options additional parameters for building the QR Code
   * @param {model.AWSDKDeviceLiveStreamType} options.type the type of the device to generate the QR code for
   * @param {String} [options.SSID] the SSID for the network the device is on
   * @param {String} [options.SSIDPassword] the SSID Password for the network the device is on
   * @returns {Promise<model.AWSDKDevicePairingStatus|error.AWSDKError>} a promise that resolves to a {@link model.AWSDKDevicePairingStatus|AWSDKDevicePairingStatus} or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.AWSDKInvalidEnum|AWSDKErrorCode.AWSDKInvalidEnum}</td><td>The value of a provided enum is invalid</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 2.8.0
   */
  buildQRCodeString(consumer, options = {}) {
    const currentFunction = 'DeviceLiveStreamService.buildQRCodeString';
    this.__logger.debug(currentFunction, 'Started');
    if (!(consumer instanceof AWSDKConsumer)) {
      const error = AWSDKError.AWSDKIllegalArgument('consumer is null or not an instance of AWSDKConsumer');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    if (!Validator.isValidEnumValue(options.type, AWSDKDeviceLiveStreamType)) {
      const error = AWSDKError.AWSDKInvalidEnum();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    const link = this.findNamedLink(consumer.links, 'deviceLiveStreamPairingRequest');
    if (!Validator.isValidLink(link)) {
      const error = AWSDKError.AWSDKInternalError('service does not have a "deviceLiveStreamPairing" link entry');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    const requestOptions = this.generateOptions('POST', link.url, false);
    requestOptions.auth = this.getUserAuth(consumer);
    if (requestOptions.auth == null) {
      const error = AWSDKError.AWSDKConsumerNotAuthenticated();
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }

    requestOptions.headers['Content-Type'] = 'application/json';
    requestOptions.body = JSON.stringify({
      id: consumer.id.encryptedId,
    });
    return this.executeRequest(requestOptions, AWSDKDevicePairingDetailsResponse)
      .then((response) => {
        this.__logger.debug(currentFunction, 'Got response', response);
        this.updateUserAuthEntry(consumer, response.authToken);
        const pairingDetailsObj = {
          pairingCode: response.devicePairingDetails.pairingCode,
          applicationServerUrl: response.devicePairingDetails.applicationServerUrl,
        };
        const pairingDetailsJson = JSON.stringify(pairingDetailsObj);
        const sanitizedSSIdPwd = options.SSIDPassword || '';
        const sanitizedSSId = options.SSID || '';
        const sanitizedSSIdLength = sanitizedSSId.length;
        const sanitizedSSIdPwdLength = sanitizedSSIdPwd.length;
        const unixTime = Math.round(+new Date() / 1000);
        const UTCTime = unixTime.toString();
        const encodedSSID = btoa(sanitizedSSId);
        const encodedSSIDLength = encodedSSID.length === 0 ? '' : encodedSSID.length;
        return `:V4:${sanitizedSSIdPwdLength}::0:,,,${sanitizedSSIdLength},${sanitizedSSId}${sanitizedSSIdPwd},${UTCTime},${encodedSSIDLength},${encodedSSID},${pairingDetailsJson},vExternal`;
      })
      .catch((error) => {
        this.__logger.error(currentFunction, 'Error', error);
        return Promise.reject(error);
      });
  }


  /**
   * A convenience function that generates and appends a QR Code to a provided HTMLElement.
   *
   * @param {model.AWSDKConsumer} consumer {@link model.AWSDKConsumer|AWSDKConsumer} the QR code will be associated with.
   * @param {Object} options additional parameters for building the QR Code
   * @param {model.AWSDKDeviceLiveStreamType} options.type the type of the device to generate the QR code for
   * @param {String} [options.SSID] the SSID for the network the device is on
   * @param {String} [options.SSIDPassword] the SSID Password for the network the device is on
   * @param {HTMLElement} options.container the HTML Element to append the generated QRCode to
   * @returns {Promise<String|error.AWSDKError>} a promise that resolves to a {String} or is rejected with a {@link error.AWSDKError|AWSDKError}
   * <p><br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <thead>
   * <tr><th>Error Code</th><th>reason</th></tr>
   * </thead>
   * <tbody>
   * <tr><td>{@link error.AWSDKErrorCode.AWSDKInvalidEnum|AWSDKErrorCode.AWSDKInvalidEnum}</td><td>The value of a provided enum is invalid</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.consumerNotAuthenticated|AWSDKErrorCode.consumerNotAuthenticated}</td><td>Consumer is not authenticated.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument|AWSDKErrorCode.illegalArgument}</td><td>A parameter is not the correct type.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError|AWSDKErrorCode.internalError}</td><td>The AWSDK could not complete the request.</td></tr>
   * </tbody>
   * </table>
   * @since 2.8.0
   *
   */
  appendQRCode(consumer, options = {}) {
    const currentFunction = 'DeviceLiveStreamService.appendQRCode';
    this.__logger.debug(currentFunction, 'Started');
    if (!(options.container instanceof HTMLElement)) {
      const error = AWSDKError.AWSDKIllegalArgument('options.container is not of type HTMLElement');
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    }
    return this.buildQRCodeString(consumer, options).then((qrCodeString) => {
      options.container.innerHTML = '';
      QRCode.toCanvas(qrCodeString, { errorCorrectionLevel: 'H' }, (err, canvas) => {
        if (err) throw err;
        options.container.appendChild(canvas);
      });
      return true;
    }).catch((error) => {
      this.__logger.error(currentFunction, 'Error', error);
      return Promise.reject(error);
    });
  }
}

export default DeviceLiveStreamService;
