/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2018 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

/**
 * Enum for AWSDKVisitModalityType.
 * @readonly
 * @enum {string}
 * @since 1.4.0
 */
const AWSDKVisitModalityType = {
  /**
   * Video modality type.
   */
  VIDEO: 'VIDEO',
  /**
   * Phone modality type.
   */
  PHONE: 'PHONE',
  /**
   * Chat modality type.
   */
  CHAT: 'CHAT',
};
Object.freeze(AWSDKVisitModalityType);
export default AWSDKVisitModalityType;
