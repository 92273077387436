/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKEntity from './awsdk_entity';
/**
 * AWSDKNamedEntity class represents a known {@link model.AWSDKEntity|AWSDKEntity} that has a name.
 * @property {String} name The full name associated with this object
 * @property {String} firstName The first name associated with this object
 * @property {String} middleInitial The middle initial associated with this object
 * @property {String} lastName The last name associated with this object
 * @extends model.AWSDKEntity
 */
class AWSDKNamedEntity extends AWSDKEntity {
  get name() {
    return this.__data.name;
  }

  /**
   * @since 3.0.0
   */
  get firstName() {
    return this.__data.firstName;
  }

  /**
   * @since 3.0.0
   */
  get middleInitial() {
    return this.__data.middleInitial;
  }

  /**
   * @since 3.0.0
   */
  get lastName() {
    return this.__data.lastName;
  }
}

export default AWSDKNamedEntity;
