/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKError from '../../error/awsdk_error';

class GenericParser {
  /**
   * Parses response to an object.
   *
   * @param {Mixed} val
   * @returns {Klass} object
   */
  static parseJSON(response, Klass) {
    if (response) {
      try {
        return new Klass(JSON.parse(response));
      } catch (e) {
        return AWSDKError.AWSDKParsingError(e.message);
      }
    } else {
      return AWSDKError.AWSDKEmptyResponseError();
    }
  }
  /**
   * Parses value to an enum object.
   *
   * @param {String} val
   * @param {Object} enumType
   * @returns {string} string
   */
  static parseEnum(val, enumType) {
    if (val === null || val === undefined) {
      return val;
    }
    const adjustedVal = val
      .replace(/MEMBER/g, 'CONSUMER')
      .replace(/member/g, 'consumer')
      .replace(/Member/g, 'Consumer')
      .replace(/member/gi, 'CONSUMER')
      .replace(/ENGAGEMENT/g, 'VISIT')
      .replace(/engagement/g, 'visit')
      .replace(/Engagement/g, 'Visit')
      .replace(/engagement/gi, 'VISIT');
    const mappedEnum = enumType[adjustedVal];
    if (!mappedEnum) {
      throw AWSDKError.AWSDKParsingError(`Value (${adjustedVal}) for enum was not parsed correctly or does not exist`);
    }
    return mappedEnum;
  }

  /**
   * Parses string value from an enum (string or symbol), replaces consumer with member and visit with engagement
   *
   * @returns {string} the value that the enum is generated from
   * @throws {Error} if the provided enumValue is not a Symbol, String, or Stringified Symbol
   *
   */
  static parseAndConvertEnumValue(enumValue) {
    if (!enumValue) {
      throw new Error('enumValue is undefined, null, or empty.');
    }

    let parsedValue;
    if (typeof enumValue === 'symbol' || enumValue.includes('Symbol(')) {
      parsedValue = enumValue.toString().match(/Symbol\(([^)]+)\)/)[1];
    } else {
      parsedValue = enumValue;
    }

    return parsedValue.replace(/CONSUMER/g, 'MEMBER')
      .replace(/consumer/g, 'member')
      .replace(/Consumer/g, 'Member')
      .replace(/consumer/gi, 'MEMBER')
      .replace(/VISIT/g, 'ENGAGEMENT')
      .replace(/visit/g, 'engagement')
      .replace(/Visit/g, 'Engagement')
      .replace(/visit/gi, 'ENGAGEMENT');
  }

  /**
   * Parses value to an object.
   *
   * @param {Mixed} value
   * @param {Class} Klass to create
   * @returns {Klass} object
   */
  static parseObject(value, Klass) {
    if (value == null) {
      return null;
    }
    if (value instanceof Klass) {
      return value;
    }
    return new Klass(value);
  }

  /**
   * Parses value to a boolean.
   *
   * @param {Mixed} val
   * @returns {Boolean} true/false
   */
  static parseBoolean(value) {
    let val = value;
    if (val == null) {
      return false;
    }

    if (typeof val === 'boolean') {
      if (val === true) {
        return true;
      }
      return false;
    }

    if (typeof val === 'string') {
      if (val === '') {
        return false;
      }

      val = val.replace(/^\s+|\s+$/g, '');
      if (val.toLowerCase() === 'true' || val.toLowerCase() === 'yes') {
        return true;
      }

      val = val.replace(/,/g, '.');
      val = val.replace(/^\s*-\s*/g, '-');
    }

    if (!isNaN(val)) {
      return (parseFloat(val) !== 0);
    }

    return false;
  }

  /**
   * Parses value to a date.
   *
   * @param {Mixed} value
   * @returns {Date} Date from value
   */
  static parseDate(value) {
    if (value == null || value === '' || value === 'null') {
      return null;
    }
    return new Date(value);
  }
}

export default GenericParser;
