/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import commonVersion from 'awcoresdk/lib/version';

import log from 'loglevel';
import uuid from 'uuid/v4';

import AWSDKCountry from './model/awsdk_country';
import AWSDKSystemConfiguration from './model/awsdk_system_configuration';
import AWSDKCreditCardType from './model/billing/awsdk_credit_card_type';
import AWSDKProviderType from './model/provider/awsdk_provider_type';

import AuthenticationService from './service/authentication_service';
import ConsumerService from './service/consumer_service';
import AWSDKError from './error/awsdk_error';
import PharmacyService from './service/pharmacy_service';
import PracticeService from './service/practice_service';
import ProviderService from './service/provider_service';
import DeviceLiveStreamService from './service/device_live_stream_service';
import VisitService from './service/visit_service';
import AppointmentService from './service/appointment_service';
import SecureMessageService from './service/secure_message_service';

import GenericParser from './internal/parser/generic_parser';
import Logger from './internal/logger/logger';
import InitializationService from './internal/service/initialization_service';
import Validator from './internal/validator/validator';
import version from './version';
import IVRService from './service/ivr_service';
import ServerLogger from './internal/logger/server_logger';
import AWSDKModality from './model/awsdk_modality';
import AWSDKModalityVendor from './model/awsdk_modality_vendor';
import AWSDKPlatformType from './model/awsdk_platform_type';
import TestMyComputerService from './service/test_my_computer_service';
import AWSDKPracticeSearchType from './model/practice/awsdk_practice_search_type';

/**
 * This is the main entry point of the American Well Consumer Web SDK<br>
 *
 * @property {boolean} isConsumer Always true for Consumer SDK instances, as a way to differentiate instances from Provider SDK instances.
 * @property {String} version The version of the SDK.
 */
class AWSDK {
  /**
   * The sdk accepts a logger config object that configures the logger. This is an optional parameter and if not provided, the sdk will default to a logger configured to
   * to output to the console with logging level set to <code>error</code>.
   * @param {Object} loggerConfig the logger configuration.
   * @param {String} loggerConfig.name a string name that identifies the logger. This is the only required property.
   * @param {String} [loggerConfig.level='error'] a string representing the logging level to use. The logger defaults to 'error'. The supported values are: 'fatal', 'error', 'warn', 'info', 'debug' and 'trace'.
   */
  constructor(loggerConfig) {
    this.__logger = new Logger();
    if (loggerConfig != null) {
      this.__logger.setLevel(loggerConfig.level);
    }
    this.__authKeys = [];
    this.__initialized = false;
    this.__initializationService = new InitializationService({ logger: this.__logger });
    this.__serverLogger = new ServerLogger({ logger: this.__logger });
    this.isConsumer = true;
    this.version = version.sdkVersion;
  }

  /**
   * Required call to initialize the AWSDK.<br>
   * Upon success, the AWSDK instance will be initialized and configured.<br>
   * @param {Object} config The configuration used to initialize the sdk.
   * @param {String} config.sdkApiKey The SDK key to use for identifying the client.
   * @param {String} config.baseUrl=https://sdk.myonlinecare.com/ The base American Well telehealth platform URL to target.
   * @param {String} [config.locale=en_US] The locale used for all SDK requests.
   * @param {Number} [config.visitPollingInterval] the interval in milliseconds between requests to update the visit while the visit is started
   * @param {Number} [config.visitCostPollingInterval] the interval in milliseconds between requests to update the visitCost while the visitCost is calculating
   * @param {Number} [config.visitCostPollingTimeout] the timeout in milliseconds to wait for the visitCost to finish calculating
   * @param {Number} [config.eligibilityPollingInterval] the interval in milliseconds between requests to update the eligibility while the insurance subscription is being updated
   * @param {Number} [config.eligibilityPollingTimeout] the timeout in milliseconds to wait for the eligibility request while the insurance subscription is being updated
   * @param {Number} [config.firstAvailablePollingInterval] the interval in milliseconds between requests to determine the status of an ongoing first available search
   * @param {Number} [config.firstAvailablePollingTimeout] the timeout in milliseconds to wait for the first available search to complete
   * @param {Number} [config.launchTelehealthVideoClientTimeout] the timeout in milliseconds to wait for the telehealth video client to launch
   * @param {boolean} [config.isIVRCallbackEnabled] set to TRUE if IVR callback should be enabled for webrtc visits
   * @param {boolean} [config.ignorePropagation] a boolean that determines whether or not a parent's account information should propagate to the dependents. This is set to false by default @since 1.4.0
   * @param {string} [config.uuid] optional uuid that will be generated if not provided and will be sent with each request to the American Well telehealth platform (for logging purposes only).
   * @since 1.0.0
   * @returns {Promise<boolean|error.AWSDKError>} Returns a promise that will be resolved to a boolean or will be rejected with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.initializationException|AWSDKErrorCode.initializationException}</td><td>The SDK failed to initialize</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.versionIncompatible|AWSDKErrorCode.versionIncompatible}</td><td>The Telehealth Platform is running an incompatible version</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationError|AWSDKErrorCode.validationError}</td><td>The provided configuration is invalid.</td></tr>
   * </table>
   */
  initialize(config = {
    sdkApiKey: '',
    baseUrl: 'https://sdk.myonlinecare.com/',
    locale: 'en_US',
    visitPollingInterval: 3000,
    launchTelehealthVideoClientTimeout: 10000 }) {
    // always log version
    const versionLog = log.getLogger('AWSDKVersion');
    versionLog.enableAll();
    versionLog.info(`AWSDK Version: ${version.sdkVersion}`);
    versionLog.info(`AWCommonSDK Version: ${commonVersion.sdkVersion}`);
    if (!config) {
      return Promise.reject(AWSDKError.AWSDKValidationError('Config is invalid'));
    }
    if (!Validator.isValidString(config.baseUrl)) {
      return Promise.reject(AWSDKError.AWSDKValidationError('config.baseUrl is not a valid string'));
    }
    if (config.uuid && !Validator.isValidString(config.uuid)) {
      return Promise.reject(AWSDKError.AWSDKValidationError('config.uuid is not a valid string'));
    }

    this.reset();
    this.__config = config;

    // always check for a trailing slash. All of our URL appending logic internally in the rest of the models depends on this.
    if (this.__config.baseUrl.charAt(this.__config.baseUrl.length - 1) !== '/') {
      this.__config.baseUrl += '/';
    }

    // generate a uuid if not provided
    if (!this.__config.uuid) {
      this.__config.uuid = uuid();
    }

    this.__initializationService.setConfig(this.__config);
    this.__serverLogger.setConfig(this.__config);

    const validateSdkKeyUrl = `${this.__config.baseUrl}restws/api/sdk/`;
    return this.__initializationService.validateSdkApiKey(validateSdkKeyUrl)
      .then((initSDKResponse) => {
        this.__links = initSDKResponse.links;
        this.__initializationService.setLinks(this.__links);
        this.__serverLogger.setLinks(this.__links);
        this.__initialized = true;
        this.__serverLogger.logToServer('info', `SDK API key validation successful. Response: ${initSDKResponse}`);
      })
      .then(() => this.__initializationService.initialization())
      .then((initializationResponse) => {
        this.__countries = initializationResponse.countries;
        this.__creditCardTypes = initializationResponse.creditCardTypes;
        this.__providerTypes = initializationResponse.providerTypes;
        this.__platformTypes = initializationResponse.platformTypes;
        this.__availableModalities = initializationResponse.availableModalities;
        this.__modalityVendors = initializationResponse.modalityVendors;
        this.__practiceSearchTypes = initializationResponse.practiceSearchTypes;
        this.__systemConfiguration = initializationResponse.systemConfiguration;
        if (this.__systemConfiguration) {
          this.__systemConfiguration.modalityVendors = this.__modalityVendors;
        }
        this.__serverLogger.logToServer('info', `SDK initialization successful. Response: ${initializationResponse}`);
        return true;
      });
  }

  /**
   * Re-initializes the AWSDK using a new locale.<br>
   * Upon success, the AWSDK instance will be re-initialized and configured for the new locale.<br>
   * @param {String}  [locale=en_US] The locale used for all SDK requests. See https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.4 for details.
   * @since 1.0.0
   * @returns {Promise<boolean|error.AWSDKError>} Returns a promise that will be resolved to a boolean or will be rejected with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th></tr>
   * <tr><td>{@link error.AWSDKErrorCode.validationRequiredParameterMissing}</td><td>Missing parameter.</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode#initializationException}</td><td>if reinitialization cannot be completed</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode#validationError}</td><td>if the config parameters are invalid.</td></tr>
   * <tr><td>{@link error.AWSDKLocaleNotSupported#localeNotSupported}</td><td>locale is not supported by caretalks.</td></tr>
   * </table>
   */
  changeLocale(locale) {
    if (locale == null) {
      return Promise.reject(AWSDKError.AWSDKValidationRequiredParameterMissing('locale'));
    }
    if (!Validator.isValidString(locale)) {
      return Promise.reject(AWSDKError.AWSDKValidationError('locale'));
    }
    if (this.__systemConfiguration && !this.__systemConfiguration.supportedLocales.includes(locale)) {
      return Promise.reject(AWSDKError.AWSDKLocaleNotSupported());
    }
    const config = this.__config;
    config.locale = locale;
    this.reset();
    return this.initialize(config);
  }

  /*
   * This method will reset the sdk
   * @private
   */
  reset() {
    this.__initialized = false;
    this.__authenticationService = undefined;
    this.__consumerService = undefined;
    this.__pharmacyService = undefined;
    this.__practiceService = undefined;
    this.__providerService = undefined;
    this.__deviceLiveStreamService = undefined;
    this.__visitService = undefined;
    this.__appointmentService = undefined;
    this.__secureMessageService = undefined;
    this.__IVRService = undefined;
    this.__TestMyComputerService = undefined;
    this.__countries = undefined;
    this.__creditCardTypes = undefined;
    this.__providerTypes = undefined;
    this.__availableModalities = undefined;
    this.__modalityVendors = undefined;
    this.__systemConfiguration = undefined;
    this.__platformTypes = undefined;
    this.__practiceSearchTypes = undefined;
  }

  // Getters/Setters
  get initialized() {
    return this.__initialized;
  }

  /**
   * Get an {@link service.AuthenticationService}
   * @type {service.AuthenticationService}
   */
  get authenticationService() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    // throw exception if not initialized
    if (this.__authenticationService === undefined) {
      this.__authenticationService = new AuthenticationService({ config: this.__config, logger: this.__logger, links: this.__links, authKeys: this.__authKeys, serverLogger: this.__serverLogger });
    }
    return this.__authenticationService;
  }

  /**
   * get a {@link service.ConsumerService}
   * @type {service.ConsumerService}
   */
  get consumerService() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__consumerService === undefined) {
      this.__consumerService = new ConsumerService({ config: this.__config, logger: this.__logger, links: this.__links, authKeys: this.__authKeys, systemConfiguration: this.__systemConfiguration, countries: this.__countries, creditCardTypes: this.__creditCardTypes, serverLogger: this.__serverLogger });
    }
    return this.__consumerService;
  }

  /**
   * Get a {@link service.PharmacyService}
   * @type {service.PharmacyService}
   */
  get pharmacyService() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__pharmacyService === undefined) {
      this.__pharmacyService = new PharmacyService({ config: this.__config, logger: this.__logger, links: this.__links, authKeys: this.__authKeys, serverLogger: this.__serverLogger });
    }
    return this.__pharmacyService;
  }

  /**
   * Get a {@link service.PracticeService}
   * @type {service.PracticeService}
   */
  get practiceService() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__practiceService === undefined) {
      this.__practiceService = new PracticeService({ config: this.__config, logger: this.__logger, links: this.__links, authKeys: this.__authKeys, serverLogger: this.__serverLogger });
    }
    return this.__practiceService;
  }

  /**
   * Get a {@link service.ProviderService}
   * @type {service.ProviderService}
   */
  get providerService() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__providerService === undefined) {
      this.__providerService = new ProviderService({ config: this.__config, logger: this.__logger, links: this.__links, authKeys: this.__authKeys, serverLogger: this.__serverLogger });
    }
    return this.__providerService;
  }

  /**
   * Get a {@link service.DeviceLiveStreamService}
   * @type {service.DeviceLiveStreamService}
   */
  get deviceLiveStreamService() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__deviceLiveStreamService === undefined) {
      this.__deviceLiveStreamService = new DeviceLiveStreamService({ config: this.__config, logger: this.__logger, links: this.__links, authKeys: this.__authKeys, serverLogger: this.__serverLogger });
    }
    return this.__deviceLiveStreamService;
  }

  /**
   * Get a {@link service.VisitService}
   * @type {service.VisitService}
   */
  get visitService() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__visitService === undefined) {
      this.__visitService = new VisitService({ config: this.__config, logger: this.__logger, links: this.__links, authKeys: this.__authKeys, systemConfiguration: this.__systemConfiguration, serverLogger: this.__serverLogger, modalityVendors: this.__modalityVendors });
    }
    return this.__visitService;
  }

  /**
   * Get a {@link service.AppointmentService}
   * @type {service.AppointmentService}
   */
  get appointmentService() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__appointmentService === undefined) {
      this.__appointmentService = new AppointmentService({ config: this.__config, logger: this.__logger, links: this.__links, authKeys: this.__authKeys, systemConfiguration: this.__systemConfiguration, serverLogger: this.__serverLogger });
    }
    return this.__appointmentService;
  }

  /**
   * Get a {@link service.SecureMessageService}
   * @type {service.SecureMessageService}
   */
  get secureMessageService() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__secureMessageService === undefined) {
      this.__secureMessageService = new SecureMessageService({ config: this.__config, logger: this.__logger, links: this.__links, authKeys: this.__authKeys, systemConfiguration: this.__systemConfiguration, serverLogger: this.__serverLogger });
    }
    return this.__secureMessageService;
  }

  /**
   * Get a {@link service.IVRService}
   * @type {service.IVRService}
   */
  get IVRService() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__IVRService === undefined) {
      this.__IVRService = new IVRService({ config: this.__config, logger: this.__logger, links: this.__links, authKeys: this.__authKeys, systemConfiguration: this.__systemConfiguration, serverLogger: this.__serverLogger });
    }
    return this.__IVRService;
  }

  /**
   * Get a {@link service.TestMyComputerService}
   */
  get testMyComputerService() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__TestMyComputerService === undefined) {
      this.__TestMyComputerService = new TestMyComputerService({ config: this.__config, logger: this.__logger, links: this.__links, authKeys: this.__authKeys, systemConfiguration: this.__systemConfiguration, serverLogger: this.__serverLogger, appointmentService: this.appointmentService });
    }
    return this.__TestMyComputerService;
  }

  /**
   * This method will get an array of {@link model.AWSDKCreditCardType}<br>
   * @returns {Promise<model.AWSDKCreditCardType[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKCreditCardType} or will
   * be rejected with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.uninitialized}</td><td>Error if the AWSDK has not been initialized</td></tr>
   * </table>
   */
  getCreditCardTypes() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__creditCardTypes === undefined) {
      return this.__initializationService.getCreditCardTypes()
        .then((creditCardTypes) => {
          this.__creditCardTypes = creditCardTypes;
          return creditCardTypes;
        });
    }
    return Promise.resolve(this.__creditCardTypes);
  }

  /**
   * This method will get an array of  {@link model.AWSDKLanguage}<br>
   * @returns {Promise<model.AWSDKLanguage[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKLanguage} or will
   * be rejected with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.uninitialized}</td><td>Error if the AWSDK has not been initialized</td></tr>
   * </table>
   * @since 1.1.0
   */
  getLanguages() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__languages === undefined) {
      return this.__initializationService.getLanguages()
        .then((languages) => {
          this.__languages = languages;
          return languages;
        });
    }
    return Promise.resolve(this.__languages);
  }

  /**
   *
   * This method will get an array of  {@link model.AWSDKCountry}<br>
   * @returns {Promise<model.AWSDKCountry[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKCountry} or will
   * be rejected with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.uninitialized}</td><td>Error if the AWSDK has not been initialized</td></tr>
   * </table>
   */
  getCountries() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__countries === undefined) {
      return this.__initializationService.getCountries()
        .then((countries) => {
          this.__countries = countries;
          return countries;
        });
    }
    return Promise.resolve(this.__countries);
  }

  /**
   * This method will get an array of {@link model.AWSDKHealthPlan}<br>
   * @returns {Promise<model.AWSDKHealthPlan[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKHealthPlan} or will
   * be rejected with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.uninitialized}</td><td>Error if the AWSDK has not been initialized</td></tr>
   * </table>
   */
  getHealthPlans() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__healthPlans === undefined) {
      return this.__initializationService.getHealthPlans()
        .then((healthPlans) => {
          this.__healthPlans = healthPlans;
          return healthPlans;
        });
    }
    return Promise.resolve(this.__healthPlans);
  }

  /**
   * This method will get an array of {@link model.AWSDKRelationshipToSubscriberCode}<br>
   * @returns {Promise<model.AWSDKRelationshipToSubscriberCode[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKRelationshipToSubscriberCode} or will
   * be rejected with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.uninitialized}</td><td>Error if the AWSDK has not been initialized</td></tr>
   * </table>
   */
  getRelationshipsToSubscriber() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__relationships === undefined) {
      return this.__initializationService.getRelationshipsToSubscriber()
        .then((relationships) => {
          this.__relationships = relationships;
          return relationships;
        });
    }
    return Promise.resolve(this.__relationships);
  }

  /**
   * This method will get an array of {@link model.AWSDKTopicType}<br>
   * @returns {Promise<model.AWSDKTopicType[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKTopicType} or will
   * be rejected with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.uninitialized}</td><td>Error if the AWSDK has not been initialized</td></tr>
   * </table>
   */
  getTopicTypes() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__topicTypes === undefined) {
      return this.__initializationService.getTopicTypes()
        .then((topicTypes) => {
          this.__topicTypes = topicTypes;
          return topicTypes;
        });
    }
    return Promise.resolve(this.__topicTypes);
  }

  /**
   * This method will get an array of {@link model.AWSDKProviderType}<br>
   * @returns {Promise<model.AWSDKProviderType[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKProviderType} or will
   * be rejected with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.uninitialized}</td><td>Error if the AWSDK has not been initialized</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError}</td><td>Error if the AWSDK found an issue with itself</td></tr>
   * </table>
   */
  getProviderTypes() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__providerTypes === undefined) {
      return this.__initializationService.getProviderTypes()
        .then((providerTypes) => {
          this.__providerTypes = providerTypes;
          return providerTypes;
        });
    }
    return Promise.resolve(this.__providerTypes);
  }

  /**
   * This method will get an array of {@link model.AWSDKPlatformType|AWSDKPlatformType}
   * @returns {Promise<model.AWSDKPlatformType[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKPlatformType} or will
   * be rejected with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.uninitialized}</td><td>Error if the AWSDK has not been initialized</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError}</td><td>Error if the AWSDK found an issue with itself</td></tr>
   * </table>
   * @since 1.4.0
   */
  getPlatformTypes() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__platformTypes === undefined) {
      return this.__initializationService.getPlatformTypes()
        .then((platformTypes) => {
          this.__platformTypes = platformTypes;
          return platformTypes;
        });
    }
    return Promise.resolve(this.__platformTypes);
  }

  /**
   * This method will get an array of {@link model.AWSDKPracticeSearchType|AWSDKPracticeSearchType}
   * @returns {Promise<model.AWSDKPracticeSearchType[]>} Returns a promise that will be resolved to an array of {@link model.AWSDKPracticeSearchType}.
   * @since 3.0.0
   */
  getPracticeSearchTypes() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    const practiceSearchTypes = Array.isArray(this.__practiceSearchTypes) ? this.__practiceSearchTypes.map(item => GenericParser.parseObject(item, AWSDKPracticeSearchType)) : [];
    return Promise.resolve(practiceSearchTypes);
  }

  /**
   * This method will get an array of {@link model.AWSDKTrackerTemplate|AWSDKTrackerTemplate}<br>
   * @param {String} [searchTerm] any sequence of characters to be used to search against.
   * @returns {Promise<model.AWSDKTrackerTemplate[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKTrackerTemplate|AWSDKTrackerTemplate} or will
   * be rejected with an {@link error.AWSDKError|AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.uninitialized}</td><td>Error if the AWSDK has not been initialized</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.internalError}</td><td>Error if the AWSDK found an issue with itself</td></tr>
   * <tr><td>{@link error.AWSDKErrorCode.illegalArgument}</td><td>Error if a bad argument is passed into the method</td></tr>
   * </table>
   * @since 1.4.0
   */
  searchTrackerTemplates(searchTerm) {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    return this.__initializationService.searchTrackerTemplates(searchTerm)
      .then((trackerTemplates) => {
        this.__trackerTemplates = trackerTemplates;
        return trackerTemplates;
      });
  }

  /**
   * This method will get an {@link model.AWSDKSystemConfiguration}<br>
   * @returns {model.AWSDKSystemConfiguration|error.AWSDKError} Returns an object that will be a {@link model.AWSDKSystemConfiguration} or will
   * be fail with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.uninitialized}</td><td>Error if the AWSDK has not been initialized</td></tr>
   * </table>
   */
  getSystemConfiguration() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    return this.__systemConfiguration;
  }

  /**
   * This method will get an array of {@link model.AWSDKModality}<br>
   * @returns {Promise<model.AWSDKModality[]|error.AWSDKError>} Returns a promise that will be resolved to an array of {@link model.AWSDKModality} or will
   * be rejected with an {@link error.AWSDKError}.
   * <br>Potential Error Codes<br>
   * <p><table summary="ErrorCodes" border="1">
   * <tr><th>Error Code</th><th>reason</th>
   * <tr><td>{@link error.AWSDKErrorCode.uninitialized}</td><td>Error if the AWSDK has not been initialized</td></tr>
   * </table>
   * @since 1.4.0
   */
  getAvailableModalities() {
    if (this.__initialized !== true) {
      throw AWSDKError.AWSDKInitializationError();
    }
    if (this.__availableModalities === undefined) {
      return this.__initializationService.getAvailableModalities()
        .then((availableModalities) => {
          this.__availableModalities = availableModalities;
          return availableModalities;
        });
    }
    return Promise.resolve(this.__availableModalities);
  }

  get instanceState() {
    const authKeys = this.__authKeys;
    const config = this.__config;
    const links = this.__links;
    const initialized = this.__initialized;
    const systemConfiguration = (this.__systemConfiguration !== undefined) ? this.__systemConfiguration.toString() : null;
    const countries = (this.__countries !== undefined) ? this.__countries.map(item => item.toString()) : null;
    const creditCardTypes = (this.__creditCardTypes !== undefined) ? this.__creditCardTypes.map(item => item.toString()) : null;
    const providerTypes = (this.__providerTypes !== undefined) ? this.__providerTypes.map(item => item.toString()) : null;
    const availableModalities = (this.__availableModalities !== undefined) ? this.__availableModalities.map(item => item.toString()) : null;
    const platformTypes = (this.__platformTypes !== undefined) ? this.__platformTypes.map(item => item.toString()) : null;
    const practiceSearchTypes = (this.__practiceSearchTypes !== undefined) ? this.__practiceSearchTypes.map(item => item.toString()) : null;
    const modalityVendors = (this.__modalityVendors !== undefined) ? this.__modalityVendors.map(item => item.toString()) : null;
    return JSON.stringify({ config, links, authKeys, initialized, systemConfiguration, countries, creditCardTypes, providerTypes, availableModalities, platformTypes, modalityVendors, practiceSearchTypes });
  }

  restoreInstanceState(state) {
    const newState = JSON.parse(state);
    this.__config = newState.config;
    this.__links = newState.links;
    this.__authKeys = newState.authKeys;
    if (newState.systemConfiguration != null) {
      this.__systemConfiguration = GenericParser.parseObject(JSON.parse(newState.systemConfiguration), AWSDKSystemConfiguration);
    }
    if (newState.countries != null) {
      this.__countries = newState.countries.map(item => GenericParser.parseObject(JSON.parse(item), AWSDKCountry));
    }
    if (newState.creditCardTypes != null) {
      this.__creditCardTypes = newState.creditCardTypes.map(item => GenericParser.parseObject(JSON.parse(item), AWSDKCreditCardType));
    }
    if (newState.providerTypes != null) {
      this.__providerTypes = newState.providerTypes.map(item => GenericParser.parseObject(JSON.parse(item), AWSDKProviderType));
    }
    if (newState.availableModalities != null) {
      this.__availableModalities = newState.availableModalities.map(item => GenericParser.parseObject(JSON.parse(item), AWSDKModality));
    }
    if (newState.platformTypes != null) {
      this.__platformTypes = newState.platformTypes.map(item => GenericParser.parseObject(JSON.parse(item), AWSDKPlatformType));
    }
    if (newState.practiceSearchTypes != null) {
      this.__practiceSearchTypes = newState.practiceSearchTypes.map(item => GenericParser.parseObject(JSON.parse(item), AWSDKPracticeSearchType));
    }
    if (newState.modalityVendors !== null && Array.isArray(newState.modalityVendors)) {
      this.__modalityVendors = newState.modalityVendors.map(item => GenericParser.parseObject(JSON.parse(item), AWSDKModalityVendor));
    }
    if (this.__systemConfiguration) {
      this.__systemConfiguration.modalityVendors = this.__modalityVendors;
    }
    this.__initializationService.setConfig(this.__config);
    this.__initializationService.setLinks(this.__links);
    this.__serverLogger.setConfig(this.__config);
    this.__serverLogger.setLinks(this.__links);
    this.__initialized = GenericParser.parseBoolean(newState.initialized);
  }
}

export default AWSDK;
