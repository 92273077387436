/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */

import AWSDKResource from './awsdk_resource';
/**
 * AWSDKLanguage class represents a language object with all necessary information to successfully describe a language.
 * See {@link model.AWSDKResource|AWSDKResource} for details.
 * @extends model.AWSDKResource
 */
class AWSDKLanguage extends AWSDKResource {}

export default AWSDKLanguage;
