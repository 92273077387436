/*!
 * American Well Consumer Web SDK
 *
 * Copyright © 2017 American Well.
 * All rights reserved.
 *
 * It is illegal to use, reproduce or distribute
 * any part of this Intellectual Property without
 * prior written authorization from American Well.
 */
import AWSDKEntity from './../awsdk_entity';
import AWSDKPayerInfo from './awsdk_payer_info';
import GenericParser from '../../internal/parser/generic_parser';

/**
 * The AWSDKEligibilityCheck represents an eligibility check object
 * @property {String} eligibilityRequestId the id of the request
 * @property {String} eligibilityCheckRequired whether or not the check was required
 * @property {String} eligibilityCheckCompleted whether or not the check was completed
 * @property {String} eligibilityError error associated with the check
 * @extends model.AWSDKEntity
 */
class AWSDKEligibilityCheck extends AWSDKEntity {
  get eligibilityRequestId() {
    return this.__data.eligibilityRequestId;
  }

  get eligibilityCheckRequired() {
    return GenericParser.parseBoolean(this.__data.eligibilityCheckRequired);
  }

  get eligibilityCheckCompleted() {
    return GenericParser.parseBoolean(this.__data.eligibilityCheckCompleted);
  }

  get eligibilityError() {
    return this.__data.eligibilityError;
  }
}

export default AWSDKEligibilityCheck;
